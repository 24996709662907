import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export class Notification {
  date: string
  name: string;
  description?: string;
  color?: string;
  status?: string
  link?: string
  vehicle?: string

  get dateFormatted (): string {
    const { date } = this
    return dayjs(date).format('MMMM D [de] YYYY [a las] hh:mm A').charAt(0).toUpperCase() + dayjs(date).format('MMMM D [de] YYYY [a las] hh:mm A').slice(1)
  }
}
