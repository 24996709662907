import gql from 'graphql-tag'

import { PaymentFragment } from '@/graphql/fragments/finance'
import { PersonFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${PaymentFragment}
  ${PersonFragment}
  query fetchPayment($id: Int!) {
    records: finance_payment_by_pk(id: $id) {
      ...Payment
      deal{
        id
        idLead: id_lead
        lead{
          id
          executive{
            id
            person{
              ...Person
            }
          }
        }
        stock{
          id
        }
      }
    }
  }
`

export default query
