
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { TextCellOptions } from '@/components/dataTables/cell/index'

@Component
  export default class TextCell extends Cell {
  declare options: TextCellOptions

  get align () {
    const { options } = this
    if (!options?.align) return 'center'

    return options.align
  }

  get textSplitted () {
    const { item } = this
    if (!item) return false

    return Boolean(item?.toString()?.includes('('))
  }

  get text1 () {
    const { item } = this
    return item?.toString()?.split('(')?.[0]
  }

  get text2 () {
    const { item } = this
    return `(${item?.toString()?.split('(')?.[1]?.replace(')', '')})`
  }
  }
