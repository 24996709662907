
  import { Component } from 'vue-property-decorator'
  import { GPdf } from '@/components/dataTables/PDF/GPdf'
  import jsPDF from 'jspdf'
  import { LinkedCellOptions } from '@/components/dataTables/cell/index'
  import { plainToInstance } from 'class-transformer'
  import dayjs from 'dayjs'
  import { PurchaseOrder } from '@/entities/purchase'
  import { mapGetters } from 'vuex'
  import { fixPrice, fixThousands } from '@/utils/general'
  import { DateGenerator } from '@/utils/date/DateGenerator'

@Component({
  computed: {
    ...mapGetters('app', ['system']),
  },
})
  export default class purchasePDF extends GPdf {
  declare options: LinkedCellOptions
  loading = false;
  imgUrl = '@/assets/companyLogo/logo.jpg'; // URL de la imagen
  carPhoto = ''
  purchaseOrder = plainToInstance(PurchaseOrder, {})
  disabledPdf = true

  system!: string

  async mounted () {
    const { item: { id } } = this
    this.purchaseOrder = await this.fetchData({
      query: { name: 'fetch', model: 'PurchaseOrder', params: { id } },
      force: true,
    })

    this.disabledPdf = this.purchaseOrder.status.status.name !== 'approved'
  }

  async docHandler () {
    this.loading = true

    this.purchaseOrder.negotiation = await this.fetchData({
      query: { name: 'fetch', model: 'Negotiation', params: { id: this.purchaseOrder.negotiation.id } },
      force: true,
    })

    this.purchaseOrder.negotiation.inspection.appraisal.deal = await this.fetchData({
      query: { name: 'fetch', model: 'Deal', params: { id: this.purchaseOrder.negotiation.inspection.appraisal.deal.id } },
      force: true,
    })

    this.purchaseOrder.negotiation.inspection.appraisal.deal.stock = await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: { deals: { id: { _eq: this.purchaseOrder.negotiation.inspection.appraisal.deal.id } } },
    })

    const identifier = (await this.fetchData({
      query: { name: 'findLite', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Identificación' } },
    }))[0]

    const color = identifier.components.find(component => component.name === 'Color')

    const component = (await this.fetchData({
      query: { name: 'find', model: 'InspectionView' },
      filter: {
        _and: [
          { id_inspection: { _eq: this.purchaseOrder.negotiation.inspection?.id } },
          { id_inspection_component: { _eq: color.id } },
        ],
      },
      force: true,
    }))[0]

    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection_inspected_component' } },
    }))[0]

    const file = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: component?.idInspectedComponent } },
          { parameter: { process: { id: { _eq: process?.id } } } },
          { parameter: { file_type: { name: { _eq: 'photo' } } } },
        ],
      },
      force: true,
    })

    this.carPhoto = await this.loadImage(file[0]?.file?.largeUrl)

    await this.getDocData()
    this.loading = false
  }

  async generatePDF (fileName) {
    const JsPDF = jsPDF
    const doc = new JsPDF('p', 'mm', 'a2')

    await doc.html(this.$refs.pdfContent)
    doc.save(fileName)
    this.loading = false
  }

  async getPayments () {
    const paymentItems = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: { id_deal: { _eq: this.purchaseOrder.negotiation.inspection.appraisal.deal.id } },
      force: true,
    })
    const payments = []

    for (const payment of paymentItems) {
      payment.paymentRecipient = await this.fetchData({
        query: { name: 'fetch', model: 'PaymentRecipient', params: { id: payment.idPaymentRecipient } },
        force: true,
      })
      payments.push({
        type: payment.paymentRecipient.paymentOrderItem.title,
        recipient: payment?.paymentRecipient?.person?.fullName?.toUpperCase() || 'No informado',
        method: payment.type?.description || 'No informado',
        bank: payment.recipientAccount?.bankData?.bank?.alias || 'N/A',
        accountNumber: payment.recipientAccount?.bankData?.accountNumber || 'N/A',
        amount: payment.amount,
      })
    }
    this.$set(this.docData, 'payments', payments)
  }

  async getDocData () {
    this.getMetadata()
    this.getPrices()
    this.getExecutive()
    await this.getCarData()
    await this.getContract()
    await this.getSystem()
    await this.getInterveners()
    await this.getDocumentation()
    await this.getPayments()
  }

  getExecutive () {
    const { capitalizeFirstLetter } = this
    const executive = this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.executive.person

    this.$set(this.docData, 'executive', {
      fullName: `${capitalizeFirstLetter(executive?.firstName.trim())} ${capitalizeFirstLetter(executive?.surname.trim())}` || 'No informado',
      phone: executive?.phoneWork || 'Teléfono no informado',
      email: executive?.email.work || 'Email no informado',
      uid: executive?.uid,
    })
  }

  async getDocumentation () {
    const inspection = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: this.purchaseOrder.negotiation.inspection.id } },
      force: true,
    })

    const category = (await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Documentación' } },
    }))[0]

    const soap = this.findComponentBySlug(category.components, 'soap')
    const technicalReview = this.findComponentBySlug(category.components, 'technical_review')
    const circulationPermit = this.findComponentBySlug(category.components, 'circulation_permit')

    const soapInfo = this.findComponentInfo(soap, inspection)
    const technicalInfo = this.findComponentInfo(technicalReview, inspection)
    const circulationInfo = this.findComponentInfo(circulationPermit, inspection)

    this.$set(this.docData, 'documentation', {
      circulationPermit: {
        city: circulationInfo.name,
        expiracy: dayjs(this.formatDate(circulationInfo.date)).format('DD/MM/ YYYY'),
      },
      technicalReview: {
        company: technicalInfo.name,
        expiracy: dayjs(this.formatDate(technicalInfo.date)).format('DD/MM/ YYYY'),
      },
      soap: {
        company: soapInfo.name,
        expiracy: dayjs(this.formatDate(soapInfo.date)).format('DD/MM/ YYYY'),
      },
    })
  }

  async getInterveners () {
    const document = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: { id_process_record: { _eq: this.purchaseOrder.id } },
    }))[0]

    const intervener = document.interveners.filter(intervener => intervener.field.name === 'sale_representative')?.[0]?.person
    const owner = this.purchaseOrder.negotiation.inspection.appraisal.deal.ownership.owners[0].person

    const address = (await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { id_person: { _eq: owner.id } },
    }))?.[0]
    const addressString = address ? `${address?.address?.streetName} ${address?.address?.streetNumber}, ${address?.address?.city?.name}`.toUpperCase() : undefined

    const intervenerAddress = (await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { id_person: { _eq: intervener?.id } },
    }))?.[0]
    const intervenerAddressString = address ? `${intervenerAddress?.address?.streetName || ''}${intervenerAddress?.address?.streetNumber ? ',' : ''} ${intervenerAddress?.address?.streetNumber || ''} ${intervenerAddress?.address?.city?.name || ''}`.toUpperCase() : undefined

    this.$set(this.docData, 'owner', {
      name: owner.fullName,
      uid: owner.uid,
      address: addressString,
      phone: owner.type.name === 'company' ? owner.secondaryPhone?.work : owner.mainPhone,
    })
    this.$set(this.docData, 'intervener', intervener ? {
      name: intervener.alias || intervener.fullName,
      uid: intervener.uid,
      address: intervenerAddressString,
      phone: intervener.type.name === 'company' ? intervener.secondaryPhone?.work : intervener.mainPhone,
    } : undefined)
  }

  async getContract () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'purchase_order' } },
    })

    const document = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: this.purchaseOrder.id } }, { parameter: { process: { id: { _eq: process[0].id } } } }, { parameter: { name: { _eq: this.purchaseOrder?.supportDocumentType?.name } } }] },
      force: true,
    })

    const type = this.purchaseOrder?.supportDocumentType?.description || 'No informado'
    const link = document?.[0]?.file?.sourceLink?.split('#')?.[0] || 'No informado'

    this.$set(this.docData, 'contract', {
      type,
      link,
    })
  }

  getPrices () {
    const agreedAmount = this.purchaseOrder.agreedAmount
    this.$set(this.docData, 'prices', {
      purchase: fixPrice(agreedAmount),
    })
  }

  getMetadata () {
    const { purchaseOrder } = this
    const id = `${dayjs(purchaseOrder.createdAt).format('YYYY')}${'0'.repeat(9 - String(purchaseOrder.id).length)}${purchaseOrder.id}`
    const date = dayjs(purchaseOrder.createdAt).format('DD /MM /YYYY')

    this.$set(this.docData, 'metadata', {
      id,
      date,
    })
  }

  async getCarData () {
    const deal = this.purchaseOrder.negotiation.inspection.appraisal.deal
    const { autoAttributes } = deal
    const chassisNumberDealAttribute = autoAttributes.filter(attribute => attribute.component.slug === 'chassis_serial')[0]
    const engineNumberDealAttribute = autoAttributes.filter(attribute => attribute.component.slug === 'engine_serial')[0]
    const auto = deal.auto
    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection' } },
      force: true,
    }))[0]
    const component = (await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: { slug: { _eq: 'mileage' } },
      force: true,
    }))[0]
    const mileage = (await this.fetchData({
      query: { name: 'find', model: 'AttributeView' },
      filter: {
        _and: [
          { id_process: { _eq: process.id } },
          { id_process_record: { _eq: this.purchaseOrder.negotiation.inspection.id } },
          { id_component: { _eq: component.id } },
        ],
      },
    }))[0]

    this.$set(this.docData, 'car', {
      plate: auto.registrationPlate,
      brand: auto.version.version.model.brand.name,
      model: `${auto.version.version.model.name}  ${auto.version.version.name}`,
      year: auto.version.year.id,
      mileage: mileage?.value ? fixThousands(mileage.value) : 'NO INFORMADO',
      engineNumber: engineNumberDealAttribute?.value,
      chassisNumber: chassisNumberDealAttribute?.value,
    })
  }

  // DEPRECATED
  addSubtitle (doc, x, y) {
    const documentId = `N°${dayjs(this.purchaseOrder.createdAt).format('YYYY')}${'0'.repeat(9 - String(this.purchaseOrder.id).length)}${this.purchaseOrder.id}`
    const inspectionId = `Inspección N°${dayjs(this.purchaseOrder.negotiation.inspection.createdAt).format('YYYY')}${'0'.repeat(9 - String(this.purchaseOrder.negotiation.inspection.id).length)}${this.purchaseOrder.negotiation.inspection.id}`
    const hourText = `Hora: ${dayjs(this.purchaseOrder.createdAt).format('HH:mm')}`
    const dateText = `Fecha: ${dayjs(this.purchaseOrder.createdAt).format('DD/MM/YYYY')}`
    doc.text(hourText, this.getCenterWidth(doc, hourText), y)
    doc.text(dateText, this.getAlignEnd(doc, dateText), y)
    doc.text(documentId, x, y - 2.5)
    doc.text(inspectionId, x, y + 2.5)
    y += 5
    y = this.separator(doc, y)
    return y
  }

  async addSeller (doc: jsPDF, x, y) {
    const document = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: { id_process_record: { _eq: this.purchaseOrder.id } },
    }))[0]

    const signer = document.interveners.filter(intervener => intervener.field.name === 'sale_representative')?.[0]?.person
    const owner = this.purchaseOrder.negotiation.inspection.appraisal.deal.ownership.owners[0].person

    const address = (await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { id_person: { _eq: owner.id } },
    }))?.[0]
    const addressString = address ? `${address?.address?.streetName} ${address?.address?.streetNumber}, ${address?.address?.city?.name}`.toUpperCase() : undefined
    if (y > doc.internal.pageSize.getHeight() - 40) {
      y = this.newPage(doc, y)
      this.addClosingSection(doc)
    }
    y = this.addSectionTitle(doc, x, y, 'Propietario')
    y = this.insertList(doc, x, y, [
        owner.type.name === 'company' ? 'Empresa' : 'Nombres',
        owner.type.name === 'company' ? undefined : 'Apellidos',
        'Rut',
        'Teléfono',
        owner.email ? 'Mail' : undefined,
        address ? 'Dirección' : undefined,
      ].filter(item => item !== undefined),
      [
        (owner.type.name === 'company' ? owner.companyName : `${owner?.firstName?.split(' ')?.[0] || ''} ${owner.secondName?.split(' ')?.[0] || ''}`.toUpperCase()).toUpperCase() + '|bold', // nombres
        owner.type.name === 'company' ? undefined : `${owner?.surname?.split(' ')?.[0] || ''} ${owner.secondSurname?.split(' ')?.[0] || ''}`.toUpperCase() + '|bold', // apellidos
        owner.uid, // rut
        owner.phone, // telefono
        owner.email?.personal || owner.email?.work || undefined, // mail
        addressString, // direccion
      ].filter(item => item !== undefined))

    if (signer) {
      y = this.separator(doc, y)
      const address = (await this.fetchData({
        query: { name: 'find', model: 'PersonAddress' },
        filter: { id_person: { _eq: signer.id } },
      }))?.[0]
      const addressString = address ? `${address?.address?.streetName} ${address?.address?.streetNumber}, ${address?.address?.city?.name}`.toUpperCase() : undefined
      if (y > doc.internal.pageSize.getHeight() - 60) {
        y = this.newPage(doc, y)
        this.addClosingSection(doc)
      }
      y = this.addSectionTitle(doc, x, y, 'Firmante')
      y = this.insertList(doc, x, y, [
          'Nombres',
          'Apellidos',
          'Rut',
          'Teléfono',
          signer ? 'Mail' : undefined,
          address ? 'Dirección' : undefined,
        ].filter(item => item !== undefined),
        [
          `${signer?.firstName?.split(' ')?.[0] || ''} ${signer.secondName?.split(' ')?.[0] || ''}`.toUpperCase() + '|bold', // nombres
          `${signer?.surname?.split(' ')?.[0] || ''} ${signer.secondSurname?.split(' ')?.[0] || ''}`.toUpperCase() + '|bold', // apellidos
          signer.uid, // rut
          signer.phone, // telefono
          signer.email?.personal || signer.email?.work || undefined, // mail
          addressString, // direccion
        ].filter(item => item !== undefined))
    }
    y = this.separator(doc, y)
    return y
  }

  async addClosingSection (doc: jsPDF) {
    const executive = this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.executive
    const clientService = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })
    this.addClosing(doc, [
        'Asesor comercial:',
        `${executive.person.firstName} ${executive.person.surname}`,
        `Tel: ${executive.person.phoneWork || 'No informado'}`,
        executive.person.email.work || 'Email no informado',
      ],
      [
        'Atención al cliente:',
        'Tel: ' + clientService.phoneWork,
      ],
    )
  }

  async addContract (doc: jsPDF, x, y) {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'purchase_order' } },
    })

    const document = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: this.purchaseOrder.id } }, { parameter: { process: { id: { _eq: process[0].id } } } }, { parameter: { name: { _eq: this.purchaseOrder?.supportDocumentType?.name } } }] },
      force: true,
    })

    y = this.addSectionTitle(doc, x, y, 'Contrato')
    y = this.insertList(doc, x, y,
      [
        'Tipo de contrato|no-bullet',
        'Link',
      ],
      [
        this.purchaseOrder.supportDocumentType.description + '|bold',
        document[0]?.file?.sourceLink.split('#')[0] || '',
      ])
    y = this.separator(doc, y)
    return y
  }

  async addDocumentation (doc: jsPDF, x, y) {
    const inspection = await this.fetchData({
      query: { name: 'fetch', model: 'Inspection', params: { id: this.purchaseOrder.negotiation.inspection.id } },
      force: true,
    })

    const category = (await this.fetchData({
      query: { name: 'find', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Documentación' } },
    }))[0]

    const soap = this.findComponentBySlug(category.components, 'soap')
    const technicalReview = this.findComponentBySlug(category.components, 'technical_review')
    const circulationPermit = this.findComponentBySlug(category.components, 'circulation_permit')

    const soapInfo = this.findComponentInfo(soap, inspection)
    const technicalInfo = this.findComponentInfo(technicalReview, inspection)
    const circulationInfo = this.findComponentInfo(circulationPermit, inspection)

    y = this.addSectionTitle(doc, x, y, 'Documentación')
    y = this.insertList(doc, x, y,
      [
        'Comuna permiso de circulacion|no-bullet',
        'Vencimiento',
        'Concesionaria revisión técnica|no-bullet',
        'Vencmiento',
        'SOAP|no-bullet',
        'Vencimiento',
      ],
      [
        circulationInfo.name + '|bold',
        circulationInfo.date,
        technicalInfo.name + '|bold',
        technicalInfo.date,
        soapInfo.name + '|bold',
        soapInfo.date,
      ],
    )
    y = this.separator(doc, y)
    return y
  }

  async addPayments (doc: jsPDF, x, y) {
    if (y > doc.internal.pageSize.getHeight() - 50) {
      y = this.newPage(doc, y)
      this.addClosingSection(doc)
    }
    const payments = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: { id_deal: { _eq: this.purchaseOrder.negotiation.inspection.appraisal.deal.id } },
      force: true,
    })

    y = this.addSectionTitle(doc, x, y, 'Formas de pago')

    for (const payment of payments) {
      payment.paymentRecipient = await this.fetchData({
        query: { name: 'fetch', model: 'PaymentRecipient', params: { id: payment.idPaymentRecipient } },
        force: true,
      })
      if (y > doc.internal.pageSize.getHeight() - 35) {
        y = this.newPage(doc, y)
        await this.addClosingSection(doc)
      }
      this.addDotedTitle(doc, x, y, 'Monto')
      doc.setFont(undefined, 'bold')
      doc.text(fixPrice(payment.amount), this.getAlignEnd(doc, fixPrice(payment.amount)), y)
      this.setDefaultFont(doc)

      y += 7

      y = this.insertList(doc, x + 5, y, [
          'Tipo de gasto',
          'Beneficiario',
          'Rut',
          'Forma de pago',
          payment.accountInfo?.split('/')?.[1] ? 'Banco' : undefined,
          payment.accountInfo?.split('/')?.[0] ? 'N° de cuenta' : undefined,
        ].filter(item => item !== undefined),
        [
          payment?.paymentRecipient.paymentOrderItem.title, // tipo de gasto
          payment?.paymentRecipient?.person?.fullName?.toUpperCase() || 'No informado', // Beneficiario
          payment?.paymentRecipient?.person?.uid || 'No informado', // RUT
          payment.type.description, // Forma de pago
          payment.accountInfo?.split('/')?.[1] || undefined, // Banco
          payment.accountInfo?.split('/')?.[0] || undefined, // Nro de cuenta
        ].filter(item => item !== undefined)
      )

      y = this.separator(doc, y)
    }
    return y
  }

  async page1 (doc: jsPDF, y, x) {
    const system = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: 1 } },
      force: true,
    })

    const imageData = await this.loadImage(system.photo)
    const pageWidth = doc.internal.pageSize.getWidth()

    if (imageData) {
      doc.addImage(imageData, 'JPEG', (pageWidth / 2 - 30), 10, 60, 6)
      y += 5
    } else {
      y -= 10
    }

    this.footer(doc)
    await this.addClosingSection(doc)
    y = this.separator(doc, y)
    y = this.addTitle(doc, y, 'NOTA DE COMPRA')
    y = this.addSubtitle(doc, x, y)
    // y = await this.addCarData(doc, x, y)
    y = await this.addContract(doc, x, y)
    y = await this.addDocumentation(doc, x, y)
    y = await this.addSeller(doc, x, y)
    await this.addPayments(doc, x, y)
  }

  addHtmlContent (doc: jsPDF, element: HTMLElement, x: number, y: number) {
    doc.html(element, {
      callback: doc => {
        this.loading = false
        doc.save(`compra ${this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.client.fullName} ${this.purchaseOrder.negotiation.inspection.appraisal.deal.lead.client.uid}.pdf`)
      },
      x,
      y, // Ajustar la posición y según la altura de la imagen y el texto
      html2canvas: { scale: 0.5 }, // Ajustar el escalado si es necesario
    })
  }

  findComponentInfo (component, inspection) {
    const matchingInspectedComponent = inspection?.inspectedComponents?.find(
      ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
    )
    if (!matchingInspectedComponent) return
    const date = DateGenerator.findGeneratedDate(matchingInspectedComponent.findInspectionParameterByOrder(2).value).internal

    const name = matchingInspectedComponent?.findInspectionParameterByOrder(1)?.value

    return { date, name }
  }

  findComponentBySlug (inspectionComponents, slug) {
    return inspectionComponents?.find(component => component.slug === slug)
  }

  get action () {
    return this.options?.action
  }

  get icon () {
    const { action, item } = this

    return action?.icon || item?.icon
  }

  get iconColor () {
    const { options } = this

    return options?.action?.iconColor || 'white'
  }

  get color () {
    const { action, item } = this

    return action?.color || item?.color
  }

  get tooltip () {
    const { options, item } = this

    return options?.tooltip || item?.tooltip || item?.name || item?.contact?.name
  }

  get disabledButton () {
    const { item } = this

    return !item?.id
  }
  }
