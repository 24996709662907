
  import { Component, Vue } from 'vue-property-decorator'

@Component
  export default class SystemConfig extends Vue {

  // Methods

  // Getters

  // Watchers
  }
