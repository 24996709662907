
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { mapActions } from 'vuex'

@Component({
  methods: {
    ...mapActions('resources', ['getExcludeStocks']),
  },
})
  export default class AlertStock extends Cell {
  getExcludeStocks!: () => Promise<[]>
  addAlert = false

  alert = {
    background: 'white',
    color: 'red',
    icon: 'mdi-camera',
    tooltip: 'Despublicado manualmente',
    value: 0,
  }

  async mounted () {
    const resp = await this.getExcludeStocks()

    this.addAlert = resp.find(item => item === this.item.id)
  }

  get alerts () {
    return [
      ...this.item.alerts,
      this.addAlert ? this.alert : null,
    ].filter(Boolean)
  }

  get background () {
    const { alert: { background } } = this
    return background
  }

  get color () {
    const { alert: { color } } = this
    return color
  }

  get icon () {
    const { alert: { icon } } = this
    return icon
  }

  get tooltip () {
    const { alert: { tooltip } } = this
    return tooltip
  }

  get value () {
    const { alert: { value = 0 } } = this
    return value
  }
  }
