
  import { Component } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { plainToInstance } from 'class-transformer'
  import { fixPrice, updateNestedObject } from '@/utils/general'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { Payment, PaymentType } from '@/entities/finance'
  import { Deal } from '@/entities/crm'
  import { Form } from '@/entities/public/Resource/metadata'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Negotiation } from '@/entities/purchase'

@Component({
  components: { GFiles, GAlert, GDatePicker, LinkedPerson, BaseForm },
  methods: { fixPrice },
  computed: {},
})
  export default class ExpensesOrderForm extends GForm {
  payment: Payment = plainToInstance(Payment, {})
  negotiation = plainToInstance(Negotiation, {})
  deal: Deal = plainToInstance(Deal, {})
  title = 'Order de pago'
  subtitle = 'Gestiona de forma sencilla y rápida las solicitudes de pago'
  showDetail = false
  idProcess = null
  formData = {
    amount: null,
    expenses: null,
    type: null,
    backup: null,
    comment: null,
    payer: null,
    paymentType: null,
    reference: null,
    bank: null,
  }

  fields = {
    amount: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
    expenses: {
      properties: {},
      rules: this.fieldRequired,
      items: [],
    },
    type: {
      properties: {},
      items: [],
    },
    paymentType: {
      properties: {},
      items: [],
    },
    payer: {
      properties: {},
      items: [],
    },
    bank: {
      properties: {},
      items: [],
    },
    backup: {
      properties: {},
      rules: this.fieldRequired,
    },
    comment: {
      properties: {},
      rules: this.fieldRequired,
    },
  }

  declare $refs: {
    form: HTMLFormElement,
    fieldClient: HTMLFormElement
  };

  metadata = {}
  metadataCollection = {
    lead: null,
    deal: null,
    payment: null,
  }

  paymentAlert = {
    open: false,
    title: '',
  }

  async mounted () {
    await this.setMetadata()
    await this.getInfo()

    await this.setTheBackup()
  }

  async setMetadata () {
    const { metadata } = this.getForm('Lead', 'lead')
    const { metadata: metadataPayment } = this.getForm('PaymentOrder', 'payment_order')

    this.metadataCollection.lead = metadata

    const { form: { title } } = metadataPayment as Form
    this.title = title
    this.metadataCollection.deal = metadataPayment

    const { uid } = this

    if (!this.isBreadCrumbPresent(this.title)) {
      this.setFormCrumbs(metadataPayment, 'Gasto', Boolean(!isNaN(uid)))
    }
  }

  async getInfo () {
    const { parentId } = this
    if (!parentId) return null
    const { deal } = await this.getParentData('Deal', parentId.toString())
    this.deal = deal

    this.fields.paymentType.items = await this.fetchData({
      query: { name: 'find', model: 'PaymentType' },
    })

    this.fields.bank.items = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { company_type: { name: { _eq: 'bank' } } },
    })

    this.setDetails()
  }

  setTheBackup () {
    const { backup, $route: { params: { id } } } = this

    if (!backup) {
      return this.close()
    }

    const isClient = 'client' in backup && backup.client
    const isReserveForm = 'reserveForm' in backup && backup.reserveForm && backup.reserveForm.client
    const isDeal = 'deal' in backup && backup.deal
    const isPaymentForm = 'paymentForm' in backup && backup.paymentForm && id !== 'create'

    if (isPaymentForm) {
      this.formData = backup.paymentForm
    }

    if (isReserveForm && !isClient) {
      this.fields.payer.items = [backup.reserveForm.client]
    }

    if (isDeal) {
      this.deal = backup.deal
      this.setDetails()
    }

    if (isClient && isPaymentForm) {
      this.formData.payer = backup.client
      this.fields.payer.items = [backup.client]
    }
  }

  async goPerson () {
    const { formData, payment } = this
    this.saveBackup()
    const idPayment = payment?.id ? payment?.id.toString() : 'created'
    const idPerson = formData.payer?.id
    await this.$router.push({ name: 'generic-person-nested', params: { model: 'Payment', uid: idPayment, id: idPerson } })
  }

  saveBackup () {
    const { backup, formData } = this

    if (backup) {
      backup.paymentForm = formData
      backup.client = null
      this.setBackup(backup)
    } else {
      this.setBackup({ paymentForm: formData })
    }
  }

  async send () {
    const client = this.$refs.fieldClient
    if (!this.$refs.form.validate() || !client.$refs.form.validate()) {
      return
    }
    this.loadingForm = true
    if (!await this.findPayment(this.formData)) {
      this.saveBackup()
      await this.close()
    }
  }

  setDetails () {
    const { metadataCollection, deal } = this

    this.metadata = {
      data: deal,
      metadata: metadataCollection.deal,
    }

    this.showDetail = Boolean(deal.id)
  }

  get bindClient () {
    const { fields: { payer } } = this

    if (!payer) return {}

    return { ...payer.properties, items: payer.items }
  }

  async searchBank (input) {
    if (!input?.length || input.length < 2) return null
    const { metadataCollection: { deal } } = this

    if (!deal) return null
    const { fields } = deal

    const query = updateNestedObject(fields.bank.computed.queries.items.where, '_eq', this.replaceSpace(input), ['name_person_type'])

    this.fields.bank.items = await this.findPersonSearch(query)
  }

  async searchPerson ({ input }) {
    if (!input?.length || input?.length < 2) return

    const { metadataCollection: { lead } } = this
    if (!lead) return
    const { fields } = lead
    const query = updateNestedObject(fields.client.computed.queries.items.where, '_eq', this.replaceSpace(input), ['name_person_type'])

    this.fields.payer.items = await this.findPersonSearch(query)
  }

  get displayTransferFields () {
    const { formData: { paymentType } } = this

    return paymentType?.isTransfer || paymentType?.isBank
  }

  get displayCardFields () {
    const { formData: { paymentType } } = this
    return paymentType?.isDebitCard || paymentType?.isCreditCard
  }

  get displayCheckFields () {
    const { formData: { paymentType } } = this

    return paymentType?.isCheck || paymentType?.isOkView
  }

  get isCashFields () {
    const { formData: { paymentType } } = this

    return paymentType?.isCash
  }

  async findPayment (data) {
    let title
    if ([PaymentType.transfer, PaymentType.bank, PaymentType.okView, PaymentType.check].includes(data.paymentType?.name)) {
      title = await this.findBank(data)
    } else if ([PaymentType.creditCard, PaymentType.debitCard, PaymentType.billExchange].includes(data.paymentType?.name)) {
      title = await this.findReference(data)
    } else if (PaymentType.cash === data.paymentType?.name) {
      const { backup } = this

      if (backup?.reserveForm?.order) {
        const { reserveForm: { order } } = backup
        const orderFiltered = order.filter(item => item.type.name === PaymentType.cash)
        title = this.findRecentPayment(orderFiltered, data)
      }
    }

    if (title) {
      this.paymentAlert = {
        open: true,
        title,
      }
      return true
    }
  }

  findDuplicatePayment (orders: any[], data: any, compareFunc: (order: any, data: any) => boolean): string | undefined {
    const exist = orders.some(order => compareFunc(order, data))
    if (exist) {
      return 'En la transacción actual ya existe un pago identico ¿deseas registrarlo?'
    }
  }

  findRecentBankPayment (orders: any[], data: any): string | undefined {
    return this.findDuplicatePayment(orders, data, (order, data) =>
      order.amount === data.amount &&
      order.date === data.paymentDate &&
      order.issuingBank.id === data.bank?.id
    )
  }

  findRecentPayment (orders: any[], data: any): string | undefined {
    return this.findDuplicatePayment(orders, data, (order, data) =>
      order.amount === data.amount &&
      order.date === data.paymentDate
    )
  }

  async findReference (val) {
    if (val.amount && val.reference) {
      const resp = await this.fetchData({
        query: { name: 'find', model: 'Payment' },
        filter: {
          _and: [
            { reference: { _eq: val.reference } },
            { amount: { _eq: val.amount } },
            { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
          ],
        },
      })

      if (resp.length) {
        return 'Ya existe un pago con la misma referencia y el mismo monto ¿deseas registrarlo?'
      }
    }

    const { backup } = this

    if (backup?.reserveForm?.order) {
      const { reserveForm: { order } } = backup
      const orderFiltered = order.filter(item => [PaymentType.creditCard, PaymentType.debitCard, PaymentType.billExchange].includes(item.type.name))
      return this.findRecentPayment(orderFiltered, val)
    }
  }

  async findBank (val) {
    if (val?.bank?.id && val.amount && val.reference) {
      const resp = await this.fetchData({
        query: { name: 'find', model: 'Payment' },
        filter: {
          _and: [
            { id_issuing_bank: { _eq: val.bank.id } },
            { reference: { _eq: val.reference } },
            { amount: { _eq: val.amount } },
            { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
          ],
        },
      })

      if (resp.length) {
        return 'Ya existe un pago de ese banco, con la misma referencia y el mismo monto ¿deseas registrarlo?'
      }
    }

    const { backup } = this

    if (backup?.reserveForm?.order) {
      const { reserveForm: { order } } = backup
      const orderFiltered = order.filter(item => [PaymentType.transfer, PaymentType.bank, PaymentType.okView, PaymentType.check].includes(item.type.name))

      return this.findRecentBankPayment(orderFiltered, val)
    }
  }

  get change () {
    const { formData } = this

    return JSON.stringify(formData)
  }

  cleanPayment () {
    this.formData.amount = ''
    this.formData.bank = null
    this.formData.reference = ''
    this.paymentAlert = {
      open: false,
      title: '',
    }
  }

  sendPayment () {
    this.paymentAlert = {
      open: false,
      title: '',
    }
    this.saveBackup()
    this.close()
  }

  async goToOrder () {
    this.saveBackup()

    const idDeal = this.negotiation.inspection.appraisal.deal.id.toString()

    await this.$router.push({
      name: 'generic-expense-order',
      params: { parentModel: 'Deal', parentId: idDeal, id: 'create' },
    })
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }
  }
