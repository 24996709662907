import { ResourceHeaders } from '..'

import { TableCellHeader } from '@/components/dataTables/cell'
import { Deal, Lead } from '@/entities/crm'
import {
  AlertsHeader,
  AppraiserHeader,
  BusinessHeader,
  ChannelHeader,
  ContactHeader,
  CreateLinkedRecordHeader,
  DateHeader,
  FinancialHeader,
  IdHeader,
  LeadActivityHistoryHeader,
  RedirectDtHeader,
  StatusAppraisalCellHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '../../helpers'
import { extendsObj } from '@/utils/vuetify/helpers'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
  },
  { ...AlertsHeader, text: 'Alertas ', value: 'alerts' },
  { ...DateHeader, text: 'Creación', value: 'created', sortable: false },
  { ...BusinessHeader, text: 'Negocio ', value: 'pipeline' },
  {
    ...ContactHeader,
    text: 'Cliente',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'client',
      },
      path: 'client.name',
      displayPhone: true,
      trigger: ({ client }: Lead) => Helpers.linkedModel(client),
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'client', process, system),
      },
      disabledMissingProperty: ['client.phone'],
    },
  },
  { ...ChannelHeader, text: 'Canal', value: 'channel' },
  { ...LeadActivityHistoryHeader, text: 'Vencimiento', value: '@', sortable: true },
  {
    ...CreateLinkedRecordHeader,
    text: 'Tareas',
    value: 'buttonActivity',
    options: {
      action: {
        color: 'primary',
        icon: 'mdi-calendar-month-outline',
        only: true,
        trigger: Helpers.linkedModel,
      },
    },
  },
  extendsObj<TableCellHeader>({
    text: 'Stock',
    value: 'sale',
    sortable: true,
    options: {
      path: 'auto.name',
      trigger: ({ stock }: Deal) => Helpers.linkedModel({ constructor: { name: 'StockPublish' }, ...stock }),
    },
  }, VehicleHeader),
  { ...VehicleStatusHeader, text: 'Estado Stock', value: 'saleStatus' },
  { ...AppraiserHeader, text: 'Tasación', value: 'purchase' },
  { ...StatusAppraisalCellHeader, text: 'Estado tasación', value: 'purchase.appraisal' },
  {
    ...CreateLinkedRecordHeader,
    text: 'Agendar',
    value: 'buttonInspection',
    options: {
      tooltip: 'Inspección',
      action: {
        color: 'purple',
        icon: 'mdi-car-search',
        only: true,
        trigger: item => Helpers.linkedModel(item),
      },
    },
  },
  {
    ...ContactHeader,
    text: 'Tasador',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'appraiser',
      },
      path: 'purchase.appraisal.appraiser.person.name',
      action: {
        always: true,
        color: 'primary',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'appraiser', process, system),
      },
      disabledMissingProperty: ['purchase.appraisal.appraiser.phone'],
    },
  },
  { ...FinancialHeader, text: 'Financiamiento', value: 'sale.order.financing.evaluation' },
  {
    ...RedirectDtHeader,
    text: 'Evaluaciones',
    value: 'sale.order.financing',
    options: {
      path: '/datatable/staff/staff_evaluations',
      query: {
        financing: {
          id: {
            _eq: '$id',
          },
        },
      },
    },
  },
  {
    ...CreateLinkedRecordHeader,
    text: 'Reservar',
    value: 'buttonReserve',
    options: {
      tooltip: 'Reservar',
      action: {
        color: 'blue',
        icon: 'mdi-car-outline',
        only: true,
        trigger: item => Helpers.linkedModel(item),
      },
    },
  },
  {
    ...CreateLinkedRecordHeader,
    text: 'Venta',
    value: 'buttonSell',
    options: {
      tooltip: 'Venta',
      action: {
        color: 'green',
        icon: 'mdi-cart-arrow-up',
        only: true,
        trigger: item => Helpers.linkedModel(item),
      },
    },
  },
]

export default function (): ResourceHeaders {
  return headers
}
