
  import { Component, Prop } from 'vue-property-decorator'

  import Row from './row.vue'
  import { LinkProps } from './interfaces'
  import { Process } from '@/entities/settings'
  import { Helpers } from '@/views/datatables/resources/helpers'
  import { getObjectValueByPath } from '@/utils/vuetify/helpers'
  import { mapGetters } from 'vuex'

@Component({
  components: {
    Row,
  },
  computed: {
    ...mapGetters('app', ['system']),
  },
})
  export default class Link extends Row implements LinkProps {
  @Prop({ type: String, default: null }) link!: string
  @Prop({ type: String, default: '_blank' }) target?: string
  @Prop({ type: String, default: 'small' }) size?: string
  @Prop({ type: Boolean, default: true }) fab?: boolean
  @Prop({ type: Boolean, default: true }) dark?: boolean
  @Prop({ type: Number, default: 0 }) elevation?: number
  @Prop({ type: String, default: null }) process?: string
  @Prop({ type: String, default: null }) targetPoint?: string
  @Prop({ type: String, default: null }) targetMessage?: string

  system!: string

  settingsProcess: Process | null = null

  async mounted () {
    if (!this.process) return
    this.settingsProcess = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: this.process } },
      force: true,
    }))[0]
  }

  get btn () {
    const { target, size, color, fab, dark, elevation, disabled } = this

    return {
      disabled,
      target,
      [size]: true,
      color,
      fab,
      dark,
      elevation,
    }
  }

  get extendBind () {
    const { container = true } = this

    return { container }
  }

  get exist () {
    const { value } = this

    return getObjectValueByPath(value, this.targetPoint)
  }

  contact () {
    Helpers.contact(this.value, this.targetMessage, this.settingsProcess?.config?.messaging, this.system)
  }
  }
