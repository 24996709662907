
  import { Prop, Watch } from 'vue-property-decorator'
  import Component from 'vue-class-component'
  import { BaseChart } from '../baseChart'
  import { Legend } from '../Components/Legend'
  import { fixColor } from '@/utils/dashboards'
  import { channelColors } from '@/utils/channelColors'
  import { financingColors } from '@/utils/financingColors'

@Component({})
  export default class LineRecords extends BaseChart {
  @Prop() recordsSettings: Record<string, any>
  @Prop() targetField: string
  @Prop({ default: undefined }) executives
  @Prop({ default: undefined, type: Function }) dataTransformer
  @Prop({ default: undefined }) filteringCriteria: Function
  @Prop({ default: undefined }) preCalcFilteringCriteria: Function
  @Prop({ default: false, type: Boolean }) useTotalAsTarget
  @Prop({ default: false, type: Boolean }) showTotalComparison
  @Prop({ default: 0, type: Number }) defaultTarget
  @Prop({ default: 1, type: Number }) multiplier
  @Prop({ default: undefined, type: Function }) totalFilter
  @Prop({ default: '', type: String }) title
  @Prop() pathToCategory: string[]
  @Prop({ default: () => ['description'] }) pathToCategoryTitle: string[]
  @Prop({ default: undefined }) pathToValue
  @Prop({ default: undefined }) pathToSubcategory: string[]
  @Prop({ default: 'categoría', type: String }) categoryName
  @Prop({ default: false, type: Boolean }) sortCategories
  @Prop({ default: false, type: Boolean }) sortLegends

  categories: Legend[] = []
  recordsCount: number = 0

  async getData () {
    this.Debug('RECORDS', this.records)
    this.Debug('RECORDS SETTINGS', this.recordsSettings)
    if (!this.parsedRecords) return

    const { pathToCategory, pathToValue, getObjectAttribute, dataTransformer, getCategories, filteringCriteria, preCalcFilteringCriteria } = this
    let records = this.parsedRecords

    if (dataTransformer) {
      records = await dataTransformer(records)
      if (this.debugging) console.log('AFTER TRANSFORM DATA', records)
    }
    if (preCalcFilteringCriteria) {
      records = records.filter(preCalcFilteringCriteria)
    }
    const categories = getCategories(records, pathToCategory, this.categoryName, this.debugging)

    if (filteringCriteria) {
      records = records.filter(filteringCriteria)
    }

    this.recordsCount = records.length
    if (this.debugging) console.log('CATEGORIES', categories)

    this.categories = categories.map((category): Legend => {
      const filteredByCategory = records.filter(record => getObjectAttribute(record, pathToCategory)?.id === category?.id)

      let value = 0
      if (pathToValue) {
        filteredByCategory.forEach(record => {
          const sumValue = getObjectAttribute(record, pathToValue)
          value += sumValue || 0
        })
      } else {
        value = filteredByCategory.length
      }
      const name = getObjectAttribute(category, this.pathToCategoryTitle)

      return {
        name,
        color: category.color ? fixColor(category.color) : (channelColors[category.name] || financingColors[name]),
        order: category.order,
        value,
        records: this.pathToSubcategory ? filteredByCategory : undefined,
        pathToSubcategory: this.pathToSubcategory,
        pathToValue: this.pathToSubcategory ? this.pathToValue : undefined,
      }
    })
    if (this.sortCategories) {
      this.Debug('SORTING CATEGORIES')
      this.categories = this.categories.sort((prev, next) => prev.order - next.order)
    }

    if (this.debugging) console.log('PROCESSED CATEGORIES', this.categories)

    this.series = this.categories.map(item => item.value || 0)
  }

  get recordsTarget () {
    if (this.useTotalAsTarget && this.records) {
      const { parsedRecords, totalFilter } = this
      let data = parsedRecords
      if (totalFilter) {
        data = data.filter(totalFilter)
      }
      return data.length
    }
    return Math.round((this.recordsSettings?.config?.projectedUtility?.[this.targetField])) || (this.useTotalAsTarget ? this.recordsCount : this.defaultTarget) || 0
  }

  get calcTarget () {
    return this.recordsTarget * this.multiplier
  }

  get percentage () {
    return (this.recordsCount / (this.recordsTarget !== 0 ? this.calcTarget : 1)) * 100
  }

  get color () {
    return this.getBarColor(this.percentage)
  }

  get watchData () {
    const { records, recordsSettings } = this
    return {
      records,
      recordsSettings,
    }
  }

  @Watch('watchData', { immediate: true, deep: true })
  async updateData () {
    this.processingData = true
    await this.getData()
    this.processingData = false
  }
  }
