
  import Cell from '@/components/dataTables/cell/cell'
  import { parseToNumber } from '@/utils/general'
  import { Component } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'

@Component({
  methods: {
    ...mapActions('resources', ['setActive']),
  },
  computed: {
    ...mapGetters('resources', ['all']),
  },
})
  export default class RedirectDt extends Cell {
    // Methods
    redirectToPage () {
      const querySearch = [this.query]
      const query = Object.assign({}, this.$route.query, { search: JSON.stringify(querySearch)?.replaceAll('$id', this.item.id) })

      window.open(this.$router.resolve({ path: this.path, query }).href, '_blank')
    }

    // Getters
    get path () {
      const { options } = this

      return options?.path
    }

    get query () {
      const { options } = this

      return options?.query
    }

    get disabledButton () {
      return !this.item?.id
    }

    get hasLead () {
      const { item, options } = this

      if (!options?.queryValidationString) {
        return true
      }

      return parseToNumber(item?.[options?.queryValidationString]) > 0
    }

  // Watchers
  }
