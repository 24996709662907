
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'

@Component
  export default class StockDailyProgress extends Cell {
  progress = 0
  background = null
  total = null

  async mounted () {
    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'stock' } },
    }))[0]

    this.total = process?.config?.rotationTime || 50
  }

  get color () {
    const { item, total } = this

    const days = item?.stockDays || item?.enablingDays

    this.progress = Math.round((days / total) * 100)

    if (!item?.stockDays) {
      this.background = 'grey'
    }

    switch (true) {
      case (this.progress >= 0 && this.progress < 6.4):
        return 'green'
      case (this.progress >= 6.6 && this.progress < 35):
        return 'yellow'
      case (this.progress >= 35 && this.progress < 50):
        return 'orange'
      case (this.progress >= 50 && this.progress < 72):
        return 'red'
      case (this.progress >= 72 && this.progress < 93):
        return 'red'
      default:
        return 'red'
    }
  }

  get isSold () {
    const { item } = this

    return Boolean(item?.status?.name === 'sold') || Boolean(item?.status?.name === 'retired')
  }

  get stockCreated () {
    const { item } = this

    return item?.created
  }

  get days () {
    const { item } = this
    return item?.stockDays || item?.enablingDays
  }
  }
