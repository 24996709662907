import Staff from './staff'
import Appraiser from './appraiser'
import Forwarder from './forwarder'
import Inspector from './inspector'
import Supervisor from './supervisor'
import External from './external'
import Validator from './validator'

const details = {
  ...Staff,
  ...Appraiser,
  ...Forwarder,
  ...Inspector,
  ...Supervisor,
  ...External,
  ...Validator,
}

export default details
