import gql from 'graphql-tag'
import { CompanyTypeFragment, PersonFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${CompanyTypeFragment}
  ${PersonFragment}
  query findCompanyTypes($filter: persons_company_type_bool_exp, $order: [persons_company_type_order_by!]) {
    records: persons_company_type(where: $filter , order_by: $order) {
      ...CompanyType
      persons:people(order_by:{alias:asc}){
        ...Person
      }
    }
  }
`
export default query
