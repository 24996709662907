import gql from 'graphql-tag'
import { EntityFields } from '..'
import { PersonFragment } from '../persons/person'
import { EmployeeFragment } from '../hr'
import { PipelineFragment } from '../crm/pipeline'
import { ProcessStatusFragment } from '../settings/processStatus'
import { ClosingReasonFragment } from '../settings/closingReason'
import { ChannelFragment } from '@/graphql/fragments/crm/channel'

export const BaseLeadFragment = gql`
  ${PersonFragment}
  ${ChannelFragment}
  fragment LeadBase on crm_lead {
    ${EntityFields}
    executive {
      ...Employee
      person{
        ...Person
      }
    }
    initialChannel:channel  {
      ...Channel
    }
    metadata
  }
`

export const LeadFragment = gql`
  ${BaseLeadFragment}
  ${PersonFragment}
  ${EmployeeFragment}
  ${PipelineFragment}
  ${ProcessStatusFragment}
  ${ClosingReasonFragment}
  fragment Lead on crm_lead {
    ...LeadBase
    client {
      ...Person
    }
    forwarder {
      ...Employee
      person{
        ...Person
      }
    }
    pipeline {
      ...Pipeline
    }
    status {
      ...ProcessStatus
    }
    closingReason: closing_reason {
      ...ClosingReason
    }
  }
`
