import VueRouter from 'vue-router'
import { Store } from 'vuex'
import { State } from '@/store/state'
import { Entity } from '@/entities'
import { Resource } from '@/entities/public'
import { View } from '@/entities/public/Resource/metadata'
import { MissingForm } from '@/errors'
import { getFormView } from '@/utils/general'
import dayjs from 'dayjs'
import { getObjectValueByPath } from '@/utils/vuetify/helpers'

function evaluateCondition (condition, item) {
  if (condition._and) {
    return condition._and.every(subCondition => evaluateCondition(subCondition, item))
  }
  if (condition._or) {
    return condition._or.some(subCondition => evaluateCondition(subCondition, item))
  }

  const { path, operator, value } = condition

  const targetValue = getObjectValueByPath(item, path)

  switch (operator) {
    case '_eq':
      return targetValue === value
    case '_neq':
      return targetValue !== value
    case '_gt':
      return targetValue > value
    case '_lt':
      return targetValue < value
    case '_gte':
      return targetValue >= value
    case '_lte':
      return targetValue <= value
    case '_is_null':
      return value ? targetValue === null || targetValue === undefined : targetValue !== null && targetValue !== undefined
    default:
      return false
  }
}

export function getGreeting () {
  const hour = dayjs().hour()
  if (hour >= 0 && hour < 12) {
    return 'Buenos días'
  } else if (hour >= 12 && hour < 19) {
    return 'Buenas tardes'
  } else {
    return 'Buenas noches'
  }
}

function generateMessage (group, item, messageConfig, system) {
  if (!messageConfig) return 'Hola'

  const $saludo = getGreeting()

  const messages = messageConfig[group]?.messages
  if (!messages) {
    throw new Error(`Group ${group} not found`)
  }

  const templateConfig = messages.find(message => evaluateCondition(message.conditions, item))
  if (!templateConfig) {
    throw new Error(`No suitable template found for the given conditions`)
  }

  const replacements = {
    $saludo,
    $system: system,
    ...Object.fromEntries(
      Object.entries(templateConfig.placeholders).map(([placeholder, path]) => [placeholder, getObjectValueByPath(item, path, '')])
    ),
  }

  let template = templateConfig.template
  for (const [key, value] of Object.entries(replacements)) {
    template = template.replace(new RegExp(`\\${key}`, 'g'), value)
  }

  return template
}

export class Helpers {
  protected static router: VueRouter
  protected static store: Store<State>

  constructor (router: VueRouter, store: Store<State>) {
    Helpers.router = router
    Helpers.store = store
  }

  static get resource () {
    const { store } = Helpers
    return store.getters['resources/active'] as Resource
  }

  static get metadata () {
    const { resource: { metadata } } = Helpers
    return metadata as View
  }

  static forms (model) {
    const { metadata } = Helpers

    return metadata.model === model ? metadata.forms : metadata.forms?.models[model]
  }

  static linkedModel (item, defaultProps = {}) {
    const { constructor: { name: model }, id, parent, props = {} } = item
    const { router } = Helpers

    const forms = Helpers.forms(model)

    const form = id ? forms?.update : forms?.create
    if (!form && !forms?.view) throw new MissingForm(model)

    const uid = id?.toString() || 'create'
    const parentId = !id ? parent?.id?.toString() : null
    const parentModel = !id ? parent?.model : null

    const view = getFormView(model, forms?.view, form)

    const returnTo = router.currentRoute.params.resource
    const buildProps = { ...props, ...defaultProps }

    router.push({
      name: view,
      params: { model, uid, parentModel, parentId },
      query: { form, returnTo, ...buildProps },
    }).catch(() => {
    })
  }

  static removeLinkedModel (item: Entity) {
    const { constructor: { name }, id } = item
    const { router } = Helpers
    const { currentRoute: { query } } = router

    router.push({
      name: 'remove_linked_model',
      params: { model: name.toLowerCase(), id: `${id}` },
      query,
    }).catch(() => {
    })
  }

  // TODO: transform the model:arg into a type that's related with the different models for better linting
  static addLinkedModel (model: string) {
    return function (item: any) {
      const { id } = item
      const { router } = Helpers
      const { currentRoute: { query } } = router

      router.push({
        name: 'add_linked_model',
        params: { model, id },
        query,
      }).catch(() => {
      })
    }
  }

  static contact (item: any, group: string, messaging: any = null, system = '') {
    if (!messaging) {
      const phone = item?.client?.whatsappMe || item?.whatsappMe || item?.whatsappMeWork

      window.open(`${phone}`, '_blank')
      return
    }

    try {
      const message = generateMessage(group, item, messaging, system)
      const phone = getObjectValueByPath(item, messaging[group].phone)
      const encodedMessage = encodeURIComponent(message)

      window.open(`https://wa.me/${phone}?text=${encodedMessage}`, '_blank')
    } catch (error) {
      console.error(error)
    }
  }

  static portal (link?: string) {
    if (!link || link === '') {
      window.open(`https://www.chileautos.cl/`, '_blank')
    }

    window.open(link, '_blank')
  }
}
