import { ResourceHeaders } from '..'
import {
  AppraisalStatusHeader,
  BusinessHeader,
  ContactHeader,
  DateHeader,
  IdHeader,
  PdfReserveCellHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      show: true,
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 130,
  },
  { ...VehicleHeader, text: 'Vehículo', value: 'saleOrder.deal.auto' },
  { ...VehicleStatusHeader, text: 'Estado de Stock', value: 'saleOrder.deal.stock' },
  { ...BusinessHeader, text: 'Negocio ', value: 'saleOrder.deal.lead.pipeline' },
  {
    ...TextHeader,
    text: 'Cliente',
    value: 'saleOrder.deal.lead.client.name',
    width: 200,
  },
  {
    ...ContactHeader,
    text: 'Ejecutivo',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'executive',
      },
      path: 'saleOrder.deal.lead.executive.name',
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'executive', process, system),
      },
      disabledMissingProperty: ['saleOrder.deal.lead.executive.phone'],
    },
  },
  { ...DateHeader, text: 'Fecha reserva', value: 'created' },
  { ...DateHeader, text: 'Fecha vencimiento', value: 'expired' },
  { ...AppraisalStatusHeader, text: 'Estado de reserva', value: '@' },
  {
    ...PdfReserveCellHeader,
    text: 'Carta reserva',
    value: '@',
    width: 150,
  },
]

export default function (): ResourceHeaders {
  return headers
}
