
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import FileView from '@/components/core/files/FileView.vue'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import { Negotiation } from '@/entities/purchase'

@Component({
  components: { FileView, FieldTitle },
})
  export default class AutoAttachments extends FilesProcess {
  @Prop({ type: Object, default: () => null }) value!: Negotiation
  @Prop({ type: String, default: '' }) title: string

  files = []
  idProcessInspection = null
  inspectionComponents = []

  openFile (fileProcess) {
    fileProcess.map(file => {
      this.openLink(file.file.uri)
    })
  }

  async findAssociatedFiles (id, tableName = 'negotiation') {
    if (!id) return {}
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: tableName } },
    })

    const idProcess = process?.[0]?.id
    const cav = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'cav' } } }] },
      force: true,
    })

    const report = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'legal_report' } } }] },
      force: true,
    })

    const penalties = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'penalty_certificate' } } }] },
      force: true,
    })

    return {
      cav,
      report,
      penalties,
    }
  }

  async setPhotoToForm (idProcessRecord, idProcess) {
    if (!idProcessRecord) return

    return this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: idProcessRecord } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { file_type: { name: { _eq: 'photo' } } } },
        ],
      },
      force: true,
    })
  }

  @Watch('value', { immediate: true, deep: true })
  async onDealChange (val: Negotiation) {
    const allowedRoles = ['staff', 'validator']
    const path = this.$route.path

    if (!allowedRoles.some(role => path.includes(role))) return
    if (!val?.id) return

    this.inspectionComponents = await this.fetchData({
      query: { name: 'find', model: 'InspectionQualification' },
      filter: {
        component: {
          _and: [
            { id_inspection: { _eq: val.inspection.id } },
          ],
        },
      },
      force: true,
    })

    let circulationPermit = null
    let soap = null
    let technicalReview = null
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection_inspected_component' } },
    })

    if (this.circulationPermit?.[0]?.idInspectedComponent) {
      circulationPermit = await this.setPhotoToForm(this.circulationPermit[0].idInspectedComponent, process?.[0]?.id)
    }
    if (this.soap?.[0]?.idInspectedComponent) {
      soap = await this.setPhotoToForm(this.soap[0].idInspectedComponent, process?.[0]?.id)
    }
    if (this.technicalReview?.[0]?.idInspectedComponent) {
      technicalReview = await this.setPhotoToForm(this.technicalReview[0].idInspectedComponent, process?.[0]?.id)
    }

    const {
      cav,
      report,
      penalties,
    } = await this.findAssociatedFiles(val?.id)

    const purchase = (await this.fetchData({
      query: { name: 'find', model: 'PurchaseOrder' },
      filter: { negotiation: { id: { _eq: val?.id } } },
    }))[0]
    if (purchase?.id) {
      const {
        cav: cavPurchase,
        report: reportPurchase,
        penalties: penaltiesPurchase,
      } = await this.findAssociatedFiles(purchase?.id, 'purchase_order')

      this.files = [
        { name: 'CAV', files: cavPurchase?.length ? cavPurchase : cav },
        { name: 'Informe Legal', files: reportPurchase?.length ? reportPurchase : report },
        { name: 'Certificado de Multas', files: penaltiesPurchase?.length ? penaltiesPurchase : penalties },
        { name: 'Permiso de circulación', files: circulationPermit },
        { name: 'SOAP', files: soap },
        { name: 'Revisión técnica', files: technicalReview },
      ].filter(file => file.files.length)
      return
    }

    this.files = [
      { name: 'CAV', files: cav },
      { name: 'Informe legal', files: report },
      { name: 'Certificado de multas', files: penalties },
    ].filter(file => file.files.length)
  }

  get circulationPermit () {
    const { inspectionComponents } = this
    if (inspectionComponents?.length) {
      const components = inspectionComponents?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'circulation_permit')
      if (components.length) {
        return components
      }
    }
    return null
  }

  get soap () {
    const { inspectionComponents } = this
    if (inspectionComponents?.length) {
      const components = inspectionComponents?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'soap')
      if (components.length) {
        return components
      }
    }
    return null
  }

  get technicalReview () {
    const { inspectionComponents } = this
    if (inspectionComponents?.length) {
      const components = inspectionComponents?.filter(ic => ic.component?.inspectionComponent?.component?.slug === 'technical_review')
      if (components.length) {
        return components
      }
    }
    return null
  }
  }
