import { Transform, Type } from 'class-transformer'
import { Entity } from '..'

import { ActivityType, DealNote, Lead } from '.'
import { ClosingReason, ProcessStatus } from '../settings'
import { Employee } from '../hr'
import { LeadActivityStatus } from '@/models/lead/interfaces'
import dayjs, { Dayjs } from 'dayjs'
import duration, { Duration } from 'dayjs/plugin/duration'
import parse from 'postgres-interval'

dayjs.extend(duration)

export interface Task {
  client: string
  lead: Lead
  timeStart: string
  timeEnd: string
  type: ActivityType
}

export class LeadActivity extends Entity {
  @Type(() => ActivityType)
  type: ActivityType;

  @Type(() => Lead)
  lead: Lead;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  schedulingDate: Dayjs;

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => ClosingReason)
  closingReason: ClosingReason;

  @Type(() => Employee)
  executor: Employee;

  @Type(() => DealNote)
  notes: DealNote[];

  @Type(() => Dayjs)
  @Transform(({ value }) => dayjs.duration(parse(value).toISOStringShort()), { toClassOnly: true })
  duration?: Duration;

  activityResult: boolean;

  constructor (lead?: Lead) {
    super()
    if (lead) {
      this.lead = lead
    }
  }

  get activityStatus () {
    const { status } = this

    const { isFinished } = status
    if (isFinished) return undefined

    const { isClosed } = status
    if (isClosed) return undefined

    const { isDeprecated } = status
    if (isDeprecated) return LeadActivityStatus.delayed

    const { isPending } = status
    if (!isPending) throw new Error(`LeadActivity => Problem with status (${status.name}: ${status.id} | ${status.toString()})`)

    const { schedulingDateLocalTime, type: { notifySince } } = this
    const now = dayjs()

    if (schedulingDateLocalTime < now) return LeadActivityStatus.delayed
    if (schedulingDateLocalTime.subtract(notifySince) < now) return LeadActivityStatus.toExpired

    return LeadActivityStatus.ok
  }

  get result () {
    const { activityResult } = this

    return activityResult === null ? { id: null } : activityResult ? { id: true } : { id: false }
  }

  set result (value) {
    this.activityResult = value.id === null ? null : value.id === true
  }

  get parent () {
    const { lead } = this

    return { id: lead?.id, model: 'Lead' }
  }

  get schedulingDateLocalTime () {
    const { schedulingDate } = this
    if (!schedulingDate) return null

    const local = dayjs(schedulingDate)
    const offset = dayjs().utcOffset()
    const localSchedulingDate = local.add(offset, 'minute')

    return dayjs(localSchedulingDate)
  }

  get schedulingDateLocalTimeFormatted () {
    const { schedulingDateLocalTime } = this
    if (!schedulingDateLocalTime) return null

    return schedulingDateLocalTime.format('DD/MM/YYYY HH:mm')
  }

  get timeStart () {
    const { schedulingDateLocalTime } = this
    if (!schedulingDateLocalTime) return null

    return schedulingDateLocalTime.format('HH:mm')
  }

  get timeEnd () {
    const { schedulingDateLocalTime, duration, timeStart } = this

    if (!schedulingDateLocalTime) return null
    if (!duration) return timeStart

    const endTime = schedulingDateLocalTime.add(duration.asMinutes(), 'minutes')
    return endTime.format('HH:mm')
  }

  get task (): Task {
    const { lead, timeStart, timeEnd, type } = this

    return {
      client: lead?.client?.name,
      timeStart,
      timeEnd,
      type,
      lead,
    }
  }
}
