import { ResourceHeaders } from '..'
import {
  AppraisalStatusHeader,
  IconHeader,
  IdHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { extendsObj } from '@/utils/data'
import { TableCellHeader } from '@/components/dataTables/cell'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 130,
  },
  { ...TextHeader, text: 'Fecha creado', value: 'created', width: 120 },
  extendsObj<TableCellHeader>({
    text: 'Vehículo',
    value: '@',
    options: {
      path: 'autoStock.name',
      trigger: item => Helpers.linkedModel({ constructor: { name: 'StockPublish' }, ...item.stock }),
    },
  }, VehicleHeader),
  { ...VehicleStatusHeader, text: 'Estado Stock', value: 'stock.status' },
  { ...TextHeader, text: 'Llave', value: 'stock.keyIdentifier.keyId', width: 120 },
  { ...IconHeader, text: 'Documentación', value: 'metadata.isDocumentationStatus', width: 100 },
  { ...IconHeader, text: 'Carroceria', value: 'metadata.isBodyWorkStatus', width: 100 },
  { ...IconHeader, text: 'Ruedas', value: 'metadata.isWheelsStatus', width: 100 },
  { ...IconHeader, text: 'Interior', value: 'metadata.isInsideStatus', width: 100 },
  { ...IconHeader, text: 'Mecánica', value: 'metadata.isMechanicalStatus', width: 100 },
  { ...IconHeader, text: 'Aseo', value: 'metadata.isCleanStatus', width: 100 },
  { ...AppraisalStatusHeader, text: 'Estado', value: '@', width: 120 },
]

export default function (): ResourceHeaders {
  return headers
}
