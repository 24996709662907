
  import { Component } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import { plainToInstance } from 'class-transformer'
  import { Form } from '@/entities/public/Resource/metadata'
  import { Lead } from '@/entities/crm'
  import InitialFields from '@/components/forms/fields/InitialFields.vue'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import StockPrices from '@/components/toolkit/details/row/custom/StockPrices.vue'
  import AutoHeader from '@/components/toolkit/details/row/expandable/auto/header.vue'
  import Simple from '@/components/toolkit/details/row/simple.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Process } from '@/entities/settings'
  import { stringifySafe } from '@/utils/general'
  import { Payment, PaymentOrder } from '@/entities/finance'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import GLoading from '@/components/core/GLoading.vue'
  import logOut from '@/components/LogOut.vue'
  import { SaleOrder } from '@/entities/sales'
  import { SaleOrderView } from '@/components/forms/view/SaleOrderView'
  import GCostField from '@/components/core/input/GCostField.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import Agenda from '@/components/forms/fields/agenda/Agenda.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import { Document as Transfer } from '@/entities/documents'
  import { ProcessTrafficTicket } from '@/entities/purchase'
  import { mapActions } from 'vuex'

@Component({
  components: {
    GRadioButton,
    Agenda,
    GDatePicker,
    GCostField,
    logOut,
    GLoading,
    GAlert,
    GFiles,
    Simple,
    StockPrices,
    FieldTitle,
    LinkedPerson,
    InitialFields,
    LinkedAuto,
    BaseForm,
    AutoHeader,
  },
  methods: {
    ...mapActions('resources/form', ['closeApprovedSale']),
  },
})
  export default class SaleOrderForm extends SaleOrderView {
  closeApprovedSale!: ({ idValidator, saleId }) => Promise<void>;

  loaded = false
  document = plainToInstance(Transfer, {})
  payments: Array<Payment> = []
  lead = plainToInstance(Lead, {})
  saleOrder = plainToInstance(SaleOrder, {})
  process: Process = plainToInstance(Process, {})
  paymentOrder: PaymentOrder = plainToInstance(PaymentOrder, {})
  minValue = null
  maxDays = null
  newAgenda = null
  activity = null
  activities = []
  currencyExchanges = {}
  penalties: ProcessTrafficTicket[] = []
  penaltiesErrorMessage = ''
  purchase = {
    open: false,
    title: '',
    item: null,
  }

  products = []

  checkboxMessage = ''
  errorMessages = ''
  errorLegalApproved = ''
  errorLegalize = ''
  errorActive = ''
  errorToRegister = ''
  errorExecutiveUpdate = ''
  documentMetadata = null
  title = ''
  idProcess = null
  showDetail = false
  fileInfo = null

  declare $refs: {
    form: HTMLFormElement
    fieldSigners: LinkedPerson
    fieldClient: LinkedPerson
    fieldAcquirer: LinkedPerson
  };

  radioButtonMessage = {
    isCav: '',
    isLegalReport: '',
    isPenalties: '',
    isContract: '',
  }

  warrantyAmount = null
  metadata = {}
  formData = {
    isCav: null,
    isLegalReport: null,
    isPenalties: null,
    isContract: null,
    cavComment: '',
    legalReportComment: '',
    penaltiesComment: '',
    contractComment: '',
    documentType: null,
    fileCav: [],
    filePenalties: [],
    fileLegalReport: [],
    linkLegalReport: null,
    contract: [],
    linkContract: null,
    legalApproved: null,
    executiveUpdate: null,
    options: null,
    legalize: null,
    active: null,
    transfer: null,
  }

  fields = {
    documentType: {
      properties: {},
      rules: null,
      items: [],
    },
    linkContract: {
      properties: {},
    },
    linkLegalReport: {
      properties: {},
    },
    order: {
      properties: {
        label: 'Abonos',
        required: true,
        readOnly: true,
        rules: [],
        appendOuterIcon: '',
      },
    },
  }

  async mounted () {
    this.loadingForm = true
    await this.setMetadata()

    const { id, uid, model, title, documentMetadata } = this

    if ((!isNaN(uid) && (model === 'Document' || model === '')) || !isNaN(id)) {
      const saleId = id || uid
      await this.getDocumentInfo(saleId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(documentMetadata, title, Boolean(this.document?.id))
    }
    this.setTheBackup()

    this.setDetails()
    this.loadingForm = false
  }

  setTheBackup () {
    const { backup } = this
    if (!backup) return
    const saleForm = 'saleForm' in backup && backup.saleForm

    if (saleForm) {
      this.formData = backup.saleForm
    }
  }

  cancelSale () {
    const { formData: { fileCav, filePenalties, contract } } = this
    this.setBackup(null)
    const files = [...fileCav, ...filePenalties, ...contract]

    this.close(files)
  }

  async getDocumentInfo (id) {
    this.document = await this.fetchData({
      query: { name: 'fetch', model: 'Document', params: { id } },
      force: true,
    })
    this.formData.documentType = this.document.support
    const saleOrder = await this.fetchData({
      query: { name: 'find', model: 'SaleOrder' },
      filter: { id_document: { _eq: id } },
      force: true,
    })
    this.saleOrder = saleOrder[0]
    this.fields.documentType.items = await this.fetchData({
      query: { name: 'find', model: 'SupportDocumentType' },
    })

    await this.setFilesData(this.saleOrder)
  }

  async findAssociatedFiles (idProcess, id) {
    if (!id) return {}
    const { isDigitalContract } = this
    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }] },
      force: true,
    })

    const cav = files.filter(file => file.parameter.name === 'cav')

    if (cav.length) {
      this.saleOrder.cavValidation = {
        expirationDate: cav[0].file.expirationDate,
        validations: cav[0].validation,
      }
    }

    const report = files.filter(file => file.parameter.name === 'legal_report')

    const penalties = files.filter(file => file.parameter.name === 'penalty_certificate')

    let contract = files.filter(file => !isDigitalContract ? file.parameter.name === 'buyer_physical_contract' : file.parameter.name === 'buyer_digital_contract')
    if (!contract?.length) {
      contract = files.filter(file => file.parameter.name === 'exempt_invoice' || file.parameter.name === 'invoice_affect')
    }
    const buyerCard = files.filter(file => file.parameter.name === 'buyer_card')
    return {
      cav,
      report,
      contract,
      penalties,
      buyerCard,
    }
  }

  async setFilesData (saleOrder) {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'sale_order' } },
    })

    const {
      cav,
      contract,
      penalties,
      report,
    } = await this.findAssociatedFiles(process?.[0]?.id, saleOrder.id)

    this.formData.fileCav = cav?.length ? [cav[0]] : []
    this.formData.fileLegalReport = report?.length ? [report[0]] : []

    if (report?.[0]?.file?.sourceLink) {
      this.formData.linkLegalReport = report?.[0]?.file?.sourceLink
    }
    this.formData.contract = contract?.length ? contract : []
    const link = contract?.find(contrac => contrac.file.sourceLink)?.file?.sourceLink

    if (link) {
      this.formData.linkContract = link
    }
    this.formData.filePenalties = penalties?.length ? [penalties[0]] : []

    this.penalties = await this.getPenalties(this.formData?.filePenalties?.[0]?.id)
    this.loaded = true
  }

  async setMetadata () {
    const { metadata } = this.getForm('Document', 'validator_transfer')
    const { form, fields } = metadata as Form

    this.documentMetadata = metadata
    this.fields.linkContract.properties = fields.linkContract.properties
    this.fields.documentType.properties = fields.documentType.properties
    this.fields.linkLegalReport.properties = fields.linkLegalReport.properties
    this.title = form.title

    await this.setFilesFieldsData()
  }

  async setFilesFieldsData () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'document' } },
    })

    const { id } = process[0] as Record<string, any>

    this.idProcess = id
  }

  setDetails () {
    const { documentMetadata, document, saleOrder } = this
    document.saleOrder = saleOrder

    this.metadata = {
      data: document,
      metadata: documentMetadata,
    }

    this.showDetail = (Boolean(document?.id))
  }

  validateAnswer (formData, validated = true) {
    const { isCav, isPenalties, isLegalReport, isContract } = formData
    const keys = ['isCav', 'isLegalReport', 'isPenalties', 'isContract']

    if (this.document.status?.name !== 'legal_review') {
      return false
    }

    if (validated) {
      this.validKey(keys)
    }
    if (isCav === null && this.formData.fileCav.length) {
      return true
    }

    return [isPenalties, isLegalReport, isContract].some(item => item === null)
  }

  validKey (keys) {
    keys.forEach(key => {
      const isNull = this.formData[key] === null
      if ((key === 'isCav' && !this.formData.fileCav.length && isNull) || (isNull && key !== 'isCav')) {
        this.setRadioButtonMessage(key)
      }
    })
  }

  setRadioButtonMessage (key) {
    this.radioButtonMessage[key] = 'Campo requerido'
  }

  validateAnswerFalse (formData, validated = true) {
    const { isPenalties, isLegalReport, isContract } = formData
    const keys = ['isCav', 'isLegalReport', 'isPenalties', 'isContract']

    if (validated) {
      this.validKey(keys)
    }

    return [isPenalties, isLegalReport, isContract].some(item => item === false)
  }

  validateEveryAnswer (formData, validationType = null) {
    const { isCav, isPenalties, isLegalReport, isContract } = formData

    if (isCav !== true && this.formData.fileCav.length) {
      return false
    }
    return [isPenalties, isLegalReport, isContract].every(item => item === validationType)
  }

  async handleFilesValidation () {
    const {
      formData: {
        fileCav,
        isCav,
        cavComment,
        filePenalties,
        isPenalties,
        penaltiesComment,
        fileLegalReport,
        isLegalReport,
        legalReportComment,
        contract,
        isContract,
        contractComment,
      },
    } = this
    if (fileCav?.length) {
      await this.insertFileProcessInfo({
        id: fileCav[0].id,
        comment: cavComment,
        valid: isCav,
        validation_type: 'manual',
      })
    }
    await this.insertFileProcessInfo({
      id: filePenalties[0].id,
      comment: penaltiesComment,
      valid: isPenalties,
      validation_type: 'manual',
    })
    await this.insertFileProcessInfo({
      id: fileLegalReport[0].id,
      comment: legalReportComment,
      valid: isLegalReport,
      validation_type: 'manual',
    })
    await this.insertFileProcessInfo({
      id: contract[0].id,
      comment: contractComment,
      valid: isContract,
      validation_type: 'manual',
    })
  }

  async closeTransfer () {
    const { formData, showLegalized, showActive, showToRegister } = this

    if (showLegalized && !formData.legalize) {
      await this.close()
      return true
    }

    if (showActive && !formData.active) {
      await this.close()
      return true
    }

    if (showToRegister && !formData.transfer) {
      await this.close()
      return true
    }
  }

  async send () {
    const {
      // missingPenalties,
      showLegalized, document, showActive, showToRegister,
    } = this

    // if (missingPenalties) {
    //  this.penaltiesErrorMessage = 'Tiene multas por validar'
    //  return
    // }
    const filesValidation = this.filesValidation()
    const validateAnswer = this.validateAnswer(this.formData)
    const validateSomeFalse = this.validateAnswerFalse(this.formData)

    if (!this.$refs.form.validate() || filesValidation || validateAnswer) {
      return
    }
    this.loadingForm = true

    if (await this.closeTransfer()) {
      return
    }

    await this.handleFilesValidation()
    if (validateSomeFalse) {
      await this.pushData({
        model: 'Document',
        fields: {
          id: document.id,
          id_process_status: this.documentStatus.toUpdate?.[0]?.id,
        },
      })
      await this.pushData({
        model: 'SaleOrder',
        fields: {
          id: this.saleOrder.id,
          id_process_status: this.saleStatus.toUpdate[0]?.id,
        },
      })
      await this.close()
      return
    }

    if (!showLegalized && !showActive && !showToRegister) {
      await this.closeApprovedSale({ idValidator: this.idEmployee, saleId: this.saleOrder.id })
    } else if (showLegalized) {
      await this.pushData({
        model: 'Document',
        fields: {
          id: document.id,
          id_process_status: this.documentStatus.active?.[0]?.id,
        },
      })
    } else if (showActive) {
      await this.pushData({
        model: 'Document',
        fields: {
          id: document.id,
          id_process_status: this.documentStatus.toRegister?.[0]?.id,
        },
      })
    } else {
      await this.closeDocument(document)
    }

    this.loadingForm = false
    await this.close()
  }

  async closeDocument (document) {
    const closed = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'document' } } }, { status: { name: { _eq: 'closed' } } }] },
    })

    const filterSuccess = {
      _and: [
        { type: { name: { _eq: 'successful' } } },
        { status: { process: { table_name: { _eq: 'document' } } } },
      ],
    }

    const closing = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: filterSuccess,
    })

    await this.pushData({
      model: 'Document',
      fields: {
        id: document.id,
        id_process_status: closed[0]?.id,
        id_closing_reason: closing[0]?.id,
      },
    })

    const closedSell = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ status: { name: { _eq: 'closed' } } }, { process: { table_name: { _eq: 'sale_order' } } }] },
    })

    const closingReasonSell = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { type: { name: { _eq: 'successful' } } },
          { status: { process: { table_name: { _eq: 'sale_order' } } } },
        ],
      },
    })

    await this.pushData({
      model: 'SaleOrder',
      fields: {
        id: this.saleOrder.id,
        id_process_status: closedSell[0]?.id,
        id_closing_reason: closingReasonSell[0]?.id,
      },
    })
  }

  async handleFinancing (sale) {
    const closed = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'evaluation' } } }, { status: { name: { _eq: 'closed' } } }] },
    })

    const resigned = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: { _and: [{ type: { name: { _eq: 'resigned' } } }, { status: { process: { table_name: { _eq: 'evaluation' } } } }] },
    })

    const financing = await this.fetchData({
      query: { name: 'find', model: 'Financing' },
      filter: { id_sale_order: { _eq: sale.id } },
      force: true,
    })

    const evaluations = financing?.[0]?.evaluations.filter(evaluation => evaluation.status.isApproved)

    if (evaluations?.length) {
      await Promise.all(evaluations?.map(async evaluation => {
        await this.pushData(({
          model: 'Evaluation',
          fields: {
            id: evaluation.id,
            id_process_status: closed[0].id,
            id_closing_reason: resigned[0].id,
          },
        }))
      }))
    }
  }

  get change () {
    const { formData, loaded } = this

    return stringifySafe([formData, loaded])
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
    }
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  paymentValid (payments) {
    const { minValue } = this
    const suma: number = payments.reduce((total, item) => {
      return total + Number(item.amount)
    }, 0)

    return suma >= minValue
  }

  get disabled () {
    return false
  }

  get isDigitalContract () {
    const { formData: { documentType }, documentFilesParameters } = this
    if (!documentType) return false

    const file = documentFilesParameters.find(file => file.name === `buyer_${documentType.name}` || file.name === documentType.name)

    if (file) {
      this.setFileProperties('contract', file)
    } else {
      this.filesProperties.contract = { name: null }
    }
    this.setFileProperties('contract', file)
    return documentType?.name === 'digital_contract'
  }

  get isInvoice () {
    const { formData: { documentType } } = this
    const isInvoice = documentType?.name.includes('invoice')

    if (isInvoice) {
      this.fields.linkContract.properties.required = false
    } else {
      this.fields.linkContract.properties.required = true
    }

    return isInvoice
  }

  get saleOrderToLegalReview () {
    const { saleOrder } = this

    return saleOrder?.status?.isLegalReview
  }

  filesValidation () {
    const { formData } = this

    const fields = ['filePenalties', 'fileLegalReport', 'contract']

    for (const field of fields) {
      if (formData[field].length === 0) {
        if (this.$refs[field]) {
          this.$refs[field].errorMessage = 'Debe subir un archivo'
        }
        return true
      } else {
        this.$refs[field].errorMessage = ''
      }
    }

    return false
  }

  get missingPenalties () {
    const { penalties } = this

    return penalties?.some(penalty => penalty.status.isToConfirm)
  }

  get pendingPenalties () {
    const { penalties } = this

    return penalties?.some(penalty => penalty.status.isPending)
  }

  get showValidationDocument () {
    const { document } = this

    return document?.status?.isLegalReview
  }

  get showLegalized () {
    const { document } = this

    return document?.status?.isLegalized
  }

  get showActive () {
    const { document } = this
    return document?.status?.isActive
  }

  get showToRegister () {
    const { document } = this
    return document?.status?.isToRegister
  }

  get isAnswered () {
    const { formData } = this

    return this.validateEveryAnswer(formData, true)
  }
  }
