
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import LaborDataContent from '@/components/person/laborData/LaborDataContent.vue'
  import ReferenceAlignment from '@/components/person/ReferenceAlignment.vue'
  import AddressAlignment from '@/components/person/AddressAlignment.vue'
  import BankDataAlignment from '@/components/person/BankDataAlignment.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { CountriesInterface, Details } from '@/utils/generalInterface'
  import { ConsultRutInterface, PersonsDetails } from '@/store/persons/state'
  import { Address, bankDataFull, personalReferenceData, PersonFormatted } from '@/store/persons/person'
  import { AddressData } from '@/store/persons/address/addressInterfaces'
  import { fixDate, fixPrice, isEmpty, sendNotification } from '@/utils/general'
  import { plainToInstance } from 'class-transformer'
  import { Email, Gender, MaritalStatus, Person, Person as PersonEntity, Phone } from '@/entities/persons'
  import { Country } from '@/entities/public'
  import dayjs from 'dayjs'
  import { PersonView } from '@/components/forms/view/PersonView'
  import { Debounce } from '@/utils/decorators'
  import GPhone from '@/components/core/input/GPhone.vue'
  import CountrySelector from '@/components/core/input/CountrySelector.vue'
  import GRut from '@/components/core/input/GRut.vue'

@Component({
  components: {
    GRut,
    CountrySelector,
    GPhone,
    GAlert,
    GDatePicker,
    BankDataAlignment,
    AddressAlignment,
    ReferenceAlignment,
    LaborDataContent,
  },
  methods: {
    ...mapMutations('persons', [
      'setId',
      'setDisabledReferenceField',
      'setRutError',
      'setResetPhone',
    ]),
    ...mapActions('persons/labor', ['laborUpdateStatus']),
    ...mapActions('persons/reference', ['deleteReference']),
    ...mapActions('persons', [
      'getPersonType',
      'getGender',
      'getCivilStatus',
      'createPerson',
      'updatePerson',
      'getCountries',
      'getFilterCountries',
      'getCountryById',
      'cleanFieldsRequired',
      'updatePhoneNumber',
    ]),
  },
  computed: {
    ...mapGetters('persons', [
      'personType',
      'gender',
      'civilStatus',
      'countries',
      'isAccountRequired',
      'isReferenceRequired',
      'isLaborDataRequired',
      'rutError',
      'resetPhone',
      'loading',
    ]),
    ...mapGetters('persons/reference', ['relationShips']),
  },
})
  export default class PersonFields extends PersonView {
  @Prop({ type: String, default: null }) type!: string
  gender!: Array<Details>;
  title = 'Persona'
  civilStatus!: Array<Details>;
  loading!: boolean
  rutError!: boolean;
  isReferenceRequired!: boolean;
  isLaborDataRequired!: boolean;
  countries!: CountriesInterface[];
  personBankData: bankDataFull[] = [];

  setId!: (id: number | null) => void;
  setRutError!: (data: boolean) => void;
  updatePhoneNumber!: ({ id, phone }: { id: number | null, phone: string }) => void;
  getCountries!: () => void;
  getCountryById!: (id: number) => void;
  getPersonType!: () => void;
  getGender!: () => void;
  getCivilStatus!: () => void;
  createPerson!: ({ person, close }: {
    person: PersonsDetails;
    close: boolean;
  }) => any;

  deleteReference!: (reference: personalReferenceData) => void;
  laborUpdateStatus!: (id: number) => void;
  updatePerson!: (person: PersonsDetails) => Promise<void>;
  cleanFieldsRequired!: () => void;
  setResetPhone!: (phone) => void
  resetPhone!: string | null
  getFilterCountries!: (name: string) => void;

  isPersonChanged = null
  rutInfoOpenGenioConflictPhone = false;
  rutInfoTitleGenioConflictPhone = '';
  rutInfoSubtitleGenioConflictPhone = '';
  rutInfoOpenGenio = false;
  rutInfoTitleGenio = '';
  rutInfoSubtitleGenio = '';
  rutInfoOpen = false;
  rutInfoTitle = '';
  rutInfoSubtitle = '';
  rutErrorMessage = ''
  rutConsulted = false;
  createNewPerson = false;
  personDirectionData: AddressData[] = [];
  personReferenceData: personalReferenceData[] = [];
  personLaborData: any[] = [];
  relationShips!: Details[];
  isPersonFound = false;
  searchCountry = '';
  removeLaborFlag = false;
  phonePersonalError = false
  phoneLaborError = false
  titleLabor =
    '¿Estás seguro de que quieres desvincular\n' + 'este dato laboral? ';

  laborToRemove: any = null;
  subtitleLabor = '';
  removeDirectionFlag = false;
  referenceToRemove: personalReferenceData = {} as personalReferenceData;
  referenceFlag = false;
  referenceSubtitle = '';
  referenceTitle =
    '¿Estás seguro de que quieres desvincular\n' +
    'esta referencia telefónica?';

  titleDirection = '¿Estás seguro de que quieres desvincular esta dirección?';
  subtitleDirection = '';
  addressToRemove: Address | null = null;
  bankDataToRemove: bankDataFull | null = null;
  titleBankData =
    '¿Estás seguro de que quieres desvincular este dato bancario?';

  isAccountRepeated!: boolean;
  subtitleBankData = '';
  removeBankDataFlag = false;
  biPersonalFlag = false;
  biPersonalTitle = '¿Es una cuenta bipersonal?';
  biPersonalSubtitle = '';

  person: PersonsDetails = {
    age: null,
    id: null,
    name: '',
    secondName: '',
    surname: '',
    secondSurname: '',
    rut: '',
    phone: '',
    phoneWork: '',
    email: '',
    emailWork: '',
    birthDate: '',
    birthDateDisplay: '',
    phoneWorkDisplay: '',
    type: 'natural_person',
    maritalStatus: '',
    nationality: null,
    gender: '',
    bankData: [],
    directionData: [],
    laborData: [],
    genderName: '',
    nationalityName: '',
    phoneReference: {} as PersonsDetails,
    phoneReferences: [],
  };

  phoneLoading = false
  rutLoading = false

  get personCreatable (): boolean {
    return (
      !isEmpty(this.person.name) &&
      !isEmpty(this.person.phone) &&
      !isEmpty(this.person.surname) &&
      !isEmpty(this.person.type) &&
      !this.rutError
    )
  }

  get addressCreatable (): boolean {
    return (
      !isEmpty(this.person.name) &&
      !isEmpty(this.person.surname) &&
      !isEmpty(this.person.type) &&
      !this.rutError
    )
  }

  get isButtonDisabled (): boolean {
    return (
      this.isAccountRequired ||
      this.isAddressRequired ||
      this.isReferenceRequired ||
      this.isLaborDataRequired ||
      this.rutError
    )
  }

  @Watch('loading', { immediate: true })
  onLoading (val) {
    if (!val) {
      this.phoneLoading = false
      this.rutLoading = false
    }
  }

  @Watch('personEntity', { immediate: true, deep: true })
  async onChangePerson (val: Person): Promise<void> {
    this.rutLoading = false
    this.phoneLoading = false

    if (val) {
      if (isEmpty(this.person.phone) && isEmpty(this.person.rut)) {
        await this.confirmSetRutDataGenio()
      } else {
        this.processPerson(this.getFoundPerson)
      }
    }
  }

  processPerson (val: PersonFormatted) {
    if (!val || this.personEntity.type.name !== 'natural_person') return
    const fullName = `${val.name} ${val.secondName || ''} ${val.surname || ''} ${val.secondSurname || ''}`

    if (isEmpty(this.person.phone) && !this.personIdComputed) {
      this.openRutInfoGenio(val.rut, fullName)
    } else if (val.phone && val.rut && this.person.phone && val.phone !== this.person.phone) {
      if (val.id !== this.person?.id && this.person?.rut === val.rut && this.person?.id) {
        sendNotification('No puedes asignar un rut de una persona existente a otra', 'error')
        this.person.rut = ''
        this.setFoundPerson(null)
        this.$emit('update-person-id')
        return undefined
      }
      this.openRutInfoGenioConflictPhone(val, fullName)
    } else if (val.phone === this.person.phone && val.name && this.person.id !== val.id) {
      this.openRutInfoGenioWithPhone(val.phone, fullName)
    }

    if (!this.rutInfoOpenGenio && !this.rutInfoOpenGenioConflictPhone) {
      this.getCountryById(val.nationality)
    }
  }

  openRutInfoGenio (rut: string, fullName: string) {
    this.rutInfoOpenGenio = true
    this.rutInfoTitleGenio = `Según nuestro sistema, el rut ${rut} corresponde a:`
    this.rutInfoSubtitleGenio = fullName
  }

  openRutInfoGenioConflictPhone (val: PersonFormatted, fullName: string) {
    this.rutInfoOpenGenioConflictPhone = true
    this.rutInfoTitleGenioConflictPhone = `Según nuestro sistema, el rut ${val.rut} corresponde a: ${fullName} con el teléfono personal: ${val.phone}`
    this.rutInfoSubtitleGenioConflictPhone = `¿Deseas remplazar el teléfono personal por ${this.person.phone}`
  }

  openRutInfoGenioWithPhone (phone: string, fullName: string) {
    this.rutInfoOpenGenio = true
    this.rutInfoTitleGenio = `Según nuestro sistema, el telefono ${phone} corresponde a:`
    this.rutInfoSubtitleGenio = fullName
  }

  @Watch('resetPhone', { immediate: true })
  onResetPhone (val) {
    if (!isEmpty(val)) {
      this.phoneLoading = false
      this.person.phone = val
      this.setResetPhone(null)
    }
  }

  @Watch('rutInfo', { immediate: true, deep: true }) onRutChange (
    val: ConsultRutInterface
  ): void {
    if (val && !isEmpty(val.firstName) && !isEmpty(this.person.rut) && val.rut !== this.getFoundPerson?.rut) {
      this.openAlert = true
      this.alertTitle = `Según nuestro sistema, el rut ${val.rut} corresponde a:`
      this.alertSubtitle = `${val.firstName} ${
        val.secondName ? val.secondName : ''
      } ${val.firstLastName ? val.firstLastName : ''} ${
        val.secondLastName ? val.secondLastName : ''
      }`
    }

    if (val && val.rut) {
      this.person.rut = val.rut
    }
    this.phoneLoading = false
    this.rutLoading = false
  }

  @Watch('personId')
  onChangePersonId (val: number): void {
    this.person.id = val
    this.emitEmbed()
  }

  @Watch('person', { immediate: true, deep: true })
  onPersonUpdate (person) {
    this.isPersonChanged = person?.id
  }

  @Watch('searchCountry') onCountryChange (val: string): void {
    if (val === this.person.nationalityName) {
      return
    }
    if (!isEmpty(val)) {
      this.getFilterCountries(val)
    } else {
      this.getCountries()
    }
  }

  @Watch('rutError') onRutError (val: boolean): void {
    if (val && this.person && this.person.rut && this.person.rut.length < 8) {
      this.rutErrorMessage = 'Rut debe tener al menos 8 caracteres'
    } else if (val) {
      this.rutErrorMessage = 'Rut no válido'
    }
    this.rutLoading = false
  }

  async mounted (): Promise<void> {
    this.getGender()
    this.getPersonType()
    this.getCivilStatus()
    this.getCountries()
    this.metadata = {}
    this.setTheBackup()
    this.findRutInfoFound()
    const { personIdComputed, getFoundPerson } = this

    if (personIdComputed) {
      await this.getPersonById(parseInt(personIdComputed))
    }
    if (getFoundPerson) {
      await this.getPersonById(getFoundPerson.id)
    }
  }

  setTheBackup () {
    const { backup } = this
    if (!backup) {
      return
    }
    const naturalForm = 'naturalForm' in backup && backup.naturalForm

    if (naturalForm) {
      this.person = backup.naturalForm
    }
  }

  findRutInfoFound () {
    const { rutInfo } = this

    if (!rutInfo) return

    this.confirmSetRutData()
  }

  initPerson (): void {
    this.person = {
      age: null,
      id: null,
      name: '',
      secondName: '',
      surname: '',
      secondSurname: '',
      rut: '',
      phone: '',
      phoneWork: '',
      email: '',
      emailWork: '',
      birthDate: '',
      birthDateDisplay: '',
      phoneWorkDisplay: '',
      type: 'natural_person',
      maritalStatus: '',
      nationality: null,
      gender: '',
      bankData: [],
      directionData: [],
      laborData: [],
      genderName: '',
      nationalityName: '',
      phoneReference: {} as PersonsDetails,
      phoneReferences: [],
    }

    this.personBankData = []
    this.personDirectionData = []
    this.createNewPerson = false
    this.isPersonFound = false
    this.cleanFieldsRequired()
  }

  setPerson (person: PersonFormatted, newPhone: string = ''): void {
    this.setId(person.id)
    const personBackup = this.person

    if (isEmpty(newPhone)) {
      this.person = {
        id: person.id,
        name: person.name,
        secondName: person.secondName,
        surname: person.surname,
        secondSurname: person.secondSurname,
        rut: person.rut,
        phone: person.phone,
        phoneWork: person.phoneWork,
        phoneWorkDisplay: person.phoneWork,
        email: person.email || personBackup?.email,
        emailWork: person.emailWork || personBackup?.emailWork,
        birthDate: person.birthDate || personBackup?.birthDate,
        birthDateDisplay: person.birthDate || personBackup?.birthDate,
        type: person.type || this.type,
        maritalStatus: person.maritalStatus || personBackup?.maritalStatus,
        nationality: person.nationality || personBackup?.nationality,
        gender: person.gender || personBackup?.gender,
        bankData: person.bankData,
        laborData: person.laborData,
        directionData: person.directionData,
        genderName: person.genderName || personBackup?.genderName,
        nationalityName: person.nationalityName || personBackup?.nationalityName,
        age: person.age || personBackup?.age,
        phoneReference: null,
        phoneReferences: person.references,
      }
      return
    }
    this.person = {
      id: person.id,
      name: person.name,
      secondName: person.secondName,
      surname: person.surname,
      secondSurname: person.secondSurname,
      rut: person.rut,
      phone: newPhone,
      phoneWork: person.phoneWork,
      phoneWorkDisplay: person.phoneWork,
      email: person.email,
      emailWork: person.emailWork,
      birthDate: person.birthDate,
      birthDateDisplay: person.birthDate,
      type: person.type || this.type,
      maritalStatus: person.maritalStatus,
      nationality: person.nationality,
      gender: person.gender,
      bankData: person.bankData,
      laborData: person.laborData,
      directionData: person.directionData,
      genderName: person.genderName,
      nationalityName: person.nationalityName,
      age: person.age,
      phoneReference: null,
      phoneReferences: person.references,
    }
  }

  async send () {
    await this.createUpdatePerson(this.person)
  }

  async createUpdatePerson (person: PersonsDetails, redirect = true): Promise<any> {
    if (!this.$refs.form.validate() || this.phonePersonalError || this.phoneLaborError) {
      await this.setFieldError()
      return
    }

    this.loadingForm = true
    const { getEmbedPerson } = this
    if ((person && person.id) || getEmbedPerson?.id) {
      const data = person?.id ? person : getEmbedPerson
      await this.updatePerson(data)
      this.emitEmbed()
    } else if (!redirect) {
      return this.createPerson({ person, close: false })
    } else {
      await this.createPerson({ person, close: false })
      this.emitEmbed()
    }

    if (redirect) {
      this.initValues()
      this.$router.back()
    }
  }

  initValues () {
    this.initPerson()
    this.setFoundPerson(null)
    this.setResetPhone(null)
    this.setRutInfo(null)
    this.setRutError(false)
  }

  beforeDestroy () {
    this.initValues()
  }

  validateRut (rut: string): void {
    if (this.person.id || (rut?.length > 7)) {
      this.setRutError(false)
      this.rutErrorMessage = ''
      this.rutLoading = true
      this.findPerson({ phone: null, rut, id: null })
    } else if (rut && rut.length < 8 && !isEmpty(rut)) {
      this.setRutError(true)
      this.rutErrorMessage = 'Rut debe tener al menos 8 caracteres'
    } else {
      this.setRutError(false)
      this.rutErrorMessage = ''
    }
  }

  findPhone (phone: string): void {
    if (!isEmpty(phone) && phone.length > 5) {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          // @ts-ignore
          detail: {
            open: true,
          },
        })
      )
      this.phoneLoading = true
      this.findPerson({ phone, rut: null, id: null })
    }
  }

  async confirmSetRutDataGenio (): Promise<void> {
    if (this.getFoundPerson) {
      this.isPersonFound = !!this.getFoundPerson.id

      if (this.getFoundPerson.id) {
        this.lookFieldsRequired(this.getFoundPerson.id)
      }
      this.setPerson(this.getFoundPerson)

      if (this.getFoundPerson?.nationality) {
        this.getCountryById(this.getFoundPerson.nationality)
      }
      this.rutInfoOpenGenio = false
    }
  }

  closeRutInfoGenio (): void {
    this.rutInfoOpenGenio = false
    this.rutInfoTitleGenio = ''
    this.rutInfoSubtitleGenio = ''
    this.person.rut = ''
    this.person.phone = ''
    this.isPersonFound = false
    this.setFoundPerson(null)
    this.setRutInfo(undefined)
  }

  closeRutInfoGenioConflictPhone (): void {
    this.rutInfoOpenGenioConflictPhone = false
    this.rutInfoTitleGenioConflictPhone = ''
    this.rutInfoSubtitleGenioConflictPhone = ''
    this.person.rut = ''
    this.person.phone = ''
    this.isPersonFound = false
    this.setFoundPerson(null)
    this.setRutInfo(undefined)
  }

  confirmSetRutDataGenioConflictPhone (): void {
    if (this.getFoundPerson) {
      this.isPersonFound = !!this.getFoundPerson.id

      if (this.getFoundPerson.id) {
        this.lookFieldsRequired(this.getFoundPerson.id)
      }
      this.setPerson(this.getFoundPerson, this.person.phone || '')

      if (this.getFoundPerson?.nationality) {
        this.getCountryById(this.getFoundPerson.nationality)
      }
      this.rutInfoOpenGenioConflictPhone = false
      this.updatePhoneNumber({
        id: this.getFoundPerson.id,
        phone: this.person.phone || '',
      })
    }
  }

  closeRutInfo () {
    this.openAlert = false
    this.person.rut = ''
    this.alertTitle = ''
    this.alertSubtitle = ''
    this.setRutInfo(undefined)
  }

  confirmSetRutData () {
    this.rutConsulted = true
    this.person.name = this.rutInfo.firstName
    this.person.secondSurname = this.rutInfo.secondLastName
    this.person.secondName = this.rutInfo.secondName
    this.person.surname = this.rutInfo.firstLastName
    this.person.rut = this.rutInfo.rut
    this.openAlert = false
  }

  emitEmbed () {
    const { personId, person, typeInfo, backup } = this
    const id = personId || person?.id
    const data = this.setData(person, id)

    if (backup) {
      backup[typeInfo] = data
      this.setBackup(backup)
    } else {
      this.setBackup({ [typeInfo]: data, naturalForm: person })
    }

    this.embedPerson({ type: typeInfo, person: data })
  }

  setData (person, id) {
    const data = plainToInstance(PersonEntity, {
      id,
      firstName: person.name,
      secondName: person.secondName,
      surname: person.surname,
      secondSurname: person.secondSurname,
      uid: person.rut || '',
      mainPhone: person.phone || '',
      type: { name: person?.type || this.type },
    })
    if (person.nationality) {
      const { countries } = this
      data.country = plainToInstance(Country, countries.find(country => country.id === person.nationality))
    }
    if (!isEmpty(person?.email) || isEmpty(person?.emailWork)) {
      data.email = plainToInstance(Email, { personal: person?.email || '', work: person?.emailWork || '' })
    }
    if (person?.phoneWork) {
      data.secondaryPhone = plainToInstance(Phone, { work: person.phoneWork })
    }
    if (!isEmpty(person.gender)) {
      const { gender } = this
      data.gender = plainToInstance(Gender, gender.find(gen => gen.name === person.gender))
    }
    if (!isEmpty(person.birthDate)) {
      data.birthDate = dayjs(fixDate(person.birthDate))
    }
    if (!isEmpty(person.maritalStatus)) {
      const { civilStatus } = this
      data.maritalStatus = plainToInstance(MaritalStatus, civilStatus.find(status => status.name === person.maritalStatus))
    }

    return data
  }

  async removeLaborData (labor: any): Promise<void> {
    this.removeLaborFlag = true
    this.laborToRemove = labor
    const dependent = `${labor.workerType} / ${labor.incomeType} / ${
      labor.timeSinceAdmission
    } /\n
    ${fixPrice(labor.rent)} / ${labor.jobTitle}`
    if (labor.idTypeWorker === 'dependent') {
      this.subtitleLabor = dependent
    } else {
      this.subtitleLabor = `${labor.workerType} / ${fixPrice(labor.rent)}`
    }
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'labor_data' } },
    })
    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: labor.idLaborData } },
          { parameter: { process: { id: { _eq: process.id } } } },
        ],
      },
      force: true,
    })

    const filesToRemove = files.map(_ => {
      return {
        id: _.file.id,
        idFileProcess: _.id,
      }
    })

    await this.deleteFile(filesToRemove)
  }

  async editLaborData (id: number): Promise<void> {
    const { person, getFoundPerson } = this
    this.redirect = false
    const idPerson = !getFoundPerson?.id ? await this.createUpdatePerson(person, false) : getFoundPerson.id

    const newPersonId = person?.id || idPerson
    await this.$router.replace({ params: { id: newPersonId.toString() } }).catch(() => {
    })
    this.saveBackup()
    if (newPersonId) {
      await this.$router.push({
        name: 'person-labor-data',
        params: {
          model: 'Person',
          uid: newPersonId.toString(),
          id: id.toString(),
        },
      })
    }
  }

  async confirmRemoveLabor (): Promise<void> {
    if (!this.laborToRemove || !this.laborToRemove.idLaborData) {
      return
    }
    this.laborUpdateStatus(this.laborToRemove.idLaborData)

    const posLabor = this.personLaborData?.indexOf(
      // @ts-ignore
      this.laborToRemove
    )
    if (posLabor !== -1) {
      this.personLaborData.splice(posLabor, 1)
    }

    const pos = this.person.laborData?.indexOf(this.laborToRemove)
    if (pos !== -1) {
      // @ts-ignore
      this.person?.laborData?.splice(pos, 1)
    }
    this.removeLaborFlag = false
    this.$emit('update')
  }

  async confirmRemoveDirection (): Promise<void> {
    if (!this.addressToRemove || !this.addressToRemove.idPersonAddress) {
      return
    }
    await this.addressUpdateStatus(this.addressToRemove.idPersonAddress)

    const posDirection = this.personDirectionData?.indexOf(
      // @ts-ignore
      this.addressToRemove
    )
    if (posDirection !== -1) {
      this.personDirectionData.splice(posDirection, 1)
    }

    const pos = this.person.directionData?.indexOf(this.addressToRemove)
    if (pos !== -1) {
      // @ts-ignore
      this.person?.directionData?.splice(pos, 1)
    }
    this.removeDirectionFlag = false
    this.$emit('update')
  }

  removeDirection (direction: Address): void {
    this.removeDirectionFlag = true
    this.addressToRemove = direction
    this.subtitleDirection = `${direction.streetName} ${direction.streetNumber}, ${direction.cityName}, ${direction.regionAbbreviation}/${direction.addressType}`
  }

  async confirmRemoveBankData (): Promise<void> {
    if (!this.bankDataToRemove || !this.bankDataToRemove.idPersonAccount) {
      return
    }

    await this.updateAccountState(this.bankDataToRemove.idPersonAccount)
    this.removeBankDataFlag = false

    const posBankData = this.personBankData.indexOf(this.bankDataToRemove)

    if (posBankData !== -1) {
      // @ts-ignore
      this.personBankData.splice(posBankData, 1)
    }

    const pos = this.person.bankData?.indexOf(this.bankDataToRemove)
    if (pos !== -1) {
      // @ts-ignore
      this.person.bankData?.splice(pos, 1)
    }
    this.$emit('update')
  }

  async confirmRemoveReferenceData (): Promise<void> {
    if (!this.person && !this.referenceToRemove) {
      return
    }

    this.deleteReference(this.referenceToRemove)
    this.referenceFlag = false

    const pos = this.personReferenceData.indexOf(this.referenceToRemove)

    if (pos !== -1) {
      // @ts-ignore
      this.personReferenceData.splice(pos, 1)
    }

    const referencePos = this.person.phoneReferences?.indexOf(
      this.referenceToRemove
    )

    if (referencePos !== -1) {
      // @ts-ignore
      this.person.phoneReferences?.splice(referencePos, 1)
    }
    this.$emit('update')
  }

  removeReference (val: personalReferenceData): void {
    this.referenceToRemove = val
    this.referenceSubtitle = `${val.fullname} / ${val.phone} / ${val.generalRelation}`
    this.referenceFlag = true
  }

  removeBankData (bankData: bankDataFull): void {
    this.bankDataToRemove = bankData
    this.subtitleBankData = `${bankData.accountNumber}/${bankData.bank}/${bankData.accountType}`
    this.removeBankDataFlag = true
  }

  saveBackup () {
    const { backup, person } = this
    if (backup) {
      backup.naturalForm = person
      this.setBackup(backup)
    } else {
      this.setBackup({
        naturalForm: { ...person },
      })
    }
  }

  async openBankData (): Promise<void> {
    const { person, getFoundPerson } = this
    this.redirect = false
    const id = !getFoundPerson?.id ? await this.createUpdatePerson(person, false) : getFoundPerson.id

    const newPersonId = person?.id || id
    await this.$router.replace({ params: { id: newPersonId.toString() } }).catch(() => {
    })
    this.saveBackup()
    if (newPersonId) {
      await this.$router.push({
        name: 'person-bank-data',
        params: {
          model: 'Person',
          uid: newPersonId.toString(),
          id: 'create',
        },
      })
    }
  }

  async openDirectionData (): Promise<void> {
    const { person, getFoundPerson } = this
    this.redirect = false
    const id = !getFoundPerson?.id ? await this.createUpdatePerson(person, false) : getFoundPerson.id

    const newPersonId = person?.id || id
    await this.$router.replace({ params: { id: newPersonId.toString() } }).catch(() => {
    })
    this.saveBackup()
    if (newPersonId) {
      await this.$router.push({
        name: 'person-address-data',
        params: {
          model: 'Person',
          uid: newPersonId.toString(),
          id: 'create',
        },
      })
    }
  }

  async openLaborData (): Promise<void> {
    const { person, getFoundPerson } = this
    this.redirect = false
    const id = !getFoundPerson?.id ? await this.createUpdatePerson(person, false) : getFoundPerson.id

    const newPersonId = person?.id || id
    await this.$router.replace({ params: { id: newPersonId.toString() } }).catch(() => {
    })
    this.saveBackup()
    if (newPersonId) {
      await this.$router.push({
        name: 'person-labor-data',
        params: {
          model: 'Person',
          uid: newPersonId.toString(),
          id: 'create',
        },
      })
    }
  }

  async openReferenceData (): Promise<void> {
    const { person, getFoundPerson } = this
    this.redirect = false
    const id = !getFoundPerson?.id ? await this.createUpdatePerson(person, false) : getFoundPerson.id
    const newPersonId = person?.id || id
    await this.$router.replace({ params: { id: newPersonId.toString() } }).catch(() => {
    })

    this.saveBackup()

    if (newPersonId) {
      await this.$router.push({
        name: 'person-reference-data',
        params: {
          model: 'Person',
          uid: newPersonId.toString(),
          id: 'create',
        },
      })
    }
  }

  get isApplicant () {
    const { backup } = this

    return Boolean(backup?.financialForm)
  }

  get isPhonePresent () {
    const { backup, getFoundPerson } = this

    return Boolean(backup) && Boolean(getFoundPerson?.phone?.length)
  }

  get watchPerson () {
    const { getFoundPerson, personIdComputed } = this

    return { getFoundPerson, personIdComputed }
  }

  @Watch('watchPerson', { immediate: true, deep: true })
  @Debounce(2000)
  onGetFoundPerson (val): void {
    const isNotAlertOpen = !this.rutInfoOpenGenio && !this.rutInfoOpenGenioConflictPhone && !this.rutInfoOpenGenio
    // const isNotSamePerson = val.personIdComputed !== newVal?.personIdComputed && isNotAlertOpen
    if (val.getFoundPerson && val.personIdComputed && isNotAlertOpen && !this.rutLoading) {
      this.setPerson(val.getFoundPerson)
    }

    if (val?.getFoundPerson?.type === 'company') {
      this.person.rut = null
      this.rutErrorMessage = 'El rut ingresado es una empresa, cambie el tipo de persona para crear una empresa'
    }
  }

  get fieldsLoading () {
    const { rutLoading, phoneLoading } = this

    return {
      rutLoading,
      phoneLoading,
    }
  }

  @Watch('fieldsLoading', { immediate: true, deep: true })
  onRutLoading (val) {
    this.$emit('loading', val.rutLoading || val.phoneLoading)
  }

  phonePersonal (flag: boolean) {
    this.phonePersonalError = flag
  }

  phoneLabor (flag: boolean) {
    this.phoneLaborError = flag
  }
  }
