
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'
  import { File as FileLoad, FileProcess } from '@/entities/files'
  import FileDetail from '@/components/core/files/FileDetail.vue'
  import FileThumbnail from '@/components/core/files/FileThumbnail.vue'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import { handleError } from '@/store/resources/form/actions'

@Component({
  components: { GAlert, FileThumbnail, FileDetail },
  methods: {
    ...mapActions('resources/form', ['uploadImage', 'fetchData', 'deleteFile']),
    ...mapActions('files', ['insertId', 'deleteId']),
  },
  computed: {
    ...mapGetters('app', ['isMobile']),
    ...mapGetters('files', ['filesStorage']),
  },
})
  export default class GFiles extends FilesProcess {
  @Prop({ type: [Object, Array, Number, String, Boolean, FileProcess] }) readonly value!: any
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: Boolean, default: false }) multiple!: boolean;
  @Prop({ type: Boolean, default: true }) deleteFileAction!: boolean;
  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: String, default: '' }) accept!: string;
  @Prop({ type: String, default: 'mdi-camera-plus' }) icon!: string;
  @Prop({ type: String, default: null }) appendOuterIcon!: string;
  @Prop({ type: [Number, String], default: null }) fileTypeId!: number | string;
  @Prop({ type: Number, default: null }) idProcess!: number;
  @Prop({ type: Number, default: null }) id!: number;
  @Prop({ type: String, default: null }) message!: string;
  @Prop({ type: Boolean, default: false }) alertView!: boolean;
  @Prop({ type: Boolean, default: false }) maxFileSize!: boolean;

  insertId!: (id: Array<number>) => void
  deleteId!: (id: Array<number>) => void
  filesStorage!: Array<number>

  $refs: {
    fileInputRef: HTMLFormElement,
    GFileRow: HTMLElement
  };

  isMobile!: boolean

  uploadImage!: (payload: { file: File[], idFileType: number, idProcess: number }) => Promise<Array<FileLoad>>
  thumbnails: {
    id: number,
    src: string,
    type: string,
    name: string,
    size: number,
    idFileProcess: number,
    smallUrl: string,
    largeUrl: string
  }[] = []

  localFile = null
  openImage = false
  snackbar = false
  fileInputKey = Date.now()
  setValue = false
  loading = false
  shouldUploadFiles = true
  errorMessage = ''
  alert = {
    open: false,
    title: '',
  }

  alertDelete = {
    open: false,
    title: '',
  }

  get size () {
    return this.$vuetify.breakpoint.height - 275
  }

  get link () {
    const { localFile } = this

    if (localFile?.length) {
      const file = localFile[0]
      if (file instanceof FileProcess) {
        return file.file.uri
      }
      return file.src
    }
    return null
  }

  get combinedRules () {
    const { required, maxFileSize } = this
    const rulesArray = []

    if (maxFileSize) {
      const max = 1024 * 1024
      rulesArray.push(v => {
        if (!v) return true
        if (v.size === undefined) return true
        return v.size < max || `El archivo no puede superar los ${max / 1024 / 1024} MB`
      })
    }

    if (required) {
      rulesArray.push(v => !!v || 'Archivo obligatorio')
      rulesArray.push(v => (Boolean(v && v.length)) || 'Archivo obligatorio')
    }

    return rulesArray
  }

  get maxVisible () {
    const { isMobile, $refs } = this
    const width = ($refs?.GFileRow as HTMLElement)?.offsetWidth

    return !isMobile && width > 300 ? 2 : 1
  }

  get fileType () {
    if (Array.isArray(this.localFile) && this.localFile.length > 0) {
      const firstType = this.localFile[0].type
      return this.determineFileType(firstType)
    }

    if (this.localFile && this.localFile.type) {
      const type = this.localFile.type
      return this.determineFileType(type)
    }

    return ''
  }

  @Watch('localFile', { immediate: true, deep: true })
  async onChangeValue (value: File[]) {
    if (!this.shouldUploadFiles || !value) return
    const { setValue, maxFileSize } = this
    if (setValue) {
      this.setValue = false
      return null
    }

    if (value) {
      if (maxFileSize && this.isSizeFile(value)) {
        return
      }
      await this.setEmitValue(value)
    } else {
      this.thumbnails = []
    }
  }

  async setEmitValue (value) {
    if (!value || value.length === 0) {
      return
    }

    this.loading = true
    this.$emit('uploading-files', true)

    const { fileTypeId, idProcess } = this
    try {
      const resp = await this.uploadImage({ file: value, idFileType: fileTypeId, idProcess })
      if (resp) {
        this.insertId(resp.map(file => file.id))
        this.duplicateFiles(resp)
        this.emitValue(resp)
      }
    } catch (error) {
      await this.sendNetworkErrorMessage({ file: value, idFileType: fileTypeId, proceso: idProcess }, error)
    } finally {
      this.loading = false
      this.$emit('uploading-files', false)
    }
  }

  async sendNetworkErrorMessage (files, error) {
    // @ts-ignore
    if (error?.message && error?.message.includes('Network Error')) {
      await this.loadNotification({
        message: `Error en la carga del archivo verifica tu conexion a internet`,
        notificationType: 'error',
      })
    }

    if (this.isFileEmpty(files.file)) {
      await handleError(files, error)
      this.fileInputKey = Date.now()
      this.snackbar = true
      return
    }

    await handleError(files, error)
    console.error(`Error al cargar la ${this.fileType}:`, error)
    this.errorMessage = `Error desconocido al cargar ${this.fileType}`
    this.fileInputKey = Date.now()
  }

  isFileEmpty (file) {
    if (!file) return true

    if (Array.isArray(file)) {
      return file.length === 0 || file.some(f => typeof f === 'object' && Object.keys(f).length === 0)
    }

    if (typeof file === 'object') {
      return Object.keys(file).length === 0
    }

    return true
  }

  emitValue (val) {
    const { multiple } = this

    const fixFiles = val.map(file => {
      return {
        id: file.id,
        src: file.uri,
        type: file.type,
        name: file.name,
        size: file.checksum,
        idFileProcess: null,
        isFileExpired: file.isFileExpired,
        sourceLink: file.sourceLink,
        error: file.error,
        smallUrl: file?.smallUrl,
        largeUrl: file?.largeUrl,
      }
    })

    if (!multiple) {
      this.$emit('input', fixFiles)
    } else {
      this.$emit('input', this.countDuplicates([...fixFiles, ...this.thumbnails]))
    }
  }

  validateSameFile (file, thumbnail) {
    return file.name === thumbnail.name && file.size === thumbnail.size
  }

  setErrorUploadingFile (val) {
    if (this.errorMessage === '') {
      const errorMessage = (val.find(file => file?.comment))?.comment || ''
      const translate = [
        'Request failed with status code 500',
        'no general info found in penalties API',
        'error Reading data, try uploading the file again',
      ]

      // Utiliza el método 'some' para verificar si 'errorMessage' incluye alguna de las cadenas en 'translate'
      const isTranslated = translate.some(msg => errorMessage.includes(msg))

      // Asigna el mensaje de error personalizado si se encuentra una coincidencia, de lo contrario, mantiene el mensaje original
      this.errorMessage = isTranslated ? 'Error al cargar el archivo, intente de nuevo' : errorMessage
    }
  }

  @Watch('value', { immediate: true, deep: true })
  async onValueChange (val) {
    this.checkFileIsExpired(val)
    if (this.setValue) {
      this.setValue = false
      return
    }
    if (val?.length) {
      const isArrayFiles = val.every(val => val?.src)

      this.setErrorUploadingFile(val)
      if (isArrayFiles) {
        this.setArrayFiles(val)
        return
      }

      this.setValue = true
      this.cleanVariables()
      for (const fileObj of val) {
        if (fileObj instanceof FileProcess) {
          const fileInfo = await this.buildFileProcessObj(fileObj)
          this.localFile.push(fileInfo)
          this.thumbnails = this.localFile
        } else {
          this.localFile.push(fileObj)
          this.thumbnails.push(fileObj)
        }
      }
    } else {
      this.cleanVariables()
    }
  }

  setArrayFiles (val) {
    this.setValue = true
    this.localFile = val
    this.thumbnails = val
  }

  cleanVariables () {
    this.localFile = []
    this.thumbnails = []
  }

  duplicateFiles (files) {
    let cont = 0
    for (const fileData of files) {
      const fileInfo = {
        id: fileData.id,
        src: fileData.uri,
        type: fileData.type,
        name: fileData.name,
        size: fileData.checksum,
        idFileProcess: null,
        isFileExpired: fileData.isFileExpired,
        sourceLink: fileData.sourceLink,
        error: fileData.error,
      }

      const isFileAlreadyExists = this.thumbnails.some(thumb => thumb.id === fileInfo.id)
      if (isFileAlreadyExists) {
        cont++
      }
    }

    if (cont) {
      this.alert = {
        open: true,
        title: `En su carga hay (${cont}) ${cont > 1 ? 'archivos repetidos' : 'archivo repetido'}. Los archivos repetidos serán ignorados. Se ${cont > 1 ? 'cargarón' : 'cargo'} ${files.length - cont}/${files.length}`,
      }
    }
  }

  confirmAction () {
    this.alert = {
      open: false,
      title: '',
    }
    this.alertDelete = {
      open: false,
      title: '',
    }
  }

  async deleteClickedFile (file) {
    this.loading = true
    this.$emit('uploading-files', true)
    this.shouldUploadFiles = false

    try {
      const existOtherField = this.filesStorage.filter(id => id === file.id)?.length > 1
      this.deleteId([file.id])

      if (!existOtherField) {
      await this.deleteFile([file])
      }
      this.thumbnails = this.thumbnails.filter(thumb => thumb.id !== file.id)
      this.localFile = this.localFile.filter(f => f !== file)
      this.$emit('input', this.thumbnails)
    } catch (error) {
      console.error('Error al eliminar la imagen:', error)
      this.errorMessage = 'Error al eliminar la imagen'
    } finally {
      this.loading = false
      this.$emit('uploading-files', false)
      this.shouldUploadFiles = true
    }
  }

  async deleteFiles (files) {
    this.loading = true
    this.$emit('uploading-files', true)
    this.confirmAction()

    const existOtherField = this.filesStorage.filter(id =>
      files.map(f => f.id).some(fileId => fileId === id)
    )?.length > 1
    this.deleteId(files.map(f => f.id))

    if (!existOtherField) {
      await this.deleteFile([files])
    }
    this.errorMessage = ''
    this.thumbnails = []
    this.localFile = []
    this.$emit('input', [])
    this.loading = false
    this.$emit('uploading-files', false)
  }

  clearFiles () {
    const { localFile } = this
    this.errorMessage = ''
    const len = localFile.length
    this.alertDelete = {
      open: true,
      title: `¿Está seguro que desea eliminar ${len > 1 ? 'todos los archivos' : 'el archivo'}?`,
    }
  }

  outerClick () {
    this.$nextTick(() => {
      const fileInputElement = this.$refs.fileInputRef.$el.querySelector('input[type="file"]')
      fileInputElement.click()
    })
  }

  checkFileIsExpired (val) {
    if (!val?.length) return

    const isErrorFile = val?.some(file => file?.error)
    const isFileProcess = val?.some(file => file instanceof FileProcess)
    let isExpired: boolean

    if (isFileProcess) {
      isExpired = val.find(fileProcess => fileProcess?.file?.isFileExpired)
    } else {
      isExpired = val.find(file => file?.isFileExpired)
    }
    if (isExpired) {
      const { label, disabled } = this
      if (!disabled) {
        this.setMessage(`El ${label} esta vencido, cargue uno nuevo`, true)
      }
    } else {
      this.setMessage('', false)
    }

    if (isErrorFile) {
      this.setMessage('Archivo no corresponde, cargue uno nuevo', true)
    } else if (!isExpired && !isErrorFile) {
      this.setMessage('', false)
    }
  }

  setMessage (message, flag) {
    this.$emit('uploading-files', flag)
    this.loading = flag

    this.errorMessage = message
  }

  countDuplicates (objectsArray: { id: number }[]): { id: number }[] {
    const uniqueIds: Record<number, boolean> = {}
    const uniqueObjects: { id: number }[] = []

    objectsArray.forEach(obj => {
      if (!uniqueIds[obj.id]) {
        uniqueIds[obj.id] = true
        uniqueObjects.push(obj)
      }
    })

    const duplicates = objectsArray.length - uniqueObjects.length

    if (duplicates) {
      this.alert = {
        open: true,
        title: `En su carga hay (${duplicates}) ${duplicates > 1 ? 'archivos repetidos' : 'archivo repetido'}. Los archivos repetidos serán ignorados. Se ${duplicates > 1 ? 'cargarán' : 'cargó'} ${objectsArray.length - duplicates}/${objectsArray.length}`,
      }
    }

    return uniqueObjects
  }

  isSizeFile (file) {
    const max = 1024 * 1024

    return file.size > max
  }

  determineFileType (type) {
    if (type.includes('application/pdf')) {
      return 'pdf'
    } else if (type.startsWith('image/')) {
      return 'imagen'
    } else if (type.startsWith('video/')) {
      return 'video'
    } else {
      return 'archivo desconocido'
    }
  }
  }
