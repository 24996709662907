import { Type } from 'class-transformer'
import { Entity } from '..'
import { FileProcess } from '.'

export class FileValidation extends Entity {
    @Type(() => FileProcess)
    fileProcess: FileProcess;

    name: string;
  value: boolean | null;
}
