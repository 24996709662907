
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'

@Component
  export default class TechnicalSpecification extends FilesProcess {
  @Prop({ type: Number, default: null }) id!: number

  link = ''

  // Methods

  // Getters

  // Watchers
  @Watch('id', { immediate: true })
  async onIdChanged (value: number) {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'version_year' } },
    })

    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: value } },
          { parameter: { process: { id: { _eq: process?.[0]?.id } } } },
        ],
      },
    })

    this.link = files.find(file => file.parameter.name === 'technical_sheet_model')?.file?.uri || ''
  }
  }
