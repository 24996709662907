import { ResourceHeaders } from '..'
import {
  AlertsHeader,
  CreateLinkedRecordHeader,
  IdHeader,
  MarketPricesHeader,
  PhotoHeader,
  PriceHeader,
  RedirectDtHeader,
  StockCharacteristicsHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '../../helpers'
import { extendsObj } from '@/utils/data'
import { CellType, TableCellHeader } from '@/components/dataTables/cell'

export const headers: ResourceHeaders = [
  extendsObj<TableCellHeader>({
    text: 'Nº',
    value: '@',
    width: 120,
  }, IdHeader),
  { ...PhotoHeader, text: 'Foto', value: '@' },
  { ...AlertsHeader, text: 'Alerta', value: 'alerts', sortable: true },
  extendsObj<TableCellHeader>({
    text: 'Stock',
    value: '@',
    options: {
      path: 'auto.name',
      trigger: item => Helpers.linkedModel({ constructor: { name: 'StockPublish' }, ...item }),
    },
  }, VehicleHeader),
  { ...VehicleStatusHeader, text: 'Estado de stock', value: 'stockStatus' },
  { ...TextHeader, text: 'Llave', value: 'keyIdentifier.keyId', width: 120 },
  { ...StockCharacteristicsHeader, text: 'Característica', value: 'auto' },
  { ...TextHeader, text: 'Kilometraje', value: 'kms' },
  { ...TextHeader, text: 'Dueños', value: 'owners' },
  { ...PriceHeader, text: 'Pie 20%', value: 'pieTwenty' },
  { ...PriceHeader, text: 'Pie 40%', value: 'pieForty' },
  {
    ...MarketPricesHeader,
    text: 'Leads',
    value: 'received',
    width: 180,
    options: {
      component: CellType.text,
      options: {
        align: 'start',
      },
    },
  },
  {
    ...RedirectDtHeader,
    text: 'Ver leads',
    value: '@',
    options: {
      path: '/datatable/staff/staff_leads',
      queryValidationString: 'leads',
      query: {
        deals: {
          stock: {
            id: {
              _eq: '$id',
            },
          },
        },
      },
    },
  },
  {
    ...CreateLinkedRecordHeader,
    text: 'Crear lead',
    value: 'buttonCreateLead',
    options: {
      tooltip: 'Crear Lead',
      action: {
        color: 'purple',
        icon: 'mdi-plus',
        only: true,
        trigger: item => Helpers.linkedModel(item),
      },
    },
  },
]

export default function (): ResourceHeaders {
  return headers
}
