import gql from 'graphql-tag'

export const getDailyStock = gql`
  query daily_stock ($model: sales_stock_daily_bool_exp) {
    records: sales_stock_daily (where: $model){
      id
      reportDate: report_date
      stock {
        id
        stockDetail: stock_detail {
          totalCost: total_cost
          acquisitionType: acquisition_type {
            id
            name
            description
          }
        }
      }
      processStatus: process_status {
        id
        status {
          id
          name
          description
          color
        }
      }
    }
  }
`

export const getSales = gql`
  query get_effective_sales_report_by_date($start_date: String!, $end_date: String!) {
    records: get_effective_sales_report_by_date(start_date: $start_date, end_date: $end_date)
  }
`

export const getSalesTarget = gql`
  {
    settings_process(where: {table_name: {_eq: "sale_order"}})
    {
      config
    }
  }
`

export const getSalesEndpoint = gql`
  query get_sales_handing_report_by_date($start_date: String!, $end_date: String!){
    records: get_sales_handing_report_by_date (start_date: $start_date, end_date: $end_date)
  }
`

export const getPurchasesTarget = gql`
  {
    settings_process(where: {table_name: {_eq: "purchase_order"}})
    {
      config
    }
  }
`

export const getSalesAmountAggregate = gql`
  query utilitySales( $aggregate: sales_sale_order_item_bool_exp) {
    records: sales_sale_order_item_aggregate(where: $aggregate) {
      aggregate {
        sum {
          amount
        }
      }
      nodes {
        id
        amount
        saleOrder: sale_order {
          id
          deal {
            id
            stock {
              id
              stockDetail: stock_detail {
                totalCost: total_cost
                acquisitionType: acquisition_type {
                  id
                  name
                  description
                }
              }
              soldDate: sold_date
            }
          }
        }
      }
    }
  }
`

export const getPurchasesEndpoint = gql`
  query get_purchase_by_date($start_date: String!, $end_date: String!){
    records: get_purchase_by_date (start_date: $start_date, end_date: $end_date)
  }
`

export const getPurchases = gql`
  query purchases($aggregate: sales_stock_view_bool_exp) {
    records: sales_stock_view_aggregate(where: $aggregate) {
      aggregate {
        count
        sum {
          totalCost: total_cost
        }
      }
      nodes {
        stockCreated: stock_created
        totalCost: total_cost
        stock {
          soldDate: sold_date
          status {
            status {
              id
              description
              name
              color
            }
          }
        }
        channel {
          id
          name
          description
          color
        }
        executive {
          id
          person {
            id
            firstName: first_name
            surname
          }
        }
        acquisitionType: acquisition_type {
          id
          name
          description
        }
        auto {
          registrationPlate: registration_plate
          versionYear: version_year {
            version {
              model {
                name
                brand {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

export const getStockSpecs = gql`
  query stockSpecs($model: sales_stock_view_bool_exp) {
    salesStockView: sales_stock_view(where: $model) {
      stock {
        prices(limit: 1, order_by: {id: desc}) {
          amount
        }
      }
      status {
        status {
          id
          description
          name
        }
      }
      auto {
        id
        generation {
          id
        }
        version: version_year {
            id
          version {
            id
          }
          year {
            id
          }
        }
        registrationPlate: registration_plate
      }
    }
  }
`

export const getStock = gql`
  query stockView($model: sales_stock_view_bool_exp) {
    records: sales_stock_view(where: $model) {
      enablementCost: enablement_cost
      stock {
        id
        createdAt: created_at
        prices(limit: 1, order_by: {id: desc}) {
          amount
          margin
          bonus {
            amount
          }
        }
        stockDetail: stock_detail {
          totalCost: total_cost
          appraisalAmount: appraisal_amount
          expectedPublicationAmount: expected_publication_amount
          acquisitionType: acquisition_type {
            id
            name
            description
          }
        }
      }
      status {
        status {
          id
          description
          name
          color
        }
      }
      auto {
        id
        generation {
          id
          versionYear: version_year {
            id
            year {
              id
            }
            version {
              id
              name
              model {
                id
                name
                id
                brand {
                  id
                  name
                }
              }
            }
          }
        }
        version: version_year {
          id
          version {
            id
            name
            model {
              id
              name
              id
              brand {
                id
                name
              }
            }
          }
          year {
            id
          }
        }
        registrationPlate: registration_plate
      }
    }
  }
`

export const getStockStatus = gql`
  query stockStatus($model: settings_process_status_bool_exp, $aggregate: sales_stock_view_bool_exp, $order: [settings_process_status_order_by!]) {
    records: settings_process_status(where: $model, order_by: $order) {
      status {
        id
        name
        description
        color
      }
      stocks: stock_view_aggregate(where: $aggregate) {
        aggregate {
          count(distinct: true)
          sum {
            totalCost: total_cost
          }
        }
      }
    }
  }
`

export const dailyStockAggregate = gql`
  query aggregates($model: settings_process_status_bool_exp, $aggregate: sales_stock_view_bool_exp, $order: [settings_process_status_order_by!]) {
    aggregates: settings_process_status(where: $model, order_by: $order) {
      status {
        id
        description
      }
      stocks: stock_view_aggregate(where: $aggregate) {
        nodes {
          created:stock_created
          cost:total_cost
        }
      }
    }
  }
`
