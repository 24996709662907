import { ResourceHeaders } from '..'
import {
  BusinessHeader,
  ContactHeader,
  DateHeader,
  IdHeader,
  InspectionSummary,
  NegotiationStatusHeader,
  PriceHeader,
  VehicleHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Process } from '@/entities/settings'
import { Inspection } from '@/entities/purchase'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 130,
  },
  { ...VehicleHeader, text: 'Vehículo', value: 'auto' },
  { ...DateHeader, text: 'Fecha creación', value: 'created' },
  { ...BusinessHeader, text: 'Negocio', value: 'pipeline', width: 70 },
  { ...NegotiationStatusHeader, text: 'Estado negociación', value: '@', width: 70 },
  {
    ...InspectionSummary,
    text: 'Reporte inspección',
    value: 'inspection',
    options: {
      tooltip: 'Reporte',
      action: {
        color: 'purple',
        icon: 'mdi-clipboard-text-search-outline',
        only: true,
      },
    },
  },
  {
    align: 'center',
    width: 100,
    filterable: true,
    ...ContactHeader,
    text: 'Ejecutivo',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'executive',
      },
      path: 'executive.name',
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Inspection, process: Process, system: string) => Helpers.contact(item, 'executive', process, system),
      },
      disabledMissingProperty: ['executive.phone'],
    },
  },
  { ...PriceHeader, text: 'Oferta tasación', value: 'dtPrice' },
  { ...PriceHeader, text: 'Costo inspección', value: 'inspectionAmount', width: 160 },
  { ...PriceHeader, text: 'Precio autorizado', value: 'dtNegotiationResponse' },
  { ...PriceHeader, text: 'Precio apelado', value: 'dtNegotiationResponseAppealed' },
]

export default function (): ResourceHeaders {
  return headers
}
