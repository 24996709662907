var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mt-6",attrs:{"fluid":""}},[(_vm.showGeneralResults)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('utility-results',{attrs:{"chart-data":_vm.chartData}})],1)],1):_vm._e(),(_vm.showGeneralResults)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('purchases-results',{attrs:{"chart-data":_vm.chartData}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('consignment-results',{attrs:{"chart-data":_vm.chartData}})],1)],1):_vm._e(),(_vm.showGeneralResults)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('sales-results',{attrs:{"chart-data":_vm.chartData}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('stock-results',{attrs:{"chart-data":_vm.chartData}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('dashboard-card',{attrs:{"title":"Compras por canal"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.purchases,"path-to-category":['channel'],"path-to-subcategory":['executive'],"small-chart":true,"data-transformer":_vm.normalicePurchases}})]},proxy:true}])})],1),_c('v-col',[_c('dashboard-card',{attrs:{"title":"Créditos por canal"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.currentMonthFilters.signedCredits})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: currentMonthFilters.signedCredits})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.signedCredits,"path-to-category":['channel'],"path-to-subcategory":['executive'],"small-chart":true,"data-transformer":_vm.normalicePurchases}})]},proxy:true}])})],1),_c('v-col',[_c('dashboard-card',{attrs:{"title":"Ventas por canal"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.sales,"path-to-category":['channel'],"path-to-subcategory":['executive'],"small-chart":true,"data-transformer":_vm.normaliceSales}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Compras por ejecutivo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-category":['executive'],"path-to-category-title":['fullName'],"category-name":"ejecutivo"}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Créditos por ejecutivo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.currentMonthFilters.signedCredits})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: currentMonthFilters.signedCredits})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.signedCredits,"records-settings":_vm.chartData.settings.loanEvaluations,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixCreditFields,"path-to-category":['executive'],"path-to-category-title":['fullName']}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Ventas por ejecutivo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.sales,"records-settings":_vm.chartData.settings.sale,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixSaleFields,"path-to-category":['executive'],"path-to-category-title":['name']}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Detalle de compras"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate})\n            }"}],attrs:{"sorting-method":_vm.sortByStockCreated,"records":_vm.chartData.currentMonth.purchases,"records-settings":_vm.chartData.settings.purchase,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixPurchaseFields,"path-to-details":[
              {
                name: 'Ejecutivo',
                path: ['executiveFullName'],
                pathToComplement: ['stockCreated'],
              },
            ],"path-to-title":['carFullName'],"path-to-subtitle":['auto', 'registrationPlate']}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Detalle de créditos"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.currentMonthFilters.signedCredits})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: currentMonthFilters.signedCredits})\n            }"}],attrs:{"sorting-method":_vm.sortBySignedDate,"records":_vm.chartData.currentMonth.signedCredits,"records-settings":_vm.chartData.settings.loanEvaluations,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixCreditFields,"path-to-details":[
              {
                name: 'Ejecutivo',
                path: ['executiveFullName'],
                pathToComplement: ['signedDate'],
              },
              {
                name: 'Financiera',
                path: ['financialCompany'],
              },
            ],"path-to-title":['carFullName'],"path-to-subtitle":['auto', 'registrationPlate']}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('dashboard-card',{attrs:{"title":"Detalle de ventas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"sorting-method":_vm.sortBySoldDate,"records":_vm.chartData.currentMonth.sales,"records-settings":_vm.chartData.settings.sale,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.fixSaleFields,"path-to-details":[
              {
                name: 'Ejecutivo',
                path: ['executiveName'],
                pathToComplement: ['soldDate'],
              },
            ],"path-to-title":['carFullName'],"path-to-subtitle":['registrationPlate']}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('financial-evaluation-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reports', queryMethod: _vm.getFinancialReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reports', queryMethod: getFinancialReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.reports}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('inspections-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'inspectionsReports', queryMethod: _vm.getInspectionReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'inspectionsReports', queryMethod: getInspectionReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.inspectionsReports}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('purchases-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'purchasesReports', queryMethod: _vm.getPurchasesReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'purchasesReports', queryMethod: getPurchasesReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.purchasesReports}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('credits-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'creditsReports', queryMethod: _vm.getCreditsReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'creditsReports', queryMethod: getCreditsReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.creditsReports}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('reserve-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reservesReports', queryMethod: _vm.getReserveReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reservesReports', queryMethod: getReserveReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.reservesReports}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('sales-report',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'salesReports', queryMethod: _vm.getSalesReports})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'salesReports', queryMethod: getSalesReports})\n            }"}],attrs:{"reports":_vm.chartData.currentMonth.salesReports}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',[_c('dashboard-card',{attrs:{"title":"Acumulado de negocios"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a-b-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQueries
                ([
                  {category: 'currentMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.currentMonthFilters.stockCreatedDate},
                  {category: 'currentMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.currentMonthFilters.startEndDate},
                  {category: 'currentMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.currentMonthFilters.signedCredits},
                  {category: 'prevMonth', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.prevMonthFilters.stockCreatedDate},
                  {category: 'prevMonth', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.prevMonthFilters.startEndDate},
                  {category: 'prevMonth', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.prevMonthFilters.signedCredits},
                ])}),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQueries\n                ([\n                  {category: 'currentMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: currentMonthFilters.stockCreatedDate},\n                  {category: 'currentMonth', field: 'sales', queryMethod: getSales, queryFilter: currentMonthFilters.startEndDate},\n                  {category: 'currentMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: currentMonthFilters.signedCredits},\n                  {category: 'prevMonth', field: 'purchases', queryMethod: getPurchases, queryFilter: prevMonthFilters.stockCreatedDate},\n                  {category: 'prevMonth', field: 'sales', queryMethod: getSales, queryFilter: prevMonthFilters.startEndDate},\n                  {category: 'prevMonth', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: prevMonthFilters.signedCredits},\n                ])}"}],attrs:{"data":[
              {
                records: _vm.chartData.currentMonth.purchases,
                name: 'Compras',
                pathToDate: ['stockCreated'],
                color: '#13CE66',
                type: 'line',
                filteringMethod: record => record.acquisitionType.name === 'purchase'
              },
              {
                records: _vm.chartData.currentMonth.purchases,
                name: 'Consignaciones',
                pathToDate: ['stockCreated'],
                color: '#FAD901',
                type: 'line',
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.currentMonth.signedCredits,
                name: 'Créditos',
                pathToDate: ['signedDate'],
                color: '#7D67BC',
                type: 'line',
              },
              {
                records: _vm.chartData.currentMonth.sales,
                name: 'Ventas',
                pathToDate: ['soldDate'],
                color: '#6185DB',
                type: 'line',
              },
            ],"prev-month-data":[
              {
                records: _vm.chartData.prevMonth.purchases,
                name: 'Compras',
                pathToDate: ['stockCreated'],
                color: '#13CE66',
                type: 'line',
                filteringMethod: record => record.acquisitionType.name === 'purchase'
              },
              {
                records: _vm.chartData.prevMonth.purchases,
                name: 'Consignaciones',
                pathToDate: ['stockCreated'],
                color: '#FAD901',
                type: 'line',
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.prevMonth.signedCredits,
                name: 'Créditos',
                pathToDate: ['signedDate'],
                color: '#7D67BC',
                type: 'line',
              },
              {
                records: _vm.chartData.prevMonth.sales,
                name: 'Ventas',
                pathToDate: ['soldDate'],
                color: '#6185DB',
                type: 'line',
              },
            ],"dates":_vm.dates,"show-total":false,"accumulated":true,"filter-zero-legends":false,"sort-legends":false}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',[_c('dashboard-card',{attrs:{"title":"Firmados por financiera actuales"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('donut-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'signedLoans', queryMethod: _vm.getSignedLoans, queryFilter: _vm.currentMonthFilters.signedLoans})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'signedLoans', queryMethod: getSignedLoans, queryFilter: currentMonthFilters.signedLoans})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.signedLoans,"path-to-category":['person'],"data-transformer":_vm.setFinancingColors,"path-to-category-title":['alias'],"path-to-value":['evaluations', 'aggregate', 'count']}})]},proxy:true}])})],1),_c('v-col',[_c('dashboard-card',{attrs:{"title":"Solicitudes de financiamiento actuales"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('loan-applications',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'evaluations', queryMethod: _vm.getFinancing, queryFilter: _vm.currentMonthFilters.loanApplications})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'evaluations', queryMethod: getFinancing, queryFilter: currentMonthFilters.loanApplications})\n            }"}],attrs:{"loan-evaluations":_vm.chartData.currentMonth.evaluations}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Reservas por ejecutivo"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: _vm.getReservesQuery, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: getReservesQuery, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.reservesQuery,"records-settings":_vm.chartData.settings.reserve,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceReservesQuery,"path-to-category":['executive'],"path-to-category-title":['name']}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Canceladas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: _vm.getReservesQuery, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: getReservesQuery, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.reservesQuery,"records-settings":_vm.chartData.settings.reserve,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceReservesQuery,"path-to-category":['executive'],"path-to-category-title":['name'],"use-total-as-target":true,"filtering-criteria":(record) => record.closingReason?.description === 'Anulada'}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('dashboard-card',{attrs:{"title":"Concretadas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: _vm.getReservesQuery, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: getReservesQuery, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.reservesQuery,"records-settings":_vm.chartData.settings.reserve,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceReservesQuery,"path-to-category":['executive'],"path-to-category-title":['name'],"use-total-as-target":true,"filtering-criteria":(record) => record.closingReason?.description === 'Concretada'}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Negocios último año"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a-b-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQueries([
                {category: 'lastYear', field: 'purchases', queryMethod: _vm.getPurchases, queryFilter: _vm.lastYearFilters.stockCreatedDate},
                {category: 'lastYear', field: 'sales', queryMethod: _vm.getSales, queryFilter: _vm.lastYearFilters.startEndDate},
                {category: 'lastYear', field: 'signedCredits', queryMethod: _vm.getLoanEvaluationsAggregates, queryFilter: _vm.lastYearFilters.signedCredits},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQueries([\n                {category: 'lastYear', field: 'purchases', queryMethod: getPurchases, queryFilter: lastYearFilters.stockCreatedDate},\n                {category: 'lastYear', field: 'sales', queryMethod: getSales, queryFilter: lastYearFilters.startEndDate},\n                {category: 'lastYear', field: 'signedCredits', queryMethod: getLoanEvaluationsAggregates, queryFilter: lastYearFilters.signedCredits},\n              ])\n            }"}],attrs:{"data":[
              {
                records: _vm.chartData.lastYear.purchases,
                name: 'Compras',
                pathToDate: ['stockCreated'],
                color: '#13CE66',
                type: 'bar',
                filteringMethod: record => record.acquisitionType.name === 'purchase'
              },
              {
                records: _vm.chartData.lastYear.purchases,
                name: 'Consignaciones',
                pathToDate: ['stockCreated'],
                color: '#FBDA01',
                type: 'bar',
                filteringMethod: record => record.acquisitionType.name === 'consignment'
              },
              {
                records: _vm.chartData.lastYear.signedCredits,
                name: 'Créditos',
                pathToDate: ['signedDate'],
                color: '#7D67BC',
                type: 'bar',
              },
              {
                records: _vm.chartData.lastYear.sales,
                name: 'Ventas',
                pathToDate: ['soldDate'],
                color: '#6185DB',
                type: 'bar',
              },
            ],"prev-month-data":[],"monthly":true,"dates":_vm.lastYearDates,"filter-zero-legends":false,"sort-legends":false}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Utilidad último año"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a-b-chart',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQueries
                ([
                  {category: 'lastYear', field: 'soldPurchases', queryMethod: _vm.getPurchases, queryFilter: _vm.lastYearFilters.soldPurchases},
                  {category: 'lastYear', field: 'soldSales', queryMethod: _vm.getSoldSales, queryFilter: _vm.lastYearFilters.soldSales},
                  {category: 'lastYear', field: 'utilityCredits', queryMethod: _vm.getUtilityCredits, queryFilter: _vm.lastYearFilters.utilityCredits},
                ])}),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQueries\n                ([\n                  {category: 'lastYear', field: 'soldPurchases', queryMethod: getPurchases, queryFilter: lastYearFilters.soldPurchases},\n                  {category: 'lastYear', field: 'soldSales', queryMethod: getSoldSales, queryFilter: lastYearFilters.soldSales},\n                  {category: 'lastYear', field: 'utilityCredits', queryMethod: getUtilityCredits, queryFilter: lastYearFilters.utilityCredits},\n                ])}"}],attrs:{"data":[
              {
                records: _vm.chartData.lastYear.soldPurchases,
                name: 'Compras',
                pathToDate: ['stock', 'soldDate'],
                color: '#13CE66',
                type: 'bar',
                pathToValue: ['totalCost'],
              },
              {
                records: _vm.chartData.lastYear.soldSales,
                name: 'Ventas',
                pathToDate: ['saleOrder', 'deal', 'stock', 'soldDate'],
                color: '#6185DB',
                type: 'bar',
                pathToValue: ['amount'],
              },
              {
                records: _vm.chartData.lastYear.utilityCredits,
                name: 'Créditos',
                pathToDate: ['evaluation', 'signedDate'],
                color: '#7D67BC',
                type: 'bar',
                pathToValue: ['fee'],
              },
            ],"prev-month-data":[],"data-transformer":_vm.transformUtility,"monthly":true,"dates":_vm.lastYearDates,"is-currency":true}})]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }