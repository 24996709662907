import gql from 'graphql-tag'
import { Lead } from '@/entities/crm'
import { QueryBuilder } from '../utils'
import { ActivityTypeFragment, BaseActivityFragment, DealFragment, LeadFragment } from '@/graphql/fragments/crm'
import { AppraisalFragment, BaseInspectionFragment } from '@/graphql/fragments/purchase'
import { SaleOrderFragment, StockFragment } from '@/graphql/fragments/sales'
import { FinancingFragment } from '@/graphql/fragments/loans'
import { AcquisitionTypeFragment, ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'
import { PersonFragment } from '@/graphql/fragments/persons'

const query = gql`
  ${LeadFragment}
  ${BaseActivityFragment}
  ${DealFragment}
  ${StockFragment}
  ${AppraisalFragment}
  ${ActivityTypeFragment}
  ${BaseInspectionFragment}
  ${SaleOrderFragment}
  ${FinancingFragment}
  ${ClosingReasonFragment}
  ${PersonFragment}
  ${ProcessStatusFragment}
  ${AcquisitionTypeFragment}
  query leads($filter: crm_lead_bool_exp, $order: [crm_lead_order_by!], $limit: Int, $offset: Int) {
    records: crm_lead(where: $filter, order_by: $order, limit: $limit, offset: $offset) {
      ...Lead
      updatedAt: updated_at
      executive {
        ...Employee
        person{
          ...Person
        }
      }
      activities (order_by: {created_at: desc_nulls_last}, where: { id_activity_type: { _nin: [6,7,8,9] }} ) {
        ...ActivityBase
        status: process_status {
          ...ProcessStatus
        }
        type: activity_type {
          ...ActivityType
        }
      }
      deals {
        ...Deal
        appraisals (order_by: {id: asc}) {
          ...Appraisal
          appraiser{
            ...Employee
            person{
              ...Person
            }
          }
          inspection {
            id
            date
            inspector{
              ...Employee
            }
            supervisor{
              ...Employee
            }
            status {
              ...ProcessStatus
            }
            closingReason:closing_reason {
              ...ClosingReason
            }
            negotiation {
              id
              status {
                ...ProcessStatus
              }
            }
          }
        }
        stock {
          id
          status {
            ...ProcessStatus
          }
          prices(limit: 2, order_by: {id: desc}){
            id
            createdAt: created_at
            amount
          }
          viewDetails: stock_detail {
            acquisitionType: acquisition_type{
              ...AcquisitionType
            }
          }
        }
        saleOrders: sale_orders{
          ...BaseSaleOrder
          saleOrderItems: sale_order_items(order_by: {item_type: {id: asc}}) {
            ...SaleOrderItem
          }
          closingReason: closing_reason {
            ...ClosingReason
          }
          reserve {
            ...Reserve
          }
          validator {
            ...Employee
          }
          status {
            ...ProcessStatus
          }
          financings(order_by: {id: desc}) {
            id
            initial
            evaluations {
              agreement {
                id
                person {
                  id
                  alias
                }
              }
              closingReason: closing_reason {
                ...ClosingReason
              }
              status {
                ...ProcessStatus
              }
              id
              responsibleAgreement:responsible_agreement {
                id
                responsible {
                  ...Person
                }
              }
            }
          }
        }
      }
    }
    total: crm_lead_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const leads = QueryBuilder(query, Lead)
export default leads
