import { ActionTree } from 'vuex'

import { State as RootState } from '@/store/state'
import { Resources } from './state'
import { getUserResources } from '@/graphql/queries/resources'
import {
  businessExcludeByStock,
  getBusinessExcludeByStock,
  removeStockPublication,
} from '@/graphql/customQueries/sales/stock/stock'

export const actions: ActionTree<Resources, RootState> = {
  loadResources: async ({ rootState: { apolloClient }, commit }) => {
    commit('setAll', await getUserResources(apolloClient))
    commit('loadLinks')
    commit('loadForms')
    commit('loadGroupedLinks')
  },
  cleanResources: ({ commit }) => commit('clean'),
  setActive: ({ commit }, resource) => {
    commit('setActive', resource)
    commit('setActiveCursor')
  },
  setFilter: ({ commit }, filter) => {
    commit('setFilter', filter)
    commit('setActiveCursor')
  },
  setCursorSize: ({ commit }, size) => commit('setCursorSize', size),
  setCursorPage: ({ commit }, page) => commit('setCursorPage', page),
  setPeriod: ({ commit }, period) => commit('setPeriod', period),
  refresh: async ({ dispatch, getters: { active: resource, period, filter, cursor } }, { force }) => {
    await dispatch('datatables/refresh', { resource, period, filter, cursor, force })
  },
  removeStock: async ({ rootState: { apolloClient } }, id): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    try {
      await apolloClient.mutate({
        mutation: removeStockPublication,
        variables: {
          idStock: id,
        },
      })
    } catch (e) {
      return null
    }
  },
  excludeStock: async ({ rootState: { apolloClient } }, { idStock, exclude }): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    try {
      await apolloClient.mutate({
        mutation: businessExcludeByStock,
        variables: {
          idStock,
          exclude,
        },
      })
    } catch (e) {
      return null
    }
  },
  getExcludeStocks: async ({ rootState: { apolloClient } }): Promise<boolean> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    try {
      const { data: { response } } = await apolloClient.query({
        query: getBusinessExcludeByStock,
      })

      return response
    } catch (e) {
      return null
    }
  },
}
