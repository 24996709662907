import { ResourceHeaders } from '..'
import {
  BusinessHeader,
  ContactHeader,
  CreateLinkedRecordHeader,
  DateHeader,
  IdHeader,
  InspectionSummary,
  NegotiationStatusHeader,
  PersonHeader,
  PriceHeader,
  VehicleHeader,
} from '../../headers'

import { Helpers } from '@/views/datatables/resources/helpers'
import { Negotiation } from '@/entities/purchase'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
    width: 130,
  },
  { ...VehicleHeader, text: 'Vehículo', value: 'auto' },
  { ...DateHeader, text: 'Creación', value: 'created' },
  { ...BusinessHeader, text: 'Negocio', value: 'pipeline', width: 100 },
  { ...NegotiationStatusHeader, text: 'Estado negociación', value: '@', width: 100 },
  {
    ...PersonHeader,
    text: 'Cliente',
    value: '@',
    options: {
      path: 'client.name',
      trigger: ({ inspection: { appraisal: { deal: { lead: { client } } } } }: Negotiation) => Helpers.linkedModel(client),
      displayPhone: true,
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'client', process, system),
      },
      disabledMissingProperty: ['client.phone'],
    },
  },
  {
    ...InspectionSummary,
    text: 'Reporte inspección',
    value: 'inspection',
    options: {
      tooltip: 'Reporte',
      action: {
        color: 'purple',
        icon: 'mdi-clipboard-text-search-outline',
        only: true,
      },
    },
  },
  { ...PriceHeader, text: 'Precio tasación', value: 'dtPrice' },
  { ...PriceHeader, text: 'Costo inspección', value: 'inspectionAmount', width: 160 },
  { ...PriceHeader, text: 'Precio autorizado', value: 'negotiationResponseDt' },
  {
    ...ContactHeader,
    text: 'Supervisor',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'supervisor',
      },
      path: 'inspection.supervisor.name',
      action: {
        always: true,
        color: 'blue',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Negotiation, process: Process, system: string) => Helpers.contact(item, 'supervisor', process, system),
      },
      disabledMissingProperty: ['inspection.supervisor.phone'],
    },
  },
  {
    ...CreateLinkedRecordHeader,
    text: 'Comprar',
    value: 'buttonPurchase',
    options: {
      tooltip: 'Comprar',
      action: {
        color: 'green',
        icon: 'mdi-cart-arrow-down',
        only: true,
        trigger: item => Helpers.linkedModel(item),
      },
    },
  },
]

export default function (): ResourceHeaders {
  return headers
}
