import gql from 'graphql-tag'

export const mutation = gql`
  mutation updateSettingProcess($id: Int!,$fields: settings_process_set_input!) {
    record: update_settings_process_by_pk(pk_columns: {id: $id}, _set: $fields) {
      id
    }
  }
`

export default mutation
