import { Entity } from '..'
import { Transform, Type } from 'class-transformer'
import { Person } from '../persons'
import dayjs, { Dayjs } from 'dayjs'

class UserMetadata {
  file: string
  path: string
}

export class User extends Entity {
  @Type(() => Person)
  person: Person;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  lastSeen?: Dayjs;

  @Type(() => UserMetadata)
  metadata: UserMetadata

  email: string

  get lastSeenLocal () {
    return this.formatDateDayjs(this.lastSeen)
  }

  get userIsOnline () {
    const now = dayjs()
    if (!this.lastSeen) return false
    const lastSeen = dayjs(this.lastSeenLocal)
    const diffInMinutes = now.diff(lastSeen, 'minute')

    return diffInMinutes < 1
  }
}
