import { Type } from 'class-transformer'
import { EnumEntity } from '..'
import { Resource } from '.'
import { ResourceTypeEnum } from '@/graphql'

export class ResourceType extends EnumEntity {
  public static readonly view: ResourceTypeEnum = 'view'
  public static readonly customView: ResourceTypeEnum = 'custom_view'
  public static readonly form: ResourceTypeEnum = 'form'
  public static readonly dashboard: ResourceTypeEnum = 'dashboard'
  public static readonly toolkit = 'toolkit' // TODO: Update with the correct one when migrated
  public static readonly configView = 'config'

  @Type(type => Resource)
  resources: Resource[];

  declare name: ResourceTypeEnum;
}
