
  import { Component, Watch } from 'vue-property-decorator'
  import { Expandable } from '@/components/toolkit/details/row/custom/Expandable'
  import { Person } from '@/entities/persons'

@Component
  export default class PersonResume extends Expandable {
  panels = [0]
  loadedAddresses: Record<number, boolean> = {} // Caché para evitar múltiples requests

  get persons (): Person[] {
    return this.value as Person[] || []
  }

  @Watch('value', { immediate: true, deep: true })
  async onValueChange (val: Person[]) {
    await Promise.all(
      val.map(async person => {
        if (!this.loadedAddresses[person.id] && (!person.addresses || !person.addresses.length)) {
          person.addresses = await this.fetchData({
            query: { name: 'find', model: 'PersonAddress' },
            filter: {
              _and: [
                { id_person: { _eq: person.id } },
                { active: { _eq: true } },
                { default: { _eq: true } },
              ],
            },
            force: true,
          })
          this.loadedAddresses[person.id] = true // Marca como cargado
        }
      })
    )
  }
  }
