import { GetterTree, MutationTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { Notification as NotificationEntity } from '@/entities/system/Notification'

function getSnackbarColor (type = 'success'): string {
  switch (type) {
    case 'error':
      return '#F03738'
    case 'info':
      return '#6185DB'
    case 'warning':
      return '#BCA301'
    default:
      return '#13CE66'
  }
}

function getSnackbarIcon (type = 'success'): string {
  switch (type) {
    case 'error':
      return 'mdi-close-circle-outline'
    case 'info':
      return 'mdi-information-outline'
    case 'warning':
      return 'mdi-alert-outline'
    default:
      return 'mdi-check-circle-outline'
  }
}

export interface NotificationContent {
  message: string
  color?: string
  notificationType?: string
  icon?: string
}

export interface Notification {
  notifications?: NotificationContent | null
  systemNotifications?: NotificationEntity[]
}

export function initState (): Notification {
  return {
    notifications: null,
    systemNotifications: [],
  }
}

export const mutations: MutationTree<Notification> = {
  setNotification: (state, notification) => {
    state.notifications = {
      message: notification.message,
      color: getSnackbarColor(notification.notificationType),
      notificationType: notification.notificationType,
      icon: getSnackbarIcon(notification.notificationType),
    }
  },
  setSystemNotification: (state, notification) => {
    state.systemNotifications.push(notification)
  },
  clearNotification: state => {
    state.notifications = null
  },
}

export const getters: GetterTree<Notification, RootState> = {
  notifications: ({ notifications }): NotificationContent => {
    return <NotificationContent>notifications
  },
  systemNotifications: ({ systemNotifications }): NotificationEntity[] => {
    return systemNotifications?.reverse()
  },
}
