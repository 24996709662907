
  import { Component } from 'vue-property-decorator'
  import { BaseView } from './view'
  import { Debounce } from '@/utils/decorators'

@Component({
  components: { FormTitle: () => import('@/components/forms/FormTitle.vue') },
})
  export default class MobileForm extends BaseView {
  tab = -1

  get items () {
    const { showDetails, hiddenDetails } = this

    const tabs = []

    tabs.push('Formulario')

    if (showDetails || !hiddenDetails) {
      tabs.push('Detalles')
    }

    this.tab = tabs.indexOf('Formulario')

    return tabs
  }

  mounted () {
    this.$nextTick(() => {
      const scrollElement = this.$el.querySelector('.v-card__text')
      if (scrollElement) {
        scrollElement.addEventListener('scroll', this.handleScroll)
      }
    })
  }

  beforeDestroy () {
    const scrollElement = this.$el.querySelector('.v-card__text')
    if (scrollElement) {
      scrollElement.removeEventListener('scroll', this.handleScroll)
    }
  }

  @Debounce(250)
  handleScroll (event) {
    const { dense } = this
    if (!event.target || !dense) return null

    const scrollPosition = event.target.scrollTop

    this.$emit('hide-title', scrollPosition !== 0)
  }
  }
