
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { User } from '@/entities/public'

  import initials from 'initials'

@Component
  export default class UserCard extends Vue {
  @Prop({ required: true }) user!: User

  get lastSeenFormatted () {
    if (!this.user.lastSeenLocal) return 'Desconocido'
    return dayjs(this.user.lastSeenLocal).format('DD/MM/YYYY HH:mm')
  }

  // Methods
  isPhoto (user) {
    return Boolean(user?.person?.photo?.main)
  }

  openLink (link) {
    if (!link?.length) return
    if (!link.startsWith('https://')) {
      link = 'https://' + link
    }

    window.open(link, '_blank')
  }

  // Getters
  getInitials (user) {
    const { person } = user
    const name = person?.firstName + ' ' + person?.surname
    const alias = person.alias || person.socialReason
    return initials(alias || name || (person.email as string)).toUpperCase()
  }

  get activeTime () {
    const now = dayjs()
    if (!this.user.lastSeenLocal) return 'Desconocido'
    const lastSeen = dayjs(this.user.lastSeenLocal)

    const diffInMinutes = now.diff(lastSeen, 'minute')
    const diffInHours = now.diff(lastSeen, 'hour')
    const diffInDays = now.diff(lastSeen, 'day')
    const diffInMonths = now.diff(lastSeen, 'month')
    const diffInYears = now.diff(lastSeen, 'year')

    if (diffInMinutes < 1) {
      return 'Activo hace unos segundos'
    } else if (diffInMinutes < 60) {
      return `Activo hace ${diffInMinutes} minuto${diffInMinutes > 1 ? 's' : ''}`
    } else if (diffInHours < 24) {
      return `Activo hace ${diffInHours} hora${diffInHours > 1 ? 's' : ''}`
    } else if (diffInDays < 30) {
      return `Activo hace ${diffInDays} día${diffInDays > 1 ? 's' : ''}`
    } else if (diffInMonths < 12) {
      return `Activo hace ${diffInMonths} mes${diffInMonths > 1 ? 'es' : ''}`
    } else {
      return `Activo hace ${diffInYears} año${diffInYears > 1 ? 's' : ''}`
    }
  }

  get withOutTime () {
    const { activeTime } = this

    return activeTime === 'Desconocido'
  }

  getName (user) {
    const { person } = user
    const name = (person?.firstName || '') + ' ' + (person?.surname || '')

    return name.toUpperCase()
  }

  openImageInNewWindow (file) {
    if (!file?.length) return
    // Crear un objeto Blob desde el base64
    const byteCharacters = atob(file.split(',')[1]) // Decodificar base64
    const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i))
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: 'image/png' })

    // Crear una URL a partir del Blob
    const blobUrl = URL.createObjectURL(blob)

    // Abrir en una nueva ventana
    window.open(blobUrl, '_blank')

    // Liberar la URL después de abrirla
    URL.revokeObjectURL(blobUrl)
  }

  // Watchers
  }
