
  import { Component, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'

  import { toRouteRecord } from '@/router/helpers'
  import { Resource } from '@/entities/public'
  import { Filter } from '@/entities/public/Resource/interfaces'
  import BaseBar from '@/layouts/BaseBar'
  import dayjs, { Dayjs } from 'dayjs'
  import Vue from 'vue'

interface MenuRef extends Vue {
  save(selected: string): void
}

@Component({
  methods: {
    ...mapActions('resources', ['setActive', 'setFilter']),
    ...mapActions('dashboard', ['setDate']),
  },
  computed: {
    ...mapGetters('resources', ['all']),
  },
})
  export default class AppBar extends BaseBar {
  private all!: Array<Resource>
  private setActive!: (resource?: Resource) => void
  private setFilter!: (filter?: Filter) => void

  private setDate!: (payload: Dayjs) => void

  get date (): Dayjs {
    return this.$store.getters['dashboard/date']
  }

  get currentMonth (): Dayjs {
    return this.$store.getters['dashboard/currentMonth']
  }

  get prevMonth (): Dayjs {
    return this.$store.getters['dashboard/prevMonth']
  }

  endOfMonth = dayjs().endOf('month')
  monthLenght = this.endOfMonth
    .diff(this.date.startOf('month'), 'days') + 1

  monthPercentage = Math.round((Number(this.date.format('DD')) / this.monthLenght) * 100)
  selected = new Date(this.date.format('YYYY/MM/DD')).toISOString().substring(0, 7)
  daysTillMonthEnd = this.endOfMonth
    .diff(this.date, 'days')

  dateString = this.getDateString(this.date)

  menu = false

  resource = -1
  option = -1

  created () {
    // const { $route: { params: { resource } }, targets } = this
    // this.resource = (resource && targets) ? targets.map(({ slug }) => slug).indexOf(resource) : 0
    this.resource = Number(sessionStorage.getItem('dashboards-tab-key'))

    const { $route: { query: { filter } }, filters } = this
    this.option = (filter && filters) ? filters.map(({ name }) => name).indexOf(filter as string) : 0
  }

  destroyed () {
    sessionStorage.removeItem('dashboards-tab-key')
  }

  saveDate () {
    (this.$refs.menu as MenuRef).save(this.selected)
    this.setDate(dayjs(this.selected))
  }

  getDateString (date) {
    const day = dayjs(date).format('DD')
    const monthNumber = dayjs(date).format('MM')
    let month
    switch (monthNumber) {
      case '01':
        month = 'Enero'
        break
      case '02':
        month = 'Febrero'
        break
      case '03':
        month = 'Marzo'
        break
      case '04':
        month = 'Abril'
        break
      case '05':
        month = 'Mayo'
        break
      case '06':
        month = 'Junio'
        break
      case '07':
        month = 'Julio'
        break
      case '08':
        month = 'Agosto'
        break
      case '09':
        month = 'Septiembre'
        break
      case '10':
        month = 'Octubre'
        break
      case '11':
        month = 'Noviembre'
        break
      default:
        month = 'Diciembre'
        break
    }
    return `${day} de ${month}`
  }

  get targets (): Array<Resource> {
    const { $route, all } = this
    const { name } = toRouteRecord($route)
    return all.filter(({ route }) => route?.name && route?.name === name)
  }

  get target (): Resource | undefined {
    const { resource, targets } = this
    if (!targets) return undefined

    return targets[resource]
  }

  get filters (): Array<Filter> | undefined {
    const { target } = this
    if (!target) return undefined

    return target.filters
  }

  get activeFilter () {
    const { option, filters } = this
    if (!filters) return undefined

    return filters[option]
  }

  @Watch('selected')
  dateChange () {
    this.saveDate()
  }

  @Watch('resource')
  saveResource () {
    sessionStorage.setItem('dashboards-tab-key', `${this.resource}`)
  }

  @Watch('target')
  onTargetChange (resource: Resource, prev: Resource) {
    if (!resource || prev === resource) return
    const query = Object.assign({}, this.$route.query)
    this.$router.replace({ path: resource.path, query }).catch(() => {
    })
    this.setActive(resource)
    this.setFilter()
  }

  @Watch('activeFilter')
  onFilterChange (filter: Filter, prev: Filter) {
    if (!filter || prev === filter) return
    const query = Object.assign({}, this.$route.query, { filter: filter.name })
    this.$router.replace({ query }).catch(() => {
    })

    this.setFilter(filter)
  }

  @Watch('isResize', { immediate: true })
  onResizeChange (val) {
    this.resize = val
  }
  }
