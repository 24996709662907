import { Transform, Type } from 'class-transformer'
import { Entity } from '..'

import { FileProcess } from '.'
import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { isBeforeToday } from '@/utils/general'
import { CavSerialization } from './CavSerialization'

dayjs.extend(duration)

export class File extends Entity {
  public static readonly image = 'image/jpeg'
  @Type(() => FileProcess)
  processes: FileProcess[];

  @Type(() => Dayjs)
  @Transform(({ value }) => dayjs(value), { toClassOnly: true })
  expirationDate: Dayjs;

  @Type(() => Dayjs)
  @Transform(({ value }) => dayjs(value), { toClassOnly: true })
  customExpirationDate: Dayjs;

  @Type(() => CavSerialization)
  serialization?: CavSerialization;

  uri: string;
  type: string;
  name: string;
  sourceLink: string;
  checksum: string;

  error?: boolean;
  metadata: Record<string, any>;

  get isFileExpired () {
    const { expirationDate, customExpirationDate, type } = this

    if ((!expirationDate.isValid() && !customExpirationDate?.isValid()) || type === File.image) return false

    return isBeforeToday(customExpirationDate) || isBeforeToday(expirationDate)
  }

  get smallUrl () {
    const { metadata, uri } = this

    return metadata?.s?.uri || uri
  }

  get largeUrl () {
    const { metadata, uri } = this

    return metadata?.l?.uri || uri
  }

  get isPdf () {
    return this.type === 'application/pdf'
  }
}
