import { inspection } from './inspection'
import { appraisal } from './appraisal'
import { leads } from './leads'
import { sales } from './sales'

const forwarder = {
  supervisor_inspections: inspection,
  supervisor_appraisals: appraisal,
  supervisor_leads: leads,
  supervisor_sales: sales,
}
export default forwarder
