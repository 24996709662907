
  import { Component, Prop } from 'vue-property-decorator'
  import { mapGetters } from 'vuex'
  import { Notification as NotificationEntity } from '@/entities/system/Notification'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'

@Component({
  computed: {
    ...mapGetters('notification', ['systemNotifications']),
    ...mapGetters('app', ['isMini']),
  },
})
  export default class NotificationSystem extends FilesProcess {
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  // Obtener las notificaciones desde Vuex
  systemNotifications!: NotificationEntity[]

  isMini!: boolean;
  // Estado del menú
  menu: boolean = false;

  // Métodos
  toggleMenu () {
    this.menu = !this.menu
  }

  handleNotificationClick (link: string) {
    this.openLink(link)

    // Cerrar el menú después de la acción
    this.menu = false
  }

  // Getters
  get count () {
    return this.systemNotifications.length
  }

  getIcon (status: string | undefined): string {
    switch (status) {
      case 'unread':
        return 'mdi-email'
      case 'read':
        return 'mdi-email-open'
      default:
        return 'mdi-bell'
    }
  }
  }
