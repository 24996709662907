export class CavCurrOwners {
  name: string;
  number: string;
  repertoire: string;
  run: string;
  rut: string;

  get isNaturalPerson () {
    const { run } = this
    return Boolean(run?.length)
  }

  get isCompany () {
    const { rut } = this
    return Boolean(rut?.length)
  }

  get type () {
    const { isNaturalPerson } = this
    return isNaturalPerson ? 'natural_person' : 'company'
  }

  // 1) 'rutClean' sin puntos pero manteniendo guiones
  get rutClean () {
    const { run, rut } = this
    return (run || rut).replace(/\./g, '')
  }

  // 2) Getters 'alias' y 'socialReason' para empresas
  get alias () {
    return this.isCompany ? this.name : undefined
  }

  get companyName () {
    return this.isCompany ? this.name : undefined
  }

  // 3) Getters para personas naturales con manejo de nombres largos
  get firstName () {
    if (this.isNaturalPerson) {
      const words = this.name.trim().split(/\s+/)
      return words[0] || ''
    }
    return undefined
  }

  get secondName () {
    if (this.isNaturalPerson) {
      const words = this.name.trim().split(/\s+/)
      if (words.length >= 4) {
        return words.slice(1, words.length - 2).join(' ')
      } else if (words.length >= 2) {
        return words[1]
      } else {
        return ''
      }
    }
    return undefined
  }

  get surname () {
    if (this.isNaturalPerson) {
      const words = this.name.trim().split(/\s+/)
      if (words.length >= 2) {
        return words[words.length - 2]
      } else {
        return ''
      }
    }
    return undefined
  }

  get secondSurname () {
    if (this.isNaturalPerson) {
      const words = this.name.trim().split(/\s+/)
      if (words.length >= 1) {
        return words[words.length - 1]
      } else {
        return ''
      }
    }
    return undefined
  }
}
