import { Actions } from '../../interfaces'

import { Process } from '@/entities/settings'
import find from './find'
import update from './update'

export const actions: Actions = {
  find,
  update,
}

export default {
  Model: { Process },
  actions: { [Process.name]: actions },
}
