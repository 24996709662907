
  import { Component, Watch } from 'vue-property-decorator'
  import Cell from '@/components/dataTables/cell/cell'
  import dayjs from 'dayjs'
  import { Helpers } from '@/views/datatables/resources/helpers'

@Component
  export default class RetirementConsignmentCell extends Cell {
  status = {
    icon: 'mdi-car-key',
    text: '',
    color: '',
  }

  disabledRetirement = false
  // Methods

  // Getters
  get info () {
    return this.item
  }

  // Watchers
  @Watch('item', { immediate: true, deep: true })
  async onItemChange (val) {
    const statusToCheck = ['to_confirm', 'to_update', 'active']

    if (statusToCheck.includes(val.status.name)) {
      this.disabledRetirement = true
      return
    }

    const stock = await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: { stock_detail: { id_purchase: { _eq: val.id } } },
      force: true,
    })
    if (!stock?.length) {
      this.setStatusValues({ description: '', color: '' })
      return
    }

    if (stock[0]?.status?.name === 'sold') {
      this.disabledRetirement = true
      this.setStatusValues({ description: 'VENDIDO', color: 'primary' })
      return
    } else if (stock[0]?.status?.name === 'retired') {
      this.disabledRetirement = true
      this.setStatusValues({ description: 'RETIRADO', color: 'primary' })
      return
    }

    const field = await this.fetchData({
      query: { name: 'find', model: 'Field' },
      filter: {
        _and: [
          { document_type: { name: { _eq: 'consignment_contract' } } },
          { name: { _eq: 'consignment_period' } },
        ],
      },
      force: true,
    })

    const differenceInDays = dayjs().diff(val.createdAt, 'day')
    if (differenceInDays > field?.[0]?.structure?.days) {
      this.setStatusValues({ description: 'VENCIDA', color: 'red' })
    } else {
      this.setStatusValues({ description: 'VIGENTE', color: 'primary' })
    }
  }

  setStatusValues (status) {
    this.status.text = status.description
    this.status.color = status.color
  }

  open () {
    Helpers.linkedModel(this.item.buttonActivity)
  }
  }
