
  import { Component, Watch } from 'vue-property-decorator'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import InitialFields from '@/components/forms/fields/InitialFields.vue'
  import { plainToInstance } from 'class-transformer'
  import { Inspection } from '@/entities/purchase'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { Lead, LeadActivity } from '@/entities/crm'
  import { Person } from '@/entities/persons'
  import dayjs from 'dayjs'
  import { fixDate, intervalFormat, localToUtc, nameAlert } from '@/utils/general'
  import { mapActions, mapGetters } from 'vuex'
  import { ScheduledInspectionView } from '@/components/forms/view/scheduledInspectionView'
  import AgendaField from '@/components/forms/fields/agenda/AgendaField.vue'

@Component({
  components: { AgendaField, GDatePicker, InitialFields, GAlert, FieldTitle, BaseForm, LinkedPerson },
  methods: {
    ...mapActions('dashboard', ['getInspections']),
  },
  computed: {
    ...mapGetters('persons/address', ['addDirection']),
  },
})
  export default class InspectionForm extends ScheduledInspectionView {
  getInspections!: (payload) => Promise<any>
  inspectionScheduled: Inspection = plainToInstance(Inspection, {})
  lead: Lead = plainToInstance(Lead, {})
  client: Person = plainToInstance(Person, {})
  maxDays = 7
  errors = ''
  addDirection!: boolean
  personActivities: LeadActivity[] = []
  title = 'Agendar inspección'
  formData = {
    schedulingDate: null,
    timeStart: null,
    timeEnd: null,
    location: null,
    direction: null,
    client: null,
  }

  activity = null
  newAgenda = null
  metadata = null

  fields = {
    location: {
      items: [],
      properties: {
        itemText: 'description',
        itemId: 'name',
        label: 'Ubicación',
        hideSelected: true,
        rules: [],
        required: true,
        returnObject: true,
      },
    },
    direction: {
      items: [],
      properties: {
        label: 'Dirección',
        required: true,
        rules: [],
        appendOuterIcon: 'mdi-plus-circle-outline',
        hideSelected: true,
        returnObject: true,
      },
    },
  }

  schedules = []

  async mounted () {
    await this.getInfo()
    await this.setMetadata()
    const { uid } = this

    if (!this.isBreadCrumbPresent(this.title)) {
      this.setFormCrumbs(this.getForm('Lead', 'lead'), 'Inspección', Boolean(!isNaN(uid)))
    }

    if (!isNaN(uid)) {
      await this.getInspectionInfo(uid)
    }

    const system = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { type: { name: { _eq: 'system' } } },
    })
    this.schedules = system?.[0]?.metadata?.schedule
    await this.setTheBackup()
  }

  get disabledWeekend () {
    const { schedules } = this

    const day = []

    if (schedules?.find(schedule => schedule?.day === 'Domingo')?.start === '') {
      day.push(0)
    }

    return day
  }

  async setTheBackup () {
    const { backup } = this

    if (backup && 'inspectionForm' in backup && backup.inspectionForm) {
      this.formData = backup.inspectionForm
      if (this.formData.location) {
        await this.setDirection(this.formData.location)
      }
    }
  }

  async setMetadata () {
    const { lead } = this
    const metadata = this.getForm('Lead', 'lead')
    this.metadata = {
      data: lead,
      metadata,
    }
  }

  saveBackup () {
    const { backup, formData } = this

    if (backup) {
      backup.inspectionForm = formData
      this.setBackup(backup)
    } else {
      this.setBackup({ inspectionForm: formData })
    }
  }

  async getInfo (model = null, id = null) {
    const { parentModel, parentId } = this
    if ((!parentId && !parentModel) && (!model && !id)) return

    const leadId = parentId ? parentId.toString() : id.toString()

    const { lead } = await this.getParentData(parentModel || model, leadId)
    this.lead = lead

    if (lead?.purchase) {
      const {
        purchase: {
          appraisal: {
            agreement: { response },
            agreementConsignment: { response: responseConsignment },
          },
        },
      } = lead
      this.setMaxDays(response, responseConsignment)
    }

    await this.setData(lead)
  }

  setMaxDays (response, responseConsignment) {
    let dateStart = response?.createdAt || responseConsignment?.createdAt
    const dateEnd = dayjs()
    const holidays = [0]
    let daysToRemove = 0

    const diffDay = dateEnd.startOf('day').diff(dateStart.startOf('day'), 'days')

    while (dateStart.isBefore(dateEnd) || dateStart.isSame(dateEnd)) {
      if (holidays.includes(dateStart.day())) {
        daysToRemove++
      }
      dateStart = dateStart.add(1, 'day')
    }

    const newDiffDay = diffDay - daysToRemove

    if (newDiffDay > 7) {
      this.maxDays = 0
    } else {
      this.maxDays = 7 - newDiffDay
    }
  }

  async getInspectionInfo (id) {
    const inspection = await this.fetchData({ query: { name: 'fetch', model: 'Inspection', params: { id } }, force: true })
    this.inspectionScheduled = inspection
    const { appraisal: { deal: { lead: { id: leadId } } }, scheduledDateLocalTime } = inspection

    await this.getInfo('Lead', leadId)

    this.formData.schedulingDate = scheduledDateLocalTime.format('DD/MM/YYYY')
    this.formData.timeStart = scheduledDateLocalTime.format('HH:mm')
    this.formData.timeEnd = scheduledDateLocalTime.add(1, 'hour').format('HH:mm')
    this.formData.location = inspection.address.type
  }

  async setData (lead) {
    const { client } = lead as Lead

    this.fields.direction.properties.rules = [...this.fieldRequired]
    this.fields.location.items = await this.fetchData({
      query: { name: 'find', model: 'AddressType' },
      filter: { person_type: { _in: [client.type.name, 'system'] } },
    })
    this.formData.location = this.fields.location.items.find(item => item.name === 'local')
    this.fields.location.properties.rules = [...this.fieldRequired]

    const resp = await this.fetchData({
      query: { name: 'find', model: 'ActivityType' },
      filter: { name: { _eq: 'inspection' } },
    })
    this.activity = resp[0]
    await this.setMetadata()
  }

  async send () {
    if (!this.$refs.form.validate()) {
      return
    }
    this.loadingForm = true
    const { inspectionScheduled, formData } = this
    const startDate = formData?.schedulingDate + ' ' + formData.timeStart
    const endDate = formData?.schedulingDate + ' ' + formData.timeEnd
    const date = dayjs(fixDate(startDate), 'YYYY-MM-DD HH:mm:ss')

    if (!inspectionScheduled?.id) {
      await this.createInspectionForm(date, startDate, endDate)
    } else {
      await this.editInspection(inspectionScheduled.id, date, startDate, endDate)
    }

    this.setBackup(null)
    await this.close()
  }

  get showDetail () {
    const { metadata, activity, newAgenda } = this

    return Boolean(metadata?.metadata) && Boolean(activity) && (newAgenda?.title)
  }

  async createInspectionForm (date, startDate, endDate): Promise<void> {
    const { lead: { purchase }, formData, lead } = this

    const inspectionStatusPending = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'inspection' } } }, { status: { name: { _eq: 'pending' } } }] },
    })

    const fields = {
      id_appraisal: purchase.appraisal?.id,
      id_person_address: formData.direction?.id,
      id_process_status: inspectionStatusPending[0]?.id,
      scheduled_date: date,
      duration: intervalFormat(fixDate(startDate), fixDate(endDate)),
    }

    const inspectionResponse = await this.pushData({ model: 'Inspection', fields })

    if (inspectionResponse) {
      const appraisalStatus = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'appraisal' } } }, { status: { name: { _eq: 'approved' } } }] },
      })

      const filterSuccess = {
        _and: [
          { type: { name: { _eq: 'scheduled_inspection' } } },
          { status: { process: { table_name: { _eq: 'appraisal' } } } },
        ],
      }

      const closing = await this.fetchData({
        query: { name: 'find', model: 'ClosingReason' },
        filter: filterSuccess,
      })
      const { appraisal } = inspectionResponse
      await this.pushData({
        model: 'Appraisal',
        fields: { id: appraisal?.id, id_process_status: appraisalStatus[0]?.id, id_closing_reason: closing[0]?.id },
      })
    }

    const leadActivityStatus = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'lead_activity' } } }, { status: { name: { _eq: 'pending' } } }] },
    })

    const task = {
      id_activity_type: this.activity.id,
      id_lead: lead?.id,
      id_process_status: leadActivityStatus[0]?.id,
      scheduling_date: date,
      duration: intervalFormat(fixDate(startDate), fixDate(endDate)),
    }

    await this.pushData({ model: 'LeadActivity', fields: task })
  }

  async editInspection (id, date, startDate, endDate) {
    const { lead: { activity }, formData, inspectionScheduled } = this

    const isInspectionDifferent = !inspectionScheduled.scheduledDateLocalTime.isSame(date) || inspectionScheduled.address.id !== formData.direction.id

    if (isInspectionDifferent) {
      const fields = {
        id,
        id_person_address: formData.direction?.id,
        scheduled_date: date,
        duration: intervalFormat(fixDate(startDate), fixDate(endDate)),
      }

      await this.pushData({ model: 'Inspection', fields })
    }

    const isDifferentTime = activity.timeStart !== formData.timeStart && activity.timeEnd !== formData.timeEnd
    if (isDifferentTime) {
      const fieldActivity = {
        id: activity.id,
        scheduling_date: date,
      }
      await this.pushData({ model: 'LeadActivity', fields: fieldActivity })
    }
  }

  @Watch('formData.location', { immediate: true })
  async onLocationChange (val) {
    if (val) {
      if (val.name !== 'local') {
        this.fields.direction.properties.appendOuterIcon = 'mdi-plus-circle-outline'
        await this.setDirection(val)
      } else {
        this.fields.direction.properties.appendOuterIcon = ''
        await this.setLocalDirection(val)
      }
    }
  }

  async setLocalDirection (val) {
    const { lead } = this
    const { client } = lead
    this.client = client

    const addressType = await this.fetchData({
      query: { name: 'find', model: 'AddressType' },
      filter: { name: { _eq: val.name } },
    })

    if (!addressType?.length) return

    const personAddress = addressType[addressType.length - 1].personAddresses

    this.fields.direction.items = personAddress
    this.formData.direction = personAddress[0]
  }

  async setDirection (val) {
    const { lead } = this
    const { client } = lead
    this.client = client

    const address = await this.fetchData({
      query: { name: 'find', model: 'PersonAddress' },
      filter: { _and: [{ id_person: { _eq: client.id }, type: { name: { _eq: val.name } } }, { active: { _eq: true } }] },
      force: true,
    })

    if (address?.length) {
      const personAddress = address[address?.length - 1]

      this.fields.direction.items = address
      this.formData.direction = personAddress
    } else {
      this.formData.direction = null
      this.fields.direction.items = []
    }
  }

  async openDirection () {
    const { lead: { client } } = this

    this.saveBackup()

    await this.$router.push({
      name: 'person-address-data',
      params: {
        model: 'Person',
        uid: client?.id.toString(),
        id: 'create',
      },
    })
  }

  async getActivitiesByLead () {
    const { idEmployee, formData: { schedulingDate } } = this
    if (!idEmployee || !schedulingDate) return []

    const filter = {
      _and: [
        {
          lead: {
            id_executive: {
              _eq: idEmployee,
            },
          },
        },
        {
          id_closing_reason: {
            _is_null: true,
          },
        },
        {
          activity_type: { name: { _eq: 'inspection' } },
        },
        {
          scheduling_date: {
            _gte: dayjs(fixDate(schedulingDate)).startOf('day'),
            _lte: dayjs(fixDate(schedulingDate)).endOf('day'),
          },
        }],
    }

    return this.fetchData({
      query: {
        name: 'find',
        model: 'LeadActivity',
      },
      filter,
      force: true,
    })
  }

  async getInspectionActivities (schedulingDate) {
    if (!schedulingDate) return []
    const status = ['pending', 'active']
    const { records } = await this.getInspections({
      start_date: dayjs(fixDate(schedulingDate)).add(-9, 'days').format('YYYY-MM-DD'),
      end_date: dayjs(fixDate(schedulingDate)).format('YYYY-MM-DD'),
    })

    const inspections = records?.filter(inspection => status.includes(inspection?.inspectionStatus))
      ?.filter(inspection => dayjs(inspection?.scheduledDate).format('YYYY-MM-DD') === dayjs(fixDate(schedulingDate)).format('YYYY-MM-DD'))
      ?.filter(inspection => inspection?.executiveId !== this.idEmployee)
    return inspections.map(inspection => {
      return plainToInstance(LeadActivity, {
        lead: {
          client: {
            alias: 'No disponible',
            type: {
              name: 'system',
            },
          },
        },
        schedulingDate: localToUtc(inspection.scheduledDate).format('YYYY-MM-DDTHH:mm:ss'),
        duration: '01:00:00',
        type: {
          icon: {
            main: 'mdi-cancel',
          },
          color: '#F03738',
        },
      })
    })
  }

  onTimeRangeSelected (range: { start: string; end: string }) {
    if (!range?.start && !range?.end) return
    this.formData.timeStart = range?.start
    this.formData.timeEnd = range?.end
  }

  get typeChange () {
    const { activity, formData: { schedulingDate } } = this
    return {
      activity,
      date: schedulingDate,
    }
  }

  @Watch('typeChange', { immediate: true, deep: true })
  onDateChange ({ activity, date }) {
    if (!activity || !date) return null
    const { lead } = this

    this.newAgenda = { title: activity, date, allowSelect: true, lead }
    this.setMetadata()
  }

  @Watch('formData.schedulingDate', { immediate: true })
  async onInspectionDateChange (val) {
    if (!val) return

    const activitiesByLead = await this.getActivitiesByLead()
    const inspectionActivities = await this.getInspectionActivities(val) || []

    this.personActivities = [...activitiesByLead, ...inspectionActivities]
  }

  async goPerson () {
    const { backup, formData } = this

    if (!backup) {
      this.setBackup({ formData })
    } else {
      const back = {
        ...formData,
      }
      this.setBackup(back)
    }
    const idPerson = this.formData?.client?.id
    await this.$router.push({ name: 'generic-person-nested', params: { model: 'Lead', uid: null, id: idPerson } })
  }

  @Watch('lead.client', { immediate: true, deep: true })
  onClientChange (val) {
    if (!val) return

    this.formData.client = val
    const errors = nameAlert(val)
    if (!val?.uid) {
      errors.push('Rut es obligatorio para agendar la inspección')
      this.errors = errors.join(', ')
    } else if (errors?.length) {
      this.errors = errors.join(', ')
    } else {
      this.errors = ''
    }
  }
  }
