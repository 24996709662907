
  import { Component, Prop } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'

@Component
  export default class IdPayment extends GForm {
  @Prop({ type: [String, Number, Object, Array] }) value!: any

  processName = null
  idProcessRecord = null

  async mounted () {
    if (!this.value?.id) return
    this.idProcessRecord = this.value.idProcessRecord
      const process = (await this.fetchData({
        query: { name: 'find', model: 'Process' },
        filter: {
          id: { _eq: this.value.idProcess },
        },
      }))[0]
    this.processName = process?.description
    }
  }
