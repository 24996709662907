import gql from 'graphql-tag'

export const getDailyAccountBalance = gql`
  query DailyAccountBalance($start_date: date, $end_date: date) {
    records: finance_daily_account_balance(where: {date: {_gte: $start_date, _lte: $end_date}}) {
      id
      date
      accountBalance: account_balance
      availableBalance: available_balance
      bankAccount: bank_account {
        id
        accountNumber: account_number
        metadata
        bank {
          id
          alias
        }
      }
    }
  }
`
