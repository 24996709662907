import gql from 'graphql-tag'
import { QueryBuilder } from '../utils'
import { Consignment } from '@/entities/purchase'
import {
  BaseAppraisalFragment,
  BaseInspectionFragment,
  BasePurchaseOrderFragment,
  NegotiationFragment,
  PurchaseOrderFragment,
  ResponseFragment,
} from '@/graphql/fragments/purchase'
import { ChannelFragment, DealFragment, LeadFragment, PipelineFragment } from '@/graphql/fragments/crm'
import { AutoFragment, BaseAutoFragment } from '@/graphql/fragments/public'
import { PersonFragment } from '@/graphql/fragments/persons'
import { VersionYearFragment } from '@/graphql/fragments/vehicle'
import { ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'
import { EmployeeFragment } from '@/graphql/fragments/hr'
import { StockFragment, StockViewDetailFragment } from '@/graphql/fragments/sales'

const query = gql`
  ${PurchaseOrderFragment}
  ${NegotiationFragment}
  ${BaseInspectionFragment}
  ${BaseAppraisalFragment}
  ${ResponseFragment}
  ${DealFragment}
  ${LeadFragment}
  ${AutoFragment}
  ${ChannelFragment}
  ${PersonFragment}
  ${BaseAutoFragment}
  ${VersionYearFragment}
  ${ProcessStatusFragment}
  ${BasePurchaseOrderFragment}
  ${StockViewDetailFragment}
  ${StockFragment}
  ${PipelineFragment}
  ${EmployeeFragment}
  ${ClosingReasonFragment}
  query consignments($filter: purchase_purchase_order_bool_exp, $order: [purchase_purchase_order_order_by!], $limit: Int, $offset: Int) {
    records: purchase_purchase_order(where: $filter, order_by: $order, limit: $limit, offset: $offset) {
      ...BasePurchaseOrder
      validator {
        ...Employee
        person{
          ...Person
        }
      }
      status {
        ...ProcessStatus
      }
      closingReason: closing_reason {
        ...ClosingReason
      }
      negotiation {
        id
        agreedAmount: agreed_amount
        responses: negotiation_responses (order_by: {created_at: desc_nulls_last}){
          ...NegotiationResponse
        }
        inspection {
          id
          appraisal {
            id
            expectedPublicationAmount: expected_publication_amount
            deal {
              id
              channel {
                ...Channel
              }
              auto{
                ...AutoBase
                version: version_year {
                  ...VersionYear
                }
                generation{
                  id
                }
                status: process_status {
                  ...ProcessStatus
                }
              }
              lead {
                id
                executive{
                  id
                  person{
                    ...Person
                  }
                }
                client{
                  ...Person
                }
                pipeline {
                  ...Pipeline
                }
              }
            }
          }
          enablement {
            id
            stock{
              ...Stock
              viewDetails: stock_detail {
                ...StockViewDetail
                auto{
                  ...Auto
                }
              }
            }
          }
        }
      }
      stockDetails: stock_detail{
        stock{
          ...Stock
        }
      }
    }
    total: purchase_purchase_order_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const consignments = QueryBuilder(query, Consignment)
export default consignments
