
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { fixAmountFormatted, fixPrice, utcToLocal } from '@/utils/general'
  import { fixColor, lowOpacityColor } from '@/utils/dashboards'
  import { Legend } from '../Components/Legend'
  import { PeriodChart } from '../periodChart'

@Component
  // Grafico de linea para un solo tipo de dato que se separa por categoria
  export default class LineChart extends PeriodChart {
  @Prop() prevMonthRecords
  @Prop() pathToCategory: string[]
  @Prop() pathToDate: string[]
  @Prop({ default: undefined }) pathToValue: string[]
  @Prop({ default: false, type: Boolean }) displayMeans
  @Prop({ default: true, type: Boolean }) showPrevMonth
  @Prop({ default: true, type: Boolean }) sortLegends
  @Prop({ default: undefined, type: Function }) dataTransformer
  @Prop({ default: 'line', type: String }) chartType
  @Prop({ default: 'categoría', type: String }) categoryName
  @Prop({ default: undefined, type: String }) legendHeader
  @Prop({ default: undefined }) pathToSubcategory: string[]
  @Prop({ default: false, type: Boolean }) meanByCount

  legends: Legend[] = []

  getDataByCategory (records, category, path) {
    return records.filter(record => {
      return this.getObjectAttribute(record, path)?.id === category?.id
    })
  }

  async transformData (currentMonthRecords, prevMonthRecords) {
    if (this.dataTransformer) {
      return {
        currentMonthRecords: await this.dataTransformer(currentMonthRecords),
        prevMonthRecords: await this.dataTransformer(prevMonthRecords),
      }
    }
    return { currentMonthRecords, prevMonthRecords }
  }

  async getData () {
    if (!this.parsedRecords) return
    const {
      pathToCategory,
      limitRange,
      getCategories,
      getObjectAttribute,
      pathToDate,
      pathToValue,
      isCurrency,
      monthly,
      displayMeans,
      months,
      parsedRecords,
    } = this
    const parsedPrevMonth = this.prevMonthRecords?.records?.aggregate?.nodes || this.prevMonthRecords?.records || this.prevMonthRecords || []
    const { currentMonthRecords, prevMonthRecords } = await this.transformData(parsedRecords, parsedPrevMonth)
    const categories = getCategories([...currentMonthRecords, ...prevMonthRecords], pathToCategory, this.categoryName, this.debugging)

    const data = categories.map(category => {
      const dataByCategory = {
        currentMonth: this.getDataByCategory(currentMonthRecords, category, pathToCategory),
        prevMonth: this.getDataByCategory(prevMonthRecords, category, pathToCategory),
      }

      return {
        name: category.description,
        data: {
          currentMonth: limitRange.currentMonth.map(date => {
            const filterByDate = dataByCategory.currentMonth.filter(item => utcToLocal(dayjs(getObjectAttribute(item, pathToDate)))?.isSame(dayjs(date), this.monthly ? 'month' : 'day'))
            if (pathToValue) {
              let sum = 0
              filterByDate.forEach(item => sum += getObjectAttribute(item, pathToValue))
              if (monthly && displayMeans) {
                const currentDate = dayjs()
                let divider = 30
                if (dayjs(date)?.isSame(currentDate, 'month')) {
                  divider = this.meanByCount ? (filterByDate.length) : (currentDate.diff(currentDate.startOf('month'), 'days') + 1)
                } else {
                  divider = this.meanByCount ? filterByDate.length : (dayjs(date).endOf('month').diff(dayjs(date).startOf('month'), 'days') + 1)
                }
                this.Debug('DIVIDER', divider)
                return Math.round(divider !== 0 ? (sum / divider) : 0)
              }
              return sum
            } else {
              if (monthly && displayMeans) {
                const currentDate = dayjs()
                let divider = 30
                if (dayjs(date)?.isSame(currentDate, 'month')) {
                  divider = this.meanByCount ? (filterByDate.length) : (currentDate.diff(currentDate.startOf('month'), 'days') + 1)
                } else {
                  divider = this.meanByCount ? filterByDate.length : (dayjs(date).endOf('month').diff(dayjs(date).startOf('month'), 'days') + 1)
                }
                return Math.round(divider !== 0 ? (filterByDate.length / divider) : 0)
              } else {
                return filterByDate.length
              }
            }
          }),
          prevMonth: limitRange.prevMonth.map(date => {
            const filterByDate = dataByCategory.prevMonth.filter(item => utcToLocal(dayjs(getObjectAttribute(item, pathToDate)))?.isSame(dayjs(date), this.monthly ? 'month' : 'day'))
            if (pathToValue) {
              let sum = 0
              filterByDate.forEach(item => sum += getObjectAttribute(item, pathToValue))
              if (monthly) {
                return Math.round(sum / 30)
              }
              return sum
            } else {
              if (monthly) {
                return Math.round(filterByDate.length / 30)
              } else {
                return filterByDate.length
              }
            }
          }),
        },
        color: fixColor(category.color),
        type: this.chartType,
      }
    })

    const currentMonthData = data.map(item => {
      const { name, color, type, data } = item
      const { currentMonth } = data
      return {
        name,
        color,
        type,
        data: this.nullZeroValues ? currentMonth.map(item => item === 0 ? null : item) : currentMonth,
      }
    })

    const prevMonthData = data.map(item => {
      const { name, color, type, data } = item
      const { prevMonth } = data
      return {
        name: name + ' (mes anterior)',
        color: lowOpacityColor(color),
        type,
        data: this.nullZeroValues ? prevMonth.map(item => item === 0 ? null : item) : prevMonth,
      }
    })

    let activeMonths = 1
    currentMonthData.forEach(item => {
      const nonZero = item.data.filter(value => Boolean(value))
      if (nonZero.length > activeMonths) {
        activeMonths = nonZero.length
        if (this.excludeLastMonth && currentMonthData.map(item => item.data).every(item => item[item.length - 1] === 0)) {
          activeMonths += 1
        }
      }
    })

    this.legends = currentMonthData
      .map(item => {
        const { name, color, data } = item
        let value = 0
        data
          .slice(0, this.excludeLastMonth ? data.length - 1 : data.length)
          .filter(item => item)
          .forEach(item => value += item !== null ? item : 0)
        if (monthly || displayMeans) {
          value /= activeMonths - (this.excludeLastMonth ? 1 : 0)
        }
        value = Math.round(value)
        return {
          name,
          color,
          value: value || 0,
          records: currentMonthRecords.filter(item => getObjectAttribute(item, pathToCategory).description === name),
        }
      })
    this.Debug('LEGENDS', this.legends)

    this.series = [...currentMonthData, ...(this.showPrevMonth ? prevMonthData : [])]

    const totals = this.getTotals(currentMonthData)

    this.series.push({
      name: 'Total',
      data: this.nullZeroValues ? totals.map(item => item === 0 ? null : item) : totals,
      color: lowOpacityColor('#737373'),
      type: 'area',
      stroke: {
        curve: 'smooth',
      },
    })

    if (!monthly) {
      const prevMonthTotals = this.getTotals(prevMonthData)

      this.series.push({
        name: 'Total (mes anterior)',
        data: this.nullZeroValues ? prevMonthTotals.map(item => item === 0 ? null : item) : totals,
        color: '#737373',
        type: 'line',
        stroke: {
          curve: 'smooth',
        },
      })
    }

    if (this.accumulated) {
      for (let i = 0; i < this.series.length; i++) {
        this.series[i].data = this.generateAccumulated(this.series[i].data)
      }
    }

    if (!monthly) {
      this.series.forEach(serie => {
        serie.data = this.fillWithNull(serie.data, this.currentMonthLength)
      })
    }

    this.chartOptions = {
      chart: {
        type: 'line',
        stacked: false,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        show: false,
      },
      stroke: {
        width: 2,
        curve: 'straight',
      },
      markers: {
        size: 3,
        strokeWidth: 0,
      },
      xaxis: {
        categories: monthly ? this.limitRange.currentMonth.map(date => dayjs(date)) : this.generateDayRange(this.currentMonth.start, this.currentMonth.end, monthly),
        type: 'category',
        labels: {
          formatter (val) {
            return monthly ? months[dayjs(val).format('MM')].short : dayjs(val).format('DD/MM')
          },
          style: {
            fontSize: '9px',
            colors: '#737373',
            fontFamily: 'Arial',
          },
        },
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter (val) {
            if (val >= 1000000) {
              return `${fixAmountFormatted(val / 1000000)}M`
            } else if (isCurrency) {
              return fixPrice(val)
            } else {
              return fixAmountFormatted(val)
            }
          },
          style: {
            fontSize: '9px',
            colors: '#737373',
            fontFamily: 'Arial',
          },
        },
      },
    }
  }

  getTotals (data) {
    const totals = []
    data.forEach(item => {
      item.data.forEach((value, index) => {
        if (!totals[index]) {
          totals[index] = 0
        }
        totals[index] += value
      })
    })
    return totals
  }

  get watchData () {
    const { records, prevMonthRecords, dates } = this
    return {
      records,
      prevMonthRecords,
      dates,
    }
  }

  @Watch('watchData', { immediate: false, deep: true })
  async update () {
    this.processingData = true
    await this.getData()
    this.processingData = false
  }
  }
