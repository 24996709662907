import { Type } from 'class-transformer'
import { Entity } from '..'
import { ProcessStatus } from '@/entities/settings'

export class StockDailies extends Entity {
  @Type(() => ProcessStatus)
  status: ProcessStatus;

  reportDate: string
}
