import { render, staticRenderFns } from "./AlertReserveSell.vue?vue&type=template&id=dd5df254&scoped=true&"
import script from "./AlertReserveSell.vue?vue&type=script&lang=ts&"
export * from "./AlertReserveSell.vue?vue&type=script&lang=ts&"
import style0 from "./AlertReserveSell.vue?vue&type=style&index=0&id=dd5df254&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd5df254",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VIcon,VRow,VTooltip})
