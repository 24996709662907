
  import { Component, Prop } from 'vue-property-decorator'
  import Row from '../row.vue'
  import { ExpandableProps } from '../interfaces'

@Component({ components: { Row } })
  export default class Expandable extends Row implements ExpandableProps {
  @Prop({ type: Boolean, default: false }) opened?: boolean;
  @Prop({ type: Boolean, default: false }) disabled?: boolean;
  @Prop({ type: Boolean, default: true }) displayPhoto?: boolean;
  @Prop({ type: Boolean, default: false }) hideVehicle?: boolean;

  panel? = -1

  created () {
    const { opened } = this
    this.panel = opened ? 0 : -1
  }

  get extendBind () {
    const { opened } = this
    return { opened }
  }
  }
