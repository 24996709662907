import gql from 'graphql-tag'
import { QueryBuilder } from '../utils'

import { BaseAutoFragment } from '@/graphql/fragments/public'
import { VersionYearFragment } from '@/graphql/fragments/vehicle'
import { PersonAccountFragment, PersonFragment } from '@/graphql/fragments/persons'
import { ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'
import { PaymentTypeFragment } from '@/graphql/fragments/finance'
import { Payment } from '@/entities/finance'

const query = gql`
  ${BaseAutoFragment}
  ${VersionYearFragment}
  ${PersonFragment}
  ${ProcessStatusFragment}
  ${PaymentTypeFragment}
  ${PersonAccountFragment}
  ${PersonAccountFragment}
  ${ClosingReasonFragment}
  query income($filter: finance_payment_bool_exp, $order: [finance_payment_order_by!], $limit: Int, $offset: Int) {
    records: finance_payment(where: $filter, order_by: $order, limit: $limit, offset: $offset) {
      id
      amount
      createdAt:created_at
      expiration
      type:payment_type {
        ...PaymentType
      }
      status{
        ...ProcessStatus
      }
      closingReason: closing_reason {
        ...ClosingReason
      }
      deal{
        id
        auto{
          ...AutoBase
          version: version_year {
            ...VersionYear
          }
        }
        lead{
          id
          executive{
            id
            person{
              ...Person
            }
          }
        }
      }
      payer{
        ...Person
      }
      financialAccount:financial_account {
        id
        personAccount:person_account {
          ...PersonAccount
        }
      }
      validator{
        id
        person{
          ...Person
        }
      }
      responsible{
        id
        person{
          ...Person
        }
      }
    }
    total: finance_payment_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const payment = QueryBuilder(query, Payment)
export default payment
