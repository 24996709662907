
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { Reserve, SaleOrder } from '@/entities/sales'

@Component
  export default class AlertReserveSell extends Cell {
  alertInsurance = null
  alertTag = null
  alertWarranty = null
  alertConsignment = null

  alert = {
    background: '',
    color: '',
    icon: '',
    tooltip: '',
    value: 0,
  }

  async mounted () {
    const { item } = this

    if (!item?.id) return

    if (item instanceof Reserve) {
      await this.getReserveInfo()
    }

    if (item instanceof SaleOrder) {
      await this.getSellInfo()
    }
  }

  async getSellInfo () {
    const { item, fetchData } = this

    const data = await fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { status: { status: { name: { _eq: 'pending' } } } },
          { deal: { id: { _eq: item.deal.id } } },
        ],
      },
      force: true,
    })

    if (!data?.length) {
      this.setAlert()
    }

    const products = await fetchData({
      query: { name: 'find', model: 'SaleProduct' },
      filter: { id_sale_order: { _eq: item.id } },
      force: true,
    })

    const insurance = products?.find(product => product.name === 'insurance')
    const tag = products?.find(product => product.name === 'tag')
    const warranty = products?.find(product => product.name === 'warranty')
    if (insurance) {
      this.alertInsurance = {
        background: 'white',
        color: 'primary',
        icon: 'mdi-shield',
        tooltip: 'Asegurado',
        value: 0,
      }
    }

    if (tag) {
      this.alertTag = {
        background: 'white',
        color: 'primary',
        icon: 'mdi-road-variant',
        tooltip: 'Tag',
        value: 0,
      }
    }

    if (warranty) {
      this.alertWarranty = {
        background: 'white',
        color: 'primary',
        icon: 'mdi-hammer-wrench',
        tooltip: 'Garantía',
        value: 0,
      }
    }

    if (item?.deal?.stock?.viewDetails?.acquisitionType?.name === 'consignment') {
      this.alertConsignment = {
        background: 'orange',
        icon: 'mdi-cart-arrow-right',
        color: undefined,
        tooltip: 'Consignación',
      }
    }
  }

  setAlert () {
    this.alert = {
      background: 'white',
      color: 'green',
      icon: 'mdi-cash',
      tooltip: 'Pagos validados',
      value: 0,
    }
  }

  get alerts () {
    return [
      this.alert,
      this.alertInsurance,
      this.alertTag,
      this.alertWarranty,
      this.alertConsignment,
    ].filter(Boolean)
  }

  async getReserveInfo () {
    const { item, fetchData } = this

    const data = await fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { status: { status: { name: { _eq: 'pending' } } } },
          { id_process_record: { _eq: item.id } },
          { process: { table_name: { _eq: 'reserve' } } },
        ],
      },
      force: true,
    })

    if (!data?.length) {
      this.setAlert()
    }
  }

  get background () {
    const { alert: { background } } = this
    return background
  }

  get color () {
    const { alert: { color } } = this
    return color
  }

  get icon () {
    const { alert: { icon } } = this
    return icon
  }

  get tooltip () {
    const { alert: { tooltip } } = this
    return tooltip
  }

  get value () {
    const { alert: { value = 0 } } = this
    return value
  }
  }
