
  import { Component, Prop } from 'vue-property-decorator'
  import Cell from './cell'

  import { ClosingReason, Icon, ProcessStatus } from '@/entities/settings'
  import { Appraisal } from '@/entities/purchase'
  import { fixPrice } from '@/utils/general'

@Component
  export default class StatusAppraisalCell extends Cell {
  @Prop({ type: String, default: 'small' }) size!: string;
  @Prop({ type: String, default: 'space-between' }) justify!: string;
  @Prop({ type: Number, default: 10 }) cols!: string;

  showDetails = true
  declare options: {
    set: Record<string, Icon>
  }

  get status (): ProcessStatus | ClosingReason {
    const { item } = this

    if (!item) return

    if (item?.closingReason) {
      return item?.closingReason
    }

    return item.status
  }

  get price () {
    const { price } = this.item as Appraisal
    return fixPrice(price)
  }

  get priceConsignment () {
    const { priceConsignment } = this.item as Appraisal
    return fixPrice(priceConsignment)
  }

  get isSmall () {
    const { size, isMobile } = this

    if (isMobile) return false

    return size === 'small'
  }
  }
