import gql from 'graphql-tag'
import { DealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'

export const query = gql`
  ${DealAutoAttributeFragment}
  query findDealAutoAttribute($filter: deal_auto_attribute_bool_exp!, $order: [deal_auto_attribute_order_by!]) {
    records: deal_auto_attribute(where: $filter, order_by: $order){
      ...DealAutoAttribute
    }
  }
`
export default query
