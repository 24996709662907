import gql from 'graphql-tag'
import { EntityFields } from '..'

export const FileFragment = gql`
  fragment File on files_file{
    ${EntityFields}
    name
    uri
    type
    checksum
    expirationDate:expiration_date
    metadata
    sourceLink:source_link
    serialization
  }
`
