import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/system',
    name: 'system',
    component: () => import('@/layouts/config/Index.vue'),
    meta: { requiresLogin: true, auth: { param: 'role' } },
    children: [
      {
        path: ':role/rules',
        name: 'system_rules',
        meta: { inherit: true },
        component: () => import('./SystemRules.vue'),
      },
      {
        path: ':role/profile',
        name: 'system_profile',
        meta: { inherit: true },
        component: () => import('./SystemProfile.vue'),
      },
    ],
  },
]

export default routes
