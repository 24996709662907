
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'

  import List from './list.vue'

  import { Resource } from '@/entities/public'
  import { Form, ToolKit } from '@/entities/public/Resource/metadata'

@Component({
  components: { List },
  methods: {
    ...mapActions('toolkit', ['fetch']),
  },
  computed: {
    ...mapGetters('toolkit', ['content']),
  },
})
  export default class DetailsComponent extends Vue {
  @Prop({ type: Object, required: true }) resource!: Resource;

  declare fetch: (payload: any) => Promise<any>;
  declare content: any; // This is the value to be used for all the rows binding

  get metadata (): ToolKit | Form {
    const { resource } = this
    return resource?.metadata as ToolKit
  }

  get rows () {
    const { metadata } = this
    return metadata.constructor === ToolKit ? metadata.children : metadata.details.children
  }

  get list () {
    const { rows, content, fetch } = this

    const hasOrder = rows.some(row => 'order' in row)

    const orderedRows = hasOrder ? rows.sort((a, b) => a.order - b.order) : rows

    return {
      content,
      rows: orderedRows,
      fetch,
    }
  }

  get onList () {
    return {}
  }
  }
