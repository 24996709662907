
  import { Component, Watch } from 'vue-property-decorator'
  import { BaseCustomForm } from '@/components/person/BasicCustomForm'
  import CompanyFields from '@/components/person/CompanyFields.vue'
  import PersonFields from '@/components/person/PersonFields.vue'
  import { Person, PersonType } from '@/entities/persons'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { mapActions, mapGetters } from 'vuex'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import { plainToInstance } from 'class-transformer'

@Component({
  components: {
    GAlert,
    PersonFields,
    CompanyFields,
    BaseForm,
  },
  methods: {
    ...mapActions('persons', ['getPersonType']),
  },
  computed: {
    ...mapGetters('persons', ['personType', 'allowEnterprise']),
  },
})
  export default class PersonForm extends BaseCustomForm {
  getPersonType!: () => void;
  personType!: Array<PersonType>;
  allowEnterprise!: boolean;
  type: PersonType | null = null;
  title = 'Persona'
  disabled: boolean = false
  metadataCollection = null
  showDetails = false

  declare $refs: {
    form: HTMLFormElement
    person: PersonFields
    company: CompanyFields
  };

  alert = {
    open: false,
    title: '',
    subtitle: '',
  }

  // Methods

  async mounted () {
    const { metadata } = this.getForm('Person', 'person')
    this.metadataCollection = metadata
    const { title, personIdComputed } = this

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadata, title, Boolean(personIdComputed))
    }
    this.getPersonType()
  }

  async closePerson () {
    const { type, backup } = this

    if (type.isNatural) {
      this.$refs.person.initValues()
    } else {
      this.$refs.company.initValues()
    }
    if (!backup) {
      await this.$router.push({ name: 'home' })
    } else {
      await this.close()
    }
  }

  send () {
    const { type } = this

    if (type.isNatural) {
      this.$refs.person.send()
    } else {
      this.$refs.company.send()
    }
  }

  setMetadata (person: Person) {
    const { metadataCollection } = this

    this.metadata = {
      data: person,
      metadata: metadataCollection,
    }

    this.showDetails = Boolean(person.id)
  }

  confirmChange () {
    this.alert = {
      open: false,
      title: '',
      subtitle: '',
    }
    this.type = this.personEntity.type
    this.setMetadata(this.personEntity)
    this.lookFieldsRequired(this.personEntity.id)
    this.setRutInfo(undefined)
  }

  cancelChange () {
    this.alert = {
      open: false,
      title: '',
      subtitle: '',
    }
    const { type } = this

    if (type.isNatural) {
      this.$refs.person.initPerson()
    } else {
      this.$refs.company.initCompany()
    }
    this.personEntity = plainToInstance(Person, {})
    this.setFoundPerson(null)
  }

  changeType (type: string) {
    this.type = this.personType.find(personType => personType.name === type)
  }

  async updatePersonId () {
    this.showDetails = false

    this.personEntity = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: this.personIdComputed } },
      force: true,
    })
  }

  async updateInfo () {
    this.showDetails = false

    this.personEntity = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id: this.personEntity.id } },
      force: true,
    })

    this.setMetadata(this.personEntity)
  }

  setLoading (loading: boolean) {
    this.loadingForm = loading
  }

  // Getters
  // Watchers
  @Watch('personEntity', { immediate: true, deep: true })
  onPersonChange (person: Person) {
    const { personIdComputed } = this

    if (!person?.id || Boolean(personIdComputed)) {
      if (person?.id) {
        this.type = person?.type
        this.setMetadata(person)
        this.lookFieldsRequired(person.id)
      }

      return
    }

    if (this.type?.name === 'natural_person') {
      return
    }

    if ((!this.type || this.type?.name === person?.type?.name)) {
      this.type = person.type
      this.setMetadata(person)
      this.lookFieldsRequired(person.id)
    } else {
      this.alert = {
        open: true,
        title: `Según nuestro sistema la persona encontrada es de tipo ${person.type.description}`,
        subtitle: `${person.fullName}`,
      }
    }
  }

  @Watch('personType', { immediate: true, deep: true })
  onPersonTypeChange (val) {
    if (!val.length) return

    this.type = val.find(type => type.name === 'natural_person')
  }

  beforeDestroy () {
    this.setAllowEnterprise(false)
  }
  }
