
  import Cell from '@/components/dataTables/cell/cell'
  import { Component } from 'vue-property-decorator'
  import { mapActions } from 'vuex'
  import GAlert from '@/components/core/alert/GAlert.vue'

@Component({
  components: { GAlert },
  methods: {
    ...mapActions('resources', ['removeStock', 'excludeStock']),
  },
})
  export default class RemoveStockPublication extends Cell {
  stockAlert = {
    open: false,
    title: '¿Estás seguro de despublicar este vehículo de todas las plataformas?',
    subtitle: 'Tardará unos minutos en reflejarse en todas las plataformas',
  }

  removeStock!: (id: number) => Promise<void>
  excludeStock!: ({ idStock, exclude }: { idStock: number, exclude: boolean }) => Promise<void>

  // Methods
  async remove () {
    this.stockAlert.open = false
    await this.removeStock(this.item.id)
    await this.excludeStock({ idStock: this.item.id, exclude: true })
  }

  // Getters
  get disabledButton () {
    const { yapo, mercadolibre, chileautos } = this.item

    return Boolean(!yapo?.length && !mercadolibre?.length && !chileautos?.length)
  }

  // Watchers
  }
