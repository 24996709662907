
  import { Component, Watch } from 'vue-property-decorator'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Debounce } from '@/utils/decorators'
  import { City } from '@/entities/public'
  import { fixDate, isValidNumber, parseToNumber } from '@/utils/general'
  import { Person } from '@/entities/persons'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import { DateGenerator } from '@/utils/date/DateGenerator'
  import GCostField from '@/components/core/input/GCostField.vue'
  import dayjs from 'dayjs'
  import { DateFormatter } from '@/utils/date/DateFormatter'

@Component({
  methods: { isValidNumber },
  components: { GCostField, RatingCell, GFiles, GDatePicker, GFormSlot, BasePanel },
})
  export default class DocumentationPanel extends BaseStepper {
  componentKeys = ['circulationPermit', 'soap', 'technicalReview']
  cities: City[] = []
  plants: City[] = []
  insurances: Person[] = []
  displayAmount = false
  disabledAmount = false
  firstRender = true
  loadingSend = false
  total = null
  amount = null
  formData = {
    soap: {
      photo: null,
      date: null,
      selection: null,
      cost: null,
    },
    technicalReview: {
      photo: null,
      date: null,
      selection: null,
      cost: null,
    },
    circulationPermit: {
      selection: null,
      date: null,
      amount: null,
      photo: null,
      cost: null,
    },
  }

  async mounted () {
    const { category } = this
    this.loadingForm = true
    this.components = category.components

    this.cities = await this.fetchData({
      query: { name: 'find', model: 'City' },
      filter: {},
    })

    this.plants = await this.fetchData({
      query: {
        name: 'find',
        model: 'Person',
        order: {
          alias: 'asc',
        },
      },
      filter: { company_type: { name: { _eq: `technical_review_plant` } } },
      limit: 100,
    })

    const companyType = await this.fetchData({
      query: { name: 'find', model: 'CompanyType' },
      filter: { name: { _eq: 'insurance_carrier' } },
    })
    this.insurances = companyType?.[0]?.persons
  }

  get soap () {
    return this.findComponentBySlug(this.components, 'soap')
  }

  get technicalReview () {
    return this.findComponentBySlug(this.components, 'technical_review')
  }

  get circulationPermit () {
    return this.findComponentBySlug(this.components, 'circulation_permit')
  }

  async send (component, data, key) {
    const { step, photoProperties, inspection, getScore } = this
    this.loadingSend = true
    if (key === 'circulationPermit') {
      await this.sendDocumentCirculationPermit(component, data, photoProperties, inspection.id)
    } else {
      await this.sendOtherDocuments(component, data, photoProperties, inspection.id, key)
    }

    this.step = step + 1
    const total = (parseInt(this.formData.circulationPermit?.cost) || 0) + (parseInt(this.formData.soap?.cost) || 0) + (parseInt(this.formData.technicalReview?.cost) || 0)
    const lastComponent = this.step > this.componentKeys.length

    if (lastComponent) {
      const { category, categoryName } = this
      this.$emit('input', { categoryName, category, score: getScore(), total })
      this.$emit('inspection-category-done')
    }
    this.loadingSend = false
  }

  async searchCity (val) {
    if (!val) return
    this.cities = await this.fetchData({
      query: { name: 'find', model: 'City' },
      filter: { name: { _ilike: `%${val}%` } },
    })
  }

  async searchCompany (val) {
    if (!val) return

    this.insurances = await this.fetchData({
      query: { name: 'find', model: 'Person', order: { alias: 'asc' } },
      filter: { company_type: { name: { _eq: `insurance_carrier` } }, alias: { _ilike: `%${val}%` } },
    })
  }

  async searchPlants (val) {
    if (!val) return

    this.plants = await this.fetchData({
      query: {
        name: 'find',
        model: 'Person',
        order: {
          alias: 'asc',
        },
      },
      filter: { _and: [{ company_type: { name: { _eq: `technical_review_plant` } } }, { alias: { _ilike: `%${val}%` } }] },
      limit: 100,
    })
  }

  get isCalculableCost () {
    const { formData } = this

    return {
      amount: formData.circulationPermit.amount,
      date: formData.circulationPermit.date,
    }
  }

  get allComponents () {
    const { circulationPermit, soap, technicalReview, selfPanel } = this

    return {
      circulationPermit,
      soap,
      technicalReview,
      selfPanel,
    }
  }

  getScore () {
    const {
      categoryQualificationConfig, expirationPeriodSoap,
      expirationPeriodTechnicalReview,
      expirationCirculation,
      evaluateDeadline,
      categoryName: name,
    } = this

    if (!categoryQualificationConfig) return null

    const score = evaluateDeadline(expirationCirculation) + evaluateDeadline(expirationPeriodSoap) + evaluateDeadline(expirationPeriodTechnicalReview)

    let configValue: number | undefined

    Object.entries(categoryQualificationConfig?.[name]?.scores).forEach(([key, value]) => {
      const config = value as Record<string, any>
      if (score >= config.min && (config.max === null || score <= config.max)) {
        configValue = config.value
      }
    })

    return configValue
  }

  @Watch('inspection', { immediate: true, deep: true })
  async onInspectionChange (val) {
    if (val?.deal?.auto) {
      const { deal: { auto: { generation } } } = val

      const date = val.date.year()
      const registration = generation?.registrations?.find(registration => parseToNumber(registration.year) === parseToNumber(date))

      this.amount = registration?.permission
      if (!this.formData.circulationPermit.amount) {
        this.formData.circulationPermit.amount = registration?.permission
      }
      this.displayAmount = true
      this.disabledAmount = Boolean(registration?.permission)
    }

    if (!isValidNumber(this.total) && val.metadata?.findCategoryByName(this.category.name)) {
      this.total = val.metadata?.findCategoryByName(this.category.name)?.total
    }
  }

  @Watch('isCalculableCost', { immediate: true, deep: true })
  onCalculableCost (val) {
    if (val.amount) {
      this.formData.circulationPermit.cost = Math.round(parseToNumber(val.amount) * this.delayPeriod)
    }
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(500)
  async onComponentsChange (val) {
    const {
      idProcess,
      inspection,
      componentKeys,
      expirationCirculation,
      expirationPeriodSoap,
      expirationPeriodTechnicalReview,
    } = this

    if (expirationCirculation && expirationPeriodSoap && expirationPeriodTechnicalReview) return
    this.step = 1
    const handleComponentChange = async (component, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents?.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        this.formData[key].date = DateGenerator.findGeneratedDate(matchingInspectedComponent.findInspectionParameterByOrder(2).value).internal

        if (key === 'circulationPermit') {
          this.formData[key].selection = { name: matchingInspectedComponent.findInspectionParameterByOrder(1).value }
          this.formData[key].amount = matchingInspectedComponent.findInspectionParameterByOrder(3).value
          await this.searchCity(this.formData[key].selection.name)
        }

        if (key === 'soap') {
          this.formData[key].selection = { alias: matchingInspectedComponent.findInspectionParameterByOrder(1).value }
          await this.searchCompany(this.formData[key].selection.alias)
        }

        if (key === 'technicalReview') {
          this.formData[key].selection = { alias: matchingInspectedComponent.findInspectionParameterByOrder(1).value }
          await this.searchPlants(this.formData[key].selection.alias)
        }
      }
    }

    for (const key of componentKeys) {
      await handleComponentChange(val[key], key)
    }
    this.loadingForm = false
  }

  @Watch('step', { immediate: true })
  @Debounce(500)
  async onStepChange (val) {
    const {
      componentKeys,
      autoClose,
      categoryName,
      category,
      categoryQualificationConfig,
      getScore,
      displayRating,
      progress,
      inspection,
      supervisor,
      loadingForm,
    } = this
    if (loadingForm) return

    const total = (parseInt(this.formData.circulationPermit?.cost) || 0) + (parseInt(this.formData.soap?.cost) || 0) + (parseInt(this.formData.technicalReview?.cost) || 0)
    const lastComponent = val > componentKeys.length
    const score = getScore()
    if (total) {
      this.total = total
    }

    if (!displayRating && !lastComponent) {
      await this.updateCategoryProgress(inspection, categoryName, progress, 2)
    } else if (displayRating && !supervisor) {
      this.$emit('input', { categoryName, category, categoryQualificationConfig, score, total })
    }

    if (lastComponent && !autoClose && supervisor) {
      this.$emit('input', { categoryName, category, categoryQualificationConfig, score, total })
    }
  }

  get progress () {
    const { step } = this

    if (step > 1) {
      return ((step - 1) / 3) * 100
    }

    return 0
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  get generateDates () {
    const { inspection } = this
    if (!inspection?.date) return []

    return DateGenerator.generateDates(inspection?.date.year())
  }

  get generateSoapDates () {
    const { inspection } = this
    if (!inspection?.date) return []

    return DateGenerator.generateDates(inspection?.date.year(), false)
  }

  get delayPeriod () {
    const { inspection, formData } = this

    return DateGenerator.calculateMonthsDifference(formData.circulationPermit.date, inspection.date)
  }

  get expirationCirculation () {
    const { formData } = this
    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.circulationPermit.date)
  }

  get expirationPeriodSoap () {
    const { formData } = this
    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.soap.date)
  }

  get expirationPeriodTechnicalReview () {
    const { formData } = this

    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.technicalReview.date)
  }

  get isTechnicalReviewMoreThanToday () {
    const { formData } = this
    if (!formData?.technicalReview?.date) return false

    return dayjs(fixDate(DateFormatter.toValidDBDate(formData.technicalReview.date)), 'DD/MM/YYYY').isAfter(dayjs())
  }

  @Watch('expirationPeriodTechnicalReview', { immediate: true })
  onTechnicalDateChange (val) {
    if (val >= 2) {
      this.formData.technicalReview.cost = null
    } else {
      this.formData.technicalReview.cost = this.technicalReview?.inspectionComponent?.config?.cost
    }
  }

  @Watch('expirationPeriodSoap', { immediate: true })
  onDateChange (val) {
    if (val >= 1) {
      this.formData.soap.cost = null
    } else {
      this.formData.soap.cost = this.soap?.inspectionComponent?.config?.cost
    }
  }

  get ppu () {
    const { inspection } = this
    if (!inspection?.deal?.auto?.ppu) return undefined

    return inspection.deal.auto.ppu?.split('')[inspection.deal.auto.ppu.length - 1]
  }

  get technicalDates () {
    const { ppu, inspection } = this

    if (!ppu) return []
    return DateGenerator.calculateMonthByDigit(parseToNumber(ppu), inspection?.deal?.auto?.year)
  }

  evaluateDeadline (deadline) {
    if (deadline === null || deadline === undefined) {
      return null
    }
    if (deadline < 0) {
      return 2
    }
    if (deadline === 0) {
      return 1
    }
    if (deadline > 0) {
      return 0
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  @Watch('formData.circulationPermit.amount', { immediate: true })
  onAmountChange (val) {
    if (!val) return

    const { amount } = this
    if (!amount) return
    const cost = amount - parseToNumber(val)

    if (cost > 0) {
      this.formData.circulationPermit.cost = cost
    } else {
      this.formData.circulationPermit.cost = 0
    }
  }

  get displayRating () {
    const { inspection, category } = this
    const score = inspection?.metadata?.findCategoryByName(category.name)?.score

    return score && score >= 0
  }

  get isCategoryDone () {
    const { inspection, category } = this
    return inspection?.metadata?.findCategoryByName(category.name)?.done
  }
  }
