import { PurchaseOrder } from '.'
import { Person } from '@/entities/persons'
import { Employee } from '@/entities/hr'

export class Consignment extends PurchaseOrder {
  owners: Person[]
  executive: Employee

  get buttonActivity () {
    const { id, status } = this
    const statusToCheck = ['approved', 'pickup_to_confirm', 'pickup_to_update', 'pickup_active']

    return {
      disabled: !statusToCheck.includes(status.name),
      constructor: { name: 'ConsignmentForm' },
      id,
    }
  }

  get utility () {
    const { agreedAmount, negotiation } = this

    const priceExpected = negotiation?.inspection?.appraisal?.expectedPublicationAmount || 0
    const priceStock = negotiation?.inspection?.enablement?.stock?.price?.amount || priceExpected || 0

    if (!priceStock) {
      return null
    }

    if (!negotiation?.inspection?.enablement?.stock?.id) {
      if (!negotiation.inspection.appraisal.expectedPublicationAmount) return null
      return { value: Number(priceStock) - Number(agreedAmount) }
    }
    const price = Number(priceStock) || 0
    const expectedPublicationAmount = price || Number(negotiation.inspection.enablement.stock.viewDetails.expectedPublicationAmount) || 0
    const purchaseAmount = Number(negotiation.inspection.enablement.stock.viewDetails.purchaseAmount) || 0
    const enablementCost = Number(negotiation.inspection.enablement.stock?.viewDetails?.enablementCost) || 0
    const bono = Number(negotiation.inspection.enablement.stock?.price?.highestBonus?.amount) || 0

    return { value: expectedPublicationAmount - purchaseAmount - enablementCost - bono }
  }

  get stockStatus () {
    const { stockDetails } = this

    return stockDetails?.stock?.stockStatus
  }

  get agreementPrice () {
    const { agreedAmount, negotiation } = this
    if (!negotiation?.inspection?.enablement?.stock?.id) {
      return { value: agreedAmount }
    }
    const purchaseAmount = Number(negotiation.inspection.enablement.stock.viewDetails.purchaseAmount)
    return { value: purchaseAmount }
  }

  get stockLeadInformation () {
    const { negotiation } = this

    return negotiation.inspection.enablement?.stock?.received
  }

  get stockDaysInformation () {
    const { negotiation } = this
    return negotiation.inspection.enablement?.stock?.stockInformation
  }
}
