import { io } from 'socket.io-client'

const url = process.env.VUE_APP_BASE_WS
// URL de tu socket
// Inicializa el socket
const socket = io(url, {
  path: '/socket.io',
  transports: ['websocket'], // Fuerza el uso de WebSocket
  secure: true,
  reconnection: true,
  reconnectionAttempts: 5,
})

// Exporta para poder usarlo en otras partes de la app
export default socket
