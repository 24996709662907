
  import { Component, Watch } from 'vue-property-decorator'
  import BasePanel from '@/components/forms/inspection/base/BasePanel.vue'
  import BaseStepper from '@/components/forms/inspection/base/BaseStepper.vue'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Debounce } from '@/utils/decorators'
  import { City } from '@/entities/public'
  import { isValidNumber, parseToNumber } from '@/utils/general'
  import { Person } from '@/entities/persons'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'
  import { DateGenerator } from '@/utils/date/DateGenerator'
  import RowData from '@/components/forms/inspection/supervisor/components/RowData.vue'

@Component({
  components: { RowData, RatingCell, GFiles, GDatePicker, GFormSlot, BasePanel },
})
  export default class DocumentationSupervisorPanel extends BaseStepper {
  componentKeys = ['circulationPermit', 'soap', 'technicalReview']
  cities: City[] = []
  plants: City[] = []
  insurances: Person[] = []
  total = null
  displayAmount = false
  disabledAmount = false
  firstRender = true
  amount = null
  formData = {
    soap: {
      photo: null,
      date: null,
      selection: null,
      cost: null,
    },
    technicalReview: {
      photo: null,
      date: null,
      selection: null,
      cost: null,
    },
    circulationPermit: {
      selection: null,
      date: null,
      amount: null,
      photo: null,
      cost: null,
    },
  }

  async mounted () {
    const { category } = this

    this.components = category.components

    this.cities = await this.fetchData({
      query: { name: 'find', model: 'City' },
      filter: {},
    })

    this.plants = await this.fetchData({
      query: {
        name: 'find',
        model: 'Person',
        order: {
          alias: 'asc',
        },
      },
      filter: { company_type: { name: { _eq: `technical_review_plant` } } },
      limit: 100,
    })

    const companyType = await this.fetchData({
      query: { name: 'find', model: 'CompanyType' },
      filter: { name: { _eq: 'insurance_carrier' } },
    })
    this.insurances = companyType?.[0]?.persons
  }

  get soap () {
    return this.findComponentBySlug(this.components, 'soap')
  }

  get technicalReview () {
    return this.findComponentBySlug(this.components, 'technical_review')
  }

  get circulationPermit () {
    return this.findComponentBySlug(this.components, 'circulation_permit')
  }

  async send () {
    const { allComponents, inspection, categoryName, category } = this
    this.total = this.getTotalCost(this.formData)
    const keys = Object.keys(allComponents)
    await Promise.all(keys.map(async key => {
      const component = allComponents[key]
      const matchingInspectedComponent = inspection.inspectedComponents.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )
      if (matchingInspectedComponent) {
        const idQualification = matchingInspectedComponent.inspectionQualification.id
        await this.updateInspectionQualificationValidated(idQualification)

        await this.pushData({
          model: 'InspectionInspectedComponent',
          fields: { id: matchingInspectedComponent.id, supervisor_cost: this.formData[key].cost || 0 },
        })
      }
    }))

    this.$emit('input', { categoryName, category, total: this.total })
    this.$emit('inspection-category-done')
  }

  async searchCity (val) {
    if (!val) return
    this.cities = await this.fetchData({
      query: { name: 'find', model: 'City' },
      filter: { name: { _ilike: `%${val}%` } },
    })
  }

  async searchCompany (val) {
    if (!val) return

    this.insurances = await this.fetchData({
      query: { name: 'find', model: 'Person', order: { alias: 'asc' } },
      filter: { company_type: { name: { _eq: `insurance_carrier` } }, alias: { _ilike: `%${val}%` } },
    })
  }

  async searchPlants (val) {
    if (!val) return

    this.plants = await this.fetchData({
      query: {
        name: 'find',
        model: 'Person',
        order: {
          alias: 'asc',
        },
      },
      filter: { _and: [{ company_type: { name: { _eq: `technical_review_plant` } } }, { alias: { _ilike: `%${val}%` } }] },
      limit: 100,
    })
  }

  get isCalculableCost () {
    const { formData } = this

    return {
      amount: formData.circulationPermit.amount,
      date: formData.circulationPermit.date,
    }
  }

  get allComponents () {
    const { circulationPermit, soap, technicalReview, selfPanel } = this

    return {
      circulationPermit,
      soap,
      technicalReview,
      selfPanel,
    }
  }

  @Watch('inspection', { immediate: true, deep: true })
  async onInspectionChange (val) {
    if (!val?.id) return
    if (val?.deal?.auto) {
      const { deal: { auto: { generation } } } = val
      const date = val.date.year()
      const registration = generation?.registrations?.find(registration => parseToNumber(registration.year) === parseToNumber(date))

      this.amount = registration?.permission
      this.formData.circulationPermit.amount = registration?.permission
      this.displayAmount = true
      this.disabledAmount = Boolean(registration?.permission)
    }

    if (!isValidNumber(this.total)) {
      const totalSupervisor = val.supervisorMetadata?.findCategoryByName(this.category.name)?.total
      const totalInspector = val.metadata?.findCategoryByName(this.category.name)?.total
      this.total = totalSupervisor || totalInspector
    }
  }

  @Watch('isCalculableCost', { immediate: true, deep: true })
  onCalculableCost (val) {
    if (val.amount) {
      this.formData.circulationPermit.cost = Math.round(parseToNumber(val.amount) * this.delayPeriod)
    }
  }

  @Watch('allComponents', { immediate: true, deep: true })
  @Debounce(500)
  async onComponentsChange (val) {
    if (!val.selfPanel) return
    const {
      idProcess,
      inspection,
      componentKeys,
      autoClose,
      disabled,
      expirationCirculation,
      expirationPeriodSoap,
      expirationPeriodTechnicalReview,
      displayQualification,
    } = this

    if (expirationCirculation && expirationPeriodSoap && expirationPeriodTechnicalReview) return
    this.step = 1
    const handleComponentChange = async (component, step, key) => {
      const matchingInspectedComponent = inspection.inspectedComponents?.find(
        ic => ic.inspectionComponent.id === component?.inspectionComponent?.id
      )

      if (matchingInspectedComponent) {
        if (!autoClose && !disabled && !displayQualification) {
          this.step = step
        }

        this.formData[key].photo = await this.fetchData({
          query: { name: 'find', model: 'FileProcess' },
          filter: {
            _and: [
              { id_process_record: { _eq: matchingInspectedComponent.id } },
              { parameter: { process: { id: { _eq: idProcess } } } },
              { parameter: { file_type: { name: { _eq: 'photo' } } } },
            ],
          },
          force: true,
        })

        this.formData[key].date = DateGenerator.findGeneratedDate(matchingInspectedComponent.findInspectionParameterByOrder(2).value).internal

        if (key === 'circulationPermit') {
          this.formData[key].selection = { name: matchingInspectedComponent.findInspectionParameterByOrder(1).value }
          this.formData[key].amount = matchingInspectedComponent.findInspectionParameterByOrder(3).value
          await this.searchCity(this.formData[key].selection.name)
        }

        if (key === 'soap') {
          this.formData[key].selection = { alias: matchingInspectedComponent.findInspectionParameterByOrder(1).value }
          await this.searchCompany(this.formData[key].selection.alias)
        }

        if (key === 'technicalReview') {
          this.formData[key].selection = { alias: matchingInspectedComponent.findInspectionParameterByOrder(1).value }
          await this.searchPlants(this.formData[key].selection.alias)
        }
      }
    }

    for (const [index, key] of componentKeys.entries()) {
      await handleComponentChange(val[key], index + 1, key)
    }
  }

  @Watch('autoClose', { immediate: true })
  onDisableChange (val) {
    if (val) {
      this.step = 1
    }
  }

  get generateDates () {
    const { inspection } = this
    if (!inspection?.date) return []

    return DateGenerator.generateDates(inspection?.date.year())
  }

  get generateSoapDates () {
    const { inspection } = this
    if (!inspection?.date) return []

    return DateGenerator.generateDates(inspection?.date.year(), false)
  }

  get delayPeriod () {
    const { inspection, formData } = this

    return DateGenerator.calculateMonthsDifference(formData.circulationPermit.date, inspection.date)
  }

  get expirationCirculation () {
    const { formData } = this
    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.circulationPermit.date)
  }

  get expirationPeriodSoap () {
    const { formData } = this
    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.soap.date)
  }

  get expirationPeriodTechnicalReview () {
    const { formData } = this
    return DateGenerator.calculateMonthsDifferenceWithCurrentDate(formData.technicalReview.date)
  }

  @Watch('expirationPeriodTechnicalReview', { immediate: true })
  onTechnicalDateChange (val) {
    if (val >= 2) {
      this.formData.technicalReview.cost = null
    } else {
      this.formData.technicalReview.cost = this.technicalReview?.inspectionComponent?.config?.cost
    }
  }

  @Watch('expirationPeriodSoap', { immediate: true })
  onDateChange (val) {
    if (val >= 1) {
      this.formData.soap.cost = null
    } else {
      this.formData.soap.cost = this.soap?.inspectionComponent?.config?.cost
    }
  }

  get ppu () {
    const { inspection } = this
    if (!inspection?.deal?.auto?.ppu) return undefined

    return inspection.deal.auto.ppu?.split('')[inspection.deal.auto.ppu.length - 1]
  }

  get technicalDates () {
    const { ppu } = this
    if (!ppu) return []
    return DateGenerator.calculateMonthByDigit(parseToNumber(ppu))
  }

  evaluateDeadline (deadline) {
    if (deadline === null || deadline === undefined) {
      return null
    }
    if (deadline < 0) {
      return 2
    }
    if (deadline === 0) {
      return 1
    }
    if (deadline > 0) {
      return 0
    }
  }

  get isMetadata () {
    const { inspection } = this

    return Boolean(inspection.metadata)
  }

  @Watch('formData.circulationPermit.amount', { immediate: true })
  onAmountChange (val) {
    if (!val) return

    const { amount } = this
    if (!amount) return
    const cost = amount - parseToNumber(val)

    if (cost > 0) {
      this.formData.circulationPermit.cost = cost
    } else {
      this.formData.circulationPermit.cost = 0
    }
  }

  get communeName () {
    const { formData: { circulationPermit: { selection } } } = this

    return selection?.name
  }

  get soapAlias () {
    const { formData: { soap: { selection } } } = this

    return selection?.alias
  }

  get technicalReviewAlias () {
    const { formData: { technicalReview: { selection } } } = this

    return selection?.alias
  }
  }
