import { render, staticRenderFns } from "./expandable.vue?vue&type=template&id=5eaf8d81&scoped=true&"
import script from "./expandable.vue?vue&type=script&lang=ts&"
export * from "./expandable.vue?vue&type=script&lang=ts&"
import style0 from "./expandable.vue?vue&type=style&index=0&id=5eaf8d81&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eaf8d81",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels})
