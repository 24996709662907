
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { fixAmountFormatted, fixPrice } from '@/utils/general'

@Component
  export default class TreeChart extends BaseChart {
  @Prop() pathToCategory: string[]
  @Prop({ default: () => ['description'] }) pathToCategoryTitle: string[]
  @Prop({ default: undefined }) pathToValue
  @Prop({ default: undefined }) pathToSubcategory: string[]
  @Prop({ default: undefined, type: Function }) dataTransformer
  @Prop({ default: undefined, type: Function }) filteringMethod
  @Prop({ default: 'categoría', type: String }) categoryName
  @Prop({ default: false, type: Boolean }) sortCategories
  @Prop({ default: true, type: Boolean }) sortLegends

  @Prop({ default: false, type: Boolean }) smallChart
  @Prop({ default: false, type: Boolean }) horizontal

  count = 0
  categories = []
  colors = ['#1E90FF', '#FF6347', '#FFD700', '#32CD32', '#FF69B4', '#8A2BE2', '#00CED1', '#FF4500', '#DA70D6', '#40E0D0', '#FF8C00', '#ADFF2F', '#1E90FF', '#FF6347', '#FFD700', '#32CD32', '#FF69B4', '#8A2BE2', '#00CED1', '#FF4500', '#DA70D6', '#40E0D0', '#FF8C00', '#ADFF2F']

  // Methods
  async getData () {
    if (!this.parsedRecords?.length) return
    if (this.debugging) console.log(this.records)

    const { pathToCategory, pathToValue, getObjectAttribute, dataTransformer, getCategories, isCurrency, filteringMethod } = this
    let records = this.parsedRecords

    if (dataTransformer) {
      records = await dataTransformer(records)
      if (this.debugging) console.log('AFTER TRANSFORM DATA', records)
    }
    if (filteringMethod) {
      records = records.filter(this.filteringMethod)
      if (this.debugging) console.log('AFTER FILTERING DATA', records)
    }

    this.count = records.length

    const categories = getCategories(records, pathToCategory, this.categoryName, this.debugging)
    if (this.debugging) console.log('CATEGORIES', categories)

    this.categories = categories.map((category, index) => {
      const filteredByCategory = records.filter(record => getObjectAttribute(record, pathToCategory)?.id === category?.id)

      let y = 0
      if (pathToValue) {
        filteredByCategory.forEach(record => {
          const sumValue = getObjectAttribute(record, pathToValue)
          y += sumValue || 0
        })
      } else {
        y = filteredByCategory.length
      }
      const name = getObjectAttribute(category, this.pathToCategoryTitle)
      const color = this.colors[index]

      return {
        x: name,
        color,
        order: category.order,
        y,
        records: this.pathToSubcategory ? filteredByCategory : undefined,
        pathToSubcategory: this.pathToSubcategory,
        pathToValue: this.pathToSubcategory ? this.pathToValue : undefined,
      }
    })
    if (this.sortCategories) {
      this.Debug('SORTING CATEGORIES')
      this.categories = this.categories.sort((prev, next) => prev.order - next.order)
    }

    if (this.debugging) console.log('PROCESSED CATEGORIES', this.categories)

    this.series = [
      {
        data: this.categories.map(item => {
          return {
            x: item.x,
            y: item.y,
            color: item.color,
          }
        }),
      },
    ]

    this.Debug('SERIES', this.series)
    this.chartOptions = {
      labels: this.categories.map(item => item.name),
      colors: this.colors,
      title: {
        align: 'center',
        style: {
          fontFamily: 'Poppins',
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      plotOptions: {
        treemap: {
          distributed: true, // Asigna un color distinto a cada sección
          enableShades: false,
        },
      },
      yaxis: {
        labels: {
          formatter (val) {
            if (val >= 1000000) {
              return isCurrency ? `${fixPrice(val / 1000000)}M` : `${fixAmountFormatted(val / 1000000)}M`
            } else if (isCurrency) {
              return fixPrice(val)
            } else {
              return fixAmountFormatted(val)
            }
          },
        },
      },
    }
  }

  // Getters

  // Watchers
  @Watch('records', { immediate: true, deep: true })
  async onDataChange () {
    this.processingData = true
    await this.getData()
    this.processingData = false
  }
  }

