
  import { Component } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { fixDate, isValidNumber, stringifySafe, updateNestedObject } from '@/utils/general'
  import { PurchaseView } from '@/components/forms/view/PurchaseView'
  import { plainToInstance } from 'class-transformer'
  import { Payment, PaymentType } from '@/entities/finance'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { Form } from '@/entities/public/Resource/metadata'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'

@Component({
  components: {
    LinkedPerson,
    GDatePicker,
    GFiles,
    BaseForm,
  },
})
  export default class TreasurerExpenseForm extends PurchaseView {
  expense = plainToInstance(Payment, {})
  title = ''
  expenseTypes = ['debt_payment', 'traffic_ticket_payment', 'retention']
  paymentType: PaymentType[] = []
  showDetail = false
  idProcess = null
  metadata = null
  documents = []

  formData = {
    document: [],
    date: null,
    amount: null,
    backup: [],
    comment: '',
    account: null,
    paymentType: null,
    beneficiary: null,
  }

  fields = {
    accounts: {
      properties: {
        label: 'Cuenta de cargo',
        itemText: 'bankAccount',
        itemValue: 'id',
        returnObject: true,
        required: true,
        rules: this.fieldRequired,
      },
      items: [],
    },
    backup: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
        label: '',
        required: false,
        icon: '',
      },
    },
    beneficiary: {
      properties: {
        required: true,
        properties: {
          label: 'Beneficiario',
          rules: this.fieldRequired,
          itemText: 'formInfo',
          required: true,
          itemValue: 'id',
          clearable: true,
          returnObject: true,
        },
      },
      rules: this.fieldRequired,
      items: [],
    },
  }

  async mounted () {
    await this.setMetadata()

    const { id, uid, title, metadata } = this

    if (isValidNumber(id) || isValidNumber(uid)) {
      const expenseId = id || uid

      await this.getExpenseInfo(expenseId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadata, title, Boolean(this.expense?.id))
    }
    await this.setTheBackup()
    this.displayDetail()
  }

  async setTheBackup () {
    const { backup } = this

    if (!backup) {
      return
    }

    const isExpenseForm = 'expenseForm' in backup && backup.expenseForm
    const isBeneficiary = 'beneficiary' in backup && backup.beneficiary

    if (isExpenseForm) {
      this.fields.beneficiary.items = [isExpenseForm.beneficiary]
      this.formData.beneficiary = isBeneficiary || isExpenseForm.beneficiary
      this.formData.paymentType = isExpenseForm?.paymentType
      this.formData.date = isExpenseForm?.date
      this.formData.account = isExpenseForm?.account
      this.formData.comment = isExpenseForm?.comment
    }
  }

  async setMetadata () {
    const { metadata } = this.getForm('ExpenseOrder', 'treasurer_expense')
    const { form } = metadata as Form
    this.title = form.title
    this.metadata = metadata
    const processPayment = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    })

    this.idProcess = processPayment?.[0]?.id

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { name: { _eq: 'expense_payment' } }] },
    })

    this.setProperties(fileInfo, 'backup')
    this.fields.accounts.items = await this.fetchData({
      query: { name: 'find', model: 'FinancialAccount' },
      filter: {
        _and: [
          { category: { name: { _eq: 'spent' } } },
          { person_account: { active: { _eq: true } } },
          { active: { _eq: true } },
        ],
      },
    })

    this.paymentType = await this.fetchData({
      query: { name: 'find', model: 'PaymentType' },
      filter: { name: { _nin: ['financing', 'vehicle_partially_paid'] } },
    })
  }

  async getExpenseInfo (id) {
    const expense = await this.fetchData({
      query: { name: 'fetch', model: 'ExpenseOrder', params: { id } },
      force: true,
    })

    this.expense = expense
    this.formData.paymentType = expense?.type
    this.formData.date = expense?.date?.format('DD/MM/YYYY')
    this.formData.comment = expense?.comment
    const idPurchase = expense?.paymentRecipient?.paymentOrder?.idProcessRecord
    const idProcess = expense?.paymentRecipient?.paymentOrder?.idProcess

    const photos = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: idPurchase } },
          { parameter: { id_process: { _eq: idProcess } } },
        ],
      },
      force: true,
    })

    this.documents = photos?.map(photo => {
      return {
        url: photo.file.uri,
        description: photo.parameter.description,
      }
    }) || []

    const files = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { parameter: { id_process: { _eq: this.idProcess } } },
        ],
      },
      force: true,
    })
    this.formData.backup = files?.filter(file => file.parameter.name === 'expense_payment')
    const spendingSupport = files?.length && files.filter(file => file.parameter.name === 'spending_support')?.length
      ? files?.filter(file => file.parameter.name === 'spending_support')?.map(file => {
        return {
          url: file.file.uri,
          description: file.parameter.description,
        }
      }) : []
    if (spendingSupport.length) {
      this.documents.push(...spendingSupport)
    }
  }

  setProperties (fileInfo, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo[0]
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  cancelExpense () {
    const { formData: { document, backup } } = this
    this.setBackup(null)
    const files = [...document, ...backup]

    this.close(files)
  }

  removePersonBackup () {
    const { backup } = this
    if (backup) {
      backup.beneficiary = null
      this.setBackup(backup)
    }
  }

  async goPerson () {
    const { formData } = this
    this.saveBackup()

    const idPerson = formData?.beneficiary?.id
    await this.$router.push({
      name: 'generic-person-nested',
      params: { model: 'Expense', id: idPerson },
    })
  }

  saveBackup () {
    const { backup, formData } = this

    if (backup) {
      backup.beneficiary = null
      this.setBackup(backup)
    } else {
      this.setBackup({ expenseForm: { ...formData } })
    }
  }

  displayDetail () {
    const { expense, metadata } = this

    expense.accounts = [expense.recipientAccount]

    this.metadata = {
      data: expense,
      metadata,
    }

    this.showDetail = Boolean(expense.id)
  }

  async send () {
    const isOverLimit = this.formData.comment?.length > 256
    if (!this.$refs.form.validate() || isOverLimit) {
      return
    }
    this.loadingForm = true
    const { expense, formData, fields, idProcess } = this

    const status = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { type: { name: { _eq: 'successful' } } },
          { status: { process: { table_name: { _eq: 'payment' } } } },
        ],
      },
    })
    const closed = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { status: { name: { _eq: 'closed' } } }] },
    })

    await this.pushData({
      model: 'Payment',
      fields: {
        id: expense.id,
        comment: formData.comment,
        date: fixDate(formData.date),
        id_process_status: closed[0]?.id,
        id_closing_reason: status?.[0]?.id,
        id_financial_account: formData.account.id,
        id_responsible: this.idEmployee,
      },
    })

    if (formData.paymentType?.id) {
      await this.pushData({
        model: 'Payment',
        fields: {
          id: expense.id,
          id_payment_type: formData.paymentType.id,
        },
      })
    }

    await this.handleFileType(formData.backup, { properties: fields?.backup?.properties }, idProcess, expense.id)
    this.loadingForm = false
    await this.close()
  }

  async searchPerson ({ input }) {
    if (!input?.length || input?.length < 2) return
    const search = {
      items: {
        uid: 'person',
        where: {
          _and: [
            {
              _or: [
                {
                  main_phone: {
                    _ilike: '@',
                  },
                },
                {
                  uid: {
                    _ilike: '@',
                  },
                },
                {
                  full_name: {
                    _ilike: '@',
                  },
                },
                {
                  company_name: {
                    _ilike: '@',
                  },
                },
                {
                  alias: {
                    _ilike: '@',
                  },
                },
              ],
            },
          ],
        },
        triggers: [
          'update:search-input',
        ],
      },
    }

    const query = updateNestedObject(search.items.where, '_eq', this.replaceSpace(input), ['name_person_type'])
    this.fields.beneficiary.items = await this.findPersonSearch(query)
  }

  get change () {
    const { formData } = this

    return stringifySafe([formData])
  }

  get bindBeneficiary () {
    const { fields: { beneficiary } } = this

    if (!beneficiary) return {}

    return { ...beneficiary.properties, items: beneficiary.items }
  }

  get isPersonSystem () {
    const { expense } = this

    return expense?.paymentRecipient?.person?.type?.name === 'system'
  }

  get isChangeablePayment () {
    const { expense, expenseTypes } = this

    return expenseTypes.includes(expense?.paymentRecipient?.paymentOrderItem?.name)
  }

  get disable () {
    const { expense } = this

    return expense?.status?.isToConfirm
  }
  }
