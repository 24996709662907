import { ResourceHeaders } from '..'
import {
  AlertReserveSellCellHeader,
  AppraisalStatusHeader,
  BusinessHeader,
  ChannelHeader,
  ContactHeader,
  DateHeader,
  IdHeader,
  PdfSaleHeader,
  TextHeader,
  TotalProfitHeader,
  VehicleHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Inspection } from '@/entities/purchase'
import { Process } from '@/entities/settings'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    width: 120,
    options: {
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...VehicleHeader, text: 'Stock', value: 'deal.auto' },
  { ...DateHeader, text: 'Fecha creado', value: 'created' },
  { ...BusinessHeader, text: 'Negocio', value: 'deal.lead.pipeline' },
  {
    ...AlertReserveSellCellHeader, text: 'Adicionales', value: '@', width: 100,
  },
  { ...ChannelHeader, text: 'Canal', value: 'deal.channel' },
  { ...AppraisalStatusHeader, text: 'Estado de venta', value: '@' },
  {
    ...ContactHeader,
    text: 'Ejecutivo',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'executive',
      },
      path: 'deal.lead.executive.name',
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Inspection, process: Process, system: string) => Helpers.contact(item, 'executive', process, system),
      },
      disabledMissingProperty: ['deal.lead.executive.phone'],
    },
  },
  { ...TextHeader, text: 'Comisión de financiamiento', value: 'financingApproved.offerFeeFormatted', width: 120 },
  { ...TextHeader, text: 'Financiera', value: 'financingApproved.agreement.person.alias', width: 180 },
  { ...TextHeader, text: 'Precio venta', value: 'priceSell.value', width: 200 },
  { ...TextHeader, text: 'Descuento disponible', value: 'marginPositive', width: 100 },
  { ...TextHeader, text: 'Utilidad neta', value: 'netIncomeFormatted', width: 200 },
  { ...TotalProfitHeader, text: 'Utilidad total', value: '@', width: 200 },
  {
    ...PdfSaleHeader,
    text: 'Nota venta',
    value: '@',
  },
]

export default function (): ResourceHeaders {
  return headers
}
