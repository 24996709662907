import { ActionCellDefinition, CellType, StatusCellDefinition } from '@/components/dataTables/cell'
import { financingStatusIcons } from '@/models/financing/interfaces'
import { Helpers } from '../helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'

export const FinancialHeader: StatusCellDefinition = {
  align: 'center',
  width: 150,
  filterable: true,
  type: CellType.status,
  options: {
    allowCreate: true,
    set: financingStatusIcons,
    showDetails: true,
  },
}

export const FIHeader: ActionCellDefinition = {
  align: 'center',
  sortable: false,
  width: 85,
  filterable: false,
  type: CellType.action,
  options: {
    disabledActionMissingProperty: {
      key: 'financing_executive',
    },
    path: 'sale.financial.responsible.person.name',
    trigger: Helpers.linkedModel,
    action: {
      always: true,
      color: 'yellow',
      icon: 'mdi-whatsapp',
      only: true,
      messaging: true,
      trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'financing_executive', process, system),
    },
  },
}
