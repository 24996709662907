import { Type } from 'class-transformer'
import { CarData } from '.'
import { CavCurrOwners } from '@/entities/files/CavCurrOwners'

export class CavInfo {
  @Type(() => CarData)
  carData: CarData

  @Type(() => CavCurrOwners)
  currOwners: CavCurrOwners[]
}
