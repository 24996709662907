import { Type } from 'class-transformer'
import { Entity } from '..'

import { Auto, Country } from '../public'
import { Attribute, AutomotiveRegistration, RotationIndex, VersionYear } from '../vehicle'

export class Generation extends Entity {
  @Type(() => Country)
  country: Country;

  @Type(() => VersionYear)
  versionYear: VersionYear;

  @Type(() => Attribute)
  attributes: Attribute[];

  @Type(() => AutomotiveRegistration)
  registration: AutomotiveRegistration[];

  @Type(() => Auto)
  auto: Auto[];

  @Type(() => RotationIndex)
  rotationIndex: RotationIndex

  sku: string;
  idRotationIndex: number;

  get characteristic () {
    const { attributes } = this
    const basic = attributes?.filter(att => att.component.id <= 4)
    return basic?.map(att => {
      const component = att.component.values
      const componentName = att.componentValue.value

      return component || componentName
    }).join('/')
  }

  get transmission () {
    const { attributes } = this
    return attributes?.find(att => att.name === 'Transmisión')
  }

  get traction () {
    const { attributes } = this
    return attributes?.find(att => att.val.toUpperCase().includes('4X4') || att.val.toUpperCase().includes('4X2'))
  }

  get fuel () {
    const { attributes } = this

    return attributes?.find(att => att.name === 'Combustible')
  }
}
