import { Type } from 'class-transformer'
import { Entity } from '..'

import { ProcessStatus } from '.'

export class Icon {
  main: string
  active?: string
  disabled?: string

  toString () {
    return this.main
  }
}

export class Status extends Entity {
  public static readonly closed = 'closed'
  public static readonly finished = 'finished'
  public static readonly supervision = 'supervision'
  public static readonly pending = 'pending'
  public static readonly deprecated = 'deprecated'
  public static readonly noOffer = 'no_offer'
  public static readonly appealed = 'appealed'
  public static readonly appealAnswered = 'appeal_answered'
  public static readonly appraised = 'appraised'
  public static readonly approved = 'approved'
  public static readonly reserved = 'reserved'
  public static readonly done = 'done'
  public static readonly prepaid = 'prepaid'
  public static readonly sold = 'sold'
  public static readonly selling = 'selling'
  public static readonly active = 'active'
  public static readonly toRegister = 'to_register'
  public static readonly reservationProcess = 'reservation_process'
  public static readonly rejected = 'rejected'
  public static readonly conditioned = 'conditioned'
  public static readonly legalReview = 'legal_review'
  public static readonly legalize = 'legalize'
  public static readonly readingFile = 'reading_file'
  public static readonly validCav = 'valid_cav'
  public static readonly toUpdate = 'to_update'
  public static readonly cavNotMatch = 'cav_not_match'
  public static readonly notMatch = 'not_match'
  public static readonly toConfirm = 'to_confirm'
  public static readonly unpaid = 'unpaid'
  public static readonly paid = 'paid'
  public static readonly toContract = 'to_contract'
  public static readonly toApproved = 'to_approved'
  public static readonly isPendingPayment = 'pending_payment'
  public static readonly isLost = 'lost'
  public static readonly isEnabling = 'enabling'
  public static readonly isRetired = 'retired'

  @Type(() => ProcessStatus)
  processStatuses: ProcessStatus[];

  @Type(() => Icon)
  icon?: Icon

  name: string;
  description: string;
  color?: string;

  toString () {
    return this.description
  }
}
