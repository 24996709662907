
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Deal } from '@/entities/crm'
  import { dateToLocal, fixPrice, isValidNumber } from '@/utils/general'
  import { Stock } from '@/entities/sales'

@Component({
  components: {
    Row: () => import('../simple.vue'),
  },
})
  export default class StockPrices extends Vue {
  @Prop({ type: Object, default: () => ({}) }) value!: Deal
  @Prop({ type: String, default: '' }) discountObtained!: string
  @Prop({ type: Boolean, default: false }) hideFields!: boolean
  @Prop({ type: Object }) labels!: {
    amount: string,
    margin: string,
    available: string,
    discountObtained: string
    expectedPrice: string,
    transfer: string,
    taxAppraisal: string
    totalAmount: string
    warrantyAmount: string
  }

  get discount () {
    const { value } = this

    if (value instanceof Stock) {
      return value?.currentPrice?.margin ?? 0
    }
    const discount = (Number(value.order?.discount?.value) || 0) + (Number(value.order?.discountPostFinancing?.value) || 0)

    return discount ?? 0
  }

  stockPrices (stock, value) {
    if (value instanceof Stock) {
      return value?.currentPrice
    }
    return stock.currentPrice
  }

  createInvoicedObject (currentPrice, isInvoice) {
    if (!isInvoice) return null

    return {
      label: 'Descuenta IVA',
      value: fixPrice(currentPrice?.amount * 0.19) || 'Por definir',
      icon: 'mdi-file-document-arrow-right',
      color: 'primary',
      position: 'start',
      textColor: 'red',
    }
  }

  get prices () {
    const { value: { stock }, value, isInvoice } = this

    if (!stock && !(value instanceof Stock)) return []
    let data = [
      this.createAmountObject(this.stockPrices(stock, value)),
      this.createInvoicedObject(this.stockPrices(stock, value), isInvoice),
      this.createLastPriceObject(),
      this.createMarginObject(this.stockPrices(stock, value)),
      ...this.createBonusObjects(this.stockPrices(stock, value)),
    ]

    // if (this.discount && !this.hideFields && this.labels.available?.length) {
    //  data.splice(2, 0, this.createAvailableObject(this.stockPrices(stock, value)))
    // }

    if (isValidNumber(this.discount) && this.labels.discountObtained?.length) {
      data.push({ label: this.labels.discountObtained, value: fixPrice(this.discount) })
    } else if (this.discountObtained && this.hideFields) {
      data.splice(2, 0, { label: this.labels.discountObtained, value: this.discountObtained })
    }

    if (value instanceof Deal && value?.order?.productWithDiscount?.value) {
      data.push({ label: 'Precio de venta', value: value?.order?.productWithDiscount.value })
    }

    if (this.labels?.warrantyAmount?.length) {
      const warrantyValue = value?.warrantyAmount?.toString().length ? !value?.warrantyAmount?.toString()?.includes('$') ? fixPrice(value?.warrantyAmount) : value?.warrantyAmount : '-'
      data.push({
        label: this.labels.warrantyAmount,
        value: warrantyValue,
        isBold: false,
      })
    }

    data = data.concat([
      this.createExpectedPriceObject(stock || value),
      this.createTaxAppraisalObject(stock || value),
      this.createTransferObject(stock || value),
    ])

    if (value?.totalAmount) {
      data.push({ label: this.labels.totalAmount, value: fixPrice(value?.totalAmount) })
    }

    if (value instanceof Stock && value?.prices?.[0]?.createdAt) {
      data.push({
        label: 'Última definición de precios ',
        value: dateToLocal(value?.prices?.[0]?.createdAt.format('MM/DD/YYYY HH:MM:ss')).format('DD/MM/YYYY HH:MM:ss'),
      })
    }

    return data.filter(_ => _?.value && _?.label)
  }

  createAmountObject (currentPrice) {
    return {
      label: this.labels.amount,
      value: fixPrice(currentPrice?.amount) || 'Por definir',
      icon: this.priceIcon,
      tooltip: currentPrice?.comment,
    }
  }

  createLastPriceObject () {
    const { value: { stock } } = this

    if (!stock) return null

    const { prices } = stock
    if (prices?.length <= 1) return null

    return {
      label: 'Precio lista anterior',
      value: fixPrice(prices[1]?.amount),
    }
  }

  createMarginObject (currentPrice) {
    return {
      label: this.labels.margin,
      value: !this.hideFields ? fixPrice(currentPrice?.margin) : null,
      isBold: false,
    }
  }

  createBonusObjects (currentPrice) {
    return currentPrice?.bonuses.map(({ title, amount }) => ({
      label: title,
      value: !this.hideFields ? fixPrice(amount) : null,
      isBold: false,
    })) || []
  }

  createAvailableObject (currentPrice) {
    const value = currentPrice?.bonuses?.[0]?.amount || currentPrice?.margin
    const result = currentPrice?.margin - this.discount
    const price = result ? fixPrice(value - this.discount) : '$0'
    return { label: this.labels.available, value: price }
  }

  createExpectedPriceObject (stock) {
    if (stock?.enablement?.inspection?.appraisal?.expectedPublicationAmount && !stock.currentPrice?.amount) {
      return {
        label: this.labels.expectedPrice,
        value: fixPrice(stock.enablement?.inspection?.appraisal?.expectedPublicationAmount),
      }
    }
    return null
  }

  createTaxAppraisalObject (stock) {
    if (stock?.auto?.generation?.registrations?.length && !this.hideFields) {
      const registration = stock.auto?.generation?.registrations[0]
      if (registration?.appraisal) {
        return { label: this.labels.taxAppraisal, value: fixPrice(registration?.appraisal) || '$0' }
      }
    }
    return null
  }

  createTransferObject (stock) {
    if (stock?.transferCost && stock?.currentPrice?.amount) {
      return { label: `${this.labels.transfer}`, value: fixPrice(stock.transferCost), isBold: false }
    }
    return null
  }

  get priceIcon () {
    const { value: { stock } } = this

    if (!stock) return ''

    const { prices } = stock
    if (prices?.length <= 1) return ''

    return prices[0].amount < prices[1].amount ? 'mdi-chevron-double-down' : 'mdi-chevron-double-up'
  }

  get isInvoice () {
    const { value } = this

    if (value instanceof Deal && value?.stock?.viewDetails?.documentType?.name.includes('invoice_affect')) {
      return true
    }

    return value instanceof Stock && value?.viewDetails?.documentType?.name.includes('invoice_affect')
  }
  }
