import gql from 'graphql-tag'
import { EntityFields } from '..'
import { PaymentTypeFragment } from '@/graphql/fragments/finance/paymentType'
import { PersonAccountFragment } from '@/graphql/fragments/persons/personAccount'
import { ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'
import { PersonFragment } from '@/graphql/fragments/persons'

export const PaymentBaseFragment = gql`
  fragment PaymentBase on finance_payment{
    ${EntityFields}
    amount
    reference:reference
    id_issuing_bank
    id_payer
    idProcess: id_process
    idProcessRecord: id_process_record
    idPaymentRecipient: id_payment_recipient
    date
    comment
    expiration
    paymentCount:payment_count
  }
`

export const PaymentFragment = gql`
  ${PaymentBaseFragment}
  ${PaymentTypeFragment}
  ${PersonAccountFragment}
  ${ProcessStatusFragment}
  ${PersonFragment}
  ${ClosingReasonFragment}
  fragment Payment on finance_payment{
    ...PaymentBase
    type:payment_type {
      ...PaymentType
    }
    recipientAccount:recipient_account {
      ...PersonAccount
      holder{
        ...Person
      }
    }
    status {
      ...ProcessStatus
    }
    closingReason: closing_reason {
      ...ClosingReason
    }
    payer {
      id
      firstName:first_name
      secondName:second_name
      surname
      uid
      secondSurname:second_surname
      alias
      type {
        name
      }
    }
    issuingBank:issuing_bank {
      id
      companyName:company_name
      type {
        name
      }
    }
    paymentRecipient: payment_recipient {
      id
      paymentOrder: payment_order {
        id
        idProcessRecord:id_process_record
        idProcess:id_process
      }
      person{
        id
        firstName:first_name
        secondName:second_name
        surname
        secondSurname:second_surname
        alias
        uid
        type {
          name
        }
      }
    }
    financialAccount:financial_account {
      id
      personAccount:person_account {
        ...PersonAccount
      }
    }
  }
`
