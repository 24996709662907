
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import Agenda from '@/components/forms/fields/agenda/Agenda.vue'
  import { RULES } from '@/components/forms'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import dayjs from 'dayjs'
  import { fixDate } from '@/utils/general'

@Component({
  components: { Agenda },
})
  export default class AgendaField extends FilesProcess {
  @Prop({ type: Object, default: () => null }) value!: any
  @Prop({ type: [Object], default: () => null }) newAgenda!: any
  @Prop({ type: Array, default: () => [] }) agendas!: any[]
  @Prop({ type: Boolean, default: false }) required!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  selectedTime = ''
  fieldRequired = RULES.isRequired
  menuVisible = false
  startHour = '08:00'
  endHour = '21:00'
  date = null

  async mounted () {
    this.openMenu()
  }

  onTimeRangeSelected (range: { start: string; end: string }) {
    const { disabled } = this

    this.menuVisible = false

    if (disabled || !(range?.start && range?.end)) return
    this.selectedTime = `${range.start} - ${range.end}`
    this.$emit('time-range-selected', range)
  }

  openMenu () {
    this.menuVisible = true
  }

  getScheduleForToday (schedule: any[], date: string): { start: string; end: string } {
    if (!schedule?.length) return { start: '08:00', end: '21:00' }

    const today = dayjs(fixDate(date)).format('dddd') // Obtenemos el día de la semana en formato largo
    let dayKey: string

    // Convertimos el formato de los días
    if (['lunes', 'martes', 'miércoles', 'jueves', 'viernes'].includes(today)) {
      dayKey = 'Lunes - Viernes'
    } else if (today === 'sábado') {
      dayKey = 'Sabado'
    } else if (today === 'domingo') {
      dayKey = 'Domingo'
    } else {
      return { start: '08:00', end: '21:00' }
    }

    // Buscar el horario correspondiente al día
    const daySchedule = schedule.find(s => s.day === dayKey)

    if (!daySchedule) {
      return { start: '00:00', end: '00:00' }
    }

    // Si es domingo y no tiene horas válidas, retornamos '00:00'
    if (dayKey === 'Domingo' && (!daySchedule.start?.length || !daySchedule.end?.length)) {
      return { start: '00:00', end: '00:00' }
    }

    // Retornamos el horario encontrado
    return {
      start: daySchedule.start || '00:00',
      end: daySchedule.end || '00:00',
    }
  }

  @Watch('value', { immediate: true, deep: true })
  async onValueChange (val) {
    if (!this.date) {
      this.date = val?.schedulingDate
      if (val?.end?.length && val?.start?.length) {
        this.selectedTime = `${val.start} - ${val.end}`
      }
    } else if (this.date && this.date !== val?.schedulingDate) {
      this.date = val?.schedulingDate
      this.selectedTime = ''
      this.$emit('time-range-selected', null)
    } else if (val?.start && val?.end) {
      this.selectedTime = `${val.start} - ${val.end}`
    } else if (val?.timeStart && val?.timeEnd) {
      this.selectedTime = `${val.timeStart} - ${val.timeEnd}`
    }

    const system = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { type: { name: { _eq: 'system' } } },
    })
    const schedule = system?.[0]?.metadata?.schedule

    const { start, end } = this.getScheduleForToday(schedule, val?.schedulingDate)

    this.startHour = start
    this.endHour = end
  }

  get timeIsNotAvailable () {
    const { startHour, endHour } = this
    return startHour === '00:00' && endHour === '00:00'
  }
  }
