
  import { Component, Watch } from 'vue-property-decorator'
  import Cell from '@/components/dataTables/cell/cell'
  import dayjs from 'dayjs'

@Component
  export default class ConsignmentCell extends Cell {
  status = {
    icon: 'mdi-car-key',
    text: '',
    color: '',
  }
  // Methods

  // Getters
  get info () {
    return this.item
  }

  // Watchers
  @Watch('item', { immediate: true, deep: true })
  async onItemChange (val) {
    if (!val?.closingReason && !val?.status.isApproved) {
      this.setStatusValues({ icon: val.status.icon.main, description: val.status.description, color: val.status.color })
      return
    }

    const stock = await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: { stock_detail: { id_purchase: { _eq: val.id } } },
      force: true,
    })
    if (!stock?.length) {
      this.setStatusValues({ description: '', color: '' })
      return
    }

    if (stock[0]?.status?.name === 'sold') {
      this.setStatusValues({ description: 'VENDIDO', color: 'primary' })
      return
    } else if (stock[0]?.status?.name === 'retired') {
      this.setStatusValues({ description: 'RETIRADO', color: 'primary' })
      return
    }

    const field = await this.fetchData({
      query: { name: 'find', model: 'Field' },
      filter: {
        _and: [
          { document_type: { name: { _eq: 'consignment_contract' } } },
          { name: { _eq: 'consignment_period' } },
        ],
      },
      force: true,
    })

    const differenceInDays = dayjs().diff(val.createdAt, 'day')
    if (differenceInDays > field?.[0]?.structure?.days) {
      this.setStatusValues({ description: 'VENCIDA', color: 'red' })
    } else {
      this.setStatusValues({ description: 'VIGENTE', color: 'primary' })
    }
  }

  setStatusValues (status) {
    this.status.text = status.description
    this.status.color = status.color
  }
  }
