
  import { Person, User } from '@/graphql/generated-types/graphql'
  import { Component, Watch } from 'vue-property-decorator'
  import SupervisorDashboard from '../SupervisorDashboard'
  import { mapGetters } from 'vuex'
  import { deepCopy, fixPrice, localToUtc, utcToLocal } from '@/utils/general'
  import dayjs from 'dayjs'

@Component({
  computed: {
    ...mapGetters('app', ['isMobile']),
  },
}
)
  export default class SupervisorStaff extends SupervisorDashboard {
    users: User[] = []
    userIndex = 0
    disableUserNav = false
    dayjs = dayjs
    console = console

    blockNavButtons () {
      this.disableUserNav = true
      setTimeout(() => {
        this.disableUserNav = false
      }, 300)
    }

    // Methods
    async beforeCreate () {
      this.getData = async () => {
        await this.getGeneralResults()
      }
    }

    getPersonUser (person: Person): User {
      const { users } = this
      return users.filter(user => user.person.id !== person.id)[0]
    }

    fixPurchaseFields (records) {
      const corrected = deepCopy(records)
      for (let i = 0; i < corrected.length; i++) {
        const { auto, executive } = corrected[i]
        const carFullName = `${auto.versionYear.version.model.brand.name} ${auto.versionYear.version.model.name}`
        const executiveFullName = executive ? `${executive.person.firstName.trim()} ${executive.person.surname.trim()}` : 'SIN EJECUTIVO'
        corrected[i].stockCreated = dayjs(corrected[i].stockCreated).format('DD/MM/YYYY')
        if (executive) {
          corrected[i].executive.fullName = executiveFullName
        }
        if (corrected[i].stock.soldDate) {
          corrected[i].stock.soldDate = utcToLocal(corrected[i].stock.soldDate).format('DD/MM/YYYY')
        }
        corrected[i] = {
          ...corrected[i],
          carFullName,
          executiveFullName,
        }
      }

      return corrected
    }

  getLeadAppraisals (records) {
    const convertedRecords = this.addAcquisitionTypeBooleans(records)

    return convertedRecords
      .map(lead => {
        const appraisals = []
        const isConsignment = lead.isConsignment
        lead.deals?.forEach(deal => {
          deal.appraisals?.forEach(appraisal => {
            appraisals.push({
              ...appraisal,
              isConsignment,
              executive: {
                ...lead.executive,
                name: (lead.executive ? `${lead.executive.person.firstName.trim()} ${lead.executive.person.surname.trim()}` : 'SIN EJECUTIVO').toUpperCase(),
              },
            })

              // executive: lead.executive,
          })
        })
        return appraisals
      })
      .flat()
      .filter(appraisal => appraisal)
  }

  getLeadInspections (records) {
    const appraisals = this.getLeadAppraisals(records)
    return appraisals
      .map(appraisal => {
        const inspection = appraisal.inspection
        if (!inspection) return
        const { isConsignment } = appraisal
        return {
          ...inspection,
          isConsignment,
        }
      })
      .filter(inspection => inspection)
  }

  addAcquisitionTypeBooleans (records) {
    const pipelines = ['purchase', 'renewal', 'financed_renovation']
    return records
      .filter(record => pipelines.includes(record.pipeline.name))
      .map(record => {
        const deals = record.deals
        const dealAcquisitionTypes = deals.map(deal => {
          const appraisals = deal.appraisals
          const appraisalsAcquisitionTypes = appraisals.map(appraisal => {
            const lastAppraisedResponse = appraisal?.lastAppraisedResponse
            // const lastAppraisedConsignmentResponse = appraisal?.lastAppraisedConsignmentResponse
            const appraisalAgreedAmount = Number(appraisal?.agreedAmount)
            const purchaseAmount = Number(lastAppraisedResponse?.amount)
            // const consignmentAmount = lastAppraisedConsignmentResponse?.amount

            const negotiation = appraisal.inspection?.negotiation
            const negotiationAgreedAmount = negotiation?.agreedAmount

            const comparisonAmount = negotiationAgreedAmount || appraisalAgreedAmount

            const responses = appraisal.responses

            const isConsignment = comparisonAmount ? (comparisonAmount > purchaseAmount) : responses.some(response => response.responseType.name === 'expected_offer_consignment')
            const isPurchase = !isConsignment

            return { isConsignment, isPurchase }
          })
          return {
            isConsignment: appraisalsAcquisitionTypes.some(appraisal => appraisal.isConsignment),
            isPurchase: !appraisalsAcquisitionTypes.some(appraisal => appraisal.isConsignment),
          }
        })

        const isConsignment = dealAcquisitionTypes.some(deal => deal.isConsignment)

        return {
          ...record,
          isConsignment,
          isPurchase: !isConsignment,
        }
      })
  }

    sortByStockCreated (records) {
      return records.sort((prev, next) => {
        if (dayjs(prev.stockCreated).isBefore(dayjs(next.stockCreated))) {
          return 1
        } else if (dayjs(prev.stockCreated).isAfter(dayjs(next.stockCreated))) {
          return -1
        }
        return 0
      })
    }

    sortByScheduled (records) {
      return records.sort((prev, next) => {
        const prevDate = dayjs(prev.scheduledDate)
        const nextDate = dayjs(next.scheduledDate)

        if (prevDate.isBefore(nextDate)) {
          return 1
        } else if (prevDate.isAfter(nextDate)) {
          return -1
        }
        return 0
      })
    }

    async normaliceData (records) {
      const leads = await this.getLeads(this.currentMonthFilters.inspectionLeadDetail)
      const inspections = this.getLeadInspections(leads.records)
      const Ids = inspections.filter(inspection => inspection.isConsignment).map(inspection => inspection.id)
      return records.map(record => {
        const {
          executive,
          executiveId,
          autoDescription,
          year,
          inspector,
          inspectorId,
          closingReason,
          status,
          scheduledDate,
          closingReasonDate,
        } = record
        const attributes = [
          'executive',
          'executiveId',
          'autoDescription',
          'year',
          'inspector',
          'inspectorId',
          'status',
          'scheduledDate',
          'closingReasonDate',
        ]
        const corrected = deepCopy(record)
        attributes.forEach(attribute => delete corrected[attribute])
        return {
          ...corrected,
          car: `${autoDescription} ${year}`,
          executive: {
            id: executiveId,
            name: executive?.toUpperCase() || 'SIN EJECUTIVO',
            scheduledDate: dayjs(localToUtc(scheduledDate)).format('DD/MM/YYYY'),
          },
          inspector: {
            id: inspectorId,
            name: inspector?.toUpperCase() || 'SIN INSPECTOR',
            closingReasonDate: dayjs(localToUtc(closingReasonDate)).format('DD/MM/YYYY'),
          },
          status: closingReason || status,
          subtitleIcon: Ids.includes(corrected.inspectionId)
            ? {
              icon: 'mdi-car-key',
              text: 'Consignación',
            } : undefined,
        }
      })
    }

    fixSaleFields (records) {
      const corrected = deepCopy(records)
      for (let i = 0; i < corrected.length; i++) {
        const { autoDescription, year, executive, executiveName } = corrected[i]
        const carFullName = `${autoDescription} ${year}`
        corrected[i].soldDate = dayjs(corrected[i].soldDate).format('DD/MM/YY')
        corrected[i].executive = executive ? {
          id: executive,
          name: executiveName,
        } : undefined
        corrected[i] = {
          ...corrected[i],
          carFullName,
          saleAmount: fixPrice(corrected[i].saleAmount),
        }
      }

      return corrected
    }

    sortBySoldDate (records) {
      return records.sort((prev, next) => {
        if (dayjs(prev.soldDate).isBefore(dayjs(next.soldDate))) {
          return 1
        } else if (dayjs(prev.soldDate).isAfter(dayjs(next.soldDate))) {
          return -1
        }
        return 0
      })
    }

    fixCreditFields (records) {
      const corrected = deepCopy(records)
      for (let i = 0; i < corrected.length; i++) {
        const { auto, executive } = corrected[i]
        const carFullName = `${auto.versionYear.version.model.brand.name} ${auto.versionYear.version.model.name}`
        const executiveFullName = `${executive?.person.firstName.trim()} ${executive?.person.surname.trim()}`
        if (executive) {
          corrected[i].executive.fullName = executiveFullName
        }
        corrected[i].signedDate = dayjs(corrected[i].signedDate).format('DD/MM/YY')
        corrected[i] = {
          ...corrected[i],
          carFullName,
          executiveFullName,
        }
      }

      return corrected
    }

    normaliceReservesQuery (records) {
      return records.map(record => {
        const { executive } = record.saleOrder.deal.lead
        const person = executive?.person
        record.executive = {
          id: executive?.id,
          name: executive ? `${person.firstName} ${person.surname}` : 'SIN EJECUTIVO',
        }
        return record
      })
    }

    async normaliceReserves (records) {
      return Promise.all(records.map(async record => {
        const { executive, executiveName } = record
        record.executive = executive ? {
          id: executive,
          name: executiveName,
          reserveDate: utcToLocal(record.createdAt).format('DD/MM/YY'),
        } : undefined
        const status = await this.fetchData({
          query: { name: 'find', model: 'ProcessStatus' },
          filter: { id: { _eq: record.statusId } },
        })
        record.status = status[0]
        return record
      }))
    }

    sortBySignedDate (records) {
      return records.sort((prev, next) => {
        if (dayjs(prev.signedDate).isBefore(dayjs(next.signedDate))) {
          return 1
        } else if (dayjs(prev.signedDate).isAfter(dayjs(next.signedDate))) {
          return -1
        }
        return 0
      })
    }

    async getPerson (idPerson) {
      return this.fetchData({
        query: {
          name: 'fetch',
          model: 'Person',
          params: { id: idPerson },
        },
      })
    }

    async getUser (idPerson) {
      const user = (await this.fetchData({
        query: {
          name: 'find',
          model: 'User',
        },
        filter: {
          id_person: { _eq: idPerson },
        },
      }))[0]
      const personProcess = (await this.fetchData({
        query: {
          name: 'find',
          model: 'Process',
        },
        filter: {
          table_name: { _eq: 'person' },
        },
      }))[0]
      const photo = (await this.fetchData({
        query: {
          name: 'find',
          model: 'FileProcess',
        },
        filter: {
          _and: [
            { id_process_record: { _eq: idPerson } },
            { parameter: { process: { id: { _eq: personProcess.id } } } },
          ],
        },
      }))[0]
      return {
        ...user,
        photo,
      }
    }

    getExecutiveSales (records) {
      const executives = this.chartData?.settings?.executives?.records
      const staffIDs = executives?.map(executive => executive?.id_executive)

      return records.filter(record => staffIDs?.includes(record.executive))
    }

    // Getters
      get executivesData () {
        return this.chartData.settings.executives?.records.sort(function (a, b) {
          if (a.first_name < b.first_name) {
            return -1
          }
          if (a.first_name > b.first_name) {
            return 1
          }
          return 0
        })
      }

      get targetMultiplier () {
        const executives = this.chartData?.settings?.executives?.records
        return 1.2 / (executives?.length || 1)
      }

    // Watchers
    @Watch('executivesData')
    async updateExecutives () {
      const { executivesData, getUser } = this
      this.users = await Promise.all(executivesData.map(async executive => getUser(executive?.id_person)))
    }
  }
