import gql from 'graphql-tag'
import { EntityFields } from '..'
import { KeyIdentifierFragment, PriceFragment } from '../sales'
import { ProcessStatusFragment } from '../settings'

export const BaseStockFragment = gql`
  fragment StockBase on sales_stock {
    ${EntityFields}
    comment
    metadata
    soldDate: sold_date
  }
`

export const StockFragment = gql`
  ${BaseStockFragment}
  ${PriceFragment}
  ${ProcessStatusFragment}
  ${KeyIdentifierFragment}
  fragment Stock on sales_stock {
    ...StockBase
    prices(order_by: {id: desc}, limit: 2) {
      ...Price
    }
    status {
      ...ProcessStatus
    }
    keyIdentifier: key_identifier{
      ...KeyIdentifier
    }
  }
`
