
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { Field } from './field'
  import { mapMutations } from 'vuex'

@Component({
  methods: {
    ...mapMutations('persons', ['setId', 'setRutInfo', 'setFoundPerson', 'setType']),
  },
})
  export default class LinkedPerson extends Field {
  @Prop({ type: Object }) readonly properties!: any;
  @Prop({ type: Array, default: () => [] }) readonly items!: Array<any>;
  @Prop({ type: String }) type!: string
  @Prop({ type: Boolean, default: true }) creatable!: boolean
  @Prop({ type: Boolean, default: false }) remove!: boolean
  @Prop({ type: Boolean, default: false }) disabledEdit!: boolean
  setId!: (id: number) => void
  setType!: (payload) => void
  active = false
  success = ''
  valid = false
  $refs!: {
    form: HTMLFormElement
  };

  disableSystem = false
  private setRutInfo!: (payload) => void
  private setFoundPerson!: (payload) => void

  get icon () {
    const { value } = this
    return value?.id ? 'mdi-pencil' : 'mdi-plus-circle-outline'
  }

  get bind () {
    const {
      properties,
      items,
      success,
      disabled,
      required,
      fieldRequired,
      multiple,
      chip,
      readonly,
      errorMessages,
    } = this

    return {
      ...properties,
      disabled,
      required,
      multiple,
      errorMessages,
      chip,
      readonly,
      rules: required ? [...fieldRequired] : [],
      items,
      name: success ? undefined : properties?.name,
    }
  }

  mounted () {
    if (this.$refs?.form?.resetValidation) this.$refs.form?.resetValidation()
  }

  @Watch('value', { immediate: true, deep: true }) onValue (val) {
    if (val?.id) {
      this.setId(val.id)
    }

    this.disableSystem = val?.type?.name === 'system'
  }

  get isMultiple () {
    const { multiple } = this
    return multiple
  }

  openForm () {
    const { type } = this
    this.setId(null)
    this.setRutInfo(null)
    this.setFoundPerson(null)
    this.setType(type)
    this.$emit('redirect', type)
  }

  editPerson (person) {
    const { type } = this
    this.setId(person?.id)
    this.setRutInfo(null)
    this.setFoundPerson(null)
    this.setType(type)
    this.$emit('edit', person)
  }

  onBlur () {
    const { value, multiple } = this
    if (multiple && value?.length) {
      this.$emit('input', value.filter(item => item?.id))
    }

    if (!multiple && !value?.id) {
      this.set(undefined)
    }
  }
  }
