import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ReserveFragment, SaleOrderItemFragment } from '.'
import { ClosingReasonFragment, ProcessStatusFragment } from '../settings'
import { PersonFragment } from '../persons'
import { EmployeeFragment } from '../hr'

export const BaseSaleOrderFragment = gql`
  fragment BaseSaleOrder on sales_sale_order {
    ${EntityFields}
    deliveryDate:delivery_date
    duration
    metadata
  }
`

export const SaleOrderFragment = gql`
  ${BaseSaleOrderFragment}
  ${SaleOrderItemFragment}
  ${ClosingReasonFragment}
  ${PersonFragment}
  ${ReserveFragment}
  ${ProcessStatusFragment}
  ${EmployeeFragment}
  fragment SaleOrder on sales_sale_order {
    ...BaseSaleOrder
    saleOrderItems: sale_order_items(order_by: {item_type: {id: asc}}) {
      ...SaleOrderItem
    }
    closingReason: closing_reason {
      ...ClosingReason
    }
    reserve {
      ...Reserve
    }
    validator {
      ...Employee
    }
    status {
      ...ProcessStatus
    }
    document {
      id
      interveners {
        id
        person {
          ...Person
        }
        field{
          id
          name
        }
      }
      support {
        id
        name
      }
    }
  }
`
