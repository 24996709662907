import { Metadata } from '.'
import { Metadata as IMetadata } from '../interfaces'

export class ConfigView extends Metadata implements Omit<IMetadata, '__type'> {
  type: string;

  get model (): string {
    const { type } = this

    return type
  }
}
