
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { SaleOrder, SaleProduct } from '@/entities/sales'
  import { fixPrice } from '@/utils/general'

@Component({
  methods: {
    fixPrice,
  },
})
  export default class TotalProfit extends Cell {
  warranty: SaleProduct | null = null

  async mounted () {
    const { item } = this
    // neta + comisión de financiamiento+ accesorios+ seguros + garantía mecánica
    if (!item?.id) return

    if (item instanceof SaleOrder) {
      await this.getSellInfo()
    }
  }

  async getSellInfo () {
    const { item, fetchData } = this

    const products = await fetchData({
      query: { name: 'find', model: 'SaleProduct' },
      filter: { id_sale_order: { _eq: item.id } },
      force: true,
    })

    this.warranty = products?.find(product => product.name === 'warranty')
  }

  get total () {
    return this.item.totalProfit
  }

  get fee () {
    const { item } = this

    return item?.financingApproved?.offerFee || 0
  }
  }
