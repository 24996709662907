import gql from 'graphql-tag'
import { PersonFragment } from '@/graphql/fragments/persons'
import { EmployeeFragment } from '@/graphql/fragments/hr'

export const updateUserLastSeen = gql`
  mutation updateUserLastSeen($id: Int!, $metadata: jsonb!) {
        update_user(
            where: { id: { _eq: $id } },
          _set: { last_seen: "now()", metadata: $metadata }
        ) {
            returning {
                id
            }
        }
    }
`

export const subscribeToOnlineUsers = gql`
    ${PersonFragment}
    ${EmployeeFragment}
    subscription subscribeToOnlineUsers {
        user {
            id
            lastSeen:last_seen
            person{
                ...Person
            }
        }
    }
`
