
  import { acquisitionTypeColors } from '@/utils/acquisitionTypeColors'
  import { bankColors } from '@/utils/bankColors'
  import { channelColors } from '@/utils/channelColors'
  import { financingColors } from '@/utils/financingColors'
  import { deepCopy, fixPrice, localToUtc, parseToNumber, utcToLocal } from '@/utils/general'
  import dayjs from 'dayjs'
  import { Component } from 'vue-property-decorator'
  import SupervisorDashboard from './SupervisorDashboard'

@Component
  export default class SupervisorSales extends SupervisorDashboard {
    fixPrice = fixPrice
    // Methods
    beforeCreate () {
      this.getData = async () => {
        await this.getGeneralResults()
        this.$set(this.chartData.currentMonth, 'dailyAccountBalance', await this.getDailyAccountBalance(this.currentMonthFilters.startEndDate))
        this.$set(this.chartData.currentMonth, 'dailyStock', await this.getDailyStock(this.currentMonthFilters.dailyStock))
        this.$set(this.chartData.prevMonth, 'dailyAccountBalance', await this.getDailyAccountBalance(this.prevMonthFilters.startEndDate))
        this.$set(this.chartData.prevMonth, 'dailyStock', await this.getDailyStock(this.prevMonthFilters.dailyStock))
        this.$set(this.chartData.lastYear, 'dailyAccountBalance', await this.getDailyAccountBalance(this.lastYearFilters.startEndDate))
        this.$set(this.chartData.lastYear, 'dailyStock', await this.getDailyStock(this.lastYearFilters.dailyStock))
      }
    }

    sortByCreated (records) {
      return records.sort((prev, next) => {
        if (dayjs(prev.createdAt).isBefore(dayjs(next.createdAt))) {
          return 1
        } else if (dayjs(prev.createdAt).isAfter(dayjs(next.createdAt))) {
          return -1
        }
        return 0
      })
    }

    sortBySoldDate (records) {
      return records.sort((prev, next) => {
        if (dayjs(prev.soldDate).isBefore(dayjs(next.soldDate))) {
          return 1
        } else if (dayjs(prev.soldDate).isAfter(dayjs(next.soldDate))) {
          return -1
        }
        return 0
      })
    }

  dayjs = dayjs
  stockByAgeCriterias = [
    {
      name: 'Hasta 1 semana',
      color: '#13CE66',
      filter: record => {
        const dates = this.generateWeeksLimit(1)
        const recordDate = utcToLocal(dayjs(record.stock.createdAt))
        return (dates.lowerLimit.isBefore(recordDate, 'day') || dates.lowerLimit.isSame(recordDate, 'day')) && (dates.upperLimit.isAfter(recordDate, 'day') || dates.upperLimit.isSame(recordDate, 'day'))
      },
    },
    {
      name: 'Hasta 2 semanas',
      color: '#7D67BC',
      filter: record => {
        const dates = this.generateWeeksLimit(2)
        const recordDate = utcToLocal(dayjs(record.stock.createdAt))
        return (dates.lowerLimit.isBefore(recordDate, 'day') || dates.lowerLimit.isSame(recordDate, 'day')) && dates.upperLimit.isAfter(recordDate, 'day')
      },
    },
    {
      name: 'Hasta 3 semanas',
      color: '#6185DB',
      filter: record => {
        const dates = this.generateWeeksLimit(3)
        const recordDate = utcToLocal(dayjs(record.stock.createdAt))
        return (dates.lowerLimit.isBefore(recordDate, 'day') || dates.lowerLimit.isSame(recordDate, 'day')) && dates.upperLimit.isAfter(recordDate, 'day')
      },
    },
    {
      name: 'Hasta 1 mes',
      color: '#FAD901',
      filter: record => {
        const dates = this.generateWeeksLimit(4)
        const recordDate = utcToLocal(dayjs(record.stock.createdAt))
        return (dates.lowerLimit.isBefore(recordDate, 'day') || dates.lowerLimit.isSame(recordDate, 'day')) && dates.upperLimit.isAfter(recordDate, 'day')
      },
    },
    {
      name: 'Hasta 2 meses',
      color: '#FF9E2C',
      filter: record => {
        const dates = this.generateWeeksLimit(2, 'month')
        const recordDate = utcToLocal(dayjs(record.stock.createdAt))
        return (dates.lowerLimit.isBefore(recordDate, 'day') || dates.lowerLimit.isSame(recordDate, 'day')) && dates.upperLimit.isAfter(recordDate, 'day')
      },
    },
    {
      name: 'Hasta 3 meses',
      color: '#30426E',
      filter: record => {
        const dates = this.generateWeeksLimit(3, 'month')
        const recordDate = utcToLocal(dayjs(record.stock.createdAt))
        return (dates.lowerLimit.isBefore(recordDate, 'day') || dates.lowerLimit.isSame(recordDate, 'day')) && dates.upperLimit.isAfter(recordDate, 'day')
      },
    },
    {
      name: 'Más de 3 meses',
      color: '#F03738',
      filter: record => {
        const dates = this.generateWeeksLimit(3, 'month')
        const recordDate = utcToLocal(dayjs(record.stock.createdAt))
        return dates.lowerLimit.isAfter(recordDate, 'day') || dates.lowerLimit.isSame(recordDate, 'day')
      },
    },
  ]

    generateWeeksLimit (count: number, type: 'week' | 'month' = 'week') {
      const currentDate = dayjs().endOf('day')
      const multiplier = type === 'month' ? 4 : 1
      const upperLimit = currentDate.subtract((count - 1) * multiplier, 'week')
      const lowerLimit = currentDate.subtract(count * multiplier, 'week')

      return {
        lowerLimit,
        upperLimit,
      }
    }

    async setProjectedUtility (records) {
      records = await Promise.all(records.map(async record => {
        let projectedUtility = 0
        const salePrice = record.stock.prices?.[0]
        const salePriceAmount = salePrice?.amount

        const { enablementCost } = record
        const { totalCost, expectedPublicationAmount } = record.stock.stockDetail

        if (totalCost) {
          if (salePriceAmount) {
            const discount = salePrice?.margin || 0
            const bonus = salePrice?.bonus?.sort((prev, next) => next - prev)?.[0]?.amount || 0
            projectedUtility = salePrice.amount - totalCost - enablementCost - (discount > bonus ? discount : bonus)
          } else if (expectedPublicationAmount) {
            const transferCost = await this.calculateTransfer(record.stock.id)
            projectedUtility = expectedPublicationAmount - (totalCost || 0) - (enablementCost || 0) - (transferCost || 0) * 1.5
          }
        }

        return {
          ...record,
          projectedUtility,
        }
      }))
      return this.joinStockReserves(records)
    }

    async joinStockReserves (records: Record<string, any>[]): Promise<Record<string, any>> {
      if (!records) return
      const statusesToRemove = ['retired', 'sold']
      records = records.filter(record => {
        return !statusesToRemove.includes(record?.status?.status?.name)
      })
      const reserveStatus = (await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: {
          status: {
            name: { _eq: 'reserved' },
          },
        },
      }))[0].status
      for (let i = 0; i < records.length; i++) {
        if (records[i].status?.status?.name === 'reservation_process') {
          records[i].status.status = reserveStatus
        }
        const status = records[i].status?.status
        if (status) {
          if (status?.name === 'enabling') {
            status.order = 1
          } else if (status?.name === 'available') {
            status.order = 3
          } else if (status?.name === 'reserved') {
            status.order = 5
          } else if (status?.name === 'selling') {
            status.order = 7
          }
          records[i].status.status = status
        }
      }
      return this.setAcquisitionTypeColors(records)
    }

    setAcquisitionTypeColors (records) {
      return records.map(record => {
        const acquisitionType = record.stock.stockDetail.acquisitionType
        if (!acquisitionType) return
        acquisitionType.color = acquisitionTypeColors[acquisitionType?.name]
        if (acquisitionType.name === 'purchase') {
          acquisitionType.description = 'Propio'
        }
        record.stock.stockDetail.acquisitionType = acquisitionType
        return record
      })
    }

    async calculateTransfer (id) {
      if (!id) return
      const processAuto = await this.fetchData({
        query: { name: 'find', model: 'Process' },
        filter: { table_name: { _eq: 'auto' } },
      })
      const { municipalityTax, fixedTransferCosts } = processAuto[0].config

      const stock = await this.fetchData({
        query: { name: 'findLite', model: 'Stock' },
        filter: { id: { _eq: id } },
      })

      const stockDetail = await this.fetchData({
        query: { name: 'find', model: 'StockViewDetails' },
        filter: { id_stock: { _eq: id } },
      })

      const { expectedPublicationAmount } = stockDetail[0]
      const { price } = stock[0]
      const { auto } = stockDetail[0]

      let automotiveRegistrationCost = 0
      const year = Number(dayjs().format('YYYY'))

      if (auto?.generation?.id) {
        const filter = { _and: [{ id_generation: { _eq: auto.generation.id } }, { year: { _eq: year } }] }
        const registration = await this.fetchData({
          query: { name: 'find', model: 'AutomotiveRegistration' },
          filter,
        })
        automotiveRegistrationCost = registration?.[0]?.appraisal
      }

      let baseValue

      if (price?.amount && automotiveRegistrationCost) {
        baseValue = Math.max(price.amount, automotiveRegistrationCost)
      } else if (price?.amount) {
        baseValue = price.amount
      } else if (automotiveRegistrationCost && expectedPublicationAmount) {
        baseValue = Math.max(automotiveRegistrationCost, expectedPublicationAmount)
      } else if (expectedPublicationAmount) {
        baseValue = expectedPublicationAmount
      } else {
        return 0
      }

      return Math.round(parseToNumber((baseValue * municipalityTax) + fixedTransferCosts))
    }

    transformDailyUtility (records) {
      return this.transformUtility(records, 'day')
    }

    transformMonthlyUtility (records) {
      return this.transformUtility(records, 'month', 'bar')
    }

    transformUtility (records, granularity: 'day' | 'month', type = 'line', debugging = false) {
      const { getObjectAttribute } = this
      const { start, end } = granularity === 'day' ? (records[0].name.includes('(mes anterior)') ? this.prevMonthDates : this.dates) : this.lastYearDates

      const recordsCopy = deepCopy(records)

      for (let i = 0; i < recordsCopy.length; i++) {
        this.$set(recordsCopy[i], 'records', recordsCopy[i].records?.records?.nodes || recordsCopy[i].records?.records || recordsCopy[i].records?.records || recordsCopy[i].records || [])
      }

      const purchasesRecords = recordsCopy?.filter(record => record.name === 'Compras' || record.name === 'Compras (mes anterior)')[0]
      const salesRecords = recordsCopy?.filter(record => record.name === 'Ventas' || record.name === 'Ventas (mes anterior)')[0]
      const creditsRecords = recordsCopy?.filter(record => record.name === 'Créditos' || record.name === 'Créditos (mes anterior)')[0]

      const ownStockSales = salesRecords.records.filter(sale => sale.saleOrder.deal.stock.stockDetail.acquisitionType.name === 'purchase')
      const ownPurchases = purchasesRecords.records.filter(purchase => purchase.acquisitionType.name === 'purchase')

      const consignedStockSales = salesRecords.records.filter(sale => sale.saleOrder.deal.stock.stockDetail.acquisitionType.name === 'consignment')
      const consignments = purchasesRecords.records.filter(purchase => purchase.acquisitionType.name === 'consignment')

      if (!purchasesRecords || !salesRecords || !creditsRecords) return

      let newDate = dayjs(start)
      const limitRange = [newDate]
      while (newDate.isBefore(dayjs(end))) {
        newDate = newDate.add(1, granularity)
        if (newDate.isBefore(dayjs(end))) {
          limitRange.push(newDate)
        }
      }

      // Stock propio
      const ownStockUtility = limitRange.map(date => {
        const sales = ownStockSales.filter(record => dayjs(utcToLocal(getObjectAttribute(record, salesRecords.pathToDate))).isSame(date, granularity))
        const purchases = ownPurchases.filter(record => dayjs(utcToLocal(getObjectAttribute(record, purchasesRecords.pathToDate))).isSame(date, granularity))
        let utility = 0
        sales.forEach(sale => utility += getObjectAttribute(sale, salesRecords.pathToValue) || 0)
        purchases.forEach(purchase => utility -= getObjectAttribute(purchase, purchasesRecords.pathToValue) || 0)
        return {
          date: localToUtc(date).format('YYYY-MM-DDTHH:mm:ss'),
          utility,
          type,
        }
      })

      // Stock consignado
      const consignedStockUtility = limitRange.map(date => {
        const sales = consignedStockSales.filter(record => dayjs(utcToLocal(getObjectAttribute(record, salesRecords.pathToDate))).isSame(date, granularity))
        const purchases = consignments.filter(record => dayjs(utcToLocal(getObjectAttribute(record, purchasesRecords.pathToDate))).isSame(date, granularity))
        let utility = 0
        sales.forEach(sale => utility += getObjectAttribute(sale, salesRecords.pathToValue) || 0)
        purchases.forEach(purchase => utility -= getObjectAttribute(purchase, purchasesRecords.pathToValue) || 0)
        return {
          date: localToUtc(date).format('YYYY-MM-DDTHH:mm:ss'),
          utility,
        }
      })
      // Creditos
      const creditsUtility = limitRange.map(date => {
        const credits = creditsRecords.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, creditsRecords.pathToDate))).isSame(date, granularity))
        let utility = 0
        credits.forEach(credit => utility += getObjectAttribute(credit, creditsRecords.pathToValue))
        return {
          date: date.format('YYYY-MM-DD'),
          utility,
        }
      })

      return [
        {
          name: 'Stock propio',
          records: ownStockUtility,
          color: '#6185DB',
          pathToDate: ['date'],
          pathToValue: ['utility'],
          type,
        },
        {
          name: 'Consignaciones',
          records: consignedStockUtility,
          color: '#FAD901',
          pathToDate: ['date'],
          pathToValue: ['utility'],
          type,
        },
        {
          name: 'Créditos',
          records: creditsUtility,
          color: '#7D67BC',
          pathToDate: ['date'],
          pathToValue: ['utility'],
          type,
        },
      ]
    }

    sortBySignedDate (records) {
      return records.sort((prev, next) => {
        if (dayjs(prev.signedDate).isBefore(dayjs(next.signedDate))) {
          return 1
        } else if (dayjs(prev.signedDate).isAfter(dayjs(next.signedDate))) {
          return -1
        }
        return 0
      })
    }

    fixCreditFields (records) {
      const corrected = deepCopy(records)
      for (let i = 0; i < corrected.length; i++) {
        const { auto, executive } = corrected[i]
        const carFullName = `${auto.versionYear.version.model.brand.name} ${auto.versionYear.version.model.name}`
        const executiveFullName = `${executive?.person.firstName.trim()} ${executive?.person.surname.trim()}`
        if (executive) {
          corrected[i].executive.fullName = executiveFullName
        }
        corrected[i].signedDate = dayjs(corrected[i].signedDate).format('DD/MM/YY')
        corrected[i] = {
          ...corrected[i],
          carFullName,
          executiveFullName,
        }
      }

      return corrected
    }

    normaliceReserves (records) {
      return records.map(record => {
        const { executive, executiveName } = record
        record.executive = executive ? {
          id: executive,
          name: executiveName.toUpperCase(),
        } : undefined
        record.deliveryDate = utcToLocal(dayjs(record.deliveryDate))?.format('DD/MM/YY HH:mm')
        return {
          ...record,
          reserveAmount: fixPrice(record?.reserveAmount),
          createdAt: utcToLocal(record?.createdAt)?.format('DD/MM/YYYY'),
          subtitleIcon: record.acquisitionType?.name === 'consignment'
            ? {
              icon: 'mdi-car-key',
              text: 'Consignación',
            } : undefined,
        }
      })
    }

    setFinancingColors (records) {
      return records.map(record => {
        const { person } = record
        person.color = financingColors[person.alias.toUpperCase()]
        record.person = person
        return record
      })
    }

    normaliceSales (records) {
      return this.normaliceReserves(this.setChannelColors(records))
    }

    normaliceReservesQuery (records) {
      return records.map(record => {
        const { executive } = record.saleOrder.deal.lead
        const person = executive?.person
        record.executive = person ? {
          id: executive.id,
          name: `${person.firstName} ${person.surname}`,
        } : undefined
        return record
      })
    }

    normalicePurchases (records) {
      return this.setChannelColors(records)
        .map(record => {
          const { executive } = record
          const person = executive?.person
          record.executive = {
            id: executive?.id,
            name: person ? `${person.firstName} ${person.surname}` : 'SIN EJECUTIVO',
          }
          return record
        })
    }

    setChannelColors (records) {
      const corrected = deepCopy(records)
      for (let i = 0; i < corrected.length; i++) {
        const { channel } = corrected[i]
        channel.color = channelColors[channel.name]
        corrected[i].channel = channel
      }
      return corrected
    }

    transformDailyBalance (records) {
      return records.map(record => {
        if (record.stock) {
          return {
            ...record,
            date: record.reportDate + 'T12:00:00',
            type: {
              id: 999,
              description: 'Stock propio',
              name: 'stock',
              color: '#6185DB',
            },
            bankAccount: {
              id: 999,
              description: 'Stock propio',
              name: 'stock',
              color: '#6185DB',
            },
            accountBalance: record.stock.stockDetail.totalCost,
          }
        }
        const { date, bankAccount } = record
        const { bank, accountNumber } = bankAccount
        const bankAlias = bankAccount.bank.alias

        return {
          ...record,
          date: date + 'T12:00:00',
          type: {
            id: 998,
            description: 'Bancos',
            name: 'banks',
            color: '#13CE66',
          },
          bankAccount: {
            ...bankAccount,
            description: `${bankAlias} / ${accountNumber}`,
            color: bankAccount.metadata?.color || bankColors[bankAlias],
            bank: {
              ...bank,
              description: bankAlias,
            },
          },
        }
      }).sort((prev, next) => next.bankAccount.accountNumber - prev.bankAccount.accountNumber)
    }

    fixSaleFields (records) {
      const corrected = deepCopy(records)
      for (let i = 0; i < corrected.length; i++) {
        const { autoDescription, year, executive, executiveName } = corrected[i]
        const carFullName = `${autoDescription} ${year}`
        corrected[i].soldDate = dayjs(corrected[i].soldDate).format('DD/MM/YY - HH:mm')
        corrected[i].executive = executive ? {
          id: executive,
          name: executiveName.toUpperCase(),
        } : undefined
        corrected[i] = {
          ...corrected[i],
          carFullName,
          saleAmount: fixPrice(corrected[i].saleAmount),
          subtitleIcon: corrected[i].acquisitionType.name === 'consignment'
            ? {
              icon: 'mdi-car-key',
              text: 'Consignación',
            } : undefined,
        }
      }

      return corrected
    }

    // Getters

    filterPurchases (record) {
      return record.stock.stockDetail.acquisitionType.name === 'purchase'
    }

    get bankAccountsData () {
      return {
        currentMonth: {
          records: [
            ...(this.chartData.currentMonth?.dailyAccountBalance?.records ? this.chartData.currentMonth?.dailyAccountBalance?.records : []),
            ...(this.chartData.currentMonth?.dailyStock?.records ? this.chartData.currentMonth?.dailyStock?.records.filter(this.filterPurchases) : []),
          ],
        },
        prevMonth: {
          records: [
            ...(this.chartData.prevMonth?.dailyAccountBalance?.records ? this.chartData.prevMonth?.dailyAccountBalance?.records : []),
            ...(this.chartData.prevMonth?.dailyStock?.records ? this.chartData.prevMonth?.dailyStock?.records.filter(this.filterPurchases) : []),
          ],
        },
        lastYear: {
          records: [
            ...(this.chartData.lastYear?.dailyAccountBalance?.records ? this.chartData.lastYear?.dailyAccountBalance?.records : []),
            ...(this.chartData.lastYear?.dailyStock?.records ? this.chartData.lastYear?.dailyStock?.records.filter(this.filterPurchases) : []),
          ],
        },
      }
    }

  // Watchers
  }
