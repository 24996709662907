
  import { Component, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'

  import { toRouteRecord } from '@/router/helpers'
  import { Resource } from '@/entities/public'
  import BaseBar from '@/layouts/BaseBar'

@Component({
  methods: {
    ...mapActions('resources/form', ['fetchData']),
  },
  computed: {
    ...mapGetters('resources', ['all']),
  },
})
  export default class AppBar extends BaseBar {
  private all!: Array<Resource>;

  resource = -1;
  option = -1;

  created () {
    const { $route: { params: { resource } }, targets } = this
    this.resource = (resource && targets) ? targets.map(({ slug }) => slug).indexOf(resource) : 0
  }

  get targets (): Array<Resource> {
    const { $route, all } = this
    const { name } = toRouteRecord($route)
    return all.filter(({ route }) => route.name && route.name === name)
  }

  get target (): Resource | undefined {
    const { resource, targets } = this
    if (!targets) return undefined

    return targets[resource]
  }

  @Watch('isResize', { immediate: true })
  onResizeChange (val) {
    this.resize = val
  }
  }
