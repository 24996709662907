
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
  export default class GTextarea extends Vue {
  // Props
  @Prop({ type: String, default: '' }) value!: string; // Prop para el valor inicial
  @Prop({ type: String, default: 'Comentario' }) label!: string; // Etiqueta
  @Prop({ type: Boolean, default: true }) outlined!: boolean; // Estilo outlined
  @Prop({ type: [Boolean, String], default: false }) error!: boolean | string; // Estado de error
  @Prop({ type: Array, default: () => [] }) errorMessages!: string[]; // Mensajes de error
  @Prop({ type: Number, default: 2 }) rows!: number; // Mensajes de error
  @Prop({ type: Number, default: 256 }) counter!: number; // Mensajes de error

  // Data
  localValue: string = this.value; // Inicializamos con el valor de `value`

  // Watchers
  @Watch('value', { immediate: true })
  onValueChange (newValue: string): void {
    this.localValue = newValue // Sincroniza `localValue` con `value` cuando cambia
  }

  // Computed
  get hasError (): boolean {
    return !!this.error || (this.errorMessages && this.errorMessages.length > 0)
  }

  // Methods
  onInput (value: string): void {
    this.localValue = value // Actualiza `localValue`
    this.$emit('input', value) // Emite el evento `input`
  }

  onBlur (): void {
    this.$emit('blur', this.localValue) // Emite el evento `blur`
  }
  }
