import { State as RootState } from '@/store/state'
import { ActionTree } from 'vuex'

import store from '@/store'
import { Forms } from './state'
import { fetchData, Models, pushData, removeData } from '@/graphql/forms'
import { setValueByPath } from '@/utils/vuetify/helpers'
import { isReactive, reactive } from 'vue'
import { AUTH_TOKEN } from '@/plugins/apollo'
import axios from 'axios'
import { File } from '@/entities/files'
import { plainToInstance } from 'class-transformer'
import { fileProcessing } from '@/graphql/mutations/files/file/cav'
import { closeLostLead } from '@/graphql/mutations/crm/lead/close'
import { findPpu } from '@/graphql/customQueries/public/auto/auto'
import { findSimpleAttributesByVersionYear } from '@/graphql/customQueries/vehicle/attribute'
import { sumCategoryScore } from '@/graphql/customQueries/purchase/inspection/inspection'
import dayjs from 'dayjs'
import {
  airtableFieldConstruction,
  filterAirtableConstructor,
  getCreatePerson,
  getOrCreateVehicle,
  getSchema,
  insertAirtable,
  searchAirtable,
} from '@/utils/airtable/general'
import { findDocument } from '@/graphql/customQueries/loans/evaluation/evaluation'
import {
  availableKey,
  closeApprovedSale,
  findStockId,
  getDailyStock,
  getSaleOrdersByStockId,
  leadReceivedStock,
  publishStock,
  removeStock,
  republishStock,
} from '@/graphql/customQueries/sales/stock/stock'
import { getExecutiveByPerson } from '@/graphql/customQueries/hr/employee/employee'
import {
  appraiseWithScrapping,
  automaticAppraisal,
  getAppraisalWithAutoId,
} from '@/graphql/customQueries/purchase/appraisal/appraisal'
import { updateDeal } from '@/graphql/customQueries/inspection/updateDeal'
import { closeDeal } from '@/graphql/customQueries/crm/deal'
import { findInspectionLeadActivity } from '@/graphql/customQueries/crm/leadActivity'
import { getLeadCustom, getOpenLead } from '@/graphql/customQueries/crm/lead'
import router from '@/router'
import { sendError } from '@/utils/notificationError'
import { processCavAndTickets } from '@/graphql/customQueries/purchase/negotiation/negotiation'
import { to } from '@/utils/asyncHelpers'
import {
  closeApprovedPurchase,
  createDirectPurchase,
  withdrawalByPurchaseId,
} from '@/graphql/customQueries/purchase/purchaseOrder/purchaseOrder'

const { VUE_APP_UPLOAD_FILE, VUE_APP_BASE_URL } = process.env

const getToken = (): string | null => {
  return localStorage.getItem(AUTH_TOKEN)
}

function parseJwt (token) {
  const base64Url = token.split('.')[1]
  if (!base64Url) return null

  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  )
  return JSON.parse(jsonPayload)
}

function toUtcString (timestampInSeconds) {
  const date = new Date(timestampInSeconds * 1000)
  return date.toUTCString() // Devuelve la fecha en UTC
}

function getTokenInfo (token) {
  const payload = parseJwt(token)
  if (!payload) return { error: 'Token inválido' }

  return {
    'Hora de autenticación': toUtcString(payload.auth_time),
    'Emitido en (iat)': toUtcString(payload.iat),
    'Expira en (exp)': toUtcString(payload.exp),
    Detalles: {
      auth_time: 'Hora en la que se autenticó el usuario.',
      iat: 'Hora en la que se emitió el token.',
      exp: 'Hora en la que el token expira.',
    },
  }
}

async function handleErrorFile (attempts, error: any) {
  if (attempts >= 3 || !error?.response?.data?.message?.includes('JWTExpired')) {
    throw error // Lanza el error si ya se intentó 3 veces o si el error no es por token expirado
  }
  await updateJWT()
}

async function updateJWT () {
  await store.dispatch('user/refreshToken') // Intenta actualizar el token
}

export async function handleError (files, errorCatched) {
  const castFiles = files.map(file => {
    return {
      name: file?.name,
      size: file?.size,
      type: file?.type,
      lastModified: file?.lastModified,
      lastModifiedDate: file?.lastModifiedDate,
    }
  })

  const currentRoute = router.currentRoute.fullPath
  const requestRoot = window.location.origin
  const fullUrl = `${requestRoot}${currentRoute}`
  const error = {
    app_name: `GENIO`,
    view: `${fullUrl}`,
    record_id: `Datos usuario ${JSON.stringify(store.getters['user/user'])}`,
    context: `Archivo :${JSON.stringify(castFiles)},\n\n Error ${JSON.stringify(errorCatched)} Informacion del token en caso de ser 401 ${getTokenInfo(getToken())}`,
  }
  console.error(error)
  await sendError(error)
}

async function uploadFiles (apolloClient, file, idFileType, idProcess) {
  if (!apolloClient) throw new Error(`Can't push entity data yet`)

  if (!file) return null
  await updateJWT()
  const formData = new FormData()
  let hasFilesToUpload = false
  if (Array.isArray(file)) {
    file.forEach((f, index) => {
      if (!('id' in f)) {
        formData.append(`process_file`, f)
        hasFilesToUpload = true
      }
    })
  } else {
    if (!('id' in file)) {
      formData.append('process_file', file)
      hasFilesToUpload = true
    }
  }

  if (!hasFilesToUpload) return null
  formData.append('id_file_type', idFileType)
  formData.append('id_process', idProcess)

  let attempts = 0

  while (attempts < 3) {
    try {
      const {
        data: { data },
        data: allData,
      } = await axios.post(`${VUE_APP_UPLOAD_FILE}/api/services/upload-process-file`, formData, {
        headers: { authorization: `Bearer ${getToken()}`, 'Content-Type': 'multipart/form-data' },
      })

      if ('postData' in allData) {
        const { postData: { setExpirationDate: { data: expiration }, readCav } } = allData
        const isError = readCav && 'error' in readCav && Boolean(Object.keys(readCav.error).length)

        return data.map(r => plainToInstance(File, {
          ...r,
          customExpirationDate: expiration?.[0]?.split('T')[0],
          error: isError,
        }))
      }
      return data.map(r => plainToInstance(File, r))
    } catch (error) {
      attempts++

      await handleErrorFile(attempts, error)
    }
  }
}

async function deleteSingleFile (apolloClient, id, idFileProcess) {
  if (!apolloClient) throw new Error(`Can't push entity data yet`)
  if (!id) return null

  let attempts = 0

  while (attempts < 3) {
    try {
      await axios.post(
        `${VUE_APP_UPLOAD_FILE}/api/services/delete-process-file`,
        { id_file: id, id_file_process: idFileProcess },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${getToken()}`,
          },
        }
      )
      return // Si la operación es exitosa, salimos de la función
    } catch (error) {
      attempts++
      await handleErrorFile(attempts, error)
    }
  }
}

export const actions: ActionTree<Forms, RootState> = {
  fetchData: async ({ rootState: { apolloClient } }, { query, filter, limit, force, offset, params, distinct }) => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    try {
      return fetchData(apolloClient, query, filter, limit, force, offset, params, distinct)
    } catch (e) {
      console.error(e)
      return []
    }
  },
  pushData: async ({ rootState: { apolloClient } }, { model, fields }) => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)
    return pushData(apolloClient, model, fields)
  },
  updateValue: ({ commit, getters: { active } }, payload) => {
    const { value, path } = payload

    if (Models[value?.constructor.name] && !isReactive(value)) reactive(value)
    const form = !path ? value : setValueByPath(active, path, value)

    commit('updateForm', form)
    return form
  },
  removeData: async ({ rootState: { apolloClient } }, { model, fields }) => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    return removeData(apolloClient, model, fields)
  },
  setCrumbs: ({ commit }, crumbs) => {
    return commit('setCrumbs', crumbs)
  },
  closeLead: async ({ rootState: { apolloClient } }, { idLead, idClosingReason }): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    const {
      data,
    } = await apolloClient.mutate({
      mutation: closeLostLead,
      variables: {
        idLead,
        idClosingReason,
      },
    })

    return data
  },
  fileProcessing: async ({ rootState: { apolloClient } }, { idDeal, idFileProcess }): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    await apolloClient.mutate({
      mutation: fileProcessing,
      variables: {
        idDeal,
        idFileProcess,
      },
    })
  },
  uploadImage: async ({ rootState: { apolloClient } }, { file, idFileType = 1, idProcess = 10 }): Promise<Array<File>> => {
    const token = getToken()

    if (!token) throw new Error('No authorization token found')

    return uploadFiles(apolloClient, file, idFileType, idProcess)
  },
  deleteFile: async ({ rootState: { apolloClient } }, files): Promise<void> => {
    if (!files?.length) return
    const token = getToken()

    if (!token) throw new Error('No authorization token found')

    for (const file of files) {
      await deleteSingleFile(apolloClient, file.id, file.idFileProcess)
    }
  },
  findPpu: async ({ rootState: { apolloClient } }, ppu): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    try {
      const {
        data,
      } = await apolloClient.query({
        query: findPpu,
        variables: {
          ppu,
        },
      })

      return data
    } catch (e) {
      // @ts-ignore
      if (e.message.includes('brand not found')) return { auto: { year: -1 } }
      return null
    }
  },
  findAttributes: async ({ rootState: { apolloClient } }, versionYearIds): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    try {
      const {
        data,
      } = await apolloClient.query({
        query: findSimpleAttributesByVersionYear,
        variables: {
          versionYearIds,
        },
        fetchPolicy: 'no-cache',
      })

      return data?.attributes
    } catch (e) {
      return null
    }
  },
  getCategoryScore: async ({ rootState: { apolloClient } }, { categoryName, idInspection }): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    try {
      const {
        data: { response: { aggregate: { sum: { score } } } },
      } = await apolloClient.query({
        query: sumCategoryScore,
        variables: {
          categoryName,
          id_inspection: idInspection,
        },
        fetchPolicy: 'no-cache',
      })

      return score
    } catch (e) {
      return null
    }
  },
  getLoansDocument: async ({ rootState: { apolloClient } }, id): Promise<Record<string, any>> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    try {
      const {
        data: { evaluation },
      } = await apolloClient.query({
        query: findDocument,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      })

      if (!evaluation?.length) return null
      const { financing: { sale_order: { deal: { stock: { transfer } } } } } = evaluation[0]

      return transfer
    } catch (e) {
      return null
    }
  },
  handleNegotiation: async ({ rootState: { apolloClient }, dispatch }, {
    formData,
    fields,
    negotiation,
  }): Promise<Record<string, any>> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)
    const {
      inspection: {
        appraisal: {
          deal: {
            lead: {
              executive: { person: { email: { work } } },
            },
          },
        },
        auto: vehicle,
      },
    } = negotiation
    let lead

    const executiveSchema = getSchema('executive')

    const executive = await searchAirtable({
      table: executiveSchema.table,
      fields: airtableFieldConstruction(executiveSchema.fields),
      filter: filterAirtableConstructor(work, executiveSchema.filter),
    })
    const person = await getCreatePerson(formData)
    const auto = await getOrCreateVehicle(vehicle)

    const leadSchema = getSchema('lead')
    const leads = await searchAirtable({
      table: leadSchema.table,
      fields: airtableFieldConstruction(leadSchema.fields),
      filter: `${filterAirtableConstructor(person?.id, leadSchema.filter)}`,
      all: true,
    })
    lead = leads?.find(l => l.fields?.['Estado del lead'] === '🟢 Activo' && l.fields?.['Record ID Auto que Vende'] === auto?.id)

    if (!lead) {
      lead = await insertAirtable({
        table: leadSchema.table,
        fields: {
          records: [
            {
              fields: {
                '🧑 Cliente': [person?.id],
                Ejecutivo: [executive?.id],
                '🚙 Vehículo que vende': [auto?.id],
              },
            },
          ],
        },
      })
    }
    const purchaseSchema = getSchema('purchase')
    let purchase = await searchAirtable({
      table: purchaseSchema.table,
      fields: airtableFieldConstruction(purchaseSchema.fields),
      filter: `${filterAirtableConstructor(lead.id, purchaseSchema.filter)}`,
    })

    if (!purchase) {
      purchase = await insertAirtable({
        table: purchaseSchema.table,
        fields: {
          records: [
            {
              fields: {
                Lead: [lead?.id],
                Ejecutivo: [executive?.id],
                'Fecha de Compra': dayjs().format('YYYY-MM-DD'),
              },
            },
          ],
        },
      })
    }

    return {
      exists: true,
      purchase,
    }
  },
  getNewKey: async ({ rootState: { apolloClient } }): Promise<number> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    try {
      const {
        data: { response: { id } },
      } = await apolloClient.query({
        query: availableKey,
        fetchPolicy: 'no-cache',
      })

      return id
    } catch (e) {
      return null
    }
  },
  getExecutiveByPerson: async ({ rootState: { apolloClient } }, idPerson): Promise<number> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    try {
      const {
        data: { response: { id } },
      } = await apolloClient.query({
        query: getExecutiveByPerson,
        variables: {
          idPerson,
        },
      })

      return id
    } catch (e) {
      return null
    }
  },
  updateDealProcessStatusAndClosingReason: async ({ rootState: { apolloClient } }, {
    id,
    idProcessStatus,
    idClosingReason,
  }): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    try {
      await apolloClient.mutate({
        mutation: updateDeal,
        variables: {
          id,
          idProcessStatus,
          idClosingReason,
        },
      })
    } catch (e) {
      return null
    }
  },
  getAppraiseWithScrapping: async ({ rootState: { apolloClient } }, {
    mileage,
    idGeneration,
    idVersionYear,
  }): Promise<any> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)
    try {
      const {
        data: { response },
      } = await apolloClient.query({
        query: appraiseWithScrapping,
        variables: {
          mileage,
          idGeneration,
          idVersionYear,
        },
      })
      return response
    } catch (e) {
      return null
    }
  },
  closeDeal: async ({ rootState: { apolloClient } }, { idDeal, idClosingReason }): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    const {
      data,
    } = await apolloClient.mutate({
      mutation: closeDeal,
      variables: {
        idDeal,
        idClosingReason,
      },
    })

    return data
  },
  findLeadActivity: async ({ rootState: { apolloClient } }, idLead): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    try {
      const {
        data: { leadActivity },
      } = await apolloClient.query({
        query: findInspectionLeadActivity,
        variables: {
          idLead,
        },
      })

      return leadActivity?.[0].id
    } catch (e) {
      return null
    }
  },
  findStockId: async ({ rootState: { apolloClient } }, autoId): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    try {
      const {
        data: { stock },
      } = await apolloClient.query({
        query: findStockId,
        variables: {
          autoId,
        },
      })

      return stock?.[0].id
    } catch (e) {
      return null
    }
  },
  publishStock: async ({ rootState: { apolloClient } }, stockId): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    await apolloClient.mutate({
      mutation: publishStock,
      variables: {
        stockId,
      },
    })
  },
  removeStock: async ({ rootState: { apolloClient } }, stockId): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    await apolloClient.mutate({
      mutation: removeStock,
      variables: {
        stockId,
      },
    })
  },
  republishStock: async ({ rootState: { apolloClient } }, stockId): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't push entity data yet`)

    await apolloClient.mutate({
      mutation: republishStock,
      variables: {
        stockId,
      },
    })
  },
  getStockSalesById: async ({ rootState: { apolloClient } }, stockId): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    try {
      const {
        data: { saleOrders },
      } = await apolloClient.query({
        query: getSaleOrdersByStockId,
        variables: {
          stockId,
        },
      })

      return saleOrders
    } catch (e) {
      return null
    }
  },
  getLeadById: async ({ rootState: { apolloClient } }, idLead): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    try {
      const {
        data: { lead },
      } = await apolloClient.query({
        query: getLeadCustom,
        variables: {
          idLead,
        },
      })

      return lead
    } catch (e) {
      return null
    }
  },
  getOpenLead: async ({ rootState: { apolloClient } }, { autoId, clientId }): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    try {
      const {
        data: { openLead },
      } = await apolloClient.query({
        query: getOpenLead,
        variables: {
          autoId,
          clientId,
        },
      })

      return openLead
    } catch (e) {
      return null
    }
  },
  automaticAppraisal: async ({ rootState: { apolloClient } }, id): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    try {
      await apolloClient.query({
        query: automaticAppraisal,
        variables: {
          id,
        },
      })
    } catch (e) {
      return null
    }
  },
  getAppraisalWithAutoId: async ({ rootState: { apolloClient } }, id): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    try {
      const {
        data: { get_appraisals_with_auto_id: appraisals },
      } = await apolloClient.query({
        query: getAppraisalWithAutoId,
        variables: {
          id,
        },
      })

      return appraisals
    } catch (e) {
      return null
    }
  },
  processCavAndTickets: async ({ rootState: { apolloClient } }, idNegotiation): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    try {
      await apolloClient.mutate({
        mutation: processCavAndTickets,
        variables: {
          idNegotiation,
        },
      })
    } catch (e) {
      return null
    }
  },
  pdfToImage: async ({ rootState: { apolloClient } }, url): Promise<String> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    try {
      const response = await axios.get(`${VUE_APP_BASE_URL}/convert-pdf-to-image/convert-pdf-to-image`, {
        params: { url },
        responseType: 'blob', // Para manejar la respuesta como un blob
      })

      // Crea un objeto URL a partir del blob para usarlo en una etiqueta <img> o v-img
      return URL.createObjectURL(response.data)
    } catch (e) {
      console.error('Error al convertir el PDF a imagen:', e)
      return null
    }
  },
  getStockDaily: async ({ rootState: { apolloClient } }, stockId): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    const [error, result] = await to(apolloClient.query({
      query: getDailyStock,
      variables: {
        stockId,
      },
    }))

    if (error) {
      console.error('Error al obtener el stock diario:', error)
      return null
    }

    return result?.data?.data
  },
  getLeadReceivedStock: async ({ rootState: { apolloClient } }, stockId): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    const [error, result] = await to(apolloClient.query({
      query: leadReceivedStock,
      variables: {
        stockId,
      },
    }))

    if (error) {
      console.error('Error al obtener el stock recibido por el lead:', error)
      return null
    }

    return result?.data?.data
  },
  withdrawalByPurchaseId: async ({ rootState: { apolloClient } }, { purchaseId, idValidator }): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    const [error, result] = await to(apolloClient.mutate({
      mutation: withdrawalByPurchaseId,
      variables: {
        purchaseId,
        idValidator,
      },
    }))

    if (error) {
      const fullUrl = window.location.href
      const errorMessage = {
        app_name: `GENIO`,
        view: `${fullUrl}`,
        record_id: `Datos usuario ${JSON.stringify(store.getters['user/user'])}`,
        context: `Error cerrando la compra consignación purchase id: ${purchaseId} validador id: ${idValidator} => Message: ${JSON.stringify(error)},`,
      }
      await sendError(errorMessage)
      console.error('Error cerrando la compra consignacion:', error)
      return null
    }

    return result?.data?.data
  },
  closeApprovedPurchase: async ({ rootState: { apolloClient } }, { purchaseId, idValidator }): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    const [error, result] = await to(apolloClient.mutate({
      mutation: closeApprovedPurchase,
      variables: {
        purchaseId,
        idValidator,
      },
    }))

    if (error) {
      const fullUrl = window.location.href
      const errorMessage = {
        app_name: `GENIO`,
        view: `${fullUrl}`,
        record_id: `Datos usuario ${JSON.stringify(store.getters['user/user'])}`,
        context: `Error cerrando la compra aprobada purchase id: ${purchaseId} validador id: ${idValidator}  => Message: ${JSON.stringify(error)},`,
      }
      await sendError(errorMessage)
      console.error('Error cerrando la compra aprobada:', error)
      return null
    }

    return result?.data?.data
  },
  createDirectPurchase: async ({ rootState: { apolloClient } }, purchaseInput): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    const [error, result] = await to(apolloClient.mutate({
      mutation: createDirectPurchase,
      variables: {
        purchaseInput,
      },
    }))

    if (error) {
      const fullUrl = window.location.href
      const errorMessage = {
        app_name: `GENIO`,
        view: `${fullUrl}`,
        record_id: `Datos usuario ${JSON.stringify(store.getters['user/user'])}`,
        context: `Error creando compra directa => Message: ${JSON.stringify(error)}, data:${JSON.stringify(purchaseInput)}`,
      }
      await sendError(errorMessage)
      console.error('Error creando compra directa:', error)
      return null
    }

    return result?.data
  },
  closeApprovedSale: async ({ rootState: { apolloClient } }, { idValidator, saleId }): Promise<void> => {
    if (!apolloClient) throw new Error(`Can't fetch entity yet`)

    const [error, result] = await to(apolloClient.mutate({
      mutation: closeApprovedSale,
      variables: {
        idValidator,
        saleId,
      },
    }))

    if (error) {
      const fullUrl = window.location.href
      const errorMessage = {
        app_name: `GENIO`,
        view: `${fullUrl}`,
        record_id: `Datos usuario ${JSON.stringify(store.getters['user/user'])}`,
        context: `Error cerrando la venta aprobada sale id: ${saleId} validador id: ${idValidator}  => Message: ${JSON.stringify(error)},`,
      }
      await sendError(errorMessage)
      console.error('Error cerrando la venta aprobada:', error)
      return null
    }

    return result?.data
  },
}
