import { Type } from 'class-transformer'
import { Entity } from '..'
import { ClosingReason, ProcessStatus, SupportDocumentType } from '@/entities/settings'
import { DocumentType, Intervener } from '.'
import { Employee } from '@/entities/hr'
import { SaleOrder } from '@/entities/sales'

export class Document extends Entity {
  @Type(() => ClosingReason)
  closingReason: ClosingReason;

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => DocumentType)
  documentType: DocumentType;

  @Type(() => Employee)
  validator: Employee;

  @Type(() => Intervener)
  interveners: Intervener[];

  @Type(() => SupportDocumentType)
  support: SupportDocumentType;

  @Type(() => SaleOrder)
  saleOrders: SaleOrder[];

  @Type(() => SaleOrder)
  saleOrder: SaleOrder;

  expirationDate: string;
  metadata: Record<string, any>

  get auto () {
    return this.saleOrders?.[0]?.deal?.auto
  }

  get stock () {
    return this.saleOrders?.[0]?.deal?.stock
  }

  get executive () {
    return this.saleOrders?.[0]?.deal?.lead?.executive
  }

  get acquirers () {
    const { interveners } = this

    return interveners?.filter(intervener => intervener.field.name === 'buyer').map(intervener => intervener.person)
  }

  get owners () {
    const { interveners } = this

    return interveners?.filter(intervener => intervener.field.name === 'seller').map(intervener => intervener.person)
  }

  get purchaseRepresentative () {
    const { interveners } = this

    return interveners?.filter(intervener => intervener.field.name === 'purchase_representative')?.map(intervener => intervener.person)
  }

  get sale () {
    const { saleOrders } = this

    return saleOrders?.[0]
  }
}
