
  import { Component, Watch } from 'vue-property-decorator'

  import { ExpandableAuto, Panels } from '../expandable'

  import { Deal } from '@/entities/crm'
  import { mapGetters } from 'vuex'
  import { Role } from '@/store/user/models'

@Component({
  components: {
    ExpandableAuto,
    ...Panels,
    Row: () => import('../simple.vue'),
  },
  computed: {
    ...mapGetters('user', ['roles']),
  },
})
  export default class ExpandableAppraisal extends ExpandableAuto {
  roles!: Array<Role>
  files = []

  get deal () {
      return (this.value as unknown) as Deal
    }

    get appraisal () {
      const { deal: { appraisal } } = this
      return appraisal
    }

    get closingReason () {
      const { appraisal: { closingReason } } = this
      return closingReason
    }

    get autoAppraisal () {
      const { bind, queries, deal, closingReason, detailed, displayPhoto } = this
      const value = deal.auto
      const { status } = deal.appraisal
      let chip
      if (!closingReason) {
        chip = {
          color: `${status?.color} lighten-1`,
          value: status?.description,
          textColor: status?.color,
          small: true,
        }
      } else {
        chip = {
          color: `${closingReason.color} lighten-1`,
          value: closingReason.description,
          textColor: closingReason.color,
          small: true,
        }
      }

      return { ...bind, queries, value, chip, detailed: detailed ?? false, displayPhoto }
    }

    get market () {
      const { appraisal: { externalOffers } } = this
      if (!externalOffers?.length) return undefined

      return {
        items: externalOffers,
        itemText: 'value',
        itemLabel: 'name',
        formatter: 'fixPrice',
      }
    }

    get maintenance () {
      const { deal: { auto: { maintenances } } } = this
      if (!maintenances?.length) return undefined

      const config = { color: 'blue lighten-2', textColor: 'blue darken-1', small: true }

      const filteredMaintenance = maintenances.filter(maintenance => maintenance?.status?.isDone)

      return {
        chips: filteredMaintenance.length ? filteredMaintenance : maintenances,
        config,
        itemText: 'description',
        last: {
          ...config,
          value: filteredMaintenance.length ? filteredMaintenance[0]?.description : maintenances[0].description,
        },
      }
    }

    get attachments () {
      return Boolean(this.files.length)
    }

    get pictures () {
      return undefined
    }

    get favorites () {
      const { deal: { autoAttributes: attributes } } = this
      if (!attributes) return []
      let favorites = attributes?.filter(({ base: { favorite } }) => favorite) || []

      const mileages = favorites?.filter(({ base: { slug } }) => slug === 'mileage') || []
      const owners = favorites?.filter(({ base: { slug } }) => slug === 'owners_number') || []

      favorites = attributes?.filter(({
        base: {
          slug,
          favorite,
        },
      }) => slug !== 'mileage' && slug !== 'owners_number' && favorite) || []
      if (mileages?.length) {
        favorites.push(mileages[mileages.length - 1])
      }
      if (owners?.length) {
        favorites.push(owners[owners.length - 1])
      }

      return favorites
    }

  async findAssociatedFiles (id) {
    if (!id) return {}
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'negotiation' } },
    })

    const idProcess = process?.[0]?.id
    const cav = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'cav' } } }] },
      force: true,
    })

    const report = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'legal_report' } } }] },
      force: true,
    })

    const penalties = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { name: { _eq: 'penalty_certificate' } } }] },
      force: true,
    })

    return {
      cav,
      report,
      penalties,
    }
  }

  @Watch('deal', { immediate: true, deep: true })
  async onDealChange (val: Deal) {
    const allowedRoles = ['staff', 'validator']
    const path = this.$route.path

    if (!allowedRoles.some(role => path.includes(role))) return
    if (!val?.id) return

    const negotiation = (await this.fetchData({
      query: { name: 'find', model: 'Negotiation' },
      filter: { inspection: { appraisal: { id_deal: { _eq: val?.id } } } },
      force: true,
    }))[0]
    if (!negotiation?.id) return
    const {
      cav,
      report,
      penalties,
    } = await this.findAssociatedFiles(negotiation?.id)

    this.files = [
      { name: 'CAV', files: cav },
      { name: 'Informe Legal', files: report },
      { name: 'Certificado de Multas', files: penalties },
    ]
  }
  }
