
  import { Component, Emit, Prop, Watch } from 'vue-property-decorator'
  import { mapActions } from 'vuex'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'

@Component({
  methods: {
    ...mapActions('persons', ['consultRut']),
  },
})
  export default class GRut extends FilesProcess {
  @Prop({ type: Boolean, default: false }) loading: boolean;
  @Prop({ type: Boolean, default: false }) required: boolean;
  @Prop({ type: Boolean, default: false }) disabled: boolean;
  @Prop({ type: String, default: 'natural_person' }) personType: string;
  @Prop({ type: String, default: '' }) value!: string;
  @Prop({ type: Number, default: null }) id!: number;

  consultRut!: ({ rut, personType }: { rut: string, personType: string }) => Promise<string>;

  rutNumber: string = '';
  verifierDigit: string = '';
  rutLoading: boolean = false;
  rutErrorMessage: string = '';
  rutBackup = '';
  disabledField = false

  get rutRules () {
    return [
      (v: string) => this.required ? !!v || 'El campo es requerido' : true,
      (v: string) => !v || v.length >= 8 || 'Rut debe tener mínimo 8 caracteres',
    ]
  }

  @Watch('value', { immediate: true })
  async onValueChange (newVal: string) {
    if (!newVal?.length) {
      this.rutNumber = ''
      this.verifierDigit = ''
      return
    }

    const [rut, dv] = newVal?.split('-')
    const isSameRut = rut === this.rutNumber

    this.rutNumber = rut || ''
    this.verifierDigit = isSameRut ? this.verifierDigit || dv : ''

    if (this.rutNumber.length >= 8) {
      await this.validateRut()
    }
    if (this.rutNumber.length === 7) {
      this.rutNumber = '0' + this.rutNumber
      await this.validateRut()
    }
  }

  @Emit('input')
  emitRut () {
    return `${this.rutNumber}-${this.verifierDigit}`.toUpperCase()
  }

  async validateRut () {
    if (this.rutNumber.length < 8 && this.rutNumber.length < 7) {
      this.rutErrorMessage = 'Rut debe tener mínimo 8 caracteres'
      return
    }

    if (this.rutNumber.length === 7) {
      this.rutNumber = '0' + this.rutNumber
    }

    if (this.rutBackup?.length && this.rutBackup === this.rutNumber) {
      return
    }

    this.rutBackup = this.rutNumber
    this.rutLoading = true

    try {
      const response = await this.consultRut({
        rut: this.rutNumber,
        personType: this.personType,
      })

      if (response.length) {
        this.verifierDigit = response.split('-')[1]
        this.rutErrorMessage = ''
        this.emitRut()
        this.$emit('blur')
      } else {
        this.rutErrorMessage = 'RUT inválido o sin dígito verificador'
      }
    } catch (error) {
      this.rutErrorMessage = 'Error al validar el RUT'
    } finally {
      this.rutLoading = false
    }
  }

  get personData () {
    const { id, rutNumber, verifierDigit } = this

    return {
      id,
      rut: `${rutNumber}-${verifierDigit}`,
    }
  }

  @Watch('personData', { immediate: true, deep: true })
  async onIdChange (newVal) {
    if (!newVal?.id) return

    const personAuto = await this.fetchData({
      query: { name: 'fetchLite', model: 'Person', params: { id: newVal.id } },
      force: true,
    })

    if (personAuto?.uid?.toUpperCase() === newVal?.rut?.toUpperCase()) {
      this.disabledField = Boolean(personAuto?.autos?.length)
    }
  }
  }
