
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { fixAmountFormatted, fixPrice } from '@/utils/general'
  import { Legend } from '../Components/Legend'
  import { fixColor } from '@/utils/dashboards'
  import { channelColors } from '@/utils/channelColors'
  import { financingColors } from '@/utils/financingColors'

@Component
  export default class DonutChart extends BaseChart {
  @Prop() pathToCategory: string[]
  @Prop({ default: () => ['description'] }) pathToCategoryTitle: string[]
  @Prop({ default: undefined, type: Function }) detailElementsGenerator
  @Prop({ default: undefined }) pathToValue
  @Prop({ default: undefined }) pathToSubcategory: string[]
  @Prop({ default: undefined, type: Function }) dataTransformer
  @Prop({ default: undefined, type: Function }) filteringMethod
  @Prop({ default: 'categoría', type: String }) categoryName
  @Prop({ default: false, type: Boolean }) sortCategories
  @Prop({ default: true, type: Boolean }) sortLegends

  @Prop({ default: false, type: Boolean }) smallChart
  @Prop({ default: false, type: Boolean }) horizontal

  categories: Legend[] = []

  // Methods
  async getData () {
    if (!this.parsedRecords?.length) return
    if (this.debugging) console.log(this.records)

    const { pathToCategory, pathToValue, getObjectAttribute, dataTransformer, getCategories, isCurrency, filteringMethod, detailElementsGenerator } = this
    let records = this.parsedRecords

    if (dataTransformer) {
      records = await dataTransformer(records)
      if (this.debugging) console.log('AFTER TRANSFORM DATA', records)
    }
    if (filteringMethod) {
      records = records.filter(this.filteringMethod)
      if (this.debugging) console.log('AFTER FILTERING DATA', records)
    }
    const categories = getCategories(records, pathToCategory, this.categoryName, this.debugging)
    if (this.debugging) console.log('CATEGORIES', categories)

    this.categories = categories.map((category): Legend => {
      const filteredByCategory = records.filter(record => getObjectAttribute(record, pathToCategory)?.id === category?.id)

      let value = 0
      if (pathToValue) {
        filteredByCategory.forEach(record => {
          const sumValue = getObjectAttribute(record, pathToValue)
          value += sumValue || 0
        })
      } else {
        value = filteredByCategory.length
      }
      const name = getObjectAttribute(category, this.pathToCategoryTitle)
      const color = category.metadata?.color ? category.metadata?.color : category.color ? fixColor(category.color) : (channelColors[category.name] || financingColors[name])

      return {
        name,
        color,
        order: category.order,
        value,
        records: this.pathToSubcategory ? filteredByCategory : undefined,
        pathToSubcategory: this.pathToSubcategory,
        subElements: detailElementsGenerator ? filteredByCategory.map(detailElementsGenerator) : undefined,
        pathToValue: this.pathToSubcategory ? this.pathToValue : undefined,
      }
    })
    if (this.sortCategories) {
      this.Debug('SORTING CATEGORIES')
      this.categories = this.categories.sort((prev, next) => prev.order - next.order)
    }

    if (this.debugging) console.log('PROCESSED CATEGORIES', this.categories)

    this.series = this.categories.map(item => item.value || 0)
    this.chartOptions = {
      labels: this.categories.map(item => item.name),
      colors: this.categories.map(item => item.color),
      title: {
        align: 'center',
        style: {
          fontFamily: 'Poppins',
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      plotOptions: {
        pie: {
          donut: {
            size: '65%',
          },
        },
      },
      yaxis: {
        labels: {
          formatter (val) {
            if (val >= 1000000) {
              return isCurrency ? `${fixPrice(val / 1000000)}M` : `${fixAmountFormatted(val / 1000000)}M`
            } else if (isCurrency) {
              return fixPrice(val)
            } else {
              return fixAmountFormatted(val)
            }
          },
        },
      },
    }
  }

  // Getters

  // Watchers
  @Watch('records', { immediate: true, deep: true })
  async onDataChange () {
    this.processingData = true
    await this.getData()
    this.processingData = false
  }
  }

