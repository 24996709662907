
  import { Component } from 'vue-property-decorator'
  import SupervisorDashboard from './SupervisorDashboard'
  import { bankColors } from '@/utils/bankColors'
  import { Criteria } from '@/components/dashboards/Generics/Criteria'

@Component
  export default class SupervisorTreasury extends SupervisorDashboard {
    financialCriterias: Criteria[] = [
      {
        name: 'Stock propio',
        color: '#6185DB',
        filter: record => record.stock,
      },
      {
        name: 'Bancos',
        color: '#13CE66',
        filter: record => record.bankAccount?.bank,
      },
    ]

    // Methods
    beforeCreate () {
      this.getData = async () => {
        this.$set(this.chartData.currentMonth, 'dailyAccountBalance', await this.getDailyAccountBalance(this.currentMonthFilters.startEndDate))
        this.$set(this.chartData.currentMonth, 'dailyStock', await this.getDailyStock(this.currentMonthFilters.dailyStock))
        this.$set(this.chartData.prevMonth, 'dailyAccountBalance', await this.getDailyAccountBalance(this.prevMonthFilters.startEndDate))
        this.$set(this.chartData.prevMonth, 'dailyStock', await this.getDailyStock(this.prevMonthFilters.dailyStock))
        this.$set(this.chartData.lastYear, 'dailyAccountBalance', await this.getDailyAccountBalance(this.lastYearFilters.startEndDate))
        this.$set(this.chartData.lastYear, 'dailyStock', await this.getDailyStock(this.lastYearFilters.dailyStock))
      }
    }

    transformDailyBalance (records) {
      return records.map(record => {
        if (record.stock) {
          return {
            ...record,
            date: record.reportDate + 'T12:00:00',
            type: {
              id: 999,
              description: 'Stock propio',
              name: 'stock',
              color: '#6185DB',
            },
            bankAccount: {
              id: 999,
              description: 'Stock propio',
              name: 'stock',
              color: '#6185DB',
            },
            accountBalance: record.stock.stockDetail.totalCost,
          }
        }
        const { date, bankAccount } = record
        const { bank, accountNumber } = bankAccount
        const bankAlias = bankAccount.bank.alias

        return {
          ...record,
          date: date + 'T12:00:00',
          type: {
            id: 998,
            description: 'Bancos',
            name: 'banks',
            color: '#13CE66',
          },
          bankAccount: {
            ...bankAccount,
            description: `${bankAlias} / ${accountNumber}`,
            color: bankAccount.metadata?.color || bankColors[bankAlias],
            bank: {
              ...bank,
              description: bankAlias,
            },
          },
        }
      }).sort((prev, next) => next.bankAccount.accountNumber - prev.bankAccount.accountNumber)
    }

    filterPurchases (record) {
      return record.stock.stockDetail.acquisitionType.name === 'purchase'
    }

    // Getters
    get bankAccountsData () {
      return {
        currentMonth: {
          records: [
            ...(this.chartData.currentMonth?.dailyAccountBalance?.records ? this.chartData.currentMonth?.dailyAccountBalance?.records : []),
            ...(this.chartData.currentMonth?.dailyStock?.records ? this.chartData.currentMonth?.dailyStock?.records.filter(this.filterPurchases) : []),
          ],
        },
        prevMonth: {
          records: [
            ...(this.chartData.prevMonth?.dailyAccountBalance?.records ? this.chartData.prevMonth?.dailyAccountBalance?.records : []),
            ...(this.chartData.prevMonth?.dailyStock?.records ? this.chartData.prevMonth?.dailyStock?.records.filter(this.filterPurchases) : []),
          ],
        },
        lastYear: {
          records: [
            ...(this.chartData.lastYear?.dailyAccountBalance?.records ? this.chartData.lastYear?.dailyAccountBalance?.records : []),
            ...(this.chartData.lastYear?.dailyStock?.records ? this.chartData.lastYear?.dailyStock?.records.filter(this.filterPurchases) : []),
          ],
        },
      }
    }

  // Watchers
  }
