import gql from 'graphql-tag'
import { QueryBuilder } from '../utils'

import { SaleOrder } from '@/entities/sales'
import { ChannelFragment, DealFragment, LeadFragment, PipelineFragment } from '@/graphql/fragments/crm'
import { PriceFragment, ReserveFragment, SaleOrderFragment } from '@/graphql/fragments/sales'
import { BaseAutoFragment } from '@/graphql/fragments/public'
import { VersionYearFragment } from '@/graphql/fragments/vehicle'
import { AcquisitionTypeFragment, ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'
import { EmployeeFragment } from '@/graphql/fragments/hr'
import { EntityFields } from '@/graphql/fragments'

const query = gql`
  ${SaleOrderFragment}
  ${DealFragment}
  ${LeadFragment}
  ${BaseAutoFragment}
  ${VersionYearFragment}
  ${ProcessStatusFragment}
  ${PipelineFragment}
  ${ChannelFragment}
  ${PriceFragment}
  ${EmployeeFragment}
  ${ReserveFragment}
  ${ClosingReasonFragment}
  ${AcquisitionTypeFragment}
  query sales($filter: sales_sale_order_bool_exp, $order: [sales_sale_order_order_by!], $limit: Int, $offset: Int) {
    records: sales_sale_order(where: $filter, order_by: $order, limit: $limit, offset: $offset) {
      ${EntityFields}
      deliveryDate:delivery_date
      duration
      financings {
        id
        evaluations {
          id
          agreement {
            id
            person{
              id
              alias
            }
          }
          offers {
            id
            fee

          }
          closingReason:closing_reason {
            ...ClosingReason
          }
        }
      }
      saleOrderItems: sale_order_items(order_by: {item_type: {id: asc}}) {
        ...SaleOrderItem
      }
      closingReason: closing_reason {
        ...ClosingReason
      }
      reserve {
        ...Reserve
      }
      validator {
        id
        person {
          ...Person
        }
      }
      status {
        ...ProcessStatus
      }
      deal {
        id
        channel {
          ...Channel
        }
        auto{
          ...AutoBase
          version: version_year {
            ...VersionYear
          }
          generation{
            id
          }
          status: process_status {
            ...ProcessStatus
          }
        }
        lead {
          id
          executive{
            id
            person{
              ...Person
            }
          }
          client {
            ...Person
          }
          pipeline {
            ...Pipeline
          }
        }
        stock{
          id
          prices(order_by: {expiration: asc_nulls_first}, limit: 2) {
            ...Price
          }
          viewDetails: stock_detail{
            purchaseAmount:purchase_amount
            enablementCost:enablement_cost
            acquisitionType: acquisition_type{
              ...AcquisitionType
            }
            executive{
              id
              person{
                ...Person
              }
            }
          }
        }
      }
    }
    total: sales_sale_order_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const sales = QueryBuilder(query, SaleOrder)
export default sales
