
  import { channelColors } from '@/utils/channelColors'
  import { deepCopy, fixPrice, localToUtc, utcToLocal } from '@/utils/general'
  import dayjs from 'dayjs'
  import { Component, Prop } from 'vue-property-decorator'
  import StaffDashboard from './StaffDashboard'

@Component
  export default class StaffSalesPurchases extends StaffDashboard {
  @Prop({ default: true, type: Boolean }) showGeneralResults

// Methods
  beforeCreate () {
    this.getData = async () => {
      await this.getGeneralResults()
      this.$set(this.specificChartData.currentMonth, 'leads', await this.getLeadsAggregate(this.specificCurrentMonthFilters.leads))
      this.$set(this.specificChartData.currentMonth, 'purchases', await this.getPurchases(this.specificCurrentMonthFilters.stockCreatedDate))
      this.$set(this.specificChartData.currentMonth, 'sales', await this.getSales(this.specificCurrentMonthFilters.startEndDate))
      this.$set(this.specificChartData.currentMonth, 'reserves', await this.getReserves(this.specificCurrentMonthFilters.startEndDate))
      this.$set(this.specificChartData.currentMonth, 'soldPurchases', await this.getPurchases(this.specificCurrentMonthFilters.soldPurchases))
      this.$set(this.specificChartData.currentMonth, 'soldSales', await this.getSoldSales(this.specificCurrentMonthFilters.soldSales))
      this.$set(this.specificChartData.currentMonth, 'utilityCredits', await this.getUtilityCredits(this.specificCurrentMonthFilters.utilityCredits))
      this.$set(this.specificChartData.currentMonth, 'signedCredits', await this.getLoanEvaluationsAggregates(this.specificCurrentMonthFilters.signedCredits))
      this.$set(this.specificChartData.currentMonth, 'inspectionLeads', await this.getLeads(this.specificCurrentMonthFilters.inspectionLeadDetail))
      this.$set(this.specificChartData.currentMonth, 'evaluations', await this.getFinancing(this.specificCurrentMonthFilters.loanApplications))
      this.$set(this.specificChartData.currentMonth, 'approvedCredits', await this.getLoanEvaluationsAggregates(this.specificCurrentMonthFilters.approvedCredits))
      this.$set(this.chartData.currentMonth, 'approvedCredits', await this.getLoanEvaluationsAggregates(this.currentMonthFilters.approvedCredits))

      this.$set(this.specificChartData.lastYear, 'purchases', await this.getPurchases(this.specificLastYearFilters.stockCreatedDate))
      this.$set(this.specificChartData.lastYear, 'sales', await this.getSales(this.specificLastYearFilters.startEndDate))
      this.$set(this.specificChartData.lastYear, 'signedCredits', await this.getLoanEvaluationsAggregates(this.specificLastYearFilters.signedCredits))
      this.$set(this.chartData.currentMonth, 'stock', await this.getStock(this.currentMonthFilters.stockSpecs))
    }
  }

  fixPurchaseFields (records) {
    const corrected = deepCopy(records)
    for (let i = 0; i < corrected.length; i++) {
      const { auto, executive } = corrected[i]
      const carFullName = `${auto.versionYear.version.model.brand.name} ${auto.versionYear.version.model.name}`
      const executiveFullName = `${executive.person.firstName.trim()} ${executive.person.surname.trim()}`
      corrected[i].stockCreated = dayjs(corrected[i].stockCreated).format('DD/MM/YY')
      corrected[i].executive.fullName = executiveFullName
      corrected[i] = {
        ...corrected[i],
        carFullName,
        executiveFullName,
      }
    }

    return corrected
  }

  fixCreditFields (records) {
    const corrected = deepCopy(records)
    for (let i = 0; i < corrected.length; i++) {
      const { auto, executive } = corrected[i]
      const carFullName = `${auto.versionYear.version.model.brand.name} ${auto.versionYear.version.model.name}`
      const executiveFullName = `${executive.person.firstName.trim()} ${executive.person.surname.trim()}`
      corrected[i].signedDate = dayjs(corrected[i].signedDate).format('DD/MM/YY - HH:mm')
      corrected[i].executive.fullName = executiveFullName
      corrected[i] = {
        ...corrected[i],
        carFullName,
        executiveFullName,
      }
    }

    return corrected
  }

    fixSaleFields (records) {
      const corrected = deepCopy(records)
      for (let i = 0; i < corrected.length; i++) {
        const { autoDescription, year, executive, executiveName } = corrected[i]
        const carFullName = `${autoDescription} ${year}`
        corrected[i].soldDate = dayjs(corrected[i].soldDate).format('DD/MM/YY - HH:mm')
        corrected[i].executive = executive ? {
          id: executive,
          name: executiveName.toUpperCase(),
        } : undefined
        corrected[i] = {
          ...corrected[i],
          carFullName,
          saleAmount: fixPrice(corrected[i].saleAmount),
        }
      }

      return corrected
    }

  setChannelColors (records) {
    const corrected = deepCopy(records)
    for (let i = 0; i < corrected.length; i++) {
      const { channel } = corrected[i]
      channel.color = channelColors[channel.name]
      corrected[i].channel = channel
    }
    return corrected
  }

  transformUtility (records) {
    const { getObjectAttribute } = this
    const { start, end } = this.lastYearDates

    const recordsCopy = deepCopy(records)

    for (let i = 0; i < recordsCopy.length; i++) {
      this.$set(recordsCopy[i], 'records', recordsCopy[i].records?.records?.nodes || recordsCopy[i].records?.records || recordsCopy[i].records?.records || recordsCopy[i].records || [])
    }

    const purchasesRecords = recordsCopy?.filter(record => record.name === 'Compras')[0]
    const salesRecords = recordsCopy?.filter(record => record.name === 'Ventas')[0]
    const creditsRecords = recordsCopy?.filter(record => record.name === 'Créditos')[0]

    if (!purchasesRecords || !salesRecords || !creditsRecords) return

    let newDate = dayjs(start)
    const limitRange = [newDate]
    while (newDate.isBefore(dayjs(end))) {
      newDate = newDate.add(1, 'month')
      if (newDate.isBefore(dayjs(end))) {
        limitRange.push(newDate)
      }
    }
    // Directa
    const directUtility = limitRange.map(date => {
      const sales = salesRecords.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, salesRecords.pathToDate))).isSame(date, 'month'))
      const purchases = purchasesRecords.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, purchasesRecords.pathToDate))).isSame(date, 'month'))
      let utility = 0
      sales.forEach(sale => utility += getObjectAttribute(sale, salesRecords.pathToValue) || 0)
      purchases.forEach(purchase => utility -= getObjectAttribute(purchase, purchasesRecords.pathToValue) || 0)
      return {
        date: localToUtc(date).format('YYYY-MM-DDTHH:mm:ss'),
        utility,
      }
    })
    // Creditos
    const creditsUtility = limitRange.map(date => {
      const credits = creditsRecords.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, creditsRecords.pathToDate))).isSame(date, 'month'))
      let utility = 0
      credits.forEach(credit => utility += getObjectAttribute(credit, creditsRecords.pathToValue))
      return {
        date: date.format('YYYY-MM-DD'),
        utility,
      }
    })

    return [
      {
        name: 'Directa',
        records: directUtility,
        color: '#FAD901',
        pathToDate: ['date'],
        pathToValue: ['utility'],
        type: 'bar',
      },
      {
        name: 'Créditos',
        records: creditsUtility,
        color: '#7D67BC',
        pathToDate: ['date'],
        pathToValue: ['utility'],
        type: 'bar',
      },
    ]
  }

  sortByStockCreated (records) {
    return records.sort((prev, next) => {
      if (dayjs(prev.stockCreated).isBefore(dayjs(next.stockCreated))) {
        return 1
      } else if (dayjs(prev.stockCreated).isAfter(dayjs(next.stockCreated))) {
        return -1
      }
      return 0
    })
  }

  sortByScheduled (records) {
    return records.sort((prev, next) => {
      const prevDate = dayjs(prev.scheduledDate)
      const nextDate = dayjs(next.scheduledDate)

      if (prevDate.isBefore(nextDate)) {
        return 1
      } else if (prevDate.isAfter(nextDate)) {
        return -1
      }
      return 0
    })
  }

  addAcquisitionTypeBooleans (records) {
    const pipelines = ['purchase', 'renewal', 'financed_renovation']
    return records
      .filter(record => pipelines.includes(record.pipeline.name))
      .map(record => {
        const deals = record.deals
        const dealAcquisitionTypes = deals.map(deal => {
          const appraisals = deal.appraisals
          const appraisalsAcquisitionTypes = appraisals.map(appraisal => {
            const lastAppraisedResponse = appraisal?.lastAppraisedResponse
            // const lastAppraisedConsignmentResponse = appraisal?.lastAppraisedConsignmentResponse
            const appraisalAgreedAmount = Number(appraisal?.agreedAmount)
            const purchaseAmount = Number(lastAppraisedResponse?.amount)
            // const consignmentAmount = lastAppraisedConsignmentResponse?.amount

            const negotiation = appraisal.inspection?.negotiation
            const negotiationAgreedAmount = negotiation?.agreedAmount

            const comparisonAmount = negotiationAgreedAmount || appraisalAgreedAmount

            const responses = appraisal.responses

            const isConsignment = comparisonAmount ? (comparisonAmount > purchaseAmount) : responses.some(response => response.responseType.name === 'expected_offer_consignment')
            const isPurchase = !isConsignment

            return { isConsignment, isPurchase }
          })
          return {
            isConsignment: appraisalsAcquisitionTypes.some(appraisal => appraisal.isConsignment),
            isPurchase: !appraisalsAcquisitionTypes.some(appraisal => appraisal.isConsignment),
          }
        })

        const isConsignment = dealAcquisitionTypes.some(deal => deal.isConsignment)

        return {
          ...record,
          isConsignment,
          isPurchase: !isConsignment,
        }
      })
  }

  getLeadAppraisals (records) {
    const convertedRecords = this.addAcquisitionTypeBooleans(records)

    return convertedRecords
      .map(lead => {
        const appraisals = []
        const isConsignment = lead.isConsignment
        lead.deals?.forEach(deal => {
          deal.appraisals?.forEach(appraisal => {
            appraisals.push({ ...appraisal, isConsignment })
          })
        })
        return appraisals
      })
      .flat()
      .filter(appraisal => appraisal)
  }

  getLeadInspections (records) {
    const appraisals = this.getLeadAppraisals(records)
    return appraisals
      .map(appraisal => {
        const inspection = appraisal.inspection
        if (!inspection) return
        const { isConsignment } = appraisal
        return {
          ...inspection,
          isConsignment,
        }
      })
      .filter(inspection => inspection)
  }

  async normaliceData (records) {
    const leads = await this.getLeads(this.currentMonthFilters.inspectionLeadDetail)
    const inspections = this.getLeadInspections(leads.records)
    const Ids = inspections.filter(inspection => inspection.isConsignment).map(inspection => inspection.id)
    return records.map(record => {
      const {
        executive,
        executiveId,
        autoDescription,
        year,
        inspector,
        inspectorId,
        closingReason,
        status,
        scheduledDate,
        closingReasonDate,
      } = record
      const attributes = [
        'executive',
        'executiveId',
        'autoDescription',
        'year',
        'inspector',
        'inspectorId',
        'status',
        'scheduledDate',
        'closingReasonDate',
      ]
      const corrected = deepCopy(record)
      attributes.forEach(attribute => delete corrected[attribute])
      return {
        ...corrected,
        car: `${autoDescription} ${year}`,
        executive: {
          id: executiveId,
          name: executive?.toUpperCase() || 'SIN EJECUTIVO',
          scheduledDate: dayjs(localToUtc(scheduledDate)).format('DD/MM/YYYY'),
        },
        inspector: {
          id: inspectorId,
          name: inspector?.toUpperCase() || 'SIN INSPECTOR',
          closingReasonDate: dayjs(localToUtc(closingReasonDate)).format('DD/MM/YYYY'),
        },
        status: closingReason || status,
        subtitleIcon: Ids.includes(corrected.inspectionId)
          ? {
            icon: 'mdi-car-key',
            text: 'Consignación',
          } : undefined,
      }
    })
  }

  normaliceReserves (records) {
    return records.map(record => {
      const { executive, executiveName } = record
      record.executive = executive ? {
        id: executive,
        name: executiveName.toUpperCase(),
      } : undefined
      record.deliveryDate = utcToLocal(dayjs(record.deliveryDate)).format('DD/MM/YY HH:mm')
      return {
        ...record,
        reserveAmount: fixPrice(record.reserveAmount),
        createdAt: utcToLocal(record.createdAt).format('DD/MM/YYYY'),
      }
    })
  }

  // Getters
  get targetMultiplier () {
    const executives = this.chartData?.settings?.executives?.records
    return 1.2 / (executives?.length || 1)
  }

  // Watchers
  }
