import { ResourceHeaders } from '..'
import {
  AppraisalStatusHeader,
  BusinessHeader,
  ChannelHeader,
  ContactHeader,
  DateHeader,
  IdHeader,
  PdfPurchaseHeader,
  PdfResponsabilityHeader,
  PersonHeader,
  PriceHeader,
  VehicleHeader,
} from '../../headers'
import { Helpers } from '@/views/datatables/resources/helpers'
import { Lead } from '@/entities/crm'
import { Process } from '@/entities/settings'
import { PurchaseOrder } from '@/entities/purchase'

export const headers: ResourceHeaders = [
  {
    ...IdHeader,
    text: 'Nº',
    value: '@',
    options: {
      expandOff: true,
      action: {
        edit: Helpers.linkedModel,
      },
    },
  },
  { ...VehicleHeader, text: 'Vehículo', value: 'negotiation.auto' },
  { ...DateHeader, text: 'Creación', value: 'created' },
  { ...BusinessHeader, text: 'Negocio ', value: 'negotiation.inspection.appraisal.deal.lead.pipeline' },
  {
    ...PersonHeader,
    text: 'Cliente',
    value: '@',
    options: {
      path: 'negotiation.client.name',
      displayPhone: true,
      trigger: ({
        negotiation: {
          inspection: { appraisal: { deal: { lead: { client } } } },
        },
      }: PurchaseOrder) => Helpers.linkedModel(client),
      action: {
        always: true,
        color: 'green',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: Lead, process: Process, system: string) => Helpers.contact(item, 'client', process, system),
      },
      disabledMissingProperty: ['negotiation.client.phone'],
    },
  },
  { ...ChannelHeader, text: 'Canal', value: 'negotiation.inspection.appraisal.deal.channel' },
  { ...AppraisalStatusHeader, text: 'Estado compra', value: '@' },
  {
    ...ContactHeader,
    text: 'Validador',
    sortable: false,
    value: '@',
    options: {
      disabledActionMissingProperty: {
        key: 'validator',
      },
      path: 'validator.name',
      action: {
        always: true,
        color: 'blue',
        icon: 'mdi-whatsapp',
        messaging: true,
        trigger: (item: PurchaseOrder, process: Process, system: string) => Helpers.contact(item, 'validator', process, system),
      },
      disabledMissingProperty: ['validator.phone'],
    },
  },
  { ...PriceHeader, text: 'Precio autorizado', value: 'authorizePriceWithOutFormat' },
  { ...PriceHeader, text: 'Precio de compra', value: 'price' },
  // { ...TextHeader, text: 'Acreedor de prenda', value: '', width: 160 },
  {
    ...PdfResponsabilityHeader,
    text: 'Carta de responsabilidad',
    value: '@',
  },
  {
    ...PdfPurchaseHeader,
    text: 'Nota de compra',
    value: '@',
  },
]

export default function (): ResourceHeaders {
  return headers
}
