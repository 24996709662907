
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'

@Component
  export default class AlertFinancierCell extends Cell {
  icons = []; // Lista de íconos que se mostrarán en la interfaz

  setIcons = {
    // Definición de los íconos con propiedades: ícono, color, fondo y descripción (tooltip)
    liquidations: {
      icon: 'mdi-file-document',
      color: 'blue',
      background: 'white',
      tooltip: 'Liquidaciones',
    },
    proofOfAddress: {
      icon: 'mdi-home',
      color: 'green',
      background: 'white',
      tooltip: 'Comprobante de domicilio',
    },
    AFPStatement: {
      icon: 'mdi-bank',
      color: 'red',
      background: 'white',
      tooltip: 'Cartola de AFP',
    },
    taxFolder: {
      icon: 'mdi-folder',
      color: 'orange',
      background: 'white',
      tooltip: 'Carpeta tributaria',
    },
    paymentReceipts: {
      icon: 'mdi-cash',
      color: 'purple',
      background: 'white',
      tooltip: 'Boletas de honorarios',
    },
  };

  idProcess = null

  async mounted () {
    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'labor_data' } },
    }))[0]
    await this.addIcons(process.id)
  }

  async addIcons (idProcess) {
    if (!this.item?.financing?.applicant?.id) return
    const laborData = await this.fetchData({
      query: { name: 'find', model: 'LaborData' },
      filter: { id_person: { _eq: this.item.financing.applicant.id } },
    })

    await Promise.all(laborData.map(async data => {
      const files = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: data.id } },
            { parameter: { process: { id: { _eq: idProcess } } } },
          ],
        },
        force: true,
      })
      if (this.someConditionForTaxFolder(files)) {
        this.icons.push(this.setIcons.taxFolder)
      }

      if (this.someConditionForLiquidations(files)) {
        this.icons.push(this.setIcons.liquidations)
      }

      if (this.someConditionForAFPStatement(files)) {
        this.icons.push(this.setIcons.AFPStatement)
      }
      if (files.length) {
        console.log(files.map(file => `${file.parameter.name} - ${file.parameter.description}`))
      }
    }))

    // Ejemplo de condiciones para agregar íconos a la lista
    if (this.someConditionForProofOfAddress()) {
      this.icons.push(this.setIcons.proofOfAddress)
    }

    if (this.someConditionForPaymentReceipts()) {
      this.icons.push(this.setIcons.paymentReceipts)
    }
  }

  // Condición para verificar si se debe mostrar el ícono de liquidaciones
  someConditionForLiquidations (files) {
    return files?.some(file => file.parameter.name === 'latest_settlements')
  }

  // Condición para el ícono de comprobante de domicilio
  someConditionForProofOfAddress () {
    return false // Cambiar según la lógica
  }

  // Condición para el ícono de cartola AFP
  someConditionForAFPStatement (files) {
    return files?.some(file => file.parameter.name === 'afp_card')
  }

  // Condición para el ícono de carpeta tributaria
  someConditionForTaxFolder (files) {
    return files?.some(file => file.parameter.name === 'tax_folder')
  }

  // Condición para el ícono de boletas de honorarios
  someConditionForPaymentReceipts () {
    return false // Cambiar según la lógica
  }
  }
