
  import { deepCopy, fixAmountFormatted, fixPrice } from '@/utils/general'
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Legend } from './Legend'

@Component({})
  export default class ChartExpansibleLegend extends Vue {
  @Prop() legends: Legend[]
  @Prop({ default: false, type: Boolean }) isCurrency
  @Prop({ default: 'total', type: String }) totalSufix
  @Prop() pathToSubcategory: string[]
  @Prop({ default: false, type: Boolean }) filterZeroLegends
  @Prop({ default: false }) refChart
  @Prop({ default: true, type: Boolean }) sort

  toggleSerie (serie) {
    if (!this.refChart) return
    const notHide = [serie, serie + ' (mes anterior)']
    this.legends.forEach(legend => {
      if (notHide.includes(legend.name)) {
        this.refChart.showSeries(legend.name)
        this.refChart.showSeries(legend.name + ' (mes anterior)')
      } else {
        this.refChart.hideSeries(legend.name)
        this.refChart.hideSeries(legend.name + ' (mes anterior)')
      }
    })
  }

  getSubcategories (records: Record<string, any>, path: string[], pathToValue: string[]): Legend[] {
    if (!records) return
    const subcategories = this.getCategories(records, path)
    return subcategories.map(subcategory => {
      let value = 0
      const filteredByCategory = records.filter(record => this.getObjectAttribute(record, path)?.id === subcategory?.id)
      if (pathToValue) {
        filteredByCategory.forEach(record => {
          value += this.getObjectAttribute(record, pathToValue) || 0
        })
      } else {
        value = filteredByCategory.length
      }
      return {
        name: subcategory?.description || subcategory?.name,
        color: subcategory?.color || '#5184F2',
        value,
      }
    })
      .filter(subcategory => subcategory.name)
      .sort((prev, next) => {
        return next.value - prev.value
      })
  }

  getCategories (records: Record<string, any>, path: string[]): Record<string, any> {
    const categories = []
    records.forEach(record => {
      const category = this.getObjectAttribute(record, path)
      const containsCategory = categories.map(category => category?.id).includes(category?.id)
      if (!containsCategory) {
        categories.push(category)
      }
    })
    return categories
  }

  getObjectAttribute (object, path: string[]): any {
    const pathCopy = deepCopy(path)
    if (pathCopy.length > 0) {
      const attribute = pathCopy.shift()
      const returnValue = object[attribute]
      if (returnValue) {
        return this.getObjectAttribute(returnValue, pathCopy)
      } else {
        return undefined
      }
    }
    return object
  }

  formatValue (value: number): string {
    if (this.isCurrency) {
      return fixPrice(value)
    }
    return fixAmountFormatted(value)
  }

  get sortedLegends () {
    const { legends } = this
    return legends
      .filter(legend => this.filterZeroLegends ? legend.value !== 0 : true)
      .sort((prev, next) => this.sort ? next.value - prev.value : 0)
  }

  get total () {
    let sum = 0
    this.legends.forEach(legend => sum += legend.value)
    return sum
  }
  }
