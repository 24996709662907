
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'
  import { Period, periods } from '@/store/resources/period'

@Component({
  methods: {
    ...mapActions('resources', ['setPeriod']),
  },
  computed: {
    periods: () => periods,
    ...mapGetters('resources', ['period']),
  },
})
  export default class PeriodSelector extends Vue {
  private period!: Period
  private periods!: Array<Period>
  private setPeriod!: (period: Period) => void

  mounted () {
    const { $route: { query: { period, search } } } = this

    if (period) {
      const currentPeriod = periods.find(p => p.name === period)
      this.setPeriod(currentPeriod)
    }

    if (search && period !== 'last_12_months') {
      const newPeriod = periods.find(p => p.name === 'last_12_months')
      this.setPeriod(newPeriod)
    }
  }

  @Watch('period', { immediate: true })
  onPeriodChange (val, prev) {
    const query = this.$route.query
    if ((val?.name && !query?.period) || (query?.period !== val.name && prev)) {
      const query = Object.assign({}, this.$route.query, { period: val.name })
      this.$router.replace({ query }).catch(() => {
      })
    }
  }
  }
