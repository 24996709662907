
  import { Component, Watch } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import { VCheckbox, VTextField } from 'vuetify/lib/components'
  import GTextarea from '@/components/core/input/GTextarea.vue'
  import _ from 'lodash'
  import { Process } from '@/entities/settings'
  import { isValidNumber } from '@/utils/general'

@Component({
  components: {
    GTextarea,
    VCheckbox,
    VTextField,
  },
})
  export default class SystemRules extends GForm {
  process: Process[] = []
  selected = null
  search = null
  panelsOpen = [0]
  panelsOpenSection = [0]
  formData = {
    process: null as any,
    values: {} as Record<string, any>,
  }

  get topLevelFields () {
    const { process } = this.formData
    const fieldsArray = process?.config?.fields || []
    return fieldsArray.filter((field: any) => !field.sections && !field.messaging)
  }

  get sectionFields () {
    const { process } = this.formData
    const fieldsArray = process?.config?.fields || []
    return fieldsArray.filter((field: any) => field.sections || field.messaging)
  }

  get filteredProcess () {
    if (!this.search?.length) {
      const lastProcess = this.formData.process
      this.selected = this.process.findIndex(p => p.id === lastProcess.id)
      return this.process
    }

    return this.process.filter((p: Process) => p.description.toLowerCase().includes(this.search?.toLowerCase()))
  }

  async mounted () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process', order: { order: 'asc' } },
      filter: { config: { _is_null: false } },
      force: true,
    })

    this.process = process.filter((p: any) => p.config && p.config.messaging && p.config.fields)
    this.formData.process = this.process[0]
    this.selected = 0
    if (this.formData.process) {
      this.initializeFields()
    }
  }

  initializeFields () {
    const { process } = this.formData
    if (!process?.config?.fields) return
    this.formData.values = this.initializeRecursive(process.config.fields)
  }

  initializeRecursive (fields: any[]): Record<string, any> {
    const values: Record<string, any> = {}

    fields.forEach((field: any) => {
      this.processField(field, values)
    })

    return values
  }

  // Funciones auxiliares públicas
  processField (field: any, values: Record<string, any>) {
    // Procesar messaging.sections si existen
    if (field.messaging && field.messaging.sections) {
      this.processSections(field.messaging.sections, values)
    }

    // Procesar sections si existen
    if (field.sections) {
      this.processSections(field.sections, values)
    }

    // Procesar fields si existen
    if (field.fields && Array.isArray(field.fields)) {
      field.fields.forEach((fieldItem: any) => {
        this.processMsgField(fieldItem, values)
      })
    }

    // Procesar key si existe
    if (field.key) {
      values[field.key] =
        this.getValueFromConfig(field.key, this.formData.process.config) ??
        field.default ??
        null
    }
  }

  processSections (sections: Record<string, any>, values: Record<string, any>) {
    Object.values(sections).forEach((section: any) => {
      this.processSection(section, values)
    })
  }

  processSection (section: any, values: Record<string, any>) {
    if (section.messages && Array.isArray(section.messages)) {
      section.messages.forEach((message: any) => {
        this.processMessage(message, values)
      })
    }
  }

  processMessage (message: any, values: Record<string, any>) {
    if (message.fields && Array.isArray(message.fields)) {
      message.fields.forEach((msgField: any) => {
        this.processMsgField(msgField, values)
      })
    }
  }

  processMsgField (msgField: any, values: Record<string, any>) {
    if (msgField.key) {
      values[msgField.key] =
        this.getValueFromConfig(msgField.key, this.formData.process.config) ??
        msgField.default ??
        null
    }
  }

  getFieldProps (field: any) {
    const props: Record<string, any> = {}
    props.label = field.label || ''
    props.default = field.default || null
    switch (field.component) {
      case 'g-textarea':
        props.rows = 10
        props.counter = 1024
        break
      case 'v-text-field':
        props.outlined = true
        props.dense = true
        break
      case 'v-checkbox':
        props.hideDetails = true
        break
      case 'v-json-editor':
        props.mode = 'code'
        props.theme = 'material'
        break
      default:
        break
    }
    return props
  }

  getValueFromConfig (key: string, config: any): any {
    try {
      if (!key || typeof key !== 'string') {
        throw new Error(`Clave inválida: "${key}"`)
      }
      const keys = key.split(/\.|\[|\]/).filter(k => k)
      return keys.reduce(
        (obj, k) => (obj && obj[k] !== undefined ? obj[k] : undefined),
        config
      )
    } catch (error) {
      console.error(`Error al obtener el valor para la clave "${key}":`, error)
      return undefined
    }
  }

  async send () {
    // console.log('Datos enviados:', this.formData.values)

    const newConfig = _.cloneDeep(this.formData.process.config)
    for (const [key, value] of Object.entries(this.formData.values)) {
      _.set(newConfig, key, value)
    }

    // console.log('Nuevo config listo para guardar:', newConfig)
    await this.pushData({
      model: 'Process',
      fields: {
        id: this.formData.process.id,
        config: newConfig,
      },
    })
    await this.loadNotification({
      // @ts-ignore catch must be any or unknown interface
      message: `Proceso actualizado`,
      notificationType: 'success',
    })
  }

  @Watch('selected', { immediate: true })
  onSelectedChange (val) {
    if (!isValidNumber(val)) {
      this.selected = 0
      this.formData.process = this.process[0]
      return
    }

    this.formData.process = this.filteredProcess[val]
    this.initializeFields()
  }
  }
