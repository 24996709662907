
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { Legend } from '../Components/Legend'
  import { fixColor } from '@/utils/dashboards'
  import { Criteria } from './Criteria'
  import { fixAmountFormatted, fixPrice } from '@/utils/general'

@Component
  export default class DonutChartByCriteria extends BaseChart {
  @Prop({ default: undefined }) pathToValue
  @Prop({ default: undefined }) pathsToFallbackValues
  @Prop({ default: undefined }) pathToSubcategory: string[]
  @Prop({ default: undefined, type: Function }) dataTransformer
  @Prop() criterias: Criteria[]
  @Prop({ default: undefined, type: Function }) detailElementsGenerator
  @Prop({ default: undefined, type: Function }) filteringMethod
  @Prop({ default: true, type: Boolean }) sortLegends

  @Prop({ default: false, type: Boolean }) smallChart
  @Prop({ default: false, type: Boolean }) horizontal

  categories: Legend[] = []

  // Methods
  async getData () {
    if (this.debugging) console.log('RECORDS', this.records)
    if (!this.parsedRecords) return

    const { pathToValue, getObjectAttribute, dataTransformer, detailElementsGenerator, filteringMethod, pathsToFallbackValues, isCurrency } = this
    let records = this.parsedRecords

    if (dataTransformer) {
      records = await dataTransformer(records)
    }

    if (filteringMethod) {
      records = await records.filter(filteringMethod)
    }

    this.categories = this.criterias.map((criteria): Legend => {
      const filteredByCriteria = records.filter(criteria.filter)

      let value = 0
      if (pathToValue) {
        filteredByCriteria.forEach(record => {
          let sumValue = getObjectAttribute(record, pathToValue)
          if (!sumValue && pathsToFallbackValues) {
            for (let i = 0; i < pathsToFallbackValues?.length; i++) {
              sumValue = getObjectAttribute(record, pathsToFallbackValues[i])
              if (sumValue) {
                i = pathsToFallbackValues.length
              }
            }
          }
          value += sumValue || 0
        })
      } else {
        value = filteredByCriteria.length
      }
      return {
        name: criteria.name,
        color: fixColor(criteria.color),
        value,
        records: this.pathToSubcategory ? filteredByCriteria : undefined,
        pathToSubcategory: this.pathToSubcategory,
        subElements: detailElementsGenerator ? filteredByCriteria.map(detailElementsGenerator) : undefined,
        pathToValue,
      }
    })

    this.Debug('CATEGORIES', this.categories)

    this.series = this.categories.map(item => item.value || 0)
    this.chartOptions = {
      labels: this.categories.map(item => item.name),
      colors: this.categories.map(item => item.color),
      title: {
        align: 'center',
        style: {
          fontFamily: 'Poppins',
        },
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      plotOptions: {
        pie: {
          donut: {
            size: '65%',
          },
        },
      },
      yaxis: {
        labels: {
          formatter (val) {
            if (val >= 1000000) {
              return isCurrency ? `${fixPrice(val / 1000000)}M` : `${fixAmountFormatted(val / 1000000)}M`
            } else if (isCurrency) {
              return fixPrice(val)
            } else {
              return fixAmountFormatted(val)
            }
          },
        },
      },
    }
  }

  // Getters

  // Watchers
  @Watch('records', { immediate: true, deep: true })
  async onDataChange () {
    this.processingData = true
    await this.getData()
    this.processingData = false
  }
  }

