var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"mt-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Desarrollo de reservas último mes"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQueries([
                {category: 'currentMonth', field: 'reserves', queryMethod: _vm.getReserves, queryFilter: _vm.currentMonthFilters.startEndDate},
                {category: 'prevMonth', field: 'reserves', queryMethod: _vm.getReserves, queryFilter: _vm.prevMonthFilters.startEndDate},
              ])
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQueries([\n                {category: 'currentMonth', field: 'reserves', queryMethod: getReserves, queryFilter: currentMonthFilters.startEndDate},\n                {category: 'prevMonth', field: 'reserves', queryMethod: getReserves, queryFilter: prevMonthFilters.startEndDate},\n              ])\n            }"}],attrs:{"records":_vm.chartData.currentMonth.reserves,"prev-month-records":_vm.chartData.prevMonth.reserves,"criterias":[
              {
                name: 'Creada',
                color: '#FF9E2C',
                filter: record => true,
              },
              {
                name: 'Vigente',
                color: '#6185DB',
                filter: record => !record.closingReason,
              },
              {
                name: 'Anulada',
                color: '#F03738',
                filter: record => record.closingReason?.type?.name === 'closed_deal',
              },
              {
                name: 'Concretada',
                color: '#13CE66',
                filter: record => record.closingReason?.type?.name === 'successful',
              },
            ],"path-to-date":['createdAt'],"data-transformer":_vm.normaliceReservesNoDate,"type":"line","dates":_vm.dates,"show-total":false,"sort-legends":"","accumulated":""}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('dashboard-card',{attrs:{"title":"Desarrollo de reservas último año"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('line-chart-by-criteria',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if (!isIntersecting) return
              _vm.submitQuery({category: 'lastYear', field: 'reserves', queryMethod: _vm.getReserves, queryFilter: _vm.lastYearFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if (!isIntersecting) return\n              submitQuery({category: 'lastYear', field: 'reserves', queryMethod: getReserves, queryFilter: lastYearFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.lastYear.reserves,"prev-month-records":[],"criterias":[
              {
                name: 'Creada',
                color: '#FF9E2C',
                filter: record => true,
              },
              {
                name: 'Vigente',
                color: '#6185DB',
                filter: record => !record.closingReason,
              },
              {
                name: 'Anulada',
                color: '#F03738',
                filter: record => record.closingReason?.type?.name === 'closed_deal',
              },
              {
                name: 'Concretada',
                color: '#13CE66',
                filter: record => record.closingReason?.type?.name === 'successful',
              },
            ],"path-to-date":['createdAt'],"data-transformer":_vm.normaliceReservesNoDate,"type":"line","dates":_vm.lastYearDates,"show-total":false,"sort-legends":"","monthly":""}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Reservas creadas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: _vm.getReservesQuery, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: getReservesQuery, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.reservesQuery,"records-settings":_vm.chartData.settings.reserve,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceReservesQuery,"path-to-category":['executive'],"path-to-category-title":['name']}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Reservas vigentes"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: _vm.getReservesQuery, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: getReservesQuery, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.reservesQuery,"records-settings":_vm.chartData.settings.reserve,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceReservesQuery,"path-to-category":['executive'],"path-to-category-title":['name'],"filtering-criteria":record => !record.closingReason,"use-total-as-target":""}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Reservas anuladas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: _vm.getReservesQuery, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: getReservesQuery, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.reservesQuery,"records-settings":_vm.chartData.settings.reserve,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceReservesQuery,"path-to-category":['executive'],"path-to-category-title":['name'],"filtering-criteria":record => record.closingReason?.type?.name === 'closed_deal',"use-total-as-target":""}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('dashboard-card',{attrs:{"title":"Reservas concretadas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail-by-category',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: _vm.getReservesQuery, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reservesQuery', queryMethod: getReservesQuery, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"records":_vm.chartData.currentMonth.reservesQuery,"records-settings":_vm.chartData.settings.reserve,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceReservesQuery,"path-to-category":['executive'],"path-to-category-title":['name'],"filtering-criteria":record => record.closingReason?.type?.name === 'successful',"use-total-as-target":""}})]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('dashboard-card',{attrs:{"title":"Detalle de reservas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reserves', queryMethod: _vm.getReserves, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reserves', queryMethod: getReserves, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"sorting-method":_vm.sortByCreated,"records":_vm.chartData.currentMonth.reserves,"records-settings":_vm.chartData.settings.reserve,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceReserves,"path-to-details":[
              {
                name: ['executive', 'name'],
                path: ['createdAt'],
              },
              {
                name: 'Monto de reserva',
                path: ['reserveAmount'],
              },
              {
                name: 'Fecha de entrega',
                path: ['deliveryDate'],
              },
            ],"path-to-title":['autoDescription'],"path-to-subtitle":['registrationPlate'],"path-to-subtitle-icon":['subtitleIcon']}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('dashboard-card',{attrs:{"title":"Detalle de reservas vigentes"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reserves', queryMethod: _vm.getReserves, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reserves', queryMethod: getReserves, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"sorting-method":_vm.sortByCreated,"records":_vm.chartData.currentMonth.reserves,"records-settings":_vm.chartData.settings.reserve,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceReserves,"path-to-details":[
              {
                name: ['executive', 'name'],
                path: ['createdAt'],
              },
              {
                name: 'Monto de reserva',
                path: ['reserveAmount'],
              },
              {
                name: 'Fecha de entrega',
                path: ['deliveryDate'],
              },
            ],"path-to-title":['autoDescription'],"path-to-subtitle":['registrationPlate'],"path-to-subtitle-icon":['subtitleIcon'],"filtering-criteria":record => !record.closingReason,"comparison-sufix":"creadas","use-total-as-target":""}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('dashboard-card',{attrs:{"title":"Detalle de reservas anuladas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reserves', queryMethod: _vm.getReserves, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reserves', queryMethod: getReserves, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"sorting-method":_vm.sortByCreated,"records":_vm.chartData.currentMonth.reserves,"records-settings":_vm.chartData.settings.reserve,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceReserves,"path-to-details":[
              {
                name: ['executive', 'name'],
                path: ['createdAt'],
              },
              {
                name: 'Monto de reserva',
                path: ['reserveAmount'],
              },
              {
                name: 'Fecha de entrega',
                path: ['deliveryDate'],
              },
            ],"path-to-title":['autoDescription'],"path-to-subtitle":['registrationPlate'],"filtering-criteria":record => record.closingReason?.type?.name === 'closed_deal',"comparison-sufix":"creadas","path-to-subtitle-icon":['subtitleIcon'],"use-total-as-target":""}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('dashboard-card',{attrs:{"title":"Detalle de reservas concretadas"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('record-detail',{directives:[{name:"intersect",rawName:"v-intersect",value:((entries, observer, isIntersecting) => {
              if(!isIntersecting) return
              _vm.submitQuery({category: 'currentMonth', field: 'reserves', queryMethod: _vm.getReserves, queryFilter: _vm.currentMonthFilters.startEndDate})
            }),expression:"(entries, observer, isIntersecting) => {\n              if(!isIntersecting) return\n              submitQuery({category: 'currentMonth', field: 'reserves', queryMethod: getReserves, queryFilter: currentMonthFilters.startEndDate})\n            }"}],attrs:{"sorting-method":_vm.sortByCreated,"records":_vm.chartData.currentMonth.reserves,"records-settings":_vm.chartData.settings.reserve,"target-field":"quantity","executives":_vm.chartData.settings.executives,"data-transformer":_vm.normaliceReserves,"path-to-details":[
              {
                name: ['executive', 'name'],
                path: ['createdAt'],
              },
              {
                name: 'Monto de reserva',
                path: ['reserveAmount'],
              },
              {
                name: 'Fecha de entrega',
                path: ['deliveryDate'],
              },
            ],"path-to-title":['autoDescription'],"path-to-subtitle":['registrationPlate'],"filtering-criteria":record => record.closingReason?.type?.name === 'successful',"comparison-sufix":"creadas","path-to-subtitle-icon":['subtitleIcon'],"use-total-as-target":""}})]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }