import { ResourceHeaders } from '..'
import {
  CreateLinkedRecordHeader,
  IdHeader,
  PhotoHeader,
  PriceHeader,
  StockCharacteristicsHeader,
  TextHeader,
  VehicleHeader,
  VehicleStatusHeader,
} from '../../headers'
import { Helpers } from '../../helpers'
import { extendsObj } from '@/utils/vuetify/helpers'
import { TableCellHeader } from '@/components/dataTables/cell'

export const headers: ResourceHeaders = [
  extendsObj<TableCellHeader>({
    text: 'Nº',
    value: '@',
    width: 130,
  }, IdHeader),
  { ...PhotoHeader, text: 'Foto', value: '@' },
  extendsObj<TableCellHeader>({
    text: 'Stock',
    value: '@',
    options: {
      path: 'auto.name',
      trigger: item => Helpers.linkedModel({ constructor: { name: 'StockPublish' }, ...item }),
    },
  }, VehicleHeader),
  { ...VehicleStatusHeader, text: 'Estado de stock', value: 'stockStatus' },
  { ...StockCharacteristicsHeader, text: 'Característica', value: 'auto' },
  { ...TextHeader, text: 'Kilometraje', value: 'kms' },
  { ...TextHeader, text: 'Dueños', value: 'owners' },
  { ...PriceHeader, text: 'Pie 20%', value: 'pieTwenty' },
  { ...PriceHeader, text: 'Pie 40%', value: 'pieForty' },
  {
    ...CreateLinkedRecordHeader,
    text: 'Facebook',
    value: 'buttonCreateLead',
    options: {
      tooltip: 'Crear Lead',
      action: {
        color: 'primary',
        icon: 'mdi-facebook',
        only: true,
        trigger: item => Helpers.linkedModel(item, { channel: 'facebook' }),
      },
    },
  },
  {
    ...CreateLinkedRecordHeader,
    text: 'Instagram',
    value: 'buttonCreateLead',
    options: {
      tooltip: 'Crear Lead',
      action: {
        color: 'red',
        icon: 'mdi-instagram',
        only: true,
        trigger: item => Helpers.linkedModel(item, { channel: 'instagram' }),
      },
    },
  },
  {
    ...CreateLinkedRecordHeader,
    text: 'Crear lead',
    value: 'buttonCreateLead',
    options: {
      tooltip: 'Crear Lead',
      action: {
        color: 'purple',
        icon: 'mdi-plus',
        only: true,
        trigger: item => Helpers.linkedModel(item),
      },
    },
  },
]

export default function (): ResourceHeaders {
  return headers
}
