
  import { Component, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'

  import { toRouteRecord } from '@/router/helpers'
  import { Resource } from '@/entities/public'
  import { Filter } from '@/entities/public/Resource/interfaces'
  import BaseBar from '@/layouts/BaseBar'

@Component({
  methods: {
    ...mapActions('resources', ['setActive', 'setFilter']),
  },
  computed: {
    ...mapGetters('resources', ['all', 'active']),
    ...mapGetters('resources/datatables', ['datatableLoading']),
  },
})
  export default class AppBar extends BaseBar {
  private active!: Resource;
  private all!: Array<Resource>;
  private setActive!: (resource?: Resource) => void;
  private setFilter!: (filter?: Filter) => void;

  datatableLoading!: boolean;

  resource = -1;
  option = -1;

  created () {
    const { $route: { params: { resource } }, targets } = this
    this.resource = (resource && targets) ? targets.map(({ slug }) => slug).indexOf(resource) : 0

    const { $route: { query: { filter } }, filters } = this
    this.option = (filter && filters) ? filters.map(({ name }) => name).indexOf(filter as string) : 0

    if (this.$route?.query?.search) {
      this.option = filters.findIndex(({ name }) => name === 'all')
    }
  }

  changeTab () {
    const query = this.$route.query
    const sortBy = query.sortBy

    if (query.search) {
      delete query.search
    }

    if (sortBy) {
      delete query.sortBy
    }

    this.$router.replace({ query }).catch(() => {
    })

    this.option = 0
  }

  get targets (): Array<Resource> {
    const { $route, all } = this
    const { name } = toRouteRecord($route)
    return all.filter(({ route }) => route?.name && route?.name === name)
  }

  get target (): Resource | undefined {
    const { resource, targets } = this
    if (!targets) return undefined

    return targets[resource]
  }

  get filters (): Array<Filter> | undefined {
    const { target } = this
    if (!target) return undefined

    return target.filters
  }

  get activeFilter () {
    const { option, filters } = this
    if (!filters) return undefined

    return filters[option]
  }

  @Watch('$route.query.search', { immediate: true })
  onSearchChange (val) {
    if (val) {
      this.option = this.filters?.findIndex(({ name }) => name === 'all') || 0
    }
  }

  @Watch('target')
  onTargetChange (resource: Resource, prev: Resource) {
    if (!resource || prev === resource) return
    const query = Object.assign({}, this.$route.query)
    this.$router.replace({ path: resource.path, query }).catch(() => {
    })
    this.setActive(resource)
    this.setFilter()
  }

  @Watch('activeFilter')
  onFilterChange (filter: Filter, prev: Filter) {
    if (!filter || prev === filter) return
    const query = Object.assign({}, this.$route.query, { filter: filter.name })
    this.$router.replace({ query }).catch(() => {
    })

    this.setFilter(filter)
  }

  @Watch('isResize', { immediate: true })
  onResizeChange (val) {
    this.resize = val
  }

  @Watch('active', { immediate: true, deep: true })
  onActiveChange (val) {
    const findPos = this.targets?.findIndex(({ id }) => id === val.id)
    const query = this.$route.query?.search
    if (findPos !== -1 && this.resource !== findPos && query) {
      this.resource = findPos
      this.option = this.filters?.[this.filters.length - 1] ? this.filters.length - 1 : 0
    } else if (findPos !== -1 && this.resource !== findPos) {
      this.resource = findPos
      this.option = 0
    }
  }
  }
