
  import { Prop, Watch } from 'vue-property-decorator'
  import Component from 'vue-class-component'
  import { deepCopy } from '@/utils/general'
  import { BaseChart } from '../baseChart'

interface Detail {
  title: string,
  value: string,
  complement: string,
}

interface IRecordDetail {
  title: string,
  subtitle: string,
  subtitleIcon?: { icon: string, text: string };
  details: Detail[]
  color: string,
}

interface PathToDetail {
  name: string | Array<string>,
  path: string[],
  pathToComplement?: string[],
}

@Component({})
  export default class RecordDetail extends BaseChart {
  @Prop() recordsSettings: Record<string, any>
  @Prop() targetField: string
  @Prop({ default: undefined }) executives
  @Prop({ default: undefined, type: String }) icon // mdi-car-search
  @Prop({ default: undefined, type: Function }) dataTransformer
  @Prop() pathToDetails: PathToDetail[]
  @Prop() pathToTitle: string[]
  @Prop() pathToSubtitle: string[]
  @Prop({ default: undefined, type: Array }) pathToSubtitleIcon
  @Prop() pathToColor: string[]
  @Prop({ default: undefined }) filteringCriteria: Function
  @Prop({ default: false, type: Boolean }) useTotalAsTarget
  @Prop({ default: undefined, type: Function }) sortingMethod
  @Prop({ default: 'en total', type: String }) totalName
  @Prop({ default: false, type: Boolean }) showTotalComparison
  @Prop({ default: 0, type: Number }) defaultTarget
  @Prop({ default: undefined, type: Function }) totalFilter
  @Prop({ default: 'esperadas', type: String }) comparisonSufix

  data: IRecordDetail[] = []

  async getData () {
    if (!this.records) return
    this.Debug('RECORDS', this.records)
    this.Debug('RECORD SETTINGS', this.recordsSettings)

    const {
      filteringCriteria,
      getObjectAttribute,
      pathToDetails,
      pathToSubtitle,
      pathToTitle,
      pathToColor,
      sortingMethod,
      dataTransformer,
      pathToSubtitleIcon,
    } = this
    let records = deepCopy(this.records?.records?.aggregate?.nodes || this.records?.records?.nodes || this.records?.records?.aggregate || this.records.records || this.records || [])
    if (sortingMethod) {
      records = sortingMethod(records)
      this.Debug('AFTER SORTING DATA', records)
    }
    if (dataTransformer) {
      records = await dataTransformer(records)
      this.Debug('AFTER TRANSFORM DATA', records)
    }
    if (filteringCriteria) {
      records = records.filter(filteringCriteria)
      this.Debug('AFTER FILTERING DATA', records)
    }

    const data = records.map((record): IRecordDetail => {
      return {
        title: getObjectAttribute(record, pathToTitle)?.toUpperCase(),
        subtitle: getObjectAttribute(record, pathToSubtitle),
        subtitleIcon: pathToSubtitleIcon ? getObjectAttribute(record, pathToSubtitleIcon) : undefined,
        details: pathToDetails.map((path): Detail => {
          return {
            title: (typeof path.name) === 'string' ? path.name : getObjectAttribute(record, path.name as Array<string>) || 'SIN EJECUTIVO',
            value: getObjectAttribute(record, path.path),
            complement: path.pathToComplement ? getObjectAttribute(record, path.pathToComplement) : '',
          }
        }),
        color: pathToColor ? getObjectAttribute(record, pathToColor) : '#737373',
      }
    })
    this.data = data
  }

  get recordsCount () {
    return this.data.length || 0
  }

  get recordsTotal () {
    if (this.records) {
      const { records } = this
      const data = deepCopy(records?.records?.aggregate?.nodes || records?.records?.nodes || records?.records?.aggregate || records.records || records || [])
      return data.length
    }
    return 0
  }

  get recordsTarget () {
    if (this.useTotalAsTarget && this.records) {
      const { records, totalFilter } = this
      let data = deepCopy(records?.records?.aggregate?.nodes || records?.records?.nodes || records?.records?.aggregate || records.records || records || [])
      if (totalFilter) {
        data = data.filter(totalFilter)
      }
      return data.length
    }
    return Math.round((this.recordsSettings?.config?.projectedUtility?.[this.targetField])) || (this.useTotalAsTarget ? this.recordsTotal : this.defaultTarget) || 0
  }

  get percentage () {
    return (this.recordsCount / (this.recordsTarget !== 0 ? this.recordsTarget : 1)) * 100
  }

  get color () {
    return this.getBarColor(this.percentage)
  }

  get watchData () {
    const { records, recordsSettings } = this
    return {
      records,
      recordsSettings,
    }
  }

  @Watch('watchData', { immediate: true, deep: true })
  async updateData () {
    this.processingData = true
    await this.getData()
    this.processingData = false
  }
  }
