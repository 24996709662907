
  import { deepCopy, fixPrice, utcToLocal } from '@/utils/general'
  import dayjs from 'dayjs'
  import { Component } from 'vue-property-decorator'
  import SupervisorDashboard from './SupervisorDashboard'

@Component
  export default class SupervisorReserves extends SupervisorDashboard {
    // Methods
    beforeCreate () {
      this.getData = async () => {
        this.submitQuery({ category: 'currentMonth', field: 'reservesQuery', queryMethod: this.getReservesQuery, queryFilter: this.currentMonthFilters.startEndDate })
        this.submitQuery({ category: 'lastYear', field: 'reserves', queryMethod: this.getReserves, queryFilter: this.lastYearFilters.startEndDate })
      }
    }

    async mounted () {
      await this.getSettings()
    }

    sortByCreated (records) {
      return records.sort((prev, next) => {
        if (dayjs(prev.createdAt).isBefore(dayjs(next.createdAt))) {
          return 1
        } else if (dayjs(prev.createdAt).isAfter(dayjs(next.createdAt))) {
          return -1
        }
        return 0
      })
    }

    normaliceReservesQuery (records) {
      return records.map(record => {
        const { executive } = record.saleOrder.deal.lead
        const person = executive?.person
        record.executive = person ? {
          id: executive.id,
          name: `${person.firstName} ${person.surname}`,
        } : undefined
        return record
      })
    }

    normaliceReserves (records) {
      const recordsCopy = deepCopy(records)
      return recordsCopy.map(record => {
        const { executive, executiveName } = record
        record.executive = executive ? {
          id: executive,
          name: executiveName.toUpperCase(),
        } : undefined
        record.deliveryDate = utcToLocal(dayjs(record.deliveryDate))?.format('DD/MM/YY HH:mm')
        return {
          ...record,
          reserveAmount: fixPrice(record?.reserveAmount),
          createdAt: utcToLocal(record?.createdAt)?.format('DD/MM/YYYY'),
          subtitleIcon: record.acquisitionType?.name === 'consignment'
            ? {
              icon: 'mdi-car-key',
              text: 'Consignación',
            } : undefined,
        }
      })
    }

    normaliceReservesNoDate (records) {
      const recordsCopy = deepCopy(records)
      return recordsCopy.map(record => {
        const { executive, executiveName } = record
        record.executive = executive ? {
          id: executive,
          name: executiveName.toUpperCase(),
        } : undefined
        record.deliveryDate = utcToLocal(dayjs(record.deliveryDate))?.format('DD/MM/YY HH:mm')
        return {
          ...record,
          reserveAmount: fixPrice(record?.reserveAmount),
          subtitleIcon: record.acquisitionType?.name === 'consignment'
            ? {
              icon: 'mdi-car-key',
              text: 'Consignación',
            } : undefined,
        }
      })
    }

  // Getters

  // Watchers
  }
