
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import AdvanceSearch from './AdvanceSearch.vue'
  import { FilterField } from '@/entities/public/Resource/interfaces'
  import { mapGetters } from 'vuex'
  import { Resource } from '@/entities/public'

@Component({
  components: {
    AdvanceSearch,
  },
  computed: {
    ...mapGetters('resources', ['active']),
    ...mapGetters('resources/datatables', ['datatableLoading']),
  },
})
  export default class MainSearch extends Vue {
  @Prop({ type: String, default: '' }) errorMessage!: string
  @Prop({ type: Array, default: [] }) routerSearch!: Array<{ key: string, value: string }>

  private active!: Resource

  menu: boolean = false
  switchVal = false
  selectedFilters: any[] = []
  datatableLoading!: boolean;

  get filterFields (): FilterField[] {
    const { active } = this

    return active?.metadata?.filterFields || []
  }

  get withOutFilter () {
    return !this.filterFields.length
  }

  removeFilter (index: number) {
    this.selectedFilters.splice(index, 1)
    this.$emit('search', { filter: this.selectedFilters, condition: this.switchVal })
  }

  onCancel () {
    this.menu = false
  }

  onSearch (filterFields: FilterField[]) {
    const activeFilters = filterFields.filter(
      field => field.value !== undefined && field.value !== null && field.value !== ''
    )

    this.selectedFilters = activeFilters
      .map(field => {
        const processedFilter = this.active.metadata.replaceSearchInFilter(
          field.filter,
          field.value
        )
        if (processedFilter !== null) {
          return {
            label: field.label,
            value: field.value,
            filter: processedFilter,
          }
        }
        return null
      })
      .filter(filter => filter !== null)

    this.$emit('search', { filter: this.selectedFilters, condition: this.switchVal })
    this.menu = false
  }

  onChipInput (value: any) {
    this.selectedFilters = value.map((chip: any) => chip.value)
    this.$emit('search', { filter: this.selectedFilters, condition: this.switchVal })
  }

  @Watch('routerSearch', { immediate: true, deep: true })
  onRouterSearchChange (val) {
    if (!val?.length) return

    this.selectedFilters = val.filter(Boolean)
      .map(searchItem => {
        // Recorrer todas las propiedades de searchItem (propiedad dinámica)
        return Object?.entries(searchItem)?.map(([key, value]) => {
          // Buscar el campo correspondiente en la lista de filtros predefinidos
          const fields = this.filterFields.filter(f => {
            const finalKeys = this.extractMatchingKeysFromArray(value, f.fieldName)

            return f.fieldName.includes(key) || finalKeys.map(k => k.key).includes(f.fieldName)
          })

          let field

          if (fields.length === 1) {
            field = fields[0]
          } else {
            field = fields.find(f => f.filter?.[0]?.[key])
          }

          if (field) {
            const valueProcessed = this.extractFinalValue(value)
            return {
              label: field?.label,
              value: valueProcessed,
              filter: this.active.metadata.replaceSearchInFilter(
                field.filter,
                valueProcessed
              ),
            }
          }
          return null
        })
      })
      .flat()// Aplanar la lista de arrays
      .filter(Boolean)
  }

  extractFinalValue (value) {
    // Caso base: si no es un objeto, simplemente retorna el valor
    if (typeof value !== 'object' || value === null) {
      if (typeof value === 'string' && value.includes('%')) {
        return value?.replaceAll('%', '')
      }

      return value
    }

    // Obtener la clave y el valor del primer par de la entrada
    const entries = Object.entries(value)
    if (entries.length === 0) return null // No hay nada que recorrer

    // Extraer la clave y el valor
    // eslint-disable-next-line
    const [key, nestedValue] = entries[0]
    // Llamada recursiva para continuar con la búsqueda en el valor anidado
    return this.extractFinalValue(nestedValue)
  }

  extractMatchingKeysFromArray (dataArray, fieldName) {
    // Si el dato no es un array, lo convertimos a un array para que la lógica funcione de forma uniforme
    if (!Array.isArray(dataArray)) {
      dataArray = [dataArray]
    }

    return dataArray
      .map(item => this.extractFinalKey(item, fieldName)) // Extrae la clave final de cada elemento
      .filter(Boolean) // Elimina resultados nulos o undefined
  }

  extractFinalKey (obj, fieldName, parentKey = null) {
    if (typeof obj !== 'object' || obj === null) {
      return null
    }

    const keys = Object.keys(obj)

    // Caso base: si hay una sola clave y no tiene objetos anidados
    if (keys.length === 1 && typeof obj[keys[0]] !== 'object') {
      // Verificamos si la clave única coincide con fieldName (por ejemplo, 'first_name')
      return keys[0] === fieldName ? { owner: parentKey, key: keys[0] } : null
    }

    let result = null

    for (const key of keys) {
      const value = obj[key]

      // Caso base: Si la clave actual coincide con fieldName
      if (key === fieldName) {
        return { owner: parentKey, key } // Devolvemos la relación clave-padre
      }

      // Recursivamente buscar en el valor (que podría ser un objeto)
      result = this.extractFinalKey(value, fieldName, key)
      if (result) return result // Si se encuentra la clave, se devuelve la relación
    }

    return result
  }
  }
